import { IconButton, Tooltip, useTheme } from '@mui/material';
import { IconArrowBackUp, IconRotateClockwise2, IconX } from '@tabler/icons';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { QueueDashboardEvents, WidgetTitleColor } from '../constants';

export const lengthOfTimeLabel = 'length of time';

export function getQueueDashboardRefreshDataEventObj() {
    return new CustomEvent(QueueDashboardEvents.RefreshWidgetData, {
        bubbles: false,
        detail: { time: new Date() }
    });
}

export function getRefreshingWidgetDataAnimateIcon(id) {
    return (
        <AnimateButton type="rotate">
            <Tooltip title="Fetching recent data">
                <span>
                    <IconButton id={`refresh${id}`} disabled size="small">
                        <IconRotateClockwise2 color={WidgetTitleColor} />
                    </IconButton>
                </span>
            </Tooltip>
        </AnimateButton>
    );
}
export function getExitPreviewButton(exitPreview) {
    return (
        <Tooltip title="Exit preview">
            <IconButton size="small" onClick={() => exitPreview()}>
                <IconX stroke={1.5} size="1.3rem" />
            </IconButton>
        </Tooltip>
    );
}

export function getPreviousLevelButton(drillLevelUp) {
    return (
        <Tooltip title="Go back to previous level">
            <IconButton size="small" onClick={() => drillLevelUp()}>
                <IconArrowBackUp stroke={1.5} size="1.3rem" />
            </IconButton>
        </Tooltip>
    );
}

export const DonutChartTooltip = (props) => {
    const theme = useTheme();
    const { active, payload } = props;
    if (active && payload && payload.length) {
        const payloadDetails = payload[0].payload;
        const label =
            payload[0].name !== 'Others'
                ? `${payload[0].name}: ${payloadDetails.Count} (${
                      payloadDetails.percent === 0 && payloadDetails.Count > 0 ? '< 1' : payloadDetails.percent
                  }%)`
                : `${payload[0].name}: ${payloadDetails.Count}`;
        return (
            <div
                className="custom-tooltip"
                style={{
                    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.background.paper,
                    padding: '5px',
                    border: '1px solid #cccc'
                }}
            >
                <p>{label}</p>
            </div>
        );
    }
    return null;
};

// update and return date time copy based on useDST value
export const getDateTimeWithDstAdjustment = (dateTimeFilter) => {
    const dateTimeFilterCopy = { ...dateTimeFilter };
    if (dateTimeFilterCopy?.useDst) {
        dateTimeFilterCopy.utcOffsetInMinutes += 60;
    }
    return dateTimeFilterCopy;
};
