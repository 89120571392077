import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    destinationSourceId: '',
    expireOption: 'None',
    deliveryOption: 'NeverSend',
    emailMessageContent: '',
    emailAddresses: [],
    importName: '',
    selectedDirImportProperties: [],
    selectedDirImportColumns: [],
    selectedDirImportFilters: [],
    selectedDirImportDefaults: [],
    isParamsSet: false,
    refreshRcData: true
};

const slice = createSlice({
    name: 'directoryImportParameters',
    initialState,
    reducers: {
        setImportParameters(state, action) {
            const {
                destinationSourceId,
                expireOption,
                deliveryOption,
                emailMessageContent,
                emailAddresses,
                importName,
                tenantDirImportProperties,
                tenantDirImportFilters,
                tenantDirImportDefaults
            } = action.payload;
            state.destinationSourceId = destinationSourceId;
            state.expireOption = expireOption;
            state.deliveryOption = deliveryOption;
            state.emailMessageContent = emailMessageContent;
            state.emailAddresses = emailAddresses;
            state.importName = importName;
            state.selectedDirImportProperties = tenantDirImportProperties;
            // state.selectedDirImportColumns = tenantDirImportColumns;
            state.selectedDirImportDefaults = tenantDirImportDefaults;
            state.selectedDirImportFilters = tenantDirImportFilters;
            state.isParamsSet = true;
            // state.refreshRcData = false;
        },
        resetImportParameters(state, action) {
            state.destinationSourceId = '';
            state.expireOption = 'None';
            state.deliveryOption = 'NeverSend';
            state.emailMessageContent = '';
            state.emailAddresses = [];
            state.importName = '';
            state.selectedDirImportProperties = [];
            state.selectedDirImportColumns = [];
            state.selectedDirImportFilters = [];
            state.selectedDirImportDefaults = [];
            state.refreshRcData = true;
        },
        updateDestinationSourceId(state, action) {
            state.destinationSourceId = action.payload;
            state.refreshRcData = false;
        },
        updateExpireOption(state, action) {
            state.expireOption = action.payload;
            state.refreshRcData = false;
        },
        updateDeliveryOption(state, action) {
            state.deliveryOption = action.payload;
            state.refreshRcData = false;
        },
        updateEmailMessageContent(state, action) {
            state.emailMessageContent = action.payload;
            state.refreshRcData = false;
        },
        updateEmailAddresses(state, action) {
            state.emailAddresses = action.payload;
            state.refreshRcData = false;
        },
        updateImportName(state, action) {
            state.importName = action.payload;
            state.refreshRcData = true;
        },
        updateSelectedDirImportProperties(state, action) {
            state.selectedDirImportProperties = action.payload;
            state.refreshRcData = true;
        },
        updateSelectedDirImportColumns(state, action) {
            state.selectedDirImportColumns = action.payload;
            state.refreshRcData = false;
        },
        updateSelectedDirImportFilters(state, action) {
            state.selectedDirImportFilters = action.payload;
            state.refreshRcData = false;
        },
        updateSelectedDirImportDefaults(state, action) {
            state.selectedDirImportDefaults = action.payload;
            state.refreshRcData = false;
        },
        setRefreshRcData(state, action) {
            state.refreshRcData = action.payload;
        }
    }
});

export default slice.reducer;

export function setImportParametersData(importData) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.setImportParameters(importData));
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function updateDestinationSourceIdData(data) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.updateDestinationSourceId(data));
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function updateExpireOptionData(data) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.updateExpireOption(data));
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function updateDeliveryOptionData(data) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.updateDeliveryOption(data));
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function updateEmailMessageContentData(data) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.updateEmailMessageContent(data));
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function updateEmailAddressesData(data) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.updateEmailAddresses(data));
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function updateImportNameData(data) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.updateImportName(data));
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function updateDirImportPropertiesData(data) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.updateSelectedDirImportProperties(data));
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function updateDirImportColumnsData(data) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.updateSelectedDirImportColumns(data));
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function updateDirImportFiltersData(data) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.updateSelectedDirImportFilters(data));
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function updateDirImportDefaultsData(data) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.updateSelectedDirImportDefaults(data));
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function resetDirImportParameters() {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.resetImportParameters());
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function setRefreshRcData(refresh) {
    return async (dispatch) => {
        dispatch(slice.actions.setRefreshRcData(refresh));
    };
}
