import { lazy } from 'react';
import { Outlet } from 'react-router-dom';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import CustomDashboardLayout from 'utils/CustomDashboardLayout';
import Loadable from 'ui-component/Loadable';
import IdleSessionTimer from 'utils/IdleSessionTimer';
import QueueDashboard from 'views/QueueDashboard';
import ReportLayout from 'layout/MainLayout/ReportLayout';

// sample page routing
const Dashboard = Loadable(lazy(() => import('views/dashboard')));
const Reports = Loadable(lazy(() => import('views/Reports')));
const ReportHistory = Loadable(lazy(() => import('views/ReportHistory')));
const ScheduledReport = Loadable(lazy(() => import('views/ScheduledReports')));
const ReportsCustomizationRoot = Loadable(lazy(() => import('views/Reports/ReportsCustomization/main')));
const ReportsCustomization = Loadable(lazy(() => import('views/Reports/ReportsCustomization')));
const ReportPreview = Loadable(lazy(() => import('views/Reports/ReportsPreview')));
const ReportRunNow = Loadable(lazy(() => import('views/Reports/ReportRunNow')));
const Logout = Loadable(lazy(() => import('views/pages/authentication/Logout')));
const Connectors = Loadable(lazy(() => import('views/Administration/Connectors')));
const ConnectorTemplate = Loadable(lazy(() => import('views/Administration/Connectors/ConnectorTemplate')));
const RemoteCollectionTemplate = Loadable(lazy(() => import('views/Administration/Connectors/RemoteCollectionTemplate')));
const QueueConfigurationBase = Loadable(lazy(() => import('views/Administration/Connectors/CallQueues/QueueConfigurationBase')));
const DirectoryImportTemplate = Loadable(lazy(() => import('views/Administration/Connectors/DirectoryImportTemplate')));
const DirectoryImportPreview = Loadable(lazy(() => import('views/Administration/Connectors/DirectoryImportPreview')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <Outlet />,
    children: [
        {
            path: '/logout',
            element: <Logout />
        },
        {
            path: '/',
            element: (
                <AuthGuard>
                    <IdleSessionTimer />
                    <MainLayout />
                </AuthGuard>
            ),
            children: [
                {
                    path: '/dashboard',
                    element: <CustomDashboardLayout />,
                    children: [
                        {
                            path: 'my-dashboard/:id',
                            element: <Dashboard />
                        },
                        {
                            path: 'queue-dashboard/:id',
                            element: <QueueDashboard />
                        }
                    ]
                },
                {
                    path: '/reporting/reports',
                    element: <Reports />
                },

                {
                    path: '/reporting/reports/customization',
                    element: <ReportsCustomizationRoot />,
                    children: [
                        {
                            path: '/reporting/reports/customization/:id',
                            element: <ReportsCustomization />
                        },
                        {
                            path: '/reporting/reports/customization/:id/edit',
                            element: <ReportsCustomization isEditMode />
                        },
                        {
                            path: '/reporting/reports/customization/:id/copy',
                            element: <ReportsCustomization isCopy />
                        },
                        {
                            path: '/reporting/reports/customization/:id/preview',
                            element: <ReportPreview />
                        },
                        {
                            path: '/reporting/reports/customization/:id/edit/preview',
                            element: <ReportPreview isEditMode />
                        },
                        {
                            path: '/reporting/reports/customization/:id/copy/preview',
                            element: <ReportPreview isCopy />
                        }
                    ]
                },
                {
                    path: '/reporting/report-history',
                    element: <ReportHistory />
                },
                {
                    path: '/reporting/scheduled-report',
                    element: <ScheduledReport />
                },
                {
                    path: '/administration/connectors',
                    element: <Connectors />
                },
                {
                    path: '/administration/connectors/connectorTemplate',
                    element: <ConnectorTemplate />
                },
                {
                    path: '/administration/connectors/connectorTemplate/:id',
                    element: <ConnectorTemplate isEditMode />
                },
                {
                    path: '/administration/connectors/RCTemplate',
                    element: <RemoteCollectionTemplate />
                },
                {
                    path: '/administration/connectors/RCTemplate/:id',
                    element: <RemoteCollectionTemplate isEditMode />
                },
                {
                    path: '/administration/connectors/connectorTemplate/:id/queues/:queueId',
                    element: <QueueConfigurationBase />
                },
                {
                    path: '/administration/connectors/DirectoryImport',
                    element: <DirectoryImportTemplate />
                },
                {
                    path: '/administration/connectors/DirectoryImport/:id',
                    element: <DirectoryImportTemplate isEditMode />
                }
            ]
        },
        {
            path: '/',
            element: (
                <AuthGuard>
                    <IdleSessionTimer />
                    <ReportLayout />
                </AuthGuard>
            ),
            children: [
                {
                    path: '/reporting/reports/:id/report',
                    element: <ReportRunNow />
                },
                {
                    path: '/reporting/reports/history/:id/report',
                    element: <ReportRunNow />
                },
                {
                    path: '/reporting/reports/customization/:id/report',
                    element: <ReportRunNow />
                },
                {
                    path: '/reporting/reports/customization/:id/edit/report',
                    element: <ReportRunNow />
                },
                {
                    path: '/reporting/reports/customization/:id/copy/report',
                    element: <ReportRunNow isCopy />
                },
                {
                    path: '/administration/connectors/DirectoryImport/:id/preview',
                    element: <DirectoryImportPreview />
                }
            ]
        }
    ]
};
export default MainRoutes;
