import React from 'react';

// material UI
import { Button, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useAuth from 'hooks/useAuth';

// project imports
import appInsightsConfig from 'utils/appInsights';
import axios from 'utils/axios';

const LegacyLinkSection = () => {
    const theme = useTheme();
    const { user, authInfo, loginType } = useAuth();
    let loginToken = JSON.parse(window.localStorage.getItem('loginToken'));
    const redirectURL = loginType === 'sql' ? process.env.REACT_APP_LEGACY_URL : process.env.REACT_APP_LEGACY_SSO_URL;
    // const url = loginToken ? `${redirectURL}?TransferToken=${encodeURIComponent(loginToken.transfer)}` : `${redirectURL}?TransferToken=""`;

    // // Construct the URL with transferToken and loginType
    // const urlWithParams = (loginToken) =>
    //     `${redirectURLBase}?TransferToken=${encodeURIComponent(loginToken?.transfer || '')}&loginType=${encodeURIComponent(loginType)}`;

    const formRef = React.useRef(null);
    const transferTokenRef = React.useRef(null);
    const loginTypeRef = React.useRef(null);

    const logLinkClick = async (event) => {
        event.preventDefault();
        if (loginType !== 'sql') {
            const response = await axios.post(`/users/auth/transfer`, authInfo).catch(() => {
                throw new Error(`Failed to retrieve the token`);
            });
            loginToken = response?.data;
            transferTokenRef.current.value = encodeURIComponent(loginToken?.transfer);
        }
        // add the event of Legacy link click to Azure App Insights
        if (appInsightsConfig?.appInsights) {
            appInsightsConfig.appInsights.trackEvent(
                {
                    name: 'Legacy link clicked'
                },
                {
                    username: user.userName,
                    client: user.clientId
                }
            );
        }

        formRef.current.target = '_blank';
        formRef.current.submit();
    };

    return (
        <Grid container alignItems="center" sx={{ pr: 1 }}>
            <form ref={formRef} action={redirectURL} method="POST" style={{ display: 'none' }}>
                <input type="hidden" name="TransferToken" ref={transferTokenRef} value={encodeURIComponent(loginToken?.transfer) || ''} />
                <input type="hidden" name="loginType" ref={loginTypeRef} value={encodeURIComponent(loginType)} />
            </form>
            <Grid item>
                <Button
                    onClick={logLinkClick}
                    sx={{
                        color: theme.palette.primary.main,
                        fontWeight: 400,
                        textDecoration: 'none',
                        background: 'none',
                        border: 'none',
                        padding: 0,
                        cursor: 'pointer'
                    }}
                >
                    Go to Infortel Classic
                </Button>
            </Grid>
        </Grid>
    );
};

export default LegacyLinkSection;
