import { useContext, useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import { IconDotsVertical, IconEdit, IconTrash } from '@tabler/icons';
import { DashboardStateActions, DashboardStateDispatchContext } from '../context/DashboardStateContext';

export default function EditWidgetMenu({ onEditClick, widgetId }) {
    const { dispatchDashboardStateAction } = useContext(DashboardStateDispatchContext);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleMenuBtnClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const deleteWidget = () => {
        dispatchDashboardStateAction({ type: DashboardStateActions.DeleteWidget, payload: { id: widgetId } });
        dispatchDashboardStateAction({ type: DashboardStateActions.SetHasUnsavedChanges, payload: true });
    };

    return (
        <>
            <IconButton title="Options" onClick={handleMenuBtnClick}>
                <IconDotsVertical id="menu-button" />
            </IconButton>
            <Menu
                id="options-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'menu-button'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <MenuItem
                    className="menu-item"
                    onClick={() => {
                        onEditClick();
                        handleClose();
                    }}
                >
                    <IconEdit style={{ marginRight: 16 }} />
                    Edit Widget
                </MenuItem>
                <MenuItem
                    className="menu-item"
                    onClick={() => {
                        deleteWidget();
                        handleClose();
                    }}
                >
                    <IconTrash color="#f00" style={{ marginRight: 16 }} />
                    Delete Widget
                </MenuItem>
            </Menu>
        </>
    );
}
