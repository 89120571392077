import moment from 'moment';

export const GUID_REGEX =
    '^(?:\\{{0,1}(?:[0-9a-fA-F]){8}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){12}\\}{0,1})$';

export const GUID_PLACEHOLDER = 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx';

export default function debounce(fn, delay) {
    let timer;

    return function (...args) {
        clearTimeout(timer);
        timer = setTimeout(() => {
            fn(...args);
        }, delay);
    };
}

export function sortDateHour(a, b) {
    if (a.indexOf(' ') === -1 && b.indexOf(' ') === -1) return parseInt(a, 10) - parseInt(b, 10);
    const [aDate, aHour] = a.split(' ');
    const [bDate, bHour] = b.split(' ');

    const aDateObj = new Date(aDate);
    const bDateObj = new Date(bDate);

    if (aDateObj < bDateObj) {
        return -1;
    }
    if (aDateObj > bDateObj) {
        return 1;
    }
    return parseInt(aHour, 10) - parseInt(bHour, 10);
}

export function sortMonthDate(a, b) {
    const aYear = parseInt(a.substring(0, 4), 10);
    const bYear = parseInt(b.substring(0, 4), 10);
    const aMonth = parseInt(a.substring(4), 10);
    const bMonth = parseInt(b.substring(4), 10);

    if (aYear < bYear) {
        return -1;
    }
    if (aYear > bYear) {
        return 1;
    }
    return aMonth - bMonth;
}

export function sortYearWeek(a, b) {
    const aYear = parseInt(a.substring(0, 4), 10);
    const bYear = parseInt(b.substring(0, 4), 10);
    const aWeekNum = parseInt(a.substring(4), 10);
    const bWeekNum = parseInt(b.substring(4), 10);

    if (aYear < bYear) {
        return -1;
    }
    if (aYear > bYear) {
        return 1;
    }
    return aWeekNum - bWeekNum;
}

export function validate63Days(startDate, endDate) {
    const start = moment(startDate);
    const end = moment(endDate);

    const daysDiff = end.diff(start, 'days');
    return daysDiff;
}

export function isDateValid(inputDate) {
    if (inputDate && inputDate.isValid() && inputDate.isAfter('1900-01-01')) {
        return true;
    }
    return false;
}
