// material-ui
import { Grid, Typography } from '@mui/material';

// assets
import { IconCircleX } from '@tabler/icons';

import { useLocation } from 'react-router-dom';

// project imports
import AuthWrapper1 from '../AuthWrapper1';
import AuthCardWrapper from '../AuthCardWrapper';
import AuthFooter from 'ui-component/cards/AuthFooter';
import MainCard from 'ui-component/cards/MainCard';
import NoAccess from 'views/pages/NoAccess';
import useRouteAccess from 'routes/useRouteAccess';

const RegisterRcFailure = () => {
    const hasAccessToCurrentRoute = useRouteAccess();
    const location = useLocation();
    const errorMessage = location.state?.error || 'Unknown error occurred';

    return (
        <AuthWrapper1>
            {!hasAccessToCurrentRoute ? (
                <MainCard>
                    <NoAccess hideDashboard />
                </MainCard>
            ) : (
                <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
                    <Grid item xs={12}>
                        <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                            <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                                <AuthCardWrapper>
                                    <Grid container spacing={2} flexDirection="column" alignItems="center" justifyContent="center">
                                        <Grid item xs={12} sx={{ pb: 1 }}>
                                            <IconCircleX size={80} color="#384055" stroke={1.5} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="h3" component="h1" sx={{ mt: 2 }}>
                                                Unable to Configure
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" component="h1" sx={{ mt: 2 }}>
                                                {errorMessage}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                                                Please try again
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </AuthCardWrapper>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ m: 3 }}>
                        <AuthFooter />
                    </Grid>
                </Grid>
            )}
        </AuthWrapper1>
    );
};

export default RegisterRcFailure;
