import { getTimeRange } from './format';
import axios from 'utils/axios';

export const FilterType = {
    input: 'Input',
    dropDown_SingleSelect: 'DropDown_SingleSelect',
    dropDown_MultiSelect: 'DropDown_MultiSelect',
    lookUp_SingleSelect: 'LookUp_SingleSelect',
    lookUp_MultiSelect: 'LookUp_MultiSelect',
    lookUp_MultiSelect_Input: 'LookUp_MultiSelect_Input',
    orgTree: 'OrgTree',
    duration: 'Duration'
};

export const OperatorType = {
    numeric: 'Numeric',
    alphaNumeric: 'AlphaNumeric',
    boolean: 'Boolean',
    money: 'Money'
};
export const CallOwnership = {
    Placed: 'Placed',
    Received: 'Received',
    Both: 'Both'
};

export function getOperators(filterName) {
    const operators = [
        { label: 'Includes', value: 'IN' },
        { label: 'Not Includes', value: 'NOT IN' }
    ];
    switch (filterName) {
        case 'DataSourceName':
            return [...operators, { label: 'like', value: 'LIKE' }, { label: 'Not like', value: 'NOT LIKE' }];
        case 'CallDirection':
        case 'CallDisposition':
            return operators;
        case 'ExtensionLocation':
        default:
            return [
                ...operators,
                { label: 'like', value: 'LIKE' },
                { label: 'not like', value: 'NOT LIKE' },
                { label: '<=', value: '<=' },
                { label: '>=', value: '>=' }
            ];
    }
}

export function getNonOrgBasedFilters() {
    return [
        {
            label: 'Data source',
            value: 'DataSourceName'
        },
        {
            label: 'Extension Location',
            value: 'ExtensionLocation'
        },
        {
            label: 'Call Direction',
            value: 'CallDirection'
        },
        {
            label: 'Call Disposition',
            value: 'CallDisposition'
        }
    ];
}

export function mapOrgNameToDesc(fldName, orgLevelDescriptions) {
    const orgInfo = orgLevelDescriptions?.find((org) => org.level === parseInt(fldName.slice(-1), 10));
    return orgInfo ? orgInfo.description : fldName;
}

export function readDisplayName(fldName, orgLevelDescriptions) {
    switch (fldName) {
        case 'DataSourceName':
            return 'Data source';
        case 'ExtensionLocation':
            return 'Extension Location';
        case 'CallDirection':
            return 'Call Direction';
        case 'CallDisposition':
            return 'Call Disposition';
        default:
            return mapOrgNameToDesc(fldName, orgLevelDescriptions);
    }
}

export const availableFiltersList = [
    {
        filterName: 'DataSourceName',
        displayName: 'Data Source',
        filterType: 'operatorWithLookup',
        lookUpColumns: [
            {
                dataField: 'value',
                caption: 'Data Source'
            },
            {
                dataField: 'id',
                caption: 'ID'
            }
        ],
        operators: [
            { label: 'Includes', value: 'IN' },
            { label: 'Not Includes', value: 'NOT IN' },
            { label: 'Like', value: 'LIKE' },
            { label: 'Not Like', value: 'NOT LIKE' }
        ]
    },
    {
        filterName: 'ExtensionLocation',
        displayName: 'Extension Location',
        filterType: 'operatorWithLookup',
        lookUpColumns: [
            {
                dataField: 'value',
                caption: 'Extension Location'
            },
            {
                dataField: 'dataSource',
                caption: 'Data Source'
            }
        ],
        operators: [
            { label: 'Includes', value: 'IN' },
            { label: 'Not Includes', value: 'NOT IN' },
            { label: 'Like', value: 'LIKE' },
            { label: 'Not Like', value: 'NOT LIKE' }
        ]
    },
    {
        filterName: 'CallDirection',
        displayName: 'Call Direction',
        filterType: 'multiSelection',
        operators: []
    },
    {
        filterName: 'CallDisposition',
        displayName: 'Call Disposition',
        filterType: 'multiSelection',
        operators: []
    },
    {
        filterName: 'Name{n}',
        displayName: 'Directory Filter',
        filterType: 'treeSelection',
        operators: []
    }
];

export function formatOrgDataForTree(orgList, orgLevelDescriptions, savedOrgFilters) {
    const usedLevels = orgLevelDescriptions.filter((desc) => !desc.description.toLowerCase().includes('unused'));

    // Recursive function to traverse and transform the organization tree
    const traverse = (org, parentSelected) => {
        const orgLevelName = `Name${org.orgLevel}`;
        const currentFilter = savedOrgFilters?.find((filter) => filter.fieldName === orgLevelName);

        // Skip org levels not found in usedLevels
        if (!usedLevels.find((l) => org.orgLevel === l.level)) {
            return org.children.map((childOrg) => traverse(childOrg, parentSelected)).flat();
        }

        let selected = false;
        if (currentFilter) {
            const isInFilter = currentFilter.value.split(';').includes(org.orgName);
            if (isInFilter && (parentSelected || !savedOrgFilters?.find((filter) => filter.fieldName === `Name${org.orgLevel + 1}`))) {
                selected = true;
            }
        }

        const items = org.children.map((childOrg) => traverse(childOrg, selected));

        const formattedOrg = {
            orgId: org.orgId,
            orgLevel: org.orgLevel,
            orgLevelDescription: usedLevels.find((l) => org.orgLevel === l.level)?.description,
            orgName: `${org.orgName}`,
            parentId: org.parentId,
            selected,
            expanded: items.some((item) => item.selected),
            items
        };

        return formattedOrg;
    };

    // Initial call to traverse the orgArray
    const result = orgList.map((org) => traverse(org, false)).flat();
    return result;
}

export function getStaticFilterValues(filterName) {
    switch (filterName) {
        case 'CallDirection':
            return [
                { label: 'Inbound', value: 'I' },
                { label: 'Internal', value: 'E' },
                { label: 'Outbound', value: 'O' },
                { label: 'Trunk to Trunk', value: 'T' }
            ];
        case 'CallDisposition':
            return [
                { label: 'Inbound Answered', value: 'IN' },
                { label: 'Outbound', value: 'OU' },
                { label: 'Inbound Abandoned', value: 'IA' },
                { label: 'Internal', value: 'EX' }
            ];
        default:
            return [];
    }
}

export async function getFilterLookUpData(ownerId, filterName) {
    try {
        let response;
        switch (filterName.toLowerCase()) {
            case 'datasourcename':
                response = await axios.get(`/sites?ownerId=${ownerId}`);
                return response?.data?.map((dataSource) => ({
                    label: dataSource.name,
                    value: dataSource.name,
                    id: dataSource.siteId
                }));
            case 'extensionlocation':
                response = await axios.get(`/locations`);
                return response.data.map((item) => ({
                    label: item.locationName,
                    value: item.locationName,
                    dataSource: item.dataSourceName
                }));
            default:
                return [];
        }
    } catch (ex) {
        console.log(ex.message);
        return [];
    }
}

// export function mapOrgNameToDesc(fldName, orgLevelDescriptions) {
//     const orgInfo = orgLevelDescriptions?.find((org) => org.level === parseInt(fldName.slice(-1), 10));
//     return orgInfo ? orgInfo.description : fldName;
// }

// export function readDisplayName(fldName, orgLevelDescriptions) {
//     switch (fldName) {
//         case 'DataSourceName':
//             return 'Data source';
//         case 'ExtensionLocation':
//             return 'Extension Location';
//         case 'CallDirection':
//             return 'Call Direction';
//         case 'CallDisposition':
//             return 'Call Disposition';
//         default:
//             return mapOrgNameToDesc(fldName, orgLevelDescriptions);
//     }
// }

// export function getOrgLevelBasedList(orgLevelMatch, orgList) {
//     const orgValueList = [];

//     function getOrgNames(node, path) {
//         Object.keys(node).forEach((key) => {
//             if (Array.isArray(node[key])) {
//                 node[key].forEach((item) => {
//                     getOrgNames(item, path);
//                 });
//             } else if (key === 'orgLevel') {
//                 if (node[key] > orgLevelMatch) {
//                     path += node.orgName !== 'Unassigned' ? `${node.orgName} / ` : '';
//                 } else if (node[key] === orgLevelMatch) {
//                     orgValueList.push({ label: path ? `${path}${node.orgName}` : node.orgName, value: node.orgName });
//                 }
//             }
//         });
//     }
//     orgList.forEach((orgInfo) => {
//         getOrgNames(orgInfo, '');
//     });
//     return orgValueList;
// }

export function getGroupByOptions({ dateType, lastX, relativeDate, startDate, endDate }) {
    // relative date
    if (dateType === 1) {
        switch (relativeDate.toString()) {
            case '1':
                if (lastX <= 48) {
                    return [
                        { label: 'Hours', value: 'Hour' },
                        { label: 'Days', value: 'Date' }
                    ];
                }
                return [{ label: 'Days', value: 'Date' }];
            case '2':
            case '3':
            case '4':
                return [
                    { label: 'Days', value: 'Date' },
                    { label: 'Weeks', value: 'Week' },
                    { label: 'Months', value: 'Month' }
                ];
            default:
                return [
                    { label: 'Days', value: 'Date' },
                    { label: 'Weeks', value: 'Week' },
                    { label: 'Months', value: 'Month' }
                ];
        }
    }
    // specific date
    // if date range more than 48 hours, exclude group by hour
    const timeRange = getTimeRange(startDate, endDate);
    if (timeRange > 48) {
        return [
            { label: 'Days', value: 'Date' },
            { label: 'Weeks', value: 'Week' },
            { label: 'Months', value: 'Month' }
        ];
    }
    return [
        { label: 'Hours', value: 'Hour' },
        { label: 'Days', value: 'Date' }
    ];
}

export function getGroupByDefault({ dateType, relativeDate, startDate, endDate }) {
    if (dateType === 1) {
        switch (relativeDate?.toString()) {
            case '1':
                return { label: 'Hours', value: 'Hour' };
            case '2':
                return { label: 'Days', value: 'Date' };
            case '3':
                return { label: 'Months', value: 'Month' };
            case '4':
                return { label: 'Days', value: 'Date' };
            default:
                return { label: 'Days', value: 'Date' };
        }
    }
    const timeRange = getTimeRange(startDate, endDate);
    if (timeRange > 48) {
        return { label: 'Days', value: 'Date' };
    }
    return { label: 'Hours', value: 'Hour' };
}

export function mapRelativeDate(relDate) {
    switch (relDate.toString()) {
        case '1':
            return 'Hours';
        case '2':
            return 'Full Days';
        case '3':
            return 'Full Months';
        case '4':
            return 'Current Month';
        default:
            return 'Full Days';
    }
}
