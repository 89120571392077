import { FilterType, OperatorType } from 'utils/filterUtils';
import moment from 'moment';

export const filterData = (string, data, properties) => {
    if (string.length > 0) {
        const newRows = data.filter((row) => {
            return properties.some((property) => {
                if (row[property].toString().toLowerCase().includes(string.toString().toLowerCase())) {
                    return true;
                }
                return false;
            });
        });

        return newRows;
    }

    return data;
};

export const filterSearch = (newString, templates) => {
    return filterData(newString, templates, ['templateName', 'templateDescription']);
};

export const filterAllTags = (tags, templates) => {
    if (tags?.length) {
        const newRows = templates.filter((row) => {
            return row.tags?.some((tagName) => tags?.some(({ value }) => tagName.tagId === value));
        });

        return newRows;
    }

    return templates;
};

export const filterFavorite = (isFavSelected, templates) => {
    if (!isFavSelected) {
        return templates;
    }
    const newRows = templates.filter((row) => row?.isFavorite);
    return newRows;
};

export const checkBoxClick = (templateId, selected) => {
    const selectedIndex = selected.indexOf(templateId);
    const newSelected = [];
    if (selectedIndex === -1) {
        return newSelected.concat(selected, templateId);
    }
    if (selectedIndex === 0) {
        return newSelected.concat(selected.slice(1));
    }
    if (selectedIndex === selected.length - 1) {
        return newSelected.concat(selected.slice(0, -1));
    }
    if (selectedIndex > 0) {
        return newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    return newSelected;
};

export const isValidEmail = (email) => {
    email = email.trim();
    // eslint-disable-next-line
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
};

export const getGmtOffSetInMinutes = (gmt) => {
    return Number(gmt) * 60;
};
export const isIncludeInvalidChar = (inputtxt) => {
    // < (less than)
    // > (greater than)
    // : (colon - sometimes works, but is actually NTFS Alternate Data Streams)
    // " (double quote)
    // / (forward slash)
    // \ (backslash)
    // | (vertical bar or pipe)
    // ? (question mark)
    // * (asterisk)
    if (inputtxt === undefined || inputtxt?.length <= 0) {
        return false;
    }
    const v =
        inputtxt.includes('<') ||
        inputtxt.includes('>') ||
        inputtxt.includes(':') ||
        inputtxt.includes(`\\`) ||
        inputtxt.includes(`"`) ||
        inputtxt.includes('/') ||
        inputtxt.includes(`|`) ||
        inputtxt.includes('?') ||
        inputtxt.includes(`*`);
    return v;
};

export const getDuplicateFileNameTemplates = (data) => {
    const lookup = data?.reduce((a, e) => {
        /* eslint-disable no-plusplus */
        a[e.fileName] = ++a[e.fileName] || 0;

        return a;
    }, {});
    const duplicates = data?.filter((e) => lookup[e.fileName]);
    return duplicates;
};

export const updateOrgLevelAlias = (templateFields, orgLevelDescription) => {
    const fieldsWithOrgLevelAlias = [];
    templateFields?.forEach((field) => {
        if (field.name === 'Name1' || field.name === 'Name2' || field.name === 'Name3' || field.name === 'Name4') {
            const myLevel = field?.name?.split('Name')?.[1];
            const orgDescription = orgLevelDescription.find(({ level }) => level === Number(myLevel));
            if (orgDescription === undefined) return;
            const orgDes = orgDescription?.description;

            const a = [];
            field?.fieldAttributes?.forEach((attribute) => {
                if (attribute.id === 1) {
                    const newAttribute = { ...attribute, value: attribute?.value?.length <= 0 ? orgDes : attribute?.value };
                    a.push(newAttribute);
                } else {
                    a.push(attribute);
                }
            });

            field = { ...field, fieldAttributes: a, fieldDisplayName: orgDes };
            fieldsWithOrgLevelAlias.push(field);
        } else {
            fieldsWithOrgLevelAlias.push(field);
        }
    });

    return fieldsWithOrgLevelAlias;
};

export const getFilters = (selectedFilters, directoryFieldId) => {
    const filters = [];
    selectedFilters.forEach((filter) => {
        if (filter.fieldId === directoryFieldId) {
            filter.data?.forEach((item) => {
                let clonedObject = { ...item };
                clonedObject = { ...clonedObject, operator: filter.operator };
                filters.push(clonedObject);
            });
        } else {
            if (filter.filterType && filter.filterType === FilterType.duration) {
                const newValues = [];
                for (let j = 0; j < filter.values?.length; j += 1) {
                    const durationInFormat = filter.values[j]?.value;
                    const durationArr = durationInFormat.split(':');
                    const durationInSeconds = +durationArr[0] * 60 * 60 + +durationArr[1] * 60 + +durationArr[2];
                    newValues.push({ id: filter.values[j]?.id, value: durationInSeconds?.toString() });
                }
                filter = {
                    ...filter,
                    values: newValues
                };
            }
            filters.push(filter);
        }
    });
    return filters;
};

export const getCurrentDateBasedOnLocale = (selectedGmt) => {
    const date = new Date();
    date.setMinutes(date.getMinutes() + selectedGmt * 60);
    const d = new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds()
    );
    return d;
};
export const isValidFormat = (duration, filterType, dataType) => {
    if (filterType === FilterType.duration && duration?.value?.length > 0) {
        return duration?.value?.match(`^([01]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$`);
    }
    if (dataType === OperatorType.numeric) {
        return false;
    }

    return true;
};

export const getDateUsingOffSet = (date, time, gmtOffsetInMinutes) => {
    const targetTime = new Date(date?.concat(' ').concat(time));
    const offsetTime = new Date(targetTime.getTime() + gmtOffsetInMinutes * 60 * 1000);
    return moment(new Date(offsetTime)).format('YYYY-MM-DD');
};

export const hasDuplicates = (arr) => {
    const valuesArray = arr.map((obj) => obj.value);
    for (let i = 0; i < valuesArray.length; i += 1) {
        if (valuesArray.indexOf(valuesArray[i]) !== i) {
            return true;
        }
    }
    return false;
};
export const isNumeric = (n) => {
    return !Number.isNaN(parseFloat(Number(n))) && Number.isFinite(Number(n));
};

export const hasWrongFormat = (dataType, arr) => {
    if (dataType === OperatorType.numeric) {
        const valuesArray = arr.map((obj) => obj.value);
        for (let i = 0; i < valuesArray.length; i += 1) {
            if (!isNumeric(valuesArray[i])) {
                return true;
            }
        }
    }
    return false;
};

export const betweenRangeError = (valuesArr) => {
    const valuesArray = valuesArr.map((obj) => obj.value);
    const filteredArray = valuesArray.filter((str) => str);
    if (filteredArray.length > 1) {
        return hasDuplicates(valuesArr);
    }
    return false;
};

export const toKebabCase = (string) =>
    string
        .replace(/([a-z])([A-Z])/g, '$1-$2')
        .replace(/[\s_]+/g, '-')
        .toLowerCase();

// Function to set the time of a date object to the beginning of the day (00:00:00)
const startOfDay = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
};

// Function to set the time of a date object to the end of the day (23:59:59)
const endOfDay = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
};

// Function to set the date to the start of the week (Sunday)
const startOfWeek = (date, startDayOfWeek) => {
    const diff = (7 + (date.getDay() - startDayOfWeek)) % 7;
    const startOfWeekDate = new Date(date);
    startOfWeekDate.setDate(startOfWeekDate.getDate() - diff);
    return startOfWeekDate;
};

// Function to set the date to the start of the previous nth week (Sunday)
const startOfPreviousNthWeek = (date, n, startDayOfWeek) => {
    const startOfWeekDate = startOfWeek(date, startDayOfWeek);
    startOfWeekDate.setDate(startOfWeekDate.getDate() - 7 * n);
    return startOfWeekDate;
};

// Function to set the date to the end of the previous week (Saturday)
const endOfPreviousWeek = (date, startDayOfWeek) => {
    const startOfWeekDate = startOfWeek(date, startDayOfWeek);
    const endOfWeekDate = new Date(startOfWeekDate);
    endOfWeekDate.setDate(endOfWeekDate.getDate() - 1);
    endOfWeekDate.setHours(23, 59, 59, 999);
    return endOfWeekDate;
};

const calculateDateRange = (currentDate, lastDuration, includeToday, relativeValue, duration) => {
    let startDateTime;
    let endDateTime;
    if (relativeValue === 'Last') {
        if (duration === 'Hour') {
            startDateTime = new Date(currentDate.getTime() - lastDuration * 3600000); // Subtract hours in milliseconds
            endDateTime = currentDate;
        } else if (duration === 'Day') {
            startDateTime = startOfDay(new Date(currentDate.getTime() - lastDuration * 86400000)); // Subtract days in milliseconds
            endDateTime = includeToday ? currentDate : endOfDay(new Date(currentDate.getTime() - 86400000)); // Subtract one day if not including today
        } else if (duration === 'Week') {
            startDateTime = startOfPreviousNthWeek(currentDate, lastDuration, 0);
            endDateTime = endOfPreviousWeek(currentDate, 0);
        } else if (duration === 'Month') {
            startDateTime = new Date(currentDate.getFullYear(), currentDate.getMonth() - lastDuration, 1);
            endDateTime = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0, 23, 59, 59, 999);
        } else if (duration === 'Year') {
            startDateTime = new Date(currentDate.getFullYear() - lastDuration, 0, 1);
            endDateTime = new Date(currentDate.getFullYear() - lastDuration, 11, 31, 23, 59, 59);
        }
    } else if (relativeValue === 'Current') {
        if (duration === 'Day') {
            startDateTime = startOfDay(currentDate);
            endDateTime = currentDate;
        } else if (duration === 'Week') {
            startDateTime = startOfWeek(currentDate, 0);
            endDateTime = includeToday ? currentDate : endOfDay(new Date(currentDate.getTime() - 86400000));
        } else if (duration === 'Month') {
            currentDate.setHours(0, 0, 0, 0);
            startDateTime = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1, 0, 0, 0);
            endDateTime = includeToday ? currentDate : endOfDay(new Date(currentDate.getTime() - 86400000));
        } else if (duration === 'Year') {
            startDateTime = new Date(currentDate.getFullYear(), 0, 1);
            endDateTime = includeToday ? currentDate : endOfDay(new Date(currentDate.getTime() - 86400000));
        }
    }
    return { startDateTime, endDateTime };
};

const getUtcDateTime = (date, time, useDst, selectedGmt) => {
    const parseTime = moment(time);
    const parseDate = moment(date);
    // Set the time from startTime to startDate
    parseDate.set({
        hour: parseTime.get('hour'),
        minute: parseTime.get('minute'),
        second: parseTime.get('second')
    });

    const utcTime = parseTime
        .subtract(useDst ? getGmtOffSetInMinutes(selectedGmt) + 60 : getGmtOffSetInMinutes(selectedGmt), 'minutes')
        .format('HH:mm:ss');
    // Convert date to UTC considering the GMT offset
    const utcDate = parseDate
        .subtract(useDst ? getGmtOffSetInMinutes(selectedGmt) + 60 : getGmtOffSetInMinutes(selectedGmt), 'minutes')
        .format('YYYY-MM-DD');
    return { utcDate, utcTime };
};

// Relative date range start date
export const getStartDateRange = (lastDuration, includeToday, startTime, useDst, selectedGmt, relativeValue, duration) => {
    const currentDate = new Date();
    const { startDateTime } = calculateDateRange(currentDate, lastDuration, includeToday, relativeValue, duration);
    const { utcDate, utcTime } = getUtcDateTime(startDateTime, startTime, useDst, selectedGmt);
    return getDateUsingOffSet(utcDate, utcTime, useDst ? getGmtOffSetInMinutes(selectedGmt) + 60 : getGmtOffSetInMinutes(selectedGmt));
};

// Relative date range end date
export const getEndDateRange = (lastDuration, includeToday, endTime, useDst, selectedGmt, relativeValue, duration) => {
    const currentDate = new Date();
    const { endDateTime } = calculateDateRange(currentDate, lastDuration, includeToday, relativeValue, duration);
    const { utcDate, utcTime } = getUtcDateTime(endDateTime, endTime, useDst, selectedGmt);
    return getDateUsingOffSet(utcDate, utcTime, useDst ? getGmtOffSetInMinutes(selectedGmt) + 60 : getGmtOffSetInMinutes(selectedGmt));
};

const getBackgroundColor = (level, totalGroups) => {
    const backgroundColors = [
        '#8A99F9', // Background color for level 0
        '#9EAAFA', // Background color for level 1
        '#B1BBFB',
        '#C5CCFC'
    ];
    const filteredColours = backgroundColors.slice(-totalGroups);
    return filteredColours[level] || '#D8DDFD'; // Default if level is 4 or above
};
export const onRowPrepared = (e, totalGroups) => {
    if (e.rowType === 'group') {
        const backgroundColor = getBackgroundColor(e.groupIndex, totalGroups);
        e.rowElement.style.backgroundColor = backgroundColor;
        e.rowElement.style.color = '#5b5e74';
    }
    if (e.rowType === 'header') {
        e.rowElement.style.backgroundColor = '#2A314D';
        e.rowElement.style.color = '#FFFFFF';
    }
    if (e.rowType === 'data') {
        e.rowElement.style.height = '45px';
    }
};
export const onQueueRowPrepared = (e) => {
    if (e.rowType === 'data') {
        e.rowElement.style.fontSize = '15px';
        e.rowElement.style.height = '45px';
    }
};

export const getLocaleGMTOffSetInMinutes = () => {
    let gmt = (Math.round(-(new Date().getTimezoneOffset() / 60) * 100) / 100).toFixed(2);
    gmt = Number(gmt);
    if (moment().isDST()) {
        gmt -= 1;
    }
    return getGmtOffSetInMinutes(gmt);
};
