// material-ui
import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 * *
 */
import logoDark from 'assets/images/ISI-logo-dark.svg';
import logo from 'assets/images/ISI-logo.svg';

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    const theme = useTheme();

    return <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="Infortel Select" width="100" />;
};

export default Logo;
