import React, { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import DraggableDialog from '../ui-component/extended/DraggableDialog'; // replace this with your actual modal library
import useAuth from 'hooks/useAuth';
import { TroubleshootRounded } from '@mui/icons-material';

const IdleSessionTimer = () => {
    const { tenant, logout, user, loginType } = useAuth();
    const [showWarning, setShowWarning] = useState(false);
    const promptBeforeIdle = 60000; // 60 seconds
    let inactivityTime = null;
    if (loginType === 'sql') {
        inactivityTime = user?.inactiveSessionTimeout > 0 ? user.inactiveSessionTimeout / 60 : 15;
    } else {
        inactivityTime = parseInt(tenant?.tenantData?.inactivityTimeoutInMinutes, 10) || 15; // in minutes
    }

    const handleLogout = async () => {
        try {
            await logout();
        } catch (err) {
            console.error(err);
        }
    };

    const onIdle = async () => {
        // if inactivity time is 0, then don't show warning or logout
        if (inactivityTime > 0) {
            setShowWarning(true);
            handleLogout();
        }
    };

    const onWarningClose = () => {
        setShowWarning(false);
    };

    const onPrompt = () => {
        if (inactivityTime > 0) {
            setShowWarning(true);
        }
    };

    const { activate } = useIdleTimer({
        onIdle,
        onPrompt,
        timeout: inactivityTime > 0 ? (inactivityTime + 1) * 60 * 1000 : null,
        promptBeforeIdle,
        throttle: 500,
        crossTab: true
    });

    const stayActive = () => {
        activate();
        setShowWarning(false);
        // clearTimeout(sessionTimeoutRef.current);
    };

    // useEffect(() => {
    //     return () => {
    //         if (sessionTimeoutRef.current) clearTimeout(sessionTimeoutRef.current);
    //     };
    // }, []);

    return (
        <>
            <DraggableDialog
                dialogTitle="Session about to expire"
                dialogContentText="You've been idle for a while. Would you like to stay logged in?"
                buttonLabel="Keep me signed in"
                handleClose={onWarningClose}
                open={showWarning}
                handleButtonClick={stayActive}
            />
        </>
    );
};

export default IdleSessionTimer;
