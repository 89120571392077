import { useTheme } from '@mui/material/styles';
import { Avatar, Tooltip } from '@mui/material';

import { IconQuestionMark } from '@tabler/icons';
import { HELP_URL } from 'config';

const HelpSection = () => {
    const theme = useTheme();

    const goToHelpLink = () => {
        // redirect to help in a new tab
        window.open(HELP_URL, '_blank');
    };

    return (
        <>
            <Tooltip title="Help" placement="top">
                <Avatar
                    variant="rounded"
                    sx={{
                        mr: 2,
                        ...theme.typography.commonAvatar,
                        ...theme.typography.mediumAvatar,
                        transition: 'all .2s ease-in-out',
                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                        color: theme.palette.secondary.main,
                        '&[aria-controls="menu-list-grow"],&:hover': {
                            background: theme.palette.secondary.main,
                            color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light
                        }
                    }}
                    onClick={goToHelpLink}
                    color="inherit"
                >
                    <IconQuestionMark stroke={1.5} size="1.3rem" />
                </Avatar>
            </Tooltip>
        </>
    );
};

export default HelpSection;
