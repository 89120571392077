import dashboard, { getQueueDashboardMenuItems } from 'menu-items/dashboard';
import reporting from 'menu-items/reporting';
import administration from 'menu-items/administration';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'store/index';
import { useNavigate } from 'react-router-dom';
import useAuth from './useAuth';
import { setHasQueueRoles, setHasLicensedQueuesAccess } from 'store/slices/menu';
import { UserRoles, APP_FEATURES } from 'store/constant';
import { QueueDataSource } from 'views/QueueDashboard/constants';
import { setReEvaluateQueueAccess, toggleIsCreateQueueDshDlgOpn } from 'store/slices/queueDashboard';
import { getAccessibleLicensedCallQueues } from 'views/QueueDashboard/Utils/queueDashboardApiCallsUtil';
import { useSnackbar } from 'notistack';

const useMenuItems = () => {
    const { appFeatures, userHasRole } = useAuth();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { hasQueueRoles, hasLicensedQueuesAccess } = useSelector((state) => state.menu);
    const { reevaluateQueueAccess } = useSelector((state) => state.queueDashboard);
    const { queueDashboards, isQueueDashboardListLoaded } = useSelector((state) => state.queueDashboard);
    const values = [dashboard];

    const onQueueDashboardClick = () => {
        if (queueDashboards.length > 0) {
            const defaultQueueDashboard = queueDashboards.find((dashboard) => dashboard.isDefault === true);
            navigate(`/dashboard/queue-dashboard/${defaultQueueDashboard?.id || queueDashboards[0].id}`);
        } else {
            dispatch(toggleIsCreateQueueDshDlgOpn());
        }
    };

    useEffect(() => {
        async function evaluateHasLicensedQueues() {
            const queues = await getAccessibleLicensedCallQueues(enqueueSnackbar, QueueDataSource.MicrosoftTeams);
            if (queues && queues.length > 0) {
                dispatch(setHasLicensedQueuesAccess(true));
            } else {
                dispatch(setHasLicensedQueuesAccess(false));
            }
            dispatch(setReEvaluateQueueAccess(false));
        }

        if (reevaluateQueueAccess && hasQueueRoles) {
            evaluateHasLicensedQueues();
        }
    }, [hasQueueRoles, reevaluateQueueAccess]);

    const userCanViewQueues =
        process.env.REACT_APP_HIDE_BETA_ITEMS === 'false' &&
        ((appFeatures && appFeatures[APP_FEATURES.Queues]) ?? false) &&
        (userHasRole(UserRoles.QueueAdmin) ||
            userHasRole(UserRoles.SystemAdmin) ||
            userHasRole(UserRoles.QueueManager) ||
            userHasRole(UserRoles.QueueAgent));

    if (userCanViewQueues) {
        dispatch(setHasQueueRoles(true));
    } else {
        dispatch(setHasQueueRoles(false));
    }

    const queueDashboardExists = values[0].children.some((item) => item?.id === 'queue-dashboard');
    if (userCanViewQueues) {
        if (!queueDashboardExists) {
            const queueDashboardMenuItems = getQueueDashboardMenuItems();
            dashboard.children.push(queueDashboardMenuItems);
        }

        // only enable this once the queue dashboards list has been loaded
        if (hasLicensedQueuesAccess && isQueueDashboardListLoaded) {
            dashboard.children[1].disabled = false;
            dashboard.children[1].onClick = onQueueDashboardClick;
        } else {
            dashboard.children[1].disabled = true;
        }
    } else if (queueDashboardExists) {
        const filteredDashboardChildren = dashboard.children.filter((child) => child.id !== 'queue-dashboard');
        values[0].children = [...filteredDashboardChildren];
    }

    if (
        userHasRole(UserRoles.ReportAdmin) ||
        userHasRole(UserRoles.ReportUser) ||
        userHasRole(UserRoles.SystemAdmin) ||
        userHasRole(UserRoles.QueueAdmin) ||
        userHasRole(UserRoles.QueueManager)
    ) {
        values.push(reporting);
    }

    if (
        userHasRole(UserRoles.ConnectorAdmin) ||
        userHasRole(UserRoles.ConnectorViewer) ||
        userHasRole(UserRoles.SystemAdmin) ||
        userHasRole(UserRoles.QueueAdmin) ||
        userHasRole(UserRoles.QueueManager)
    ) {
        values.push(administration);
    }

    return {
        items: values
    };
};

export default useMenuItems;
