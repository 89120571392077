import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

export const initialState = {
    scheduleType: 1,
    selectedMonthlyOption: 1,
    specificMonthlyDay: 1,
    relativeMonthlyWeek: 1,
    relativeMonthlyDay: 'Monday',
    weeklyDay: 'Sunday',
    daysOfWeek: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    hourlyInterval: 1,
    startTime: moment().format('HH:mm'),
    scheduleActive: false,
    countryCode: 'US',
    useDst: true,
    timeZoneGmtOffset: '-6.00'
};

const slice = createSlice({
    name: 'dirImportSchedule',
    initialState,
    reducers: {
        setDirImportSchedule(state, action) {
            const {
                scheduleType,
                selectedMonthlyOption,
                specificMonthlyDay,
                relativeMonthlyWeek,
                relativeMonthlyDay,
                weeklyDay,
                daysOfWeek,
                hourlyInterval,
                startTime,
                scheduleActive,
                countryCode,
                useDst,
                timeZoneGmtOffset
            } = action.payload;
            state.scheduleType = scheduleType;
            state.selectedMonthlyOption = selectedMonthlyOption;
            state.specificMonthlyDay = specificMonthlyDay;
            state.relativeMonthlyWeek = relativeMonthlyWeek;
            state.relativeMonthlyDay = relativeMonthlyDay;
            state.weeklyDay = weeklyDay;
            state.daysOfWeek = daysOfWeek;
            state.hourlyInterval = hourlyInterval;
            state.startTime = startTime;
            state.scheduleActive = scheduleActive;
            state.countryCode = countryCode;
            state.useDst = useDst;
            state.timeZoneGmtOffset = timeZoneGmtOffset;
        },
        updateScheduleType(state, action) {
            state.scheduleType = action.payload;
        },
        updateMonthlyOption(state, action) {
            state.selectedMonthlyOption = action.payload;
        },
        updateSpecificMonthlyDay(state, action) {
            state.specificMonthlyDay = action.payload;
        },
        updateRelativeMonthlyWeek(state, action) {
            state.relativeMonthlyWeek = action.payload;
        },
        updateRelativeMonthlyDay(state, action) {
            state.relativeMonthlyDay = action.payload;
        },
        updateWeeklyDay(state, action) {
            state.weeklyDay = action.payload;
        },
        updateDaysOfWeek(state, action) {
            state.daysOfWeek = action.payload;
        },
        updateHourlyInterval(state, action) {
            state.hourlyInterval = action.payload;
        },
        updateStartTime(state, action) {
            state.startTime = action.payload;
        },
        updateScheduleActive(state, action) {
            state.scheduleActive = action.payload;
        },
        updateCountryCode(state, action) {
            state.countryCode = action.payload;
        },
        updateUseDst(state, action) {
            state.useDst = action.payload;
        },
        updateTimeZoneGmtOffset(state, action) {
            state.timeZoneGmtOffset = action.payload;
        }
    }
});

export default slice.reducer;

export function updateScheduleType(scheduleType) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.updateScheduleType(scheduleType));
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function updateMonthlyOption(monthlyOption) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.updateMonthlyOption(monthlyOption));
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function updateSpecificMonthlyDay(monthlyDay) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.updateSpecificMonthlyDay(monthlyDay));
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function updateRelativeMonthlyWeek(monthlyWeek) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.updateRelativeMonthlyWeek(monthlyWeek));
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function updateRelativeMonthlyDay(monthlyDay) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.updateRelativeMonthlyDay(monthlyDay));
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function updateWeeklyDay(weeklyDay) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.updateWeeklyDay(weeklyDay));
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function updateDaysOfWeek(daysOfWeek) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.updateDaysOfWeek(daysOfWeek));
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function updateHourlyInterval(hourlyInterval) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.updateHourlyInterval(hourlyInterval));
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function updateStartTime(startTime) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.updateStartTime(startTime));
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function updateScheduleActive(active) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.updateScheduleActive(active));
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function updateCountryCode(cc) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.updateCountryCode(cc));
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function updateUseDst(useDst) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.updateUseDst(useDst));
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function updateTimeZoneGmtOffset(gmtOffset) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.updateTimeZoneGmtOffset(gmtOffset));
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function setDirImportSchedule(config) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.setDirImportSchedule(config));
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function resetDirImportSchedule() {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.setDirImportSchedule({ ...initialState, startTime: moment().format('HH:mm') }));
        } catch (ex) {
            console.log(ex);
        }
    };
}
