import { createSlice } from '@reduxjs/toolkit';
import { axiosReportService } from 'utils/axios';
import { SIZE } from '../constant';

// initial state
export const initialState = {
    historyData: [],
    historyLoading: false,
    error: null,
    hasMoreData: true,
    isReportFetchInprogress: false,
    reportData: {},
    isDeleteCompleted: false,
    isReportDeleteInprogress: false
};

// ==============================|| SLICE - history ||============================== //
const slice = createSlice({
    name: 'history',
    initialState,
    reducers: {
        fetchHistoryRequest(state, action) {
            state.historyLoading = true;
            if (action.payload) {
                state.historyData = [];
                state.hasMoreData = true;
            }
        },
        fetchHistoryFailure(state, action) {
            state.historyLoading = false;
            state.error = action.payload;
        },
        fetchHistorySuccess(state, action) {
            state.historyLoading = false;
            if (action.payload?.length < SIZE) {
                state.hasMoreData = false;
            }
            state.historyData = state.historyData.concat(action.payload);
        },
        setDownloadStart(state, action) {
            // action payload have reportId
            for (let i = 0; i < state.historyData.length; i += 1) {
                if (state.historyData[i].reportId === action.payload.reportId) {
                    state.historyData[i].downloadStart = true;
                }
            }
        },
        setDownloadPercentage(state, action) {
            for (let i = 0; i < state.historyData.length; i += 1) {
                if (state.historyData[i].reportId === action.payload.reportId) {
                    state.historyData[i].downloadPercentage = action.payload.percentage;
                }
            }
        },
        resetHistoryData(state, action) {
            state.historyData = [];
        },

        deleteReportRequest(state, action) {
            state.isReportDeleteInprogress = true;
        },
        deleteReportFailure(state, action) {
            state.isReportDeleteInprogress = false;
            state.isDeleteCompleted = true;
        },
        deleteReportSuccess(state, action) {
            state.isDeleteCompleted = true;
            state.historyData = action.payload;
            state.isReportDeleteInprogress = false;
        },

        deleteReportClear(state) {
            state.isDeleteCompleted = false;
        },
        fetchReportRequest(state, action) {
            state.isReportFetchInprogress = true;
        },
        fetchReportFailure(state, action) {
            state.isReportFetchInprogress = false;
        },
        fetchReportSuccess(state, action) {
            state.isReportFetchInprogress = false;
            state.reportData = action.payload;
        }
    }
});

export default slice.reducer;

export function fetchHistory(enqueueSnackbar, offset, searchText, isFromSearch, ownerIdFilter) {
    return async (dispatch) => {
        const payload = { offset, size: SIZE, q: searchText, ownerIdFilter };
        dispatch(slice.actions.fetchHistoryRequest(isFromSearch));

        try {
            const response = await axiosReportService.get('/reports', { params: payload });
            if (response?.data?.success) {
                dispatch(slice.actions.fetchHistorySuccess(response.data.data));
            } else {
                dispatch(slice.actions.fetchHistoryFailure(response.data.error));
                enqueueSnackbar('Error in fetching data', { variant: 'error' });
            }
        } catch (ex) {
            console.log(ex);
            if (ex.response) {
                dispatch(slice.actions.fetchHistoryFailure(ex.response));
            }
            if (ex?.response?.status === 401) {
                enqueueSnackbar('Session expired, please login again', { variant: 'error' });
            } else {
                enqueueSnackbar('Error in fetching data', { variant: 'error' });
            }
        }
    };
}

export function fetchReportById(enqueueSnackbar, reportId) {
    return async (dispatch) => {
        dispatch(slice.actions.fetchReportRequest());

        try {
            const response = await axiosReportService.get(`/reports/${reportId}`);
            if (response?.data?.success) {
                dispatch(slice.actions.fetchReportSuccess(response.data.data));
            } else {
                dispatch(slice.actions.fetchReportFailure(response.data.error));
                enqueueSnackbar('Error in fetching data', { variant: 'error' });
            }
        } catch (ex) {
            console.log(ex);
            if (ex.response) {
                dispatch(slice.actions.fetchReportFailure(ex.response));
            }
            if (ex?.response?.status === 401) {
                enqueueSnackbar('Session expired, please login again', { variant: 'error' });
            } else {
                enqueueSnackbar('Error in fetching data', { variant: 'error' });
            }
        }
    };
}

export function deleteReportByIds(enqueueSnackbar, payload) {
    return async (dispatch, getState) => {
        dispatch(slice.actions.deleteReportRequest());

        try {
            const response = await axiosReportService.delete(`/reports`, { data: payload });
            if (response?.data?.success) {
                const { historyData } = getState().history;
                setTimeout(() => {
                    dispatch(slice.actions.deleteReportSuccess(historyData.filter((item) => !payload.find((rm) => item.reportId === rm))));
                    enqueueSnackbar('Report Deleted Successfully', { variant: 'info' });
                }, 500);
            } else {
                dispatch(slice.actions.deleteReportFailure(response.data.error));
                enqueueSnackbar('Error in deleting report', { variant: 'error' });
            }
        } catch (ex) {
            console.log(ex);
            if (ex.response) {
                dispatch(slice.actions.deleteReportFailure(ex.response));
            }
            if (ex?.response?.status === 401) {
                enqueueSnackbar('Session expired, please login again', { variant: 'error' });
            } else {
                enqueueSnackbar('Error in deleting report', { variant: 'error' });
            }
        }
    };
}

export function setFileDownloadStart(reportId) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.setDownloadStart({ reportId }));
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function setFileDownloadPercentage(reportId, percentage) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.setDownloadPercentage({ reportId, percentage }));
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function resetHistoryData() {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.resetHistoryData());
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function deleteReportClear() {
    return async (dispatch) => {
        dispatch(slice.actions.deleteReportClear());
    };
}
