import React, { useContext, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import MetricsSubsetGrid from '../MetricsSubsetGrid/MetricsSubsetGrid';
import { useSelector } from 'store';
import { DashboardStateActions, DashboardStateDispatchContext } from '../context/DashboardStateContext';
import { FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, FormHelperText } from '@mui/material';

export default function EditAgentMetricsTableDialog({ widgetId, columns, isEditTableDialogOpen, toggleEditTableDialog, config }) {
    const { allowedAgentTableWidgetConfig } = useSelector((state) => state.queueDashboard);
    const { dispatchDashboardStateAction } = useContext(DashboardStateDispatchContext);
    const [columnConfig, setColumnConfig] = useState(columns);
    const [hasError, setHasError] = useState(false);

    const [sortOrder] = config?.sortOrder != null ? config?.sortOrder : [];
    const [sortByColumn, setSortByColumn] = useState(sortOrder?.columnName ?? 'Name');
    const [isSortOrderAscending, setIsSortOrderAscending] = useState(sortOrder?.ascending ?? true);

    const sortOptions = ['Name', ...columnConfig.map((item) => item.displayAs)];
    const hasSortingError = !sortOptions?.includes(sortByColumn);

    const saveColumnConfig = () => {
        dispatchDashboardStateAction({
            type: DashboardStateActions.UpdateWidget,
            payload: {
                widgetId,
                config: {
                    ...config,
                    sortOrder: [
                        {
                            columnName: sortByColumn,
                            ascending: isSortOrderAscending
                        }
                    ]
                },
                metrics: columnConfig.map((column, index) => ({ ...column, displayOrder: index }))
            }
        });
        dispatchDashboardStateAction({ type: DashboardStateActions.SetHasUnsavedChanges, payload: true });
        toggleEditTableDialog();
    };

    return (
        <Dialog fullWidth open={isEditTableDialogOpen}>
            <div className="edit-table-dialog-container">
                <div className="edit-table-dialog-header">
                    <DialogTitle style={{ padding: 0 }}>Edit Widget</DialogTitle>
                    <IconButton aria-label="close" onClick={toggleEditTableDialog}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <div className="sorting-field">
                    <FormControl fullWidth sx={{ maxWidth: '25%', mb: 2 }}>
                        <InputLabel id="sort-select-label" error={hasSortingError}>
                            Sort By
                        </InputLabel>
                        <Select
                            labelId="sort-select-label"
                            value={sortByColumn}
                            onChange={(e) => setSortByColumn(e.target.value)}
                            label="Sort By"
                            error={hasSortingError}
                        >
                            {sortOptions?.map((item) => (
                                <MenuItem key={`sort-option_${item}`} value={item}>
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                        {hasSortingError && <FormHelperText error>Select a valid Sort By value</FormHelperText>}
                    </FormControl>

                    <FormControl>
                        <RadioGroup
                            row
                            name="order-row-radio-buttons-group"
                            value={isSortOrderAscending}
                            onChange={(e, v) => setIsSortOrderAscending(v === 'true')}
                        >
                            <FormControlLabel value control={<Radio />} label="Ascending" />
                            <FormControlLabel value={false} control={<Radio />} label="Descending" />
                        </RadioGroup>
                    </FormControl>
                </div>
                <MetricsSubsetGrid
                    fieldsConfig={columnConfig}
                    setFieldsConfig={setColumnConfig}
                    allowedMetricsConfig={allowedAgentTableWidgetConfig?.allowedMetrics ?? {}}
                    setHasError={setHasError}
                    isReorderable
                />
                <Stack spacing={2} sx={{ marginTop: 2 }} direction="row" justifyContent="flex-end">
                    <Button variant="text" color="error" onClick={toggleEditTableDialog}>
                        Cancel
                    </Button>
                    <Button disabled={hasError || hasSortingError} variant="contained" onClick={saveColumnConfig}>
                        Save
                    </Button>
                </Stack>
            </div>
        </Dialog>
    );
}
