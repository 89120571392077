import useAuth from 'hooks/useAuth';
import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { UserRoles } from 'store/constant';

const RoutesAccessConfig = [
    // {
    //     path: '/dashboard/my-dashboard/:id',
    //     // allowedRoles: ['DSHADMIN', 'DSHUSER']
    // },
    // {
    //     path: '/dashboard',
    //     // allowedRoles: ['DSHADMIN', 'DSHUSER']
    // },
    {
        path: '/dashboard/queue-dashboard/:id',
        allowedRoles: [UserRoles.SystemAdmin, UserRoles.QueueAdmin, UserRoles.QueueManager, UserRoles.QueueAgent]
    },
    {
        path: '/register/cloud-connect',
        allowedRoles: [UserRoles.SystemAdmin, UserRoles.ConnectorAdmin]
    },
    {
        path: '/register/cloud-connect/success',
        allowedRoles: [UserRoles.SystemAdmin, UserRoles.ConnectorAdmin]
    },
    {
        path: '/register/cloud-connect/failure',
        allowedRoles: [UserRoles.SystemAdmin, UserRoles.ConnectorAdmin]
    },
    {
        path: '/reporting/reports',
        allowedRoles: [UserRoles.ReportAdmin, UserRoles.ReportUser, UserRoles.SystemAdmin, UserRoles.QueueManager, UserRoles.QueueAdmin]
    },
    {
        path: '/reporting/reports/customization/:id',
        allowedRoles: [UserRoles.ReportAdmin, UserRoles.ReportUser, UserRoles.SystemAdmin, UserRoles.QueueManager, UserRoles.QueueAdmin]
    },
    {
        path: '/reporting/reports/customization/:id/edit',
        allowedRoles: [UserRoles.ReportAdmin, UserRoles.ReportUser, UserRoles.SystemAdmin, UserRoles.QueueManager, UserRoles.QueueAdmin]
    },
    {
        path: '/reporting/reports/customization/:id/copy',
        allowedRoles: [UserRoles.ReportAdmin, UserRoles.ReportUser, UserRoles.SystemAdmin, UserRoles.QueueManager, UserRoles.QueueAdmin]
    },
    {
        path: '/reporting/reports/customization/:id/preview',
        allowedRoles: [UserRoles.ReportAdmin, UserRoles.ReportUser, UserRoles.SystemAdmin, UserRoles.QueueManager, UserRoles.QueueAdmin]
    },
    {
        path: '/reporting/reports/customization/:id/edit/preview',
        allowedRoles: [UserRoles.ReportAdmin, UserRoles.ReportUser, UserRoles.SystemAdmin, UserRoles.QueueManager, UserRoles.QueueAdmin]
    },
    {
        path: '/reporting/reports/customization/:id/copy/preview',
        allowedRoles: [UserRoles.ReportAdmin, UserRoles.ReportUser, UserRoles.SystemAdmin, UserRoles.QueueManager, UserRoles.QueueAdmin]
    },
    {
        path: '/reporting/reports/customization/:id/report',
        allowedRoles: [UserRoles.ReportAdmin, UserRoles.ReportUser, UserRoles.SystemAdmin, UserRoles.QueueManager, UserRoles.QueueAdmin]
    },
    {
        path: '/reporting/reports/:id/report',
        allowedRoles: [UserRoles.ReportAdmin, UserRoles.ReportUser, UserRoles.SystemAdmin, UserRoles.QueueManager, UserRoles.QueueAdmin]
    },
    {
        path: '/reporting/report-history',
        allowedRoles: [UserRoles.ReportAdmin, UserRoles.ReportUser, UserRoles.SystemAdmin, UserRoles.QueueManager, UserRoles.QueueAdmin]
    },
    {
        path: '/reporting/scheduled-report',
        allowedRoles: [UserRoles.ReportAdmin, UserRoles.ReportUser, UserRoles.SystemAdmin, UserRoles.QueueManager, UserRoles.QueueAdmin]
    },
    {
        path: '/administration/connectors',
        allowedRoles: [
            UserRoles.SystemAdmin,
            UserRoles.ConnectorAdmin,
            UserRoles.ConnectorViewer,
            UserRoles.QueueAdmin,
            UserRoles.QueueManager
        ]
    },
    {
        path: '/administration/connectors/connectorTemplate',
        allowedRoles: [UserRoles.SystemAdmin, UserRoles.ConnectorAdmin, UserRoles.QueueAdmin, UserRoles.QueueManager]
    },
    {
        path: '/administration/connectors/connectorTemplate/:id',
        allowedRoles: [UserRoles.SystemAdmin, UserRoles.ConnectorAdmin, UserRoles.QueueAdmin, UserRoles.QueueManager]
    },
    {
        path: '/administration/connectors/RCTemplate',
        allowedRoles: [UserRoles.SystemAdmin, UserRoles.ConnectorAdmin]
    },
    {
        path: '/administration/connectors/RCTemplate/:id',
        allowedRoles: [UserRoles.SystemAdmin, UserRoles.ConnectorAdmin]
    },
    {
        path: '/administration/connectors/DirectoryImport',
        allowedRoles: [UserRoles.SystemAdmin, UserRoles.ConnectorAdmin]
    },
    {
        path: '/administration/connectors/DirectoryImport/:id',
        allowedRoles: [UserRoles.SystemAdmin, UserRoles.ConnectorAdmin]
    },
    {
        path: '/administration/connectors/connectorTemplate/:id/queues/:queueId',
        allowedRoles: [UserRoles.SystemAdmin, UserRoles.ConnectorAdmin, UserRoles.QueueAdmin, UserRoles.QueueManager]
    }
];

const useRouteAccess = () => {
    const location = useLocation();
    const params = useParams();
    const { user } = useAuth();

    const currentPathConfig = useMemo(
        () =>
            RoutesAccessConfig.find(({ path }) => {
                let newPath = path;
                Object.keys(params).forEach((key) => {
                    newPath = path.replace(`:${key}`, params[key]);
                });

                return newPath === location.pathname;
            }),
        [params, location.pathname]
    );

    if (currentPathConfig?.allowedRoles) {
        return user?.roles?.some((role) => {
            return currentPathConfig?.allowedRoles?.includes(role);
        });
    }

    return true;
};

export default useRouteAccess;
