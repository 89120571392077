import ReactDOM from 'react-dom';

// third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

// project imports
import App from 'app';
import { BASE_PATH, MUI_LICENSE_KEY } from 'config';
import { store, persistor } from 'store';
import * as serviceWorker from 'serviceWorker';
import reportWebVitals from 'reportWebVitals';
import { ConfigProvider } from 'contexts/ConfigContext';

// style + assets
// import 'assets/scss/style.scss';
import 'assets/scss/_themes-vars.module.scss';

// load mock apis
import '_mockApis';

import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);

// =============================||   HOST REDIRECT   ||=============================== //

const stagingOld = 'stagingpreview.isi-info.com';
const stagingNew = 'https://stagingapp.isianalytics.com';
const prodOld = 'preview.isi-info.com';
const prodNew = 'https://app.isianalytics.com';

if (window.location.hostname === prodOld) {
    window.location.replace(prodNew + window.location.pathname + window.location.search);
} else if (window.location.hostname === stagingOld) {
    window.location.replace(stagingNew + window.location.pathname + window.location.search);
}

// ==============================|| REACT DOM RENDER  ||============================== //

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ConfigProvider>
                <BrowserRouter basename={BASE_PATH}>
                    <App />
                </BrowserRouter>
            </ConfigProvider>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
