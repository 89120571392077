import { createSlice } from '@reduxjs/toolkit';
import { axiosReportService } from 'utils/axios';

// initial state
export const initialState = {
    preferences: null,
    loading: false,
    error: null
};

// ==============================|| SLICE - preferences ||============================== //
const preferences = createSlice({
    name: 'preferences',
    initialState,
    reducers: {
        fetchPreferencesRequest(state) {
            state.loading = true;
        },
        fetchPreferencesFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        fetchPreferencesSuccess(state, action) {
            state.loading = false;

            if (action.payload) {
                const pref = {};
                action.payload.forEach((element) => {
                    pref[element.preferenceKey] = element;
                });

                state.preferences = pref;
            }
        }
    }
});

export function fetchPreferences(enqueueSnackbar) {
    return async (dispatch) => {
        dispatch(preferences.actions.fetchPreferencesRequest());
        try {
            const response = await axiosReportService.get('/user/preferences/list');
            if (response?.data?.success) {
                dispatch(preferences.actions.fetchPreferencesSuccess(response.data.data));
            } else {
                dispatch(preferences.actions.fetchPreferencesFailure(response.data.error));
            }
        } catch (ex) {
            console.log(ex);
            if (ex.response) {
                dispatch(preferences.actions.fetchPreferencesFailure(ex.response));
            }
            if (ex?.response?.status === 401) {
                enqueueSnackbar('Session expired, please login again', { variant: 'error' });
            } else {
                enqueueSnackbar('Error in getting Prefrences', { variant: 'error' });
            }
        }
    };
}

export function setPreference(enqueueSnackbar, payload) {
    return async (dispatch) => {
        try {
            await axiosReportService.post('/user/preferences', payload);

            dispatch(fetchPreferences(enqueueSnackbar));
            enqueueSnackbar(payload.successMessage || 'Preference saved successfully', { variant: 'info' });
        } catch (ex) {
            console.log(ex);
            if (ex?.response?.status === 401) {
                enqueueSnackbar('Session expired, please login again', { variant: 'error' });
            } else {
                enqueueSnackbar(payload.ErrorMessage || 'Failed to save preference', { variant: 'error' });
            }
        }
    };
}

export function updatePreference(enqueueSnackbar, payload) {
    return async (dispatch) => {
        try {
            await axiosReportService.patch(`/user/preferences/${payload.userPreferenceId}`, payload);

            dispatch(fetchPreferences());
            enqueueSnackbar(payload.successMessage || 'Preference saved successfully', { variant: 'info' });
        } catch (ex) {
            console.log(ex);
            if (ex?.response?.status === 401) {
                enqueueSnackbar('Session expired, please login again', { variant: 'error' });
            } else {
                enqueueSnackbar(payload.ErrorMessage || 'Failed to save preference', { variant: 'error' });
            }
        }
    };
}

export default preferences.reducer;

export const { fetchPreferencesRequest, fetchPreferencesFailure, fetchPreferencesSuccess } = preferences.actions;
