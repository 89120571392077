import { useState, useContext, useEffect, useCallback } from 'react';

import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import { sortBy } from 'lodash';
import { IconMenu2 } from '@tabler/icons';
import { QueueDashboardEvents, StatusColor, RefreshIntervalMs } from '../constants';
import { DashboardStateActions, DashboardStateContext, DashboardStateDispatchContext } from '../context/DashboardStateContext';
import { useSnackbar } from 'notistack';
import TitleBar from '../TitleBar/TitleBar';
import {
    getDateTimeWithDstAdjustment,
    getQueueDashboardRefreshDataEventObj,
    getRefreshingWidgetDataAnimateIcon
} from '../Utils/queueDashboardUtils';
import { useTheme } from '@emotion/react';
import { getAllMetricsForQueue, getFilteredQueuesMetricsSummary, updateSelectedQueueId } from '../Utils/queueDashboardApiCallsUtil';
import { Divider } from '@mui/material';

export default function QueueListSelector({ children, height }) {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [loadingFilteredQueueSummary, setLoadingFilteredQueueSummary] = useState(false);
    const [refreshing, setRefreshing] = useState(false);
    const { isInEditMode, dashboardId, dataSource, filteredQueuesMetricsSummary, selectedQueueId, queueIdsFilter, dateTimeFilter } =
        useContext(DashboardStateContext);
    const { dispatchDashboardStateAction } = useContext(DashboardStateDispatchContext);
    const [needsRefresh, setNeedsRefresh] = useState(false);
    const [savingSelectedQueueId, setSavingSelectedQueueId] = useState(false);
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const selectedBorderColor = theme.palette.mode === 'dark' ? '#ffffff' : '#2b314e';

    const setSelectedQueueId = useCallback(
        async (queueId) => {
            dispatchDashboardStateAction({ type: DashboardStateActions.SetSelectedQueueId, payload: queueId });

            // Doing this after the dashboard state is set to allow user to see data change
            // Also, no need to do this in edit mode or when user changes queues while the previous is still being saved
            if (!isInEditMode && !savingSelectedQueueId) {
                setSavingSelectedQueueId(true);
                const successful = await updateSelectedQueueId(dashboardId, queueId);
                if (!successful) {
                    // NOTE: No need to notify the user if this fails
                    console.log('Failed to update the selected queue Id in the database');
                }
                setSavingSelectedQueueId(false);
            }
        },
        [dispatchDashboardStateAction, dashboardId, isInEditMode, savingSelectedQueueId]
    );

    // Setup the interval to refresh widget data
    useEffect(() => {
        // If we are in edit Mode or the selected queueId is undefined, don't set the refresh interval
        if (isInEditMode || !selectedQueueId || !selectedQueueId.length === 0) {
            return undefined;
        }

        const triggerRefresh = () => {
            const event = getQueueDashboardRefreshDataEventObj();
            document.body.dispatchEvent(event);
        };

        const intervalId = setInterval(triggerRefresh, RefreshIntervalMs);

        // cleanup interval
        return () => {
            clearInterval(intervalId);
        };
    }, [isInEditMode, selectedQueueId]);

    // Subscribe to queue dashboard events
    useEffect(() => {
        const handler = (e) => {
            setNeedsRefresh(true);
        };
        document.body.addEventListener(QueueDashboardEvents.RefreshWidgetData, handler);
        return () => {
            document.body.removeEventListener(QueueDashboardEvents.RefreshWidgetData, handler);
        };
    }, []);

    // load filteredQueues metrics summary
    const loadQueuesSummary = useCallback(
        async (inputDataSource, inputQueueIdsFilter, inputDateTimeFilter, isRefresh = false) => {
            const summary = await getFilteredQueuesMetricsSummary(
                enqueueSnackbar,
                inputDataSource,
                inputQueueIdsFilter,
                getDateTimeWithDstAdjustment(inputDateTimeFilter)
            );
            if (summary) {
                const sortedSummary = sortBy(summary, [(queue) => queue?.displayName?.toLowerCase()]);
                dispatchDashboardStateAction({ type: DashboardStateActions.SetFilteredQueuesMetricsSummary, payload: sortedSummary });
            } else if (isRefresh) {
                enqueueSnackbar(`Failed to refresh queues summary data`, { variant: 'error' });
            } else {
                // TODO: Handle error state for this
                enqueueSnackbar(`Failed to ${isRefresh ? 'refresh' : 'load'} queues summary data`, { variant: 'error' });
                dispatchDashboardStateAction({ type: DashboardStateActions.SetFilteredQueuesMetricsSummary, payload: [] });
            }
        },
        [dispatchDashboardStateAction]
    );

    const loadMetricsData = useCallback(
        async (inputDataSource, queueId, inputDateTimeFilter, isRefresh = false) => {
            const metrics = await getAllMetricsForQueue(
                enqueueSnackbar,
                inputDataSource,
                queueId,
                getDateTimeWithDstAdjustment(inputDateTimeFilter)
            );
            if (metrics?.data) {
                dispatchDashboardStateAction({ type: DashboardStateActions.SetQueueMetrics, payload: metrics?.data?.queueMetrics ?? {} });
                dispatchDashboardStateAction({ type: DashboardStateActions.SetAgentMetricsList, payload: metrics?.data?.agents ?? [] });
                dispatchDashboardStateAction({
                    type: DashboardStateActions.SetDashboardDateTimeRange,
                    payload: metrics?.data?.dateTimeRange
                });
            } else if (isRefresh) {
                enqueueSnackbar('Failed to refresh queue metrics data', { variant: 'error' });
            } else {
                if (metrics?.status === 404) {
                    enqueueSnackbar('Selected Queue not found. Update Selected queue filter', { variant: 'error' });
                } else {
                    enqueueSnackbar('Failed to load queue metrics data', { variant: 'error' });
                }
                dispatchDashboardStateAction({ type: DashboardStateActions.SetQueueMetrics, payload: metrics?.data?.queueMetrics ?? {} });
                dispatchDashboardStateAction({ type: DashboardStateActions.SetAgentMetricsList, payload: metrics?.data?.agents ?? [] });
                dispatchDashboardStateAction({
                    type: DashboardStateActions.SetDashboardDateTimeRange,
                    payload: metrics?.data?.dateTimeRange
                });
            }
        },
        [dispatchDashboardStateAction]
    );

    useEffect(() => {
        async function refreshData() {
            setRefreshing(true);
            dispatchDashboardStateAction({ type: DashboardStateActions.SetRefreshingQueueAgentMetrics, payload: true });

            try {
                await Promise.all([
                    loadQueuesSummary(dataSource, queueIdsFilter, dateTimeFilter, true),
                    loadMetricsData(dataSource, selectedQueueId, dateTimeFilter, true)
                ]);
            } catch (ex) {
                console.log('Failed to refresh data. Error: ', ex);
            }

            dispatchDashboardStateAction({ type: DashboardStateActions.SetRefreshingQueueAgentMetrics, payload: false });
            setRefreshing(false);
            setNeedsRefresh(false);
        }
        if (!isInEditMode && needsRefresh) {
            refreshData();
        }
    }, [
        dispatchDashboardStateAction,
        loadQueuesSummary,
        isInEditMode,
        needsRefresh,
        dataSource,
        queueIdsFilter,
        selectedQueueId,
        dateTimeFilter
    ]);

    // Load the summary for the filtered queues
    useEffect(() => {
        async function onLoad() {
            setLoadingFilteredQueueSummary(true);
            await loadQueuesSummary(dataSource, queueIdsFilter, dateTimeFilter);
            setLoadingFilteredQueueSummary(false);
        }
        // TODO: Check why is this being called when the user switches to a different dashboard
        if (queueIdsFilter.length > 0) {
            onLoad();
        } else {
            dispatchDashboardStateAction({ type: DashboardStateActions.SetFilteredQueuesMetricsSummary, payload: [] });
        }
    }, [dispatchDashboardStateAction, loadQueuesSummary, dataSource, queueIdsFilter, dateTimeFilter]);

    useEffect(() => {
        // If the earlier selected queue was filtered out, set the selected queue to be the first queue
        if (
            filteredQueuesMetricsSummary.length > 0 &&
            filteredQueuesMetricsSummary.find((q) => q.queueId === selectedQueueId) === undefined
        ) {
            setSelectedQueueId(filteredQueuesMetricsSummary[0].queueId);
        }
    }, [filteredQueuesMetricsSummary, setSelectedQueueId]);

    useEffect(() => {
        async function onLoad() {
            dispatchDashboardStateAction({ type: DashboardStateActions.SetLoadingQueueAgentMetrics, payload: true });

            // Load the data from the service
            await loadMetricsData(dataSource, selectedQueueId, dateTimeFilter);

            dispatchDashboardStateAction({ type: DashboardStateActions.SetLoadingQueueAgentMetrics, payload: false });
        }
        if (
            !loadingFilteredQueueSummary &&
            !refreshing &&
            dataSource &&
            selectedQueueId &&
            filteredQueuesMetricsSummary.length > 0 &&
            filteredQueuesMetricsSummary.find((q) => q.queueId === selectedQueueId) !== undefined
        ) {
            onLoad();
        }

        // NOTE: refreshing is not added in the dependency array
    }, [loadMetricsData, loadingFilteredQueueSummary, filteredQueuesMetricsSummary, dataSource, selectedQueueId, dateTimeFilter]);

    const toggleDrawer = () => {
        setDrawerOpen((open) => !open);
    };

    const selectedQueue = filteredQueuesMetricsSummary.find((q) => q.queueId === selectedQueueId);
    let error = null;
    if (loadingFilteredQueueSummary) {
        error = null;
    } else if (!queueIdsFilter || !queueIdsFilter.length) {
        error = 'Queues Filter not selected. Edit the Dashboard to select the queues to be displayed';
    } else if (!filteredQueuesMetricsSummary || !filteredQueuesMetricsSummary.length) {
        error = 'Selected Queues Filter is no longer valid. Edit the Dashboard to select the filter again';
    } else if (!selectedQueue) {
        error = 'You no longer have access to the previously selected queue. Select a different queue';
    }

    return (
        <>
            <div className="dashboard-headers-container">
                <TitleBar />
                <Card
                    className="queue-title-card"
                    style={theme.palette.mode === 'dark' ? { backgroundColor: '#111936', border: 'none' } : null}
                >
                    <IconButton onClick={toggleDrawer}>
                        <IconMenu2 />
                    </IconButton>
                    <p className="selected-queue-title" style={{ color: theme.palette.mode === 'dark' ? '#d7dcec' : '#222631' }}>
                        {selectedQueue?.displayName}
                    </p>
                </Card>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
                {drawerOpen && (
                    <Card
                        className="queues-list-drawer"
                        style={{ height: height - 156, border: theme.palette.mode === 'dark' ? 'none' : '1px solid #d7ccfc' }}
                    >
                        <div
                            className="queues-list-title-container"
                            style={{ backgroundColor: theme.palette.mode === 'dark' ? '#212946' : '#fff', zIndex: 10 }}
                        >
                            <p className="queues-list-title" style={{ color: theme.palette.mode === 'dark' ? '#d7dcec' : '#222631' }}>
                                Queues
                            </p>
                            {!isInEditMode &&
                                (refreshing || loadingFilteredQueueSummary) &&
                                getRefreshingWidgetDataAnimateIcon('queue-selector')}
                        </div>
                        {filteredQueuesMetricsSummary.map((item) => (
                            <>
                                <Card
                                    key={item.queueId}
                                    className="queues-card"
                                    style={{
                                        backgroundColor: StatusColor[item.status] ?? StatusColor.Normal,
                                        borderLeft: selectedQueueId === item.queueId ? `8px solid ${selectedBorderColor}` : 'none',
                                        transition: 'border-left 300ms ease-in-out',
                                        cursor: selectedQueueId !== item.queueId ? 'pointer' : 'default'
                                    }}
                                    onClick={() => setSelectedQueueId(item.queueId)}
                                >
                                    <p className="queue-card-info">{item.displayName}</p>
                                    <div style={{ display: 'flex' }}>
                                        <div className="queue-info-section">
                                            <p className="queue-card-info">
                                                {item.serviceLevelPercent === 'NA' ? 'N/A' : item.serviceLevelPercent}
                                                {item.serviceLevelPercent !== 'NA' && '%'}
                                            </p>
                                            <p className="queue-info-label">Service Level</p>
                                        </div>
                                        <div className="queue-info-section">
                                            <p className="queue-card-info">{item.totalCalls}</p>
                                            <p className="queue-info-label">Total Calls</p>
                                        </div>
                                    </div>
                                </Card>
                                <Divider sx={{ borderColor: '#000000', opacity: '50%' }} />
                            </>
                        ))}
                    </Card>
                )}
                <div className="queue-widgets-container" style={{ height: height - 142, overflowY: 'auto' }}>
                    {error ? (
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                            <span>{error}</span>
                        </div>
                    ) : (
                        children
                    )}
                </div>
            </div>
        </>
    );
}
