import { Outlet } from 'react-router-dom';

// project imports
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import RegisterRC from 'views/pages/RcRegistration/RegisterRC';
import RegisterRcSuccess from 'views/pages/RcRegistration/RegisterRcSuccess';
import RegisterRcFailure from 'views/pages/RcRegistration/RegisterRcFailure';

const RcRegistrationRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <Outlet />
        </NavMotion>
    ),
    children: [
        {
            path: '/',
            element: <MinimalLayout />,
            children: [
                {
                    path: '/register/cloud-connect',
                    element: <RegisterRC />
                },
                {
                    path: '/register/cloud-connect/success',
                    element: <RegisterRcSuccess />
                },
                {
                    path: '/register/cloud-connect/failure',
                    element: <RegisterRcFailure />
                }
            ]
        }
    ]
};

export default RcRegistrationRoutes;
