import { useEffect } from 'react';
// routing
import Routes from 'routes';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import RTLLayout from 'ui-component/RTLLayout';
import ThemeCustomization from 'themes';
import appInsightsConfig from 'utils/appInsights';
import { useLocation } from 'react-router-dom';

// auth provider
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
import { SnackbarProvider } from 'notistack';

// ==============================|| APP ||============================== //

const App = () => {
    const location = useLocation();

    // This is used to track page changes in Azure Application Insights
    useEffect(() => {
        if (appInsightsConfig.appInsights) {
            appInsightsConfig.appInsights.trackPageView({ name: location.pathname }); // Log page views
        }
    }, [location]); // Re-run whenever the location changes

    return (
        <ThemeCustomization>
            {/* RTL layout */}
            <RTLLayout>
                <Locales>
                    <NavigationScroll>
                        <AuthProvider>
                            <SnackbarProvider>
                                <Routes />
                            </SnackbarProvider>
                        </AuthProvider>
                    </NavigationScroll>
                </Locales>
            </RTLLayout>
        </ThemeCustomization>
    );
};

export default App;
