import { Button, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const ConfirmationDialog = ({ title, content, okButton, isOkCancel, open, handleYes, handleNo, handleOk }) => {
    const theme = useTheme();

    return (
        <Dialog
            open={open}
            onClose={handleNo}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ p: 3 }}
        >
            {open && (
                <>
                    <DialogTitle id="confirm-dialog-title">{title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="confirm-dialog-description">
                            <Typography variant="body2" component="span">
                                {content}
                            </Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ pr: 2.5 }}>
                        {okButton ? (
                            <Button variant="contained" size="small" onClick={handleOk}>
                                OK
                            </Button>
                        ) : (
                            <>
                                <Button
                                    variant="Text"
                                    sx={{ height: 'fit-content', color: theme.palette.error.main }}
                                    size="small"
                                    onClick={handleNo}
                                >
                                    {isOkCancel ? `Cancel` : `No`}
                                </Button>
                                <Button variant="contained" size="small" onClick={handleYes} autoFocus>
                                    {isOkCancel ? `OK` : `Yes`}
                                </Button>
                            </>
                        )}
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default ConfirmationDialog;
