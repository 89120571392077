// third-party
import { FormattedMessage } from 'react-intl';

// ==============================|| WIDGET MENU ITEMS ||============================== //
// assets
import { IconHistory, IconDeviceAnalytics, IconTemplate, IconCalendarStats } from '@tabler/icons';

// constant
const icons = {
    IconDeviceAnalytics,
    IconTemplate,
    IconCalendarStats,
    IconHistory
};

const reporting = {
    id: 'reporting',
    title: <FormattedMessage id="reporting" />,

    type: 'group',

    children: [
        {
            id: 'reports',
            title: <FormattedMessage id="report-templates" />,
            type: 'item',
            url: '/reporting/reports',
            icon: icons.IconTemplate
        },

        {
            id: 'scheduled-report',
            title: <FormattedMessage id="Scheduled Reports" />,
            type: 'item',
            url: '/reporting/scheduled-report',
            icon: icons.IconCalendarStats
        },
        {
            id: 'report-history',
            title: <FormattedMessage id="Report History" />,
            type: 'item',
            url: '/reporting/report-history',
            icon: icons.IconHistory
        }
    ]
};

export default reporting;
