import React, { useEffect, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { useSnackbar } from 'notistack';
// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, Container, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';

// project imports
import Header from './Header';
import Sidebar from './Sidebar';
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';

import LAYOUT_CONST from 'constant';
import useConfig from 'hooks/useConfig';
import { drawerWidth } from 'store/constant';
import { useSelector, dispatch } from 'store';

import useRouteAccess from 'routes/useRouteAccess';
import MainCard from 'ui-component/cards/MainCard';
import NoAccess from 'views/pages/NoAccess';
import { openDrawer } from 'store/slices/menu';
import { getDashboardList } from 'store/slices/dashboard';
/**
 * Queue Dashboards menu items
 */
import {
    loadAllowedWidgetsConfig,
    loadQueueDashboardList,
    loadQueueDashboardTemplateList,
    toggleIsCreateQueueDshDlgOpn
} from 'store/slices/queueDashboard';
import CreateQueueDashboardDialog from 'views/QueueDashboard/CreateQueueDashboardDialog/CreateQueueDashboardDialog';
import useAuth from 'hooks/useAuth';
import useMenuItems from 'hooks/useMenuItems';

import { initializePendo } from 'utils/pendo';
import { getOrgLevelDescriptions } from 'store/slices/lookup';

// assets
import { IconChevronRight } from '@tabler/icons';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open, layout }) => ({
    ...theme.typography.mainContent,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    ...(!open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.shorter + 200
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: layout === LAYOUT_CONST.VERTICAL_LAYOUT ? -(drawerWidth - 72) : '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            marginTop: layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? 135 : 88
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.shorter + 200
        }),
        marginLeft: layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? '20px' : 0,
        marginTop: layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? 135 : 88,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.up('md')]: {
            marginTop: layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? 135 : 88
        }
    }),
    [theme.breakpoints.down('md')]: {
        marginLeft: '20px',
        padding: '16px',
        marginTop: 88,
        ...(!open && {
            width: `calc(100% - ${drawerWidth}px)`
        })
    },
    [theme.breakpoints.down('sm')]: {
        marginLeft: '10px',
        marginRight: '10px',
        padding: '16px',
        marginTop: 88,
        ...(!open && {
            width: `calc(100% - ${drawerWidth}px)`
        })
    }
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    const { hasQueueRoles, hasLicensedQueuesAccess } = useSelector((state) => state.menu);
    const { isCreateQueueDshDlgOpen } = useSelector((state) => state.queueDashboard);
    const hasAccessToCurrentRoute = useRouteAccess();
    // const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
    const { user } = useAuth();
    const { enqueueSnackbar } = useSnackbar();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const navigation = useMenuItems();
    const { drawerOpen } = useSelector((state) => state.menu);
    const { container, layout, navType } = useConfig();

    const condition = layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd;
    const { newDashboardId } = useSelector((state) => state.dashboard);

    const toggleCreateQueueDshDialogOpen = () => {
        dispatch(toggleIsCreateQueueDshDlgOpn());
    };

    // load dashboard list for the user
    // this is required to dynamically display the available dashboards on the sidebar menu
    useEffect(() => {
        dispatch(getDashboardList());
    }, [newDashboardId, dispatch]);

    /**
     * Queue Dashboards menu items
     */
    useEffect(() => {
        if (hasQueueRoles && hasLicensedQueuesAccess) {
            dispatch(loadQueueDashboardList(enqueueSnackbar));
            // Load the queue dashboard templates
            dispatch(loadQueueDashboardTemplateList(enqueueSnackbar));
            // Load the allowed config for the queue dashboard widgets
            dispatch(loadAllowedWidgetsConfig(enqueueSnackbar));
        }
    }, [dispatch, hasQueueRoles, hasLicensedQueuesAccess]);

    // load the org level descriptions when loading the main layout. This might be needed even before we add or load new widgets
    useEffect(() => {
        dispatch(getOrgLevelDescriptions());
    }, [dispatch]);

    // useEffect(() => {
    //     dispatch(openDrawer(!matchDownMd));
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [matchDownMd]);

    useEffect(() => {
        dispatch(openDrawer(false));
        // initialize pendo
        if (user) {
            initializePendo(user);
        } else {
            const anonymousUser = {
                clientId: '',
                userName: 'VISITOR-UNIQUE-ID'
            };
            initializePendo(anonymousUser);
        }
    }, [dispatch, user]);

    const header = useMemo(
        () => (
            <Toolbar sx={{ p: condition ? '10px' : '16px' }}>
                <Header />
            </Toolbar>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [layout, matchDownMd]
    );

    return (
        <Box
            sx={{ display: 'flex' }}
            className={`dx-viewport ${navType === 'dark' ? 'dx-swatch-base-dark' : ''}`} // Responsible for theme support for Devextreme components
        >
            {/* header */}
            <AppBar enableColorOnDark position="fixed" color="inherit" elevation={0} sx={{ bgcolor: theme.palette.background.default }}>
                {header}
            </AppBar>

            {/* horizontal menu-list bar */}
            {layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd && <Sidebar menuItem={navigation} />}

            {/* drawer */}
            {(layout === LAYOUT_CONST.VERTICAL_LAYOUT || matchDownMd) && <Sidebar menuItem={navigation} />}

            {/* main content */}
            <Main theme={theme} open={drawerOpen} layout={layout}>
                {!hasAccessToCurrentRoute ? (
                    <MainCard>
                        <NoAccess />
                    </MainCard>
                ) : (
                    <>
                        {container && (
                            <Container maxWidth={container ? 'lg' : false} {...(!container && { sx: { px: { xs: 0 } } })}>
                                {/* breadcrumb */}
                                <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                                <Outlet />
                            </Container>
                        )}
                        {!container && (
                            <>
                                <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                                <Outlet />
                            </>
                        )}
                    </>
                )}
            </Main>
            <>
                {isCreateQueueDshDlgOpen && (
                    <CreateQueueDashboardDialog isDialogOpen={isCreateQueueDshDlgOpen} toggleDialogOpen={toggleCreateQueueDshDialogOpen} />
                )}
            </>
        </Box>
    );
};

export default MainLayout;
