import { useState } from 'react';
import CreateQueueDashboardDialog from './CreateQueueDashboardDialog';
import IconButton from '@mui/material/IconButton';
import { IconPlus } from '@tabler/icons';
import { useSelector, dispatch } from 'store/index';

export default function CreateNewQueueDashboardButton() {
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const { hasLicensedQueuesAccess } = useSelector((state) => state.menu);

    const toggleDialogOpen = () => {
        setIsDialogOpen((open) => !open);
    };

    return (
        <div
            style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1,
                marginLeft: 8,
                marginBottom: 4
            }}
        >
            <IconButton disabled={!hasLicensedQueuesAccess} onClick={toggleDialogOpen}>
                <IconPlus stroke={1.5} size="18px" />
            </IconButton>
            {isDialogOpen && <CreateQueueDashboardDialog isDialogOpen={isDialogOpen} toggleDialogOpen={toggleDialogOpen} />}
        </div>
    );
}
