import moment from 'moment';
import { getStartEndDateForMonth, getStartEndDateForWeek } from './format';

function getColorsByCallTypeDesc(callTypeDescriptions) {
    const callTypeColorsLight = new Map([
        [1, '#3C59FC'],
        [2, '#00DBB7'],
        [3, '#DF309A'],
        [4, '#9435F9'],
        [5, '#FF7819'],
        [6, '#3C0077'],
        [7, '#FF8300'],
        [8, '#C339BE'],
        [9, '#808585'],
        [10, '#9067A7'],
        [11, '#AB6857'],
        [12, '#CCC210']
    ]);
    const callTypeColorsDark = new Map([
        [1, '#3C59FC'],
        [2, '#00DBB7'],
        [3, '#DF309A'],
        [4, '#9435F9'],
        [5, '#FF7819'],
        [6, '#0180D4'],
        [7, '#FF8300'],
        [8, '#C339BE'],
        [9, '#808585'],
        [10, '#9067A7'],
        [11, '#AB6857'],
        [12, '#CCC210']
    ]);
    return callTypeDescriptions.reduce(
        (acc, currItm) => {
            acc.light[currItm.effCallTypeDesc] = callTypeColorsLight.get(currItm.effCallTypeCode);
            acc.dark[currItm.effCallTypeDesc] = callTypeColorsDark.get(currItm.effCallTypeCode);
            return acc;
        },
        { light: {}, dark: {} }
    );
}

export function getMappedColors(category, callTypeDescriptions) {
    switch (category?.toLowerCase()) {
        case 'calldirection':
            return {
                light: {
                    Outbound: '#3C59FC',
                    Inbound: '#00DBB7',
                    Internal: '#DF309A',
                    'Trunk to Trunk': '#9435F9'
                },
                dark: {
                    Outbound: '#3C59FC',
                    Inbound: '#00DBB7',
                    Internal: '#DF309A',
                    'Trunk to Trunk': '#9435F9'
                }
            };
        case 'calldisposition':
            return {
                light: {
                    'Inbound Answered': '#3C59FC',
                    'Inbound Abandoned': '#00DBB7',
                    Outbound: '#DF309A',
                    Internal: '#9435F9'
                },
                dark: {
                    'Inbound Answered': '#3C59FC',
                    'Inbound Abandoned': '#00DBB7',
                    Outbound: '#DF309A',
                    Internal: '#9435F9'
                }
            };
        case 'calltypedesc':
            return getColorsByCallTypeDesc(callTypeDescriptions);
        default:
            return {
                light: [
                    '#3C59FC',
                    '#00DBB7',
                    '#DF309A',
                    '#9435F9',
                    '#FF7819',
                    '#3C0077',
                    '#FF8300',
                    '#C339BE',
                    '#808585',
                    '#9067A7',
                    '#AB6857',
                    '#CCC210',
                    '#8CCBAC',
                    '#AEA2E0',
                    '#CBCDB5',
                    '#EA4D41',
                    '#F2C96D'
                ],
                dark: [
                    '#3C59FC',
                    '#00DBB7',
                    '#DF309A',
                    '#9435F9',
                    '#FF7819',
                    '#0180D4',
                    '#FF8300',
                    '#C339BE',
                    '#808585',
                    '#9067A7',
                    '#AB6857',
                    '#CCC210',
                    '#8CCBAC',
                    '#AEA2E0',
                    '#CBCDB5',
                    '#EA4D41',
                    '#F2C96D'
                ]
            };
    }
}

export function getInitialBarProps(graphData) {
    const barCategories = Object.keys(graphData[0])?.slice(1);
    return barCategories?.reduce((acc, itm) => {
        acc[itm] = false;
        return acc;
    }, {});
}

export function getColumnTypeFromName(name) {
    switch (name) {
        case 'duration':
            return 'number';
        case 'date':
            return 'date';
        default:
            return 'string';
    }
}

export function getDrillDownFields(xAxisField, fields) {
    const filteredFields = fields.filter(
        (fieldItm) => fieldItm.name !== 'Hour' && fieldItm.name !== 'Date' && fieldItm.name !== 'Week' && fieldItm.name !== 'Month'
    );
    return [...filteredFields, { name: xAxisField, displayName: xAxisField, function: null }];
}

export function getOrgDrillDownFields(xAxisField, fields) {
    const filteredFields = fields.filter((fieldItm) => !fieldItm.name.startsWith('Name'));
    return [...filteredFields, { name: xAxisField, displayName: xAxisField, function: null }];
}

export function getOrgDrillDownFilters(widgetConfig, filterValue) {
    const nonOrgFilters = widgetConfig.filters.filter((filterItm) => !filterItm.fieldName.startsWith('Name'));
    const xAxisNumber = widgetConfig.xAxisField.startsWith('Name') ? parseInt(widgetConfig.xAxisField.replace('Name', ''), 10) : 0;
    const existingOrgFilter = widgetConfig.filters.filter((filterItm) => filterItm.fieldName.startsWith('Name'));
    // Filter the existingOrgFilter to get only those filters which have a "lower" org level than current level
    const lowerLevelFilters = existingOrgFilter?.filter((itm) => {
        const itmNumber = parseInt(itm.fieldName.replace('Name', ''), 10);
        return itmNumber < xAxisNumber;
    });

    const newOrgFilters = [...lowerLevelFilters, { fieldName: widgetConfig.xAxisField, operator: 'IN', value: filterValue }];

    return [...nonOrgFilters, ...newOrgFilters];
}

export function groupDataByOther(data, dataKey, idx) {
    const top7 = data.slice(0, 7);
    const otherCount = data.slice(7).reduce((acc, curr) => acc + curr.Count, 0);
    const result = [...top7];
    if (otherCount > 0) {
        result.push({ [dataKey]: 'Others', Count: otherCount, index: top7.length + idx });
    }
    return result;
}

export function getOrgGroupLevel(currOrgLevel) {
    const level = currOrgLevel.substring(currOrgLevel.length - 1);
    switch (level) {
        case '4':
            return 'Name3';
        case '3':
            return 'Name2';
        case '2':
            return 'Name1';
        case '1':
            return 'OwnerName';
        default:
            return '';
    }
}

export function getLowerLevelGroupingParams(widgetConfig, dataVal) {
    let groupingLevel = null;
    let isoStartDate = null;
    let isoEndDate = null;
    let updatedFields = null;

    switch (widgetConfig.xAxisField.toLowerCase()) {
        case 'month':
            groupingLevel = 'Week';
            [isoStartDate, isoEndDate] = getStartEndDateForMonth(dataVal, widgetConfig);
            updatedFields = getDrillDownFields(groupingLevel, widgetConfig.fields);
            break;
        case 'week':
            groupingLevel = 'Date';
            [isoStartDate, isoEndDate] = getStartEndDateForWeek(dataVal);
            updatedFields = getDrillDownFields(groupingLevel, widgetConfig.fields);
            break;
        case 'date':
            groupingLevel = 'Hour';
            isoStartDate = moment(dataVal, 'M/D/YY').format('YYYY-MM-DD');
            isoEndDate = isoStartDate;
            updatedFields = getDrillDownFields(groupingLevel, widgetConfig.fields);
            break;
        default:
            break;
    }
    if (widgetConfig.xAxisField.toLowerCase().startsWith('name')) {
        // call a function that takes the current xAxisField and returns new xAxisField based on level,
        const xAxisValue = getOrgGroupLevel(widgetConfig.xAxisField);
        // new field with new level, modified filter based on current level
        const updatedFields = getOrgDrillDownFields(xAxisValue, widgetConfig.fields);
        const updatedFilters = getOrgDrillDownFilters(widgetConfig, dataVal);
        return {
            xAxisField: xAxisValue,
            fields: updatedFields,
            filters: updatedFilters
        };
        // eg - if current level is Org level 4, return new xAxis as Name3, fields - Name3, filters - Name4 IN dataVal
    }
    return {
        groupByField: groupingLevel,
        xAxisField: groupingLevel,
        dateType: 2,
        startDate: isoStartDate,
        endDate: isoEndDate,
        fields: updatedFields
    };
}

export function getYAxisCaption(caption, xAxisField) {
    if (xAxisField === 'Date') {
        return `${caption} per day`;
    }
    return `${caption} per ${xAxisField.toLowerCase()}`;
}
