import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Divider, Grid, InputLabel, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { useFormik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import moment, { weekdays } from 'moment';
import TimeZone from 'ui-component/extended/TimeZone';
import { formatGMTOffsetToHoursAndMinutes, getUtcOffsetInMinutes } from 'utils/QueueConfigurationFormatAndValidation';
import { startEndDateDifference } from 'utils/format';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { useTheme } from '@mui/material/styles';
import { defaultDateRangeFormat, relativeDateRangeFormat } from '../constants';

export const DateRangeType = {
    Specific: 'specific',
    Relative: 'relative',
    CurrentQueuePeriod: 'queuePeriod'
};

export const TimeRangeType = {
    Continuous: 'continuous',
    Interval: 'interval'
};

export const RelativeDateUnitOptions = {
    LastNHours: 'lastNHours',
    LastNDays: 'lastNDays'
};

export const RelativeDateUnitOptionsLabels = {
    LastNDays: 'Days',
    LastNHours: 'Hours'
};

const showDateError = (dateError, date) => {
    let error = '';
    if (dateError === 'invalidDate' || dateError === 'minDate') {
        error = `${date} should be in 'MM:DD:YYYY' format`;
    } else if (dateError === 'disableFuture') {
        error = `${date} should not be a future date`;
    }
    return error;
};

export default function DateTimeFilter({ initialValues, setDateTimeFilterValues, setHasDateTimeErrors, selectedQueue }) {
    const [endTimeError, setEndTimeError] = useState(null);
    const [startTimeError, setStartTimeError] = useState(null);
    const [timeError, setTimeError] = useState(null);
    const [endDateError, setEndDateError] = useState(null);
    const [startDateError, setStartDateError] = useState(null);
    const [dateError, setDateError] = useState(null);
    const [timeZoneError, setTimeZoneError] = useState(false);
    const formik = useFormik({
        initialValues: {
            type: initialValues?.type || DateRangeType.Specific,
            startDate: initialValues?.date?.specific?.startDate || moment().subtract(30, 'days').format('YYYY-MM-DD'),
            endDate: initialValues?.date?.specific?.endDate || moment().format('YYYY-MM-DD'),
            relativeDateType: initialValues?.date?.relative?.type || RelativeDateUnitOptions.LastNDays,
            relativeDateCount: initialValues?.date?.relative?.length || 31,
            includeToday: initialValues?.date?.relative?.includeToday ?? true,
            weekDays: initialValues?.date?.weekDays ?? [true, true, true, true, true, true, true],
            timeType: initialValues?.time?.type || 'continuous',
            startTime: moment(initialValues?.time?.startTime || '00:00', 'HH:mm'),
            endTime: moment(initialValues?.time?.endTime || '23:59', 'HH:mm'),
            countryCode: initialValues?.countryCode || 'US',
            utcOffsetInMinutes: formatGMTOffsetToHoursAndMinutes(initialValues?.utcOffsetInMinutes ?? -360),
            useDst: initialValues?.useDst ?? false
        }
    });

    const theme = useTheme();

    useEffect(() => {
        setDateTimeFilterValues?.(formik.values);
    }, [formik.values]);

    useEffect(() => {
        if (!formik.values.startTime || !formik.values.endTime || startTimeError || endTimeError) {
            setTimeError("Time should be in 'HH:MM' format");
        } else if (
            ((formik.values.type === DateRangeType.Specific && formik.values.startDate === formik.values.endDate) ||
                formik.values.timeType === TimeRangeType.Interval) &&
            formik.values.startTime &&
            formik.values.endTime &&
            formik.values.startTime >= formik.values.endTime
        ) {
            setTimeError('Start time should be before end time');
        } else {
            setTimeError(null);
        }
        if (formik.values.type === DateRangeType.Specific) {
            if (startDateError || endDateError) {
                setDateError("Date should be in 'MM:DD:YYYY' format");
            } else if (formik.values.startDate > formik.values.endDate) {
                setDateError('Start date should be before end date');
            } else if (startEndDateDifference(formik.values.startDate, formik.values.endDate) > 30) {
                setDateError('Maximum 31 days can be selected for a specific date range');
            } else {
                setDateError(null);
            }
        }
        if (formik.values.type === DateRangeType.Relative) {
            if (!formik.values.relativeDateCount) {
                setDateError('Value is required');
            } else if (
                formik.values.relativeDateType === 'lastNDays' &&
                (formik.values.relativeDateCount > 31 || formik.values.relativeDateCount < 1)
            ) {
                setDateError('Days can be in range 1 - 31');
            } else if (
                formik.values.relativeDateType === 'lastNHours' &&
                (formik.values.relativeDateCount > 24 || formik.values.relativeDateCount < 1)
            ) {
                setDateError('Hours can be in range 1 - 24');
            } else {
                setDateError(null);
            }
        }
        if (formik.values.type === DateRangeType.CurrentQueuePeriod) {
            setDateError(null);
            setTimeError(null);
            setStartTimeError(null);
            setEndTimeError(null);
        }
    }, [formik.values, startDateError, endDateError, startTimeError, endTimeError]);

    useEffect(() => {
        if (timeZoneError || startTimeError || endTimeError || timeError || dateError) {
            setHasDateTimeErrors(true);
        } else {
            setHasDateTimeErrors(false);
        }
    }, [timeZoneError, endTimeError, startTimeError, timeError, dateError, formik.values]);

    const [startDate, endDate] = useMemo(() => {
        if (formik.values.type === DateRangeType.Relative && formik.values.relativeDateType === 'lastNDays') {
            const currentUTCDate = moment().utc();
            const endDateTimeInUTC = formik.values.includeToday ? currentUTCDate : moment(currentUTCDate).subtract(1, 'days');
            const startDateTimeInUTC = moment(currentUTCDate).subtract(formik.values.relativeDateCount, 'days');

            const userSelectedTZOffset = formik.values.useDst
                ? getUtcOffsetInMinutes(formik.values.utcOffsetInMinutes) + 60
                : getUtcOffsetInMinutes(formik.values.utcOffsetInMinutes);
            const startDateTime = startDateTimeInUTC.utcOffset(userSelectedTZOffset).format(relativeDateRangeFormat);
            const endDateTime = endDateTimeInUTC.utcOffset(userSelectedTZOffset).format(relativeDateRangeFormat);

            return [startDateTime, endDateTime];
        }
        return [undefined, undefined];
    }, [formik.values]);

    const [queueRangeStartDate, queueRangeEndDate] = useMemo(() => {
        if (formik.values.type === DateRangeType.CurrentQueuePeriod) {
            const currentDateInQueueTZ = moment().utc().utcOffset(selectedQueue.utcOffsetInMinutes);
            const startTimeParts = selectedQueue.activeStartTime.split(':');
            const endTimeParts = selectedQueue.activeEndTime.split(':');
            // Initialize  queue start and end time in queue timezone
            const startDateTimeInQueueTZ = moment(currentDateInQueueTZ).set({
                hour: startTimeParts[0],
                minute: startTimeParts[1],
                second: startTimeParts[2]
            });

            let endDateTimeInQueueTZ = moment(currentDateInQueueTZ).set({
                hour: endTimeParts[0],
                minute: endTimeParts[1],
                second: endTimeParts[2]
            });
            if (endDateTimeInQueueTZ <= startDateTimeInQueueTZ) {
                endDateTimeInQueueTZ = endDateTimeInQueueTZ.add(1, 'days');
            }
            // Convert to user selectedTimezone
            const userSelectedTZOffset = formik.values.useDst
                ? getUtcOffsetInMinutes(formik.values.utcOffsetInMinutes) + 60
                : getUtcOffsetInMinutes(formik.values.utcOffsetInMinutes);
            const startDateTime = moment.utc(startDateTimeInQueueTZ).utcOffset(userSelectedTZOffset);
            const endDateTime = moment.utc(endDateTimeInQueueTZ).utcOffset(userSelectedTZOffset);
            return [startDateTime.format(defaultDateRangeFormat), endDateTime.format(defaultDateRangeFormat)];
        }
        return [undefined, undefined];
    }, [selectedQueue, formik.values]);

    const showDateRange = (startDate, endDate) => (
        <Grid direction="row">
            <Typography variant="caption" sx={{ color: 'grey.700' }}>
                {startDate}
            </Typography>
            <Typography variant="caption" sx={{ color: 'grey.700' }}>
                {' '}
                -{' '}
            </Typography>
            <Typography variant="caption" sx={{ color: 'grey.700' }}>
                {endDate}
            </Typography>
        </Grid>
    );
    return (
        <>
            <div className="date-filter-dialog-content">
                <FormControl>
                    <Typography id="date-radio-button-group" variant="h6">
                        Date Range
                    </Typography>
                    <RadioGroup
                        value={formik.values.type}
                        onChange={formik.handleChange}
                        row
                        aria-labelledby="date-radio-button-group"
                        name="type"
                        id="type"
                        className="radio-buttons-container"
                    >
                        <FormControlLabel
                            sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                            value={DateRangeType.Specific}
                            control={<Radio />}
                            label="Specific"
                        />
                        <FormControlLabel
                            sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                            value={DateRangeType.Relative}
                            control={<Radio />}
                            label="Relative"
                        />
                        <FormControlLabel
                            sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                            value={DateRangeType.CurrentQueuePeriod}
                            control={<Radio />}
                            label="Current Queue Period"
                        />
                    </RadioGroup>
                </FormControl>
                {formik.values.type === DateRangeType.CurrentQueuePeriod && showDateRange(queueRangeStartDate, queueRangeEndDate)}
                {formik.values.type === DateRangeType.Specific && (
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <div style={{ display: 'flex', gap: 20 }}>
                            <DatePicker
                                slotProps={{
                                    textField: {
                                        variant: 'outlined',
                                        error: !!dateError,
                                        helperText: showDateError(startDateError, 'Start Date')
                                    },
                                    layout: {
                                        sx: {
                                            '& .MuiPickersDay-root': {
                                                color: theme.palette.mode === 'dark' ? '#dfe2ea' : ''
                                            }
                                        }
                                    }
                                }}
                                value={formik.values.startDate ? moment(formik.values.startDate, 'YYYY-MM-DD') : null}
                                onChange={(e) => formik.setFieldValue('startDate', moment(e).format('YYYY-MM-DD'))}
                                label="Start Date"
                                id="startDate"
                                name="startDate"
                                disableFuture
                                onError={(newError) => setStartDateError(newError)}
                            />
                            <DatePicker
                                slotProps={{
                                    textField: {
                                        variant: 'outlined',
                                        error: !!dateError,
                                        helperText: showDateError(endDateError, 'End Date')
                                    },
                                    layout: {
                                        sx: {
                                            '& .MuiPickersDay-root': {
                                                color: theme.palette.mode === 'dark' ? '#dfe2ea' : ''
                                            }
                                        }
                                    }
                                }}
                                value={formik.values.endDate ? moment(formik.values.endDate, 'YYYY-MM-DD') : null}
                                onChange={(e) => formik.setFieldValue('endDate', moment(e).format('YYYY-MM-DD'))}
                                label="End Date"
                                id="endDate"
                                name="endDate"
                                disableFuture
                                onError={(newError) => setEndDateError(newError)}
                            />
                        </div>
                        <div>
                            {!startDateError && !endDateError && (
                                <span
                                    style={{
                                        color: '#f44336',
                                        fontSize: '0.75rem',
                                        fontWeight: '400',
                                        marginLeft: '14px',
                                        marginTop: '3px'
                                    }}
                                >
                                    {dateError}
                                </span>
                            )}
                        </div>
                    </LocalizationProvider>
                )}
                {formik.values.type === DateRangeType.Relative && (
                    <>
                        <div className="date-range-container">
                            <p>Last</p>
                            <TextField
                                style={{ width: 60 }}
                                id="relativeDateCount"
                                name="relativeDateCount"
                                variant="outlined"
                                type="number"
                                InputProps={{
                                    inputProps:
                                        formik.values.relativeDateType === 'lastNDays'
                                            ? {
                                                  max: 31,
                                                  min: 1
                                              }
                                            : {
                                                  max: 24,
                                                  min: 1
                                              }
                                }}
                                value={formik.values.relativeDateCount}
                                onChange={formik.handleChange}
                                error={!!dateError}
                            />
                            <Select
                                labelId="date-unit-select-label"
                                id="relativeDateType"
                                name="relativeDateType"
                                value={formik.values.relativeDateType}
                                onChange={formik.handleChange}
                                sx={{ width: '30%' }}
                            >
                                {Object.keys(RelativeDateUnitOptions).map((item) => (
                                    <MenuItem key={`date-unit-option_${item}`} value={RelativeDateUnitOptions[item]}>
                                        {RelativeDateUnitOptionsLabels[item]}
                                    </MenuItem>
                                ))}
                            </Select>
                            {formik.values.relativeDateType === RelativeDateUnitOptions.LastNDays && (
                                <FormControlLabel
                                    sx={{ whiteSpace: 'nowrap' }}
                                    control={
                                        <Checkbox
                                            checked={formik.values.includeToday}
                                            onChange={(event) => formik.setFieldValue('includeToday', event.target.checked)}
                                        />
                                    }
                                    label="Include Today"
                                />
                            )}
                        </div>
                        {dateError && (
                            <span
                                style={{
                                    color: '#f44336',
                                    fontSize: '0.75rem',
                                    fontWeight: '400',
                                    marginLeft: '45px',
                                    marginTop: '3px'
                                }}
                            >
                                {dateError}
                            </span>
                        )}
                        {formik.values.relativeDateType === RelativeDateUnitOptions.LastNDays &&
                            !dateError &&
                            showDateRange(startDate, endDate)}
                    </>
                )}
                {formik.values.type !== DateRangeType.CurrentQueuePeriod && (
                    <div style={{ display: 'flex', margin: '8px 0' }}>
                        {weekdays().map((wd, index) => (
                            <FormControlLabel
                                key={wd}
                                sx={{ whiteSpace: 'nowrap' }}
                                control={
                                    <Checkbox
                                        checked={formik.values.weekDays[index]}
                                        onChange={(event) => {
                                            const newWeekDaysSelection = [...formik.values.weekDays];
                                            newWeekDaysSelection[index] = event.target.checked;
                                            formik.setFieldValue('weekDays', newWeekDaysSelection);
                                        }}
                                    />
                                }
                                label={wd.substring(0, 3)}
                            />
                        ))}
                    </div>
                )}
            </div>
            <Divider />
            <div className="date-filter-dialog-content">
                {formik.values.type !== DateRangeType.CurrentQueuePeriod &&
                    !(
                        formik.values.type === DateRangeType.Relative &&
                        formik.values.relativeDateType === RelativeDateUnitOptions.LastNHours
                    ) && (
                        <>
                            <FormControl>
                                <Typography id="time-radio-button-group" variant="h6">
                                    Time
                                </Typography>
                                <RadioGroup
                                    value={formik.values.timeType}
                                    onChange={formik.handleChange}
                                    row
                                    aria-labelledby="time-radio-button-group"
                                    name="timeType"
                                    id="timeType"
                                    className="radio-buttons-container"
                                >
                                    <FormControlLabel value={TimeRangeType.Continuous} control={<Radio />} label="Continuous" />
                                    <FormControlLabel value={TimeRangeType.Interval} control={<Radio />} label="Interval" />
                                </RadioGroup>
                            </FormControl>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <div style={{ display: 'flex', gap: 20 }}>
                                    <FormControl sx={{ width: '40%' }}>
                                        <TimePicker
                                            label={
                                                formik.values.timeType === TimeRangeType.Continuous
                                                    ? 'Start time on first day'
                                                    : 'Start time each day'
                                            }
                                            value={moment(formik.values.startTime)}
                                            onChange={(e) => formik.setFieldValue('startTime', e)}
                                            id="startTime"
                                            name="startTime"
                                            slotProps={{
                                                textField: {
                                                    variant: 'outlined',
                                                    error: !!timeError,
                                                    helperText:
                                                        startTimeError || !formik.values.startTime
                                                            ? "Start time should be in 'HH:MM' format"
                                                            : ''
                                                }
                                            }}
                                            viewRenderers={{
                                                hours: renderTimeViewClock,
                                                minutes: renderTimeViewClock,
                                                seconds: renderTimeViewClock
                                            }}
                                            onError={(newError) => setStartTimeError(newError)}
                                        />
                                    </FormControl>
                                    <FormControl sx={{ width: '40%' }}>
                                        <TimePicker
                                            label={
                                                formik.values.timeType === TimeRangeType.Continuous
                                                    ? 'End time on last day'
                                                    : 'End time each day'
                                            }
                                            value={moment(formik.values.endTime)}
                                            onChange={(e) => formik.setFieldValue('endTime', e)}
                                            id="endTime"
                                            name="endTime"
                                            slotProps={{
                                                textField: {
                                                    variant: 'outlined',
                                                    error: !!timeError,
                                                    helperText:
                                                        endTimeError || !formik.values.endTime ? "End time should be in 'HH:MM' format" : ''
                                                }
                                            }}
                                            viewRenderers={{
                                                hours: renderTimeViewClock,
                                                minutes: renderTimeViewClock,
                                                seconds: renderTimeViewClock
                                            }}
                                            onError={(newError) => setEndTimeError(newError)}
                                        />
                                    </FormControl>
                                </div>
                                <div
                                    style={{
                                        marginBottom: '2px'
                                    }}
                                >
                                    {!startTimeError && !endTimeError && formik.values.endTime && formik.values.startTime && (
                                        <span
                                            style={{
                                                color: '#f44336',
                                                fontSize: '0.75rem',
                                                fontWeight: '400',
                                                marginLeft: '14px',
                                                marginTop: '3px'
                                            }}
                                        >
                                            {timeError}
                                        </span>
                                    )}
                                </div>
                            </LocalizationProvider>
                        </>
                    )}
                <InputLabel sx={{ mb: 1 }} htmlFor="time-zone-section-label">
                    Timezone
                </InputLabel>
                <div id="time-zone-section-label">
                    <TimeZone
                        country={formik.values.countryCode}
                        useDst={formik.values.useDst}
                        setCountry={(value) => formik.setFieldValue('countryCode', value)}
                        setUseDst={(isChecked) => formik.setFieldValue('useDst', isChecked)}
                        gmtOffset={formik.values.utcOffsetInMinutes}
                        setGmt={(offset) => formik.setFieldValue('utcOffsetInMinutes', offset)}
                        setTimeZoneError={setTimeZoneError}
                    />
                </div>
            </div>
        </>
    );
}
