import { Link } from 'react-router-dom';

// material-ui
import { styled } from '@mui/material/styles';
import { Button, Card, CardMedia, Grid, Typography } from '@mui/material';

// project imports
import { DASHBOARD_PATH } from 'config';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { gridSpacing } from 'store/constant';

// assets
import { IconDashboard } from '@tabler/icons';

import image403 from 'assets/images/maintenance/403.svg';

// styles
const CardMediaWrapper = styled('div')({
    maxWidth: 720,
    margin: '0 auto',
    position: 'relative'
});

const ErrorWrapper = styled('div')({
    maxWidth: 350,
    margin: 'auto',
    textAlign: 'center'
});

const ErrorCard = styled(Card)({
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
});

// ==============================|| ERROR PAGE ||============================== //

const NoAccess = ({ hideDashboard }) => {
    return (
        <ErrorCard>
            <Grid container justifyContent="center" spacing={gridSpacing}>
                <Grid item sm={6} xs={12}>
                    <CardMediaWrapper>
                        <CardMedia component="img" image={image403} title="Slider5 image" />
                    </CardMediaWrapper>
                </Grid>
                <Grid item sm={6} xs={12} justifyContent="center" display="flex">
                    <ErrorWrapper>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12}>
                                <Typography variant="h1" component="div">
                                    Error 403
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2">{`You don't have permission to access this resource.`}</Typography>
                            </Grid>
                            {!hideDashboard && (
                                <Grid item xs={12}>
                                    <AnimateButton>
                                        <Button variant="contained" size="large" component={Link} to={DASHBOARD_PATH}>
                                            <IconDashboard style={{ fontSize: '1.3rem', marginRight: 5 }} /> Dashboard
                                        </Button>
                                    </AnimateButton>
                                </Grid>
                            )}
                        </Grid>
                    </ErrorWrapper>
                </Grid>
            </Grid>
        </ErrorCard>
    );
};

export default NoAccess;
