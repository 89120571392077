import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import { InputLabel, Tooltip } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { IconChartDonut, IconChartPie } from '@tabler/icons';

import { DashboardStateActions, DashboardStateDispatchContext } from '../context/DashboardStateContext';
import { DefaultDataGroupingOption, DonutWidgetChartTypes } from '../constants';
import { useContext } from 'react';
import * as Yup from 'yup';
import { useTheme } from '@emotion/react';
import { useSelector } from 'store';

export default function EditDonutChartWidgetDialog({
    isEditDonutChartDialogOpen,
    toggleEditDonutChartDialog,
    widget,
    onWidgetConfigUpdate
}) {
    const { allowedDonutOrPieChartWidgetConfig } = useSelector((state) => state.queueDashboard);
    const { dispatchDashboardStateAction } = useContext(DashboardStateDispatchContext);
    const validationSchemaForFields = Yup.object().shape({
        widgetName: Yup.string().trim().max(50, 'at most 50 charachters are allowed').required('Widget Name is required')
    });
    const theme = useTheme();

    const selectedChartTypeBorderColor = theme.palette.mode === 'dark' ? '#a0a9c0' : '#000000';

    const formik = useFormik({
        initialValues: {
            widgetName: widget.name,
            groupDataBy: widget.config?.groupDataBy ?? DefaultDataGroupingOption.Donut,
            graphType: widget.config?.graphType ?? DonutWidgetChartTypes.Donut
        },

        validationSchema: validationSchemaForFields,
        onSubmit: (values) => {
            const payload = {
                widgetId: widget.id,
                config: {
                    ...widget.config,
                    graphType: values.graphType,
                    name: values.widgetName,
                    groupDataBy: values.groupDataBy
                }
            };
            dispatchDashboardStateAction({
                type: DashboardStateActions.UpdateWidgetConfig,
                payload
            });
            dispatchDashboardStateAction({ type: DashboardStateActions.SetHasUnsavedChanges, payload: true });
            onWidgetConfigUpdate(payload);
            toggleEditDonutChartDialog();
        }
    });

    return (
        <Dialog fullWidth open={isEditDonutChartDialogOpen}>
            <div className="edit-donut-dialog-container">
                <div className="edit-donut-dialog-header">
                    <DialogTitle sx={{ padding: 0, paddingLeft: 3 }}>Edit Widget</DialogTitle>
                    <IconButton sx={{ marginRight: 2 }} aria-label="close" onClick={toggleEditDonutChartDialog}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <form className="edit-donut-dialog-content" onSubmit={formik.handleSubmit}>
                    <TextField
                        style={{ width: '50%' }}
                        id="widgetName"
                        name="widgetName"
                        label="Widget Name"
                        variant="outlined"
                        value={formik.values.widgetName}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={!!formik.errors.widgetName}
                        helperText={formik.errors.widgetName}
                    />
                    <FormControl fullWidth style={{ width: '50%' }}>
                        <InputLabel id="group-by-select-label">Group By</InputLabel>
                        <Select
                            labelId="group-by-select-label"
                            id="groupDataBy"
                            name="groupDataBy"
                            value={formik.values.groupDataBy}
                            onChange={formik.handleChange}
                            label="Group By"
                        >
                            {Object.entries(allowedDonutOrPieChartWidgetConfig?.allowedDataGroupingOptions ?? []).map(([item, value]) => (
                                <MenuItem key={`group-by-option_${item}`} value={item}>
                                    {value.displayName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <InputLabel id="group-by-select-label">Graph Type</InputLabel>
                    <div style={{ display: 'flex', gap: 20 }}>
                        {Object.entries(allowedDonutOrPieChartWidgetConfig?.allowedGraphTypes ?? []).map(([item, value]) => {
                            return (
                                <Tooltip key={item} title={value.displayName}>
                                    <IconButton
                                        key={item}
                                        style={{
                                            borderRadius: 5,
                                            width: 74,
                                            height: 74,
                                            border: formik.values.graphType === item ? `5px solid ${selectedChartTypeBorderColor}` : 'none',
                                            backgroundColor: theme.palette.mode === 'dark' ? '#111936' : '#EDE7F7'
                                        }}
                                        onClick={() => {
                                            formik.setFieldValue('graphType', item);
                                        }}
                                    >
                                        {item === DonutWidgetChartTypes.Pie ? <IconChartPie size={60} /> : <IconChartDonut size={60} />}
                                    </IconButton>
                                </Tooltip>
                            );
                        })}
                    </div>
                    <Stack spacing={2} direction="row" justifyContent="flex-end">
                        <Button variant="text" color="error" onClick={toggleEditDonutChartDialog}>
                            Cancel
                        </Button>
                        <Button variant="contained" type="submit" disabled={!formik.isValid}>
                            Save
                        </Button>
                    </Stack>
                </form>
            </div>
        </Dialog>
    );
}
