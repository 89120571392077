// theme constant
export const gridSpacing = 3;
export const drawerWidth = 300;
export const appDrawerWidth = 320;
export const SIZE = 20;
export const graphColors = {
    light: [
        '#3C59FC',
        '#DF309A',
        '#9435F9',
        '#38B3E3',
        '#3C0077',
        '#FF8300',
        '#C339BE',
        '#808585',
        '#9067A7',
        '#AB6857',
        '#CCC210',
        '#8CCBAC',
        '#AEA2E0',
        '#CBCDB5',
        '#EA4D41',
        '#F2C96D',
        '#00DBB7'
    ],
    dark: [
        '#3C59FC',
        '#DF309A',
        '#9435F9',
        '#38B3E3',
        '#0180D4',
        '#FF8300',
        '#C339BE',
        '#808585',
        '#9067A7',
        '#AB6857',
        '#CCC210',
        '#8CCBAC',
        '#AEA2E0',
        '#CBCDB5',
        '#EA4D41',
        '#F2C96D',
        '#00DBB7'
    ]
};

export const APP_FEATURES = {
    Queues: 'CallQueuesReporting'
};

// These should match the connector Type enum in the backend service
export const ConnectorTypes = {
    Cucm: 1,
    MSTeams: 2,
    Webex: 3
};

export const UserRoles = {
    SystemAdmin: 'SYSADMIN',
    QueueAdmin: 'QUEUEADMIN',
    QueueManager: 'QUEUEMGR',
    QueueAgent: 'QUEUEAGENT',
    ConnectorAdmin: 'CONNECTORADMIN',
    ConnectorViewer: 'CONNECTORVIEWER',
    ReportUser: 'RPTUSER',
    ReportAdmin: 'RPTADMIN'
    // TODO: Add other roles here too
};

export const QueuesDisabledMessage = 'Contact your Sales Representative to enable Queue Analytics';

export const DiscardChangesConfirmationDlgMsg = 'You have unsaved changes. Are you sure you want to discard them and leave the screen?';
