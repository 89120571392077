import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useLayoutEffect, useReducer, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import QueueListSelector from './QueueListSelector/QueueListSelector';
import MainWidgetGrid from './MainWidgetGrid/MainWidgetGrid';
import {
    DEFAULT_DASHBOARD_STATE,
    DashboardStateActions,
    DashboardStateContext,
    DashboardStateDispatchContext,
    DashboardStateReducer
} from './context/DashboardStateContext';
import { getSelectedDashboardDetails } from './Utils/queueDashboardApiCallsUtil';
import { useSnackbar } from 'notistack';

export default function QueueDashboard() {
    const { id: selectedDashboardId } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const { enqueueSnackbar } = useSnackbar();
    const [dashboardState, dispatchDashboardStateAction] = useReducer(DashboardStateReducer, DEFAULT_DASHBOARD_STATE);

    const handleResize = useCallback((e) => {
        setWindowHeight(e.target.innerHeight);
    }, []);

    useLayoutEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getDashboardData = useCallback(async () => {
        setIsLoading(true);
        const dashboardData = await getSelectedDashboardDetails(enqueueSnackbar, selectedDashboardId);
        if (!dashboardData) {
            setIsLoading(false);
            setError('No Data available for the selected dashboard'); // TODO: Check with the design team
            return;
        }
        setError('');

        // Order the widget metrics
        dashboardData.widgets.forEach((widget) => {
            widget.metrics.sort((metric1, metric2) => metric1.displayOrder - metric2.displayOrder);
        });

        dispatchDashboardStateAction({
            type: DashboardStateActions.LoadDashboard,
            payload: {
                dataSource: dashboardData.dataSource,
                queueIdsFilter: dashboardData.queueIdsFilter,
                selectedQueueId: dashboardData.selectedQueueId,
                widgets: dashboardData.widgets,
                dateTimeFilter: dashboardData.dateTimeFilter,
                name: dashboardData.name,
                isDefault: dashboardData.isDefault,
                id: dashboardData.id
            }
        });
        setIsLoading(false);
    }, [selectedDashboardId]);

    useEffect(() => {
        getDashboardData();
    }, [selectedDashboardId]);

    useEffect(() => {
        // TODO: Remove this after dev completion
        console.debug('Dashboard State [context]: ', dashboardState);
    }, [dashboardState]);

    if (isLoading) {
        return (
            <div className="loading-container">
                <CircularProgress />
            </div>
        );
    }

    if (error) {
        return (
            <div className="loading-container">
                <p>{error}</p>
            </div>
        );
    }

    return (
        <DashboardStateContext.Provider value={dashboardState}>
            <DashboardStateDispatchContext.Provider value={{ dispatchDashboardStateAction }}>
                <div key={selectedDashboardId} className="queue-dashboard-container" style={{ height: windowHeight - 108 }}>
                    <QueueListSelector height={windowHeight - 108}>
                        <MainWidgetGrid />
                    </QueueListSelector>
                </div>
            </DashboardStateDispatchContext.Provider>
        </DashboardStateContext.Provider>
    );
}
