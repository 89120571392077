// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconHeadset, IconPlus } from '@tabler/icons';
import CreateNewQueueDashboardButton from 'views/QueueDashboard/CreateQueueDashboardDialog/CreateNewQueueDashboardButton';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

export const getQueueDashboardMenuItems = () => {
    const queueDashboardMenuItems = {
        id: 'queue-dashboard',
        url: '/dashboard',
        title: <FormattedMessage id="queue-dashboards" />,
        icon: IconHeadset,
        disabled: true,
        iconRight: <CreateNewQueueDashboardButton />,
        type: 'collapse',
        children: []
    };

    return queueDashboardMenuItems;
};

const getChildItems = () => {
    const childItems = [
        {
            id: 'dashboard',
            url: '/dashboard',
            title: <FormattedMessage id="my-dashboard" />,
            icon: IconDashboard,
            type: 'collapse',
            children: [],
            isBeta: 'true'
        }
    ];
    return childItems;
};

const dashboard = {
    id: 'dashboard',
    title: <FormattedMessage id="dashboard" />,
    icon: IconDashboard,
    type: 'group',
    children: getChildItems()
};

export default dashboard;
