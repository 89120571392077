import { UNSAFE_NavigationContext as unsafeNavigationContext, useLocation } from 'react-router-dom';
import { useContext, useEffect, useCallback, useState } from 'react';

/**
 * This hook provides a way to block the navigation from current location
 * @param {Boolean value which determines when to block navigation } when
 */
function useNavigationBlocker(when) {
    const navigator = useContext(unsafeNavigationContext).navigator;

    const location = useLocation();
    const [showPrompt, setShowPrompt] = useState(false);
    const [nextLocation, setNextLocation] = useState(null);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);

    const cancelNavigation = useCallback(() => {
        setShowPrompt(false);
    }, []);

    const confirmNavigation = useCallback(() => {
        setShowPrompt(false);
        setConfirmedNavigation(true);
    }, []);

    const handleBlockedNavigation = useCallback(
        (newLocation) => {
            if (!confirmedNavigation && newLocation.location.pathname !== location.pathname) {
                setNextLocation(newLocation);
                setShowPrompt(true);
                return false;
            }
            return true;
        },
        [confirmedNavigation, location]
    );

    useEffect(() => {
        if (confirmedNavigation && nextLocation) {
            nextLocation.retry();
        }
    }, [confirmedNavigation, nextLocation]);

    useEffect(() => {
        if (!when) {
            return () => {};
        }

        const unblock = navigator.block((tx) => {
            const autoUnblockingTx = {
                ...tx,
                retry() {
                    unblock();
                    tx.retry();
                }
            };

            handleBlockedNavigation(autoUnblockingTx);
        });

        return unblock;
    }, [navigator, handleBlockedNavigation, when]);

    return [showPrompt, confirmNavigation, cancelNavigation];
}

export default useNavigationBlocker;
