// project imports
import { createSlice } from '@reduxjs/toolkit';
import { axiosUserDataService, axiosMSTeamsCQService } from 'utils/axios';
import { getAllowedMetricsConfigurationFromAPI, setAllowedMetricsConfig } from './queueConfig';

export const initialState = {
    queueDashboards: [],
    queueDashboardTemplates: [], // NOTE: This would have a list of  { id, name, dataSource } objects
    queueDashboardError: null,
    isQueueDashboardListLoaded: false,
    reevaluateQueueAccess: true,
    allowedQueueMetricsWidgetConfig: {},
    allowedAgentTableWidgetConfig: {},
    allowedDonutOrPieChartWidgetConfig: {},
    allowedTimeseriesWidgetConfig: {},
    isCreateQueueDshDlgOpen: false
};

const slice = createSlice({
    name: 'queueDashboard',
    initialState,
    reducers: {
        getQueueDashboardListSuccess(state, action) {
            state.queueDashboards = action.payload;
            state.isQueueDashboardListLoaded = true;
        },
        getQueueDashboardTemplateListSuccess(state, action) {
            state.queueDashboardTemplates = action.payload;
        },
        getQueueDashboardFailure(state, action) {
            state.queueDashboardError = action.payload;
        },
        setDefaultQueueDashboardSuccess(state, action) {
            state.queueDashboards = state.queueDashboards.map((d) => ({
                ...d,
                isDefault: d.id === action.payload
            }));
        },
        createQueueDashboardSuccess(state, action) {
            state.queueDashboards = [
                ...state.queueDashboards,
                {
                    id: state.queueDashboards.length + 1,
                    name: action.payload.name,
                    displayOrder: 0,
                    layout: '',
                    ownerId: 0
                }
            ];
        },
        setReEvaluateQueueAccess(state, action) {
            state.reevaluateQueueAccess = action.payload;
        },
        setAllowedQueueMetricsWidgetConfig(state, action) {
            state.allowedQueueMetricsWidgetConfig = action.payload;
        },
        setAllowedAgentTableWidgetConfig(state, action) {
            state.allowedAgentTableWidgetConfig = action.payload;
        },
        setAllowedDonutOrPieChartWidgetConfig(state, action) {
            state.allowedDonutOrPieChartWidgetConfig = action.payload;
        },
        setAllowedTimeseriesWidgetConfig(state, action) {
            state.allowedTimeseriesWidgetConfig = action.payload;
        },
        deleteDashboardSuccess(state, action) {
            state.queueDashboards = state.queueDashboards.filter((item) => item.id !== action.payload);
        },
        toggleIsCreateQueueDshDlgOpn(state) {
            state.isCreateQueueDshDlgOpen = !state.isCreateQueueDshDlgOpen;
        },
        updateDashboardName(state, action) {
            state.queueDashboards = state.queueDashboards.map((item) => {
                if (item.id === parseInt(action.payload.id, 10)) {
                    return {
                        ...item,
                        name: action.payload.name
                    };
                }
                return item;
            });
        }
    }
});

export default slice.reducer;

export const {
    setReEvaluateQueueAccess,
    getQueueDashboardListSuccess,
    getQueueDashboardTemplateListSuccess,
    getQueueDashboardFailure,
    setDefaultQueueDashboardSuccess,
    createQueueDashboardSuccess,
    deleteDashboardSuccess,
    setAllowedQueueMetricsWidgetConfig,
    setAllowedAgentTableWidgetConfig,
    setAllowedDonutOrPieChartWidgetConfig,
    setAllowedTimeseriesWidgetConfig,
    toggleIsCreateQueueDshDlgOpn,
    updateDashboardName
} = slice.actions;

export function loadQueueDashboardList(enqueueSnackbar) {
    return async (dispatch) => {
        try {
            // Call API to get the list of queue dashboards for the logged in user - dashId, dashName
            const response = await axiosUserDataService.get('/api/queueDashboard');
            if (response?.data?.success && response?.data?.data) {
                dispatch(slice.actions.getQueueDashboardListSuccess(response.data.data));
            } else {
                enqueueSnackbar('Failed to load the queue dashboards', { variant: 'error' });
                console.log('Failed to get the queue dashboards. Error: ', response?.data?.error);
            }
        } catch (ex) {
            if (ex.response) {
                if (ex.response.status === 403) {
                    // No access
                    enqueueSnackbar('Insufficient privelleges for queue dashboards', { variant: 'error' });
                } else {
                    enqueueSnackbar('Failed to load the queue dashboards', { variant: 'error' });
                    console.log('Failed to query queue dashboards. Error: ', ex.response);
                }
            } else {
                enqueueSnackbar('Failed to load the queue dashboards', { variant: 'error' });
                console.log('Failed to connect to server while trying to get queue dashboards', ex);
            }
        }
    };
}

export function loadQueueDashboardTemplateList(enqueueSnackbar) {
    return async (dispatch) => {
        try {
            // Call API to get the list of queue dashboards for the logged in user - dashId, dashName
            const response = await axiosUserDataService.get('/api/queueDashboard/templates');
            if (response?.data?.success && response?.data?.data) {
                dispatch(slice.actions.getQueueDashboardTemplateListSuccess(response.data.data));
            } else {
                console.log('Failed to get the queue dashboards. Error: ', response?.data?.error);
            }
        } catch (ex) {
            if (ex.response) {
                if (ex.response.status === 403) {
                    // No access
                    enqueueSnackbar('Insufficient privileges for queue dashboards', { variant: 'error' });
                } else {
                    enqueueSnackbar('Failed to get the queue dashboard templates', { variant: 'error' });
                    console.log('Failed to query queue dashboard template. Error: ', ex.response);
                }
            } else {
                enqueueSnackbar('Failed to get the queue dashboards', { variant: 'error' });
                console.log('Failed to connect to server while trying to get queue dashboards', ex);
            }
        }
    };
}

export function createBlankQueueDashboard(enqueueSnackbar, dashboard, callback) {
    return async (dispatch) => {
        try {
            const response = await axiosUserDataService.post('api/queueDashboard', dashboard);
            if (response?.data?.success) {
                dispatch(loadQueueDashboardList(enqueueSnackbar));
                callback(response.data.data);
                enqueueSnackbar(`Successfully created dashboard - ${dashboard.name}`, { variant: 'success' });
            } else {
                enqueueSnackbar(`Failed to create queue dashboard`, { variant: 'error' });
                callback(null);
            }
        } catch (ex) {
            enqueueSnackbar(`Failed to create queue dashboard`, { variant: 'error' });
            callback(null);
        }
    };
}

export function createQueueDashboardByCopying(enqueueSnackbar, dashboard, callback) {
    return async (dispatch) => {
        try {
            const response = await axiosUserDataService.post('api/queueDashboard/copy', dashboard);
            if (response?.data?.success) {
                dispatch(loadQueueDashboardList(enqueueSnackbar));
                callback(response.data.data);
                enqueueSnackbar(`Successfully created dashboard - ${dashboard.name}`, { variant: 'success' });
            } else {
                callback(null);
                enqueueSnackbar(`Failed to create queue dashboard`, { variant: 'error' });
            }
        } catch (ex) {
            callback(null);
            enqueueSnackbar(`Failed to create queue dashboard`, { variant: 'error' });
        }
    };
}

// This method is not used right now, but might be needed later
export function loadAllowedQueueAndAgentMetricsConfiguration() {
    return async (dispatch) => {
        const allowedMetrics = await getAllowedMetricsConfigurationFromAPI();
        if (allowedMetrics) {
            dispatch(setAllowedMetricsConfig(allowedMetrics));
        }
    };
}

export function loadAllowedWidgetsConfig(enqueueSnackbar) {
    return async (dispatch) => {
        try {
            const response = await axiosUserDataService.get(`/api/queueDashboardMeta/allowedWidgetsConfig`);
            if (response?.data?.success && response.data.data) {
                // Success
                dispatch(slice.actions.setAllowedQueueMetricsWidgetConfig(response.data.data.queueMetrics));
                dispatch(slice.actions.setAllowedAgentTableWidgetConfig(response.data.data.agentTable));
                dispatch(slice.actions.setAllowedDonutOrPieChartWidgetConfig(response.data.data.donutOrPieChart));
                dispatch(slice.actions.setAllowedTimeseriesWidgetConfig(response.data.data.timeSeries));
            } else {
                // Failure
                enqueueSnackbar('Failed to load allowed widgets configuration', { variant: 'error' });
                console.log('Failed to load allowed widgets configuration: ', response.data.error);
            }
        } catch (ex) {
            if (ex.response) {
                // Failure from backend
                enqueueSnackbar('Failed to load allowed widgets configuration', { variant: 'error' });
                console.log('Error Response: ', ex.response);
            } else {
                // Failed to connect to backend
                enqueueSnackbar('Failed to load allowed widgets configuration. Please try again in some time', {
                    variant: 'error'
                });
                console.log('Failed to connect to backend while trying to load allowed widgets configuration. Exception: ', ex);
            }
        }
    };
}
