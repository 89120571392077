import React, { useContext, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Card from '@mui/material/Card';
import { IconPlus } from '@tabler/icons';

import QueueMetricWidgetThumbnail from 'assets/images/icons/queue-single-metric.svg';
import QueueDonutWidgetThumbnail from 'assets/images/icons/queue-donut-widget.svg';
import QueueTimeseriesWidgetThumbnail from 'assets/images/icons/queue-timeseries-widget.svg';
import QueueTableWidgetThumbnail from 'assets/images/icons/queue-table-widget.svg';

import { DashboardStateActions, DashboardStateContext, DashboardStateDispatchContext } from '../context/DashboardStateContext';
import {
    DefaultDataGroupingOption,
    DonutWidgetChartTypes,
    TimeSeriesGroupTimeByOptions,
    TimeSeriesMetricsCategory,
    TimeSeriesWidgetChartTypes,
    WidgetTypes
} from '../constants';

function getXandYForNewWidget(widgets, breakpoint, itemToBeAddedWidth) {
    if (!widgets.length) {
        return { x: 0, y: 0 };
    }
    const ySortedLayout = widgets.map((widget) => widget.layout[breakpoint]).toSorted((a, b) => a.y - b.y);
    const lastYItem = ySortedLayout[ySortedLayout.length - 1];
    const y = lastYItem.y + lastYItem.h;
    const firstEmptyX = Math.max(...ySortedLayout.filter((item) => item.y === lastYItem.y).map((item) => item.x + item.w));
    const x = itemToBeAddedWidth + firstEmptyX <= 6 ? firstEmptyX : 0;
    return { x, y };
}

export default function CreateWidgetDialog({ disabled }) {
    const [isWidgetCreationDialogOpen, setIsWidgetCreationDialogOpen] = useState(false);

    const { widgets, breakpoint } = useContext(DashboardStateContext);
    const { dispatchDashboardStateAction } = useContext(DashboardStateDispatchContext);

    const toggleWidgetCreationDialog = () => {
        setIsWidgetCreationDialogOpen((open) => !open);
    };

    const addNewWidget = (type) => {
        let dimensions;
        let config;
        let metrics;
        let name;
        // TODO: Get this from backend service
        switch (type) {
            case WidgetTypes.AgentTable:
                name = 'New Agents Table';
                dimensions = { h: 4, w: 4 };
                metrics = [
                    {
                        id: 'new-widget-temp',
                        metric: 'answeredCalls',
                        calculationType: 'count',
                        displayAs: 'Answered Calls',
                        displayOrder: 0
                    }
                ];
                config = {
                    sortOrder: [
                        {
                            columnName: 'Name',
                            ascending: true
                        }
                    ]
                };
                break;
            case WidgetTypes.QueueMetrics:
                name = 'New Queue Metrics Widget';
                dimensions = { h: 2, w: 2 };
                config = {
                    maxMetricsPerRow: 5
                };
                metrics = [
                    {
                        id: 'new-widget-temp',
                        metric: 'answeredCalls',
                        calculationType: 'count',
                        displayAs: 'Answered Calls',
                        displayOrder: 0
                    }
                ];
                break;
            case WidgetTypes.DonutOrPieChart:
                name = 'New Donut Widget';
                config = {
                    graphType: DonutWidgetChartTypes.Donut,
                    groupDataBy: DefaultDataGroupingOption.Donut
                };
                metrics = [];
                dimensions = { h: 2, w: 2 };
                break;
            case WidgetTypes.TimeSeries:
                name = 'New TimeSeries Widget';
                dimensions = { h: 2, w: 2 };
                config = {
                    graphType: TimeSeriesWidgetChartTypes.Line,
                    groupTimeBy: TimeSeriesGroupTimeByOptions.Hours,
                    metricCategory: TimeSeriesMetricsCategory.QueueCallCounts,
                    groupDataBy: DefaultDataGroupingOption.TimeSeries,
                    yAxisCaption: '# calls per hour'
                };
                metrics = null;
                break;
            default:
                // Unknown
                break;
        }

        const coordinates = getXandYForNewWidget(widgets, breakpoint, dimensions.w);

        dispatchDashboardStateAction({
            type: DashboardStateActions.AddWidgetToWigetsList,
            payload: {
                name,
                type,
                metrics,
                config,
                layout: {
                    sm: {
                        ...coordinates,
                        ...dimensions
                    },
                    md: {
                        ...coordinates,
                        ...dimensions
                    },
                    lg: {
                        ...coordinates,
                        ...dimensions
                    },
                    xs: {
                        ...coordinates,
                        ...dimensions
                    },
                    xxs: {
                        ...coordinates,
                        ...dimensions
                    }
                }
            }
        });
        dispatchDashboardStateAction({ type: DashboardStateActions.SetHasUnsavedChanges, payload: true });
        toggleWidgetCreationDialog();
    };

    return (
        <>
            <IconButton title="Add Widget" disabled={disabled} onClick={toggleWidgetCreationDialog}>
                <IconPlus color="#681abb" />
            </IconButton>
            <Dialog open={isWidgetCreationDialogOpen}>
                <div className="create-widget-dialog">
                    <div className="create-widget-header">
                        <DialogTitle style={{ padding: 0 }}>Add Widget</DialogTitle>
                        <IconButton aria-label="close" onClick={toggleWidgetCreationDialog}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <div className="widget-options-grid-container">
                        <Card className="widget-option" onClick={() => addNewWidget(WidgetTypes.QueueMetrics)}>
                            <div className="single-metric-thumbnail">
                                <img src={QueueMetricWidgetThumbnail} alt="queue metrics" />
                            </div>
                            <p className="widget-option-label">Queue Metrics</p>
                        </Card>
                        <Card className="widget-option" onClick={() => addNewWidget(WidgetTypes.DonutOrPieChart)}>
                            <img height={150} style={{ paddingTop: 10 }} src={QueueDonutWidgetThumbnail} alt="donut metric" />
                            <p className="widget-option-label">Donut</p>
                        </Card>
                        <Card className="widget-option" onClick={() => addNewWidget(WidgetTypes.TimeSeries)}>
                            <img width={198} src={QueueTimeseriesWidgetThumbnail} alt="timeseries metric" />
                            <p className="widget-option-label">Timeseries</p>
                        </Card>
                        <Card className="widget-option" onClick={() => addNewWidget(WidgetTypes.AgentTable)}>
                            <img width={198} src={QueueTableWidgetThumbnail} alt="table metric" />
                            <p className="widget-option-label">Agent</p>
                        </Card>
                    </div>
                </div>
            </Dialog>
        </>
    );
}
