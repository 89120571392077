// project imports
import services from 'utils/mockAdapter';

const dummyData = [
    {
        id: 1,
        title: 'Top Usage by Data Source',
        description:
            'Summary of the top # (defaut 10) extension for each data source based on duration or cost. Report includes call count, cost and duration information for each call type.',
        scheduedOn: '10-12-2023',
        scheduedFor: 'Daily'
    },
    {
        id: 2,
        title: 'Call Detail Record Search - May month 2',
        description: 'Individual call recored sorted by phone number, daye/time , call type, duratin or cost',
        scheduedOn: '10-12-2023',
        scheduedFor: 'Daily'
    },
    {
        id: 3,
        title: 'Top Usage by Data Source',
        description:
            'Summary of the top # (defaut 10) extension for each data source based on duration or cost. Report includes call count, cost and duration information for each call type.',
        scheduedOn: '10-12-2023',
        scheduedFor: 'Daily'
    },
    {
        id: 4,
        title: 'Top Usage by Data Source',
        description:
            'Summary of the top # (defaut 10) extension for each data source based on duration or cost. Report includes call count, cost and duration information for each call type.',
        scheduedOn: '10-12-2023',
        scheduedFor: 'Daily'
    },
    {
        id: 5,
        title: 'Call Detail Record Search - May month 2',
        description:
            'Summary of the top # (defaut 10) extension for each data source based on duration or cost. Report includes call count, cost and duration information for each call type.',
        scheduedOn: '10-12-2023',
        scheduedFor: 'Daily'
    },
    {
        id: 6,
        title: 'Top Usage by Data Source',
        description:
            'Summary of the top # (defaut 10) extension for each data source based on duration or cost. Report includes call count, cost and duration information for each call type.',
        scheduedOn: '10-12-2023',
        scheduedFor: 'Daily'
    },
    {
        id: 7,
        title: 'Top Usage by Data Source',
        description: 'Individual call recored sorted by phone number, daye/time , call type, duratin or cost',
        scheduedOn: '10-12-2023',
        scheduedFor: 'Daily'
    },
    {
        id: 8,
        title: 'Top Usage by Data Source',
        description: 'Individual call recored sorted by phone number, daye/time , call type, duratin or cost',
        scheduedOn: '10-12-2023',
        scheduedFor: 'Daily'
    },
    {
        id: 9,
        title: 'Top Usage by Data Source',
        description:
            'Summary of the top # (defaut 10) extension for each data source based on duration or cost. Report includes call count, cost and duration information for each call type.',
        scheduedOn: '10-12-2023',
        scheduedFor: 'Daily'
    },
    {
        id: 10,
        title: 'Top Usage by Data Source',
        description:
            'Summary of the top # (defaut 10) extension for each data source based on duration or cost. Report includes call count, cost and duration information for each call type.',
        scheduedOn: '10-12-2023',
        scheduedFor: 'Daily'
    }
];

// ==============================|| MOCK SERVICES ||============================== //

services.onGet('/api/scheduleReport/list').reply(200, { data: dummyData, success: true });
