import PropTypes from 'prop-types';
import { useEffect } from 'react';

// material-ui
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

// third-party
import rtlPlugin from 'stylis-plugin-rtl';
import useConfig from 'hooks/useConfig';
import { CssBaseline } from '@mui/material';

// ==============================|| RTL LAYOUT ||============================== //

const RTLLayout = ({ children }) => {
    const { rtlLayout } = useConfig();
    const id = document.getElementById('styleNonce')?.nonce;
    useEffect(() => {
        document.dir = rtlLayout ? 'rtl' : 'ltr';
    }, [rtlLayout]);

    const cacheRtl = createCache({
        key: rtlLayout ? 'rtl' : 'css',
        prepend: true,
        nonce: id,
        stylisPlugins: rtlLayout ? [rtlPlugin] : []
    });

    return (
        <CacheProvider value={cacheRtl}>
            <CssBaseline />
            {children}
        </CacheProvider>
    );
};

RTLLayout.propTypes = {
    children: PropTypes.node
};

export default RTLLayout;
