// third-party
import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

// project imports
import menuReducer from './slices/menu';
import widgetsReducer from './slices/widgets';
import dashboardReducer from './slices/dashboard';
import lookupReducer from './slices/lookup';
import templateReducer from './slices/templates';
import reportParametersReducer from './slices/reportParameters';
import reportReducer from './slices/report';
import breadcrumbReducer from './slices/breadcrumb';
import scheduledReportsReducer from './slices/scheduledReports';
import preferencesReducer from './slices/preferences';
import notificationsReducer from './slices/notifications';
import historyReducer from './slices/history';
import connectorReducer from './slices/connector';
import remoteCollectionsReducer from './slices/remoteCollections';
import queueDashboardReducer from './slices/queueDashboard';
import callQueuesReducer from './slices/callQueues';
import directoryImportsReducer from './slices/directoryImports';
import directoryImportParameters from './slices/directoryImportParameters';
import directoryImportSchedule from './slices/dirImportSchedule';
import queueConfigReducer from './slices/queueConfig';

// ==============================|| COMBINE REDUCER ||============================== //

const menuPersistConfig = {
    key: 'menu',
    version: 1,
    storage,
    whitelist: ['drawerOpen']
};

const reducer = combineReducers({
    menu: persistReducer(menuPersistConfig, menuReducer),
    dashboard: dashboardReducer,
    queueDashboard: queueDashboardReducer,
    widgets: widgetsReducer,
    lookup: lookupReducer,
    templates: templateReducer,
    reportParameters: reportParametersReducer,
    report: reportReducer,
    breadcrumb: breadcrumbReducer,
    scheduledReports: scheduledReportsReducer,
    preferences: preferencesReducer,
    notifications: notificationsReducer,
    history: historyReducer,
    connector: connectorReducer,
    remoteCollection: remoteCollectionsReducer,
    callQueues: callQueuesReducer,
    directoryImport: directoryImportsReducer,
    directoryImportParameters,
    directoryImportSchedule,
    queueConfig: queueConfigReducer
});

export default reducer;
