import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Legend, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';

// project imports
import { graphColors } from 'store/constant';
import { groupDataByOther } from 'utils/graphUtils';
import { IconCircle } from '@tabler/icons';

const othersLabel = 'others';
const CustomLegend = ({ payload, onGoBack, isExpanded, onLegendClick, showCount }) => (
    <Grid container alignItems="center" style={{ paddingRight: '16px' }}>
        {isExpanded && (
            <Grid item xs={12}>
                <Typography onClick={onGoBack} style={{ cursor: 'pointer', marginBottom: '10px' }}>
                    Previous level
                </Typography>
            </Grid>
        )}
        {payload.map((entry, index) => (
            <Grid
                item
                key={`item-${index}`}
                style={{ display: 'flex', alignItems: 'center', marginBottom: '5px', cursor: 'pointer' }}
                onClick={() => onLegendClick(entry)}
                onKeyUp={() => onLegendClick(entry)}
                role="button"
                tabIndex={0}
                xs={12}
            >
                <Typography>
                    <IconCircle style={{ color: entry.color, fill: entry.color, marginRight: 5 }} size={10} />

                    {entry.value}
                    {entry.value.toLowerCase() !== othersLabel && showCount && ` (${entry.Count})`}
                </Typography>
            </Grid>
        ))}
    </Grid>
);

const BasicPieChart = ({ data, widgetConfig, handleDrillDown, generateTooltip, showPointerCursor }) => {
    const sortedData = data.sort((a, b) => b.Count - a.Count);
    const theme = useTheme();
    const themeColors = theme.palette.mode === 'dark' ? graphColors.dark : graphColors.light;
    const [selected, setSelected] = useState({});

    const getDisplayData = (groupData) => {
        return groupData.filter((dataItem) => !selected[dataItem[widgetConfig.nameKey]]);
    };

    const [groupedData, setGroupedData] = useState(() => groupDataByOther(sortedData, widgetConfig.nameKey, 0));
    const [history, setHistory] = useState([]);

    const colorMap = {};
    groupedData.forEach((dataItm, index) => {
        colorMap[dataItm.name] = dataItm.fill ?? themeColors[index];
    });

    const handleLegendClick = ({ color, value }) => {
        setSelected({
            ...selected,
            [value]: !selected[value]
        });
    };

    const expandOther = (groupedData, index) => {
        if (groupedData[index][widgetConfig.nameKey].toLowerCase() === othersLabel) {
            const lastIndex = groupedData[index].index;
            const remainingData = sortedData.slice(lastIndex);
            const expandedData = groupDataByOther(remainingData, widgetConfig.nameKey, lastIndex);
            return [...expandedData];
        }
        return data;
    };

    const handleExpand = () => {
        const index = groupedData.findIndex((item) => item[widgetConfig.nameKey].toLowerCase() === othersLabel);
        if (index !== -1) {
            const newData = expandOther(groupedData, index);
            setHistory([...history, groupedData]);
            setGroupedData(newData);
        }
    };

    const handleSectorClick = (name, widgetConfig) => {
        // if xAxisField is OwnerName and not clicked on Other, stop the drill down from this level
        if (widgetConfig?.xAxisField?.toLowerCase() === 'ownername' && name.toLowerCase() !== othersLabel) {
            return;
        }
        if (name.toLowerCase() === othersLabel) {
            handleExpand();
        } else {
            handleDrillDown(name, widgetConfig);
        }
    };
    const handleGoBack = () => {
        if (history.length > 0) {
            const prevData = history[history.length - 1];
            setHistory(history.slice(0, -1));
            setGroupedData(prevData);
        }
    };

    const getDataKey = () => {
        if (widgetConfig.dataKey && widgetConfig.dataKey !== '') return widgetConfig.dataKey;
        return getDisplayData(groupedData)[0][widgetConfig.rankingField]
            ? widgetConfig.rankingField
            : Object.keys(getDisplayData(groupedData)[0])[1];
    };
    useEffect(() => {
        setGroupedData(groupDataByOther(data, widgetConfig.nameKey, 0));
    }, [data]);

    const payload = groupedData.map((entry) => ({
        ...entry,
        color: selected[entry[widgetConfig.nameKey]] ? '#CCCCCC' : colorMap[entry.name],
        value: entry.displayName || entry.name
    }));

    return (
        <ResponsiveContainer width={widgetConfig.width} height={widgetConfig.height} align="center">
            <PieChart>
                {getDisplayData(groupedData).length > 0 && (
                    <Pie
                        data={getDisplayData(groupedData)}
                        dataKey={getDataKey()}
                        nameKey={widgetConfig.nameKey}
                        cx="50%"
                        cy="50%"
                        outerRadius="80%"
                        innerRadius={widgetConfig.renderDonut ? '60%' : 0}
                        isAnimationActive={false}
                        onClick={(data) => handleSectorClick(data.name, widgetConfig)}
                    >
                        {getDisplayData(groupedData).map((entry, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={colorMap[entry.name]}
                                cursor={
                                    showPointerCursor || entry[widgetConfig.nameKey].toLowerCase() === othersLabel ? 'pointer' : 'default'
                                }
                            />
                        ))}
                    </Pie>
                )}
                {generateTooltip()}
                {groupedData.length > 10 ? (
                    <Legend iconType="circle" iconSize={10} onClick={handleLegendClick} payload={payload} />
                ) : (
                    <Legend
                        layout="vertical"
                        verticalAlign="middle"
                        align="right"
                        width="45%"
                        content={
                            <CustomLegend
                                onGoBack={handleGoBack}
                                isExpanded={history.length > 0}
                                onLegendClick={handleLegendClick}
                                showCount={widgetConfig.showCountInLegend}
                            />
                        }
                        payload={payload}
                    />
                )}
            </PieChart>
        </ResponsiveContainer>
    );
};

BasicPieChart.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    widgetConfig: PropTypes.object,
    handleDrillDown: PropTypes.func,
    generateTooltip: PropTypes.func,
    showPointerCursor: PropTypes.bool
};

CustomLegend.propTypes = {
    onGoBack: PropTypes.func,
    isExpanded: PropTypes.bool,
    onLegendClick: PropTypes.func,
    payload: PropTypes.arrayOf(PropTypes.object)
};

export default BasicPieChart;
