import {
    mdiPhoneIncomingOutline,
    mdiPhoneCheckOutline,
    mdiPhoneRemoveOutline,
    mdiPhoneForwardOutline,
    mdiPhoneLogOutline,
    mdiPhoneRingOutline,
    mdiPhoneInTalkOutline,
    mdiPhoneOutline,
    mdiPhoneAlertOutline
} from '@mdi/js';
import { graphColors } from 'store/constant';

export const Status = {
    Normal: 'Normal',
    Warning: 'Warning',
    Critical: 'Critical'
};

export const StatusColor = {
    Normal: '#00DBB7',
    Warning: '#FFA823',
    Critical: '#FF4D58'
};

export const WidgetTitleColor = '#212121';

export const CalculationTypes = {
    count: 'count',
    average: 'average',
    minimum: 'minimum',
    maximum: 'maximum',
    percent: 'percent'
};

export const CalculationLabels = {
    count: 'Count',
    average: 'Average',
    minimum: 'Minimum',
    maximum: 'Maximum',
    percent: 'Percentage'
};

export const WidgetTypes = {
    QueueMetrics: 'queueMetrics',
    AgentTable: 'agentTable',
    DonutOrPieChart: 'donutOrPieChart',
    TimeSeries: 'timeSeries'
};

export const DonutWidgetChartTypes = {
    Donut: 'donut',
    Pie: 'pie'
};

export const TimeSeriesGroupTimeByOptions = {
    Hours: 'hours',
    Days: 'days',
    Weeks: 'weeks'
};

export const TimeSeriesWidgetChartTypes = {
    VerticalBar: 'stackedBarVertical',
    HorizontalBar: 'stackedBarHorizontal',
    Line: 'line',
    Area: 'area'
};

export const QueueDataSource = {
    MicrosoftTeams: 'microsoftTeams',
    CiscoWebex: 'ciscoWebex'
};

export const DateTimeFilterType = {
    Specific: 'Specific',
    Relative: 'Relative',
    QueuePeriod: 'QueuePeriod'
};

export const RelativeDateFilterUnitType = {
    Hour: 'Hour',
    Day: 'Day',
    Week: 'Week'
};

export const TimeFilterType = {
    Continuous: 'Continuous',
    Interval: 'Interval'
};

export const TimeSeriesMetricsCategory = {
    QueueCallCounts: 'queueCallCounts',
    QueueCallTimes: 'queueCallTimes',
    CallsAnsweredByAgent: 'agentCallCounts',
    AgentCallTimes: 'agentCallTimes'
};

export const GroupWidgetDataBy = {
    QueueSourceType: 'queueSourceType',
    QueueDisposition: 'queueDisposition',
    QueueHandlingType: 'queueHandlingType',
    QueueFinalAction: 'queueFinalAction',
    CallsAnsweredByAgent: 'answeringAgent'
};

// Defaults should be one of the allowed grouping options
export const DefaultDataGroupingOption = {
    Donut: GroupWidgetDataBy.QueueSourceType,
    TimeSeries: GroupWidgetDataBy.QueueSourceType
};

export const GroupDonutChartDataHierarchy = {
    [GroupWidgetDataBy.QueueSourceType]: GroupWidgetDataBy.QueueDisposition,
    [GroupWidgetDataBy.QueueDisposition]: GroupWidgetDataBy.QueueHandlingType,
    [GroupWidgetDataBy.QueueHandlingType]: GroupWidgetDataBy.QueueFinalAction
};

export const QueueDashboardEvents = {
    RefreshWidgetData: 'refreshQueueDashboardWidgetData'
};

// Refresh every 5 mins (300000 milliseconds)
export const RefreshIntervalMs = 300000;

export const MetricCardConfig = {
    totalCalls: {
        label: 'Total Calls',
        icon: () => mdiPhoneIncomingOutline
    },
    answeredCalls: {
        label: 'Answered Calls',
        icon: () => mdiPhoneCheckOutline
    },
    unansweredCalls: {
        label: 'Unanswered Calls',
        icon: () => null
    },
    abandonedCalls: {
        label: 'Abandoned Calls',
        icon: () => mdiPhoneRemoveOutline
    },
    missedCalls: {
        label: 'Missed Calls',
        icon: () => mdiPhoneForwardOutline
    },
    queuedCalls: {
        label: 'Queued Calls',
        icon: () => mdiPhoneLogOutline
    },
    presentedCalls: {
        label: 'Presented Calls',
        icon: () => mdiPhoneIncomingOutline
    },
    queueTime: {
        label: 'Queue Time',
        icon: () => mdiPhoneLogOutline
    },
    answerTime: {
        label: 'Answer Time',
        icon: () => mdiPhoneRingOutline
    },
    talkTime: {
        label: 'Talk Time',
        icon: () => mdiPhoneInTalkOutline
    },
    abandonTime: {
        label: 'Abandon Time',
        icon: () => mdiPhoneRingOutline
    },
    serviceLevel: {
        label: 'Service Level',
        icon: (status) => (status === Status.Normal ? mdiPhoneOutline : mdiPhoneAlertOutline),
        suffix: '%'
    }
};

export function getMappedColorsForQueueDashboardWidgets(groupDataBy = '') {
    switch (groupDataBy?.toLowerCase()) {
        case 'queuesourcetype': {
            return {
                light: {
                    Direct: '#00dbb7',
                    'Auto Attendant': '#3b55f6',
                    User: '#9d42e6',
                    Queue: '#ff7819'
                },
                dark: {
                    Direct: '#00dbb7',
                    'Auto Attendant': '#3b55f6',
                    User: '#9d42e6',
                    Queue: '#ff7819'
                }
            };
        }
        case 'queuedisposition': {
            return {
                light: {
                    Answered: '#00dbb7',
                    Missed: '#FF4D58'
                },
                dark: { Answered: '#00dbb7', Missed: '#FF4D58' }
            };
        }
        case 'queuehandlingtype': {
            return {
                light: {
                    Answered: '#00dbb7',
                    Abandoned: '#FF4D58',
                    Unanswered: '#ffc541',
                    'Timed Out': '#9d42e6',
                    Overflown: '#3b55f6',
                    'No Agents': '#ff7819',
                    Other: '#3C0077'
                },
                dark: {
                    Answered: '#00dbb7',
                    Abandoned: '#FF4D58',
                    Unanswered: '#ffc541',
                    'Timed Out': '#9d42e6',
                    Overflown: '#3b55f6',
                    'No Agents': '#ff7819',
                    Other: '#3C0077'
                }
            };
        }
        case 'queuefinalaction': {
            return {
                light: {
                    'Completed by Agent': '#00dbb7',
                    'Abandoned by Caller': '#FF4D58',
                    'Forwarded to Voicemail': '#ff7819',
                    'Transferred to Voicemail': '#ffc541',
                    'Transferred to User': '#3C59FC',
                    'Forwarded to User': '#AB6857',
                    'Forwarded to Queue': '#9435F9',
                    'Conferenced in User': '#AEA2E0',
                    'Conferenced in External Phone Number': '#C339BE',
                    'Forwarded to External Phone Number': '#3C0077'
                },
                dark: {
                    'Completed by Agent': '#00dbb7',
                    'Abandoned by Caller': '#FF4D58',
                    'Forwarded to Voicemail': '#ff7819',
                    'Transferred to Voicemail': '#ffc541',
                    'Transferred to User': '#3C59FC',
                    'Forwarded to User': '#F2C96D',
                    'Forwarded to Queue': '#CBCDB5',
                    'Conferenced in User': '#AEA2E0',
                    'Conferenced in External Phone Number': '#C339BE',
                    'Forwarded to External Phone Number': '#3C0077'
                }
            };
        }

        default:
            return graphColors;
    }
}

export const defaultDateRangeFormat = 'YYYY-MM-DD HH:mm:ss';
export const relativeDateRangeFormat = 'YYYY-MM-DD';
