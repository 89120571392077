import { createSlice } from '@reduxjs/toolkit';

import axios from 'utils/axios';
import columnMapping from 'views/Administration/Connectors/Constants/columnMapping';
import moment from 'moment';
import { isDateValid } from 'utils/generic';

export const initialState = {
    directoryImports: [],
    currentDirImport: null,
    isDirImportsLoading: true,
    dirImportProps: null,
    directoryImportFields: [],
    isDirImportFieldsLoading: true,
    previewData: null,
    previewDataLoading: false,
    previewDataError: null,
    rcData: null,
    rcDataLoading: false,
    rcDataError: null,
    runNowRequested: false,
    runNowSuccessMsg: null,
    runNowError: null,
    dirImportSchedules: null,
    isDirImportSchedulesLoading: true,
    currentDirImportScheduleId: 0,
    fetchDataRequested: false,
    fetchDataError: null,
    isFetchingData: false,
    fetchDataLoadStatus: null,
    fetchDataLastModifiedDate: null,
    refetchRequired: false
};

const slice = createSlice({
    name: 'directoryImport',
    initialState,
    reducers: {
        createDirImportSuccess(state, action) {
            // state.newRemoteCollectionId = action.payload;
            // state.directoryImports = [
            //     ...state.directoryImports,
            //     { id: action.payload.id, remoteName: action.payload.remoteName, contactInfo: action.payload.contactInfo }
            // ];
            // state.newRemoteCollectionId = action.payload.id;
        },
        getDirectoryImportsSuccess(state, action) {
            state.directoryImports = action.payload;
            state.isDirImportsLoading = false;
        },
        getDirectoryImportsFailure(state, action) {
            state.isDirImportsLoading = false;
        },
        setCurrentDirectoryImport(state, action) {
            state.currentDirImport = {
                ...state.currentDirImport,
                ...action.payload
            };
        },
        resetIsDirImportsLoading(state, action) {
            state.isDirImportsLoading = true;
        },
        setDirImportProps(state, action) {
            state.dirImportProps = action.payload;
        },
        // resetCurrentRemoteCollection(state, action) {
        //     state.currentRemoteCollection = null;
        // },
        saveDirectoryImportSuccess(state, action) {
            state.currentDirImport = {
                ...state.currentDirImport,
                ...action.payload
            };
            state.directoryImports = [...state.directoryImports, action.payload];
        },
        deleteDirImportSuccess(state, action) {
            state.directoryImports = state.directoryImports.filter((dirImp) => dirImp.tenantDirImportId !== action.payload);
            // state.deleteSuccess = true;
        },
        deleteDirImportSchSuccess(state, action) {
            state.dirImportSchedules = state.dirImportSchedules.filter((sch) => sch.eventId !== action.payload);
        },
        setSourceConnectorId(state, action) {
            state.sourceConnectorId = action.payload;
        },
        resetDeleteSuccess(state, action) {
            // state.deleteSuccess = false;
        },
        getDirImportFieldsSuccess(state, action) {
            state.directoryImportFields = action.payload;
            state.isDirImportFieldsLoading = false;
        },
        setTenantDirImportColumns(state, action) {
            state.currentDirImport = {
                ...state.currentDirImport,
                tenantDirImportColumns: action.payload
            };
        },
        createPreviewDataRequest(state) {
            state.previewDataLoading = true;
            state.previewData = null;
            state.previewDataError = null;
        },
        createPreviewDataSuccess(state, action) {
            state.previewDataLoading = false;
            state.previewData = action.payload;
            state.previewDataError = null;
        },
        createPreviewDataError(state, action) {
            state.previewDataLoading = false;
            state.previewData = null;
            state.previewDataError = action.payload;
        },
        resetPreviewError(state, action) {
            state.previewDataError = null;
        },
        resetPreviewData(state, action) {
            state.previewData = null;
        },
        createRcDataRequest(state) {
            state.rcDataLoading = true;
            state.rcData = null;
            state.rcDataError = null;
        },
        createRcDataSuccess(state, action) {
            state.rcDataLoading = false;
            state.rcData = action.payload;
            state.rcDataError = null;
        },
        createRcDataError(state, action) {
            state.rcDataLoading = false;
            state.rcData = null;
            state.rcDataError = action.payload;
        },
        resetRcError(state, action) {
            state.rcDataError = null;
        },
        resetRcData(state, action) {
            state.rcData = null;
        },
        createRunNowRequest(state, action) {
            state.runNowRequested = true;
            state.runNowError = null;
            state.runNowSuccessMsg = null;
        },
        createRunNowSuccess(state, action) {
            state.runNowSuccessMsg = action.payload;
            state.runNowRequested = false;
            state.runNowError = null;
        },
        createRunNowError(state, action) {
            state.runNowSuccessMsg = null;
            state.runNowRequested = false;
            state.runNowError = action.payload;
        },
        resetRunNowError(state, action) {
            state.runNowError = null;
        },
        resetFetchDataError(state, action) {
            state.fetchDataError = null;
        },
        resetRunNowSuccessMsg(state, action) {
            state.runNowSuccessMsg = null;
        },
        createFetchDataRequest(state, action) {
            state.fetchDataRequested = true;
            state.fetchDataError = null;
            state.refetchRequired = false;
        },
        createFetchDataError(state, action) {
            state.fetchDataRequested = false;
            state.fetchDataError = action.payload;
        },
        createFetchDataSuccess(state, action) {
            state.fetchDataRequested = false;
            state.fetchDataError = null;
            state.isFetchingData = true;
            state.fetchDataLoadStatus = 'fetchingData';
        },
        createDataStatusSuccess(state, action) {
            const { dataStatus } = action.payload;
            const fileDate = moment(dataStatus.FileDate);
            const lastFetchDate = moment(dataStatus.LastFetch);
            // if state is either fetching or waiting, set status to fetching
            if (dataStatus.State.toLowerCase() === 'fetching' || dataStatus.State.toLowerCase() === 'waiting') {
                state.isFetchingData = true;
                state.fetchDataLoadStatus = 'fetchingData';
            } else if (isDateValid(fileDate) && dataStatus.State.toLowerCase() !== 'fetching') {
                // fileDate valid
                // if fileDate >= lastFetchDate then that means fetch completed
                // else still fetching
                if (isDateValid(lastFetchDate) && fileDate.isSameOrAfter(lastFetchDate)) {
                    state.isFetchingData = false;
                    state.fetchDataLoadStatus = 'availableData';
                    state.fetchDataLastModifiedDate = fileDate.format('YYYY-MM-DD hh:mm:ss A');
                } else {
                    state.isFetchingData = false;
                    state.fetchDataLoadStatus = 'availableData';
                    state.fetchDataLastModifiedDate = fileDate.format('YYYY-MM-DD hh:mm:ss A');
                }
            } else if (!isDateValid(fileDate) && dataStatus.State.toLowerCase() !== 'fetching') {
                // fileDate invalid
                // if lastFetchDate is valid, then fetching data
                // otherwise no data
                if (isDateValid(lastFetchDate)) {
                    state.isFetchingData = true;
                    state.fetchDataLoadStatus = 'fetchingData';
                } else if (state.fetchDataLoadStatus !== 'fetchingData') {
                    state.isFetchingData = false;
                    state.fetchDataLoadStatus = 'noData';
                    state.fetchDataLastModifiedDate = null;
                }
            }
        },
        updateFetchStatusSuccess(state, action) {
            const fetchStatus = action.payload;
            if (fetchStatus === 'fetchingData') {
                state.isFetchingData = true;
                state.fetchDataLoadStatus = 'fetchingData';
            }
        },
        getDirImportSchedulesSuccess(state, action) {
            state.dirImportSchedules = action.payload;
            state.isDirImportSchedulesLoading = false;
        },
        getDirImportSchedulesFailure(state, action) {
            state.isDirImportSchedulesLoading = false;
        },
        saveDirImportScheduleSuccess(state, action) {
            const { schId, schConfig, edit } = action.payload;
            state.currentDirImportScheduleId = schId;
            const updatedDirImportSchedules = [...state.dirImportSchedules];
            if (edit) {
                const filteredSchedules = updatedDirImportSchedules.filter((sch) => sch.eventId !== schId);
                state.dirImportSchedules = [...filteredSchedules, schConfig];
            } else {
                state.dirImportSchedules = [...updatedDirImportSchedules, { ...schConfig, eventId: schId }];
            }
        },
        setCurrentDirImportScheduleId(state, action) {
            state.currentDirImportScheduleId = action.payload;
        },
        resetCurrentDirImportScheduleId(state, action) {
            state.currentDirImportScheduleId = 0;
        },
        resetFetchStatusSuccess(state, action) {
            state.fetchDataError = null;
            state.isFetchingData = false;
            state.fetchDataLoadStatus = null;
            state.fetchDataLastModifiedDate = null;
        },
        resetDirectoryImportFields(state, action) {
            state.directoryImportFields = [];
            state.isDirImportFieldsLoading = true;
        },
        setRefetchRequired(state, action) {
            state.refetchRequired = action.payload;
        }
    }
});

export default slice.reducer;

export function getDirectoryImports(enqueueSnackbar, dataSourceList) {
    return async (dispatch) => {
        // call API to get list of all saved Directory Imports
        try {
            const response = await axios.get(`/TenantDirectoryConfiguration`).catch((error) => {
                if (error.response) {
                    console.log(`${error.response.status} - ${error.response.data}`);
                } else if (error.request) {
                    console.log(`${error.request}`);
                } else {
                    console.log(`Error - ${error.message}`);
                }
            });
            if (response?.data) {
                // go through the list of data and insert a "destinationSourceName" field by looking up the data source name in the dataSourceList
                const updatedDataSet = response.data.map((dirImp) => ({
                    ...dirImp,
                    destinationSourceName: dataSourceList.find((site) => site.id === dirImp.destinationSourceId)?.label || '',
                    lastRunDate: dirImp.lastRunDate ? moment(dirImp.lastRunDate) : null
                }));
                const sortedList = updatedDataSet.sort((a, b) => {
                    if (a.importName < b.importName) {
                        return -1;
                    }
                    if (a.importName > b.importName) {
                        return 1;
                    }
                    return 0;
                });
                dispatch(slice.actions.getDirectoryImportsSuccess(sortedList));
            }
        } catch (ex) {
            if (ex?.response?.status === 401) {
                enqueueSnackbar('Session expired, please login again', { variant: 'error' });
            } else {
                dispatch(slice.actions.getDirectoryImportsFailure());
                enqueueSnackbar('Failed to get the Directory Imports', { variant: 'error' });
            }
        }
    };
}

export function saveDirectoryImport(enqueueSnackbar, configObj, isEdit = false, scheduleSaveConfig, clientId) {
    return async (dispatch) => {
        if (isEdit) {
            const response = await axios.put(`/TenantDirectoryConfiguration`, configObj).catch((error) => {
                if (error.response) {
                    console.log(`${error.response.status} - ${error.response.data}`);
                } else if (error.request) {
                    console.log(`${error.request}`);
                } else {
                    console.log(`Error - ${error.message}`);
                }
            });
            if (response?.data?.data) {
                dispatch(slice.actions.saveDirectoryImportSuccess(response.data.data));
            }
        } else {
            const response = await axios.post(`/TenantDirectoryConfiguration`, configObj).catch((error) => {
                if (error.response) {
                    console.log(`${error.response.status} - ${error.response.data}`);
                } else if (error.request) {
                    console.log(`${error.request}`);
                } else {
                    console.log(`Error - ${error.message}`);
                }
            });
            if (response?.data?.data) {
                // attempt to save the scheduler
                const configObj = {
                    ...scheduleSaveConfig,
                    // eventId: response.data.data?.tenantDirImportId,
                    eventName: `${response.data.data?.tenantDirImportId}`
                };
                if (scheduleSaveConfig) {
                    const schResponse = await axios.post(`/scheduler/directory-import`, configObj).catch((error) => {
                        if (error.response) {
                            console.log(`${error.response.status} - ${error.response.data}`);
                        } else if (error.request) {
                            console.log(`${error.request}`);
                        } else {
                            console.log(`Error - ${error.message}`);
                        }
                    });
                    dispatch(slice.actions.saveDirectoryImportSuccess(response.data.data));
                    if (schResponse?.data?.success) {
                        dispatch(
                            slice.actions.saveDirImportScheduleSuccess({ schId: schResponse.data.data, schConfig: configObj, edit: false })
                        );
                    } else {
                        enqueueSnackbar('Failed to save Directory Import Schedule', { variant: 'error' });
                    }
                } else {
                    dispatch(slice.actions.saveDirectoryImportSuccess(response.data.data));
                }
                // attempt to do a fetchData
                dispatch(slice.actions.createFetchDataRequest());
                const payload = {
                    request: 'FetchData',
                    data: {
                        recordId: response.data.data.tenantDirImportId,
                        clientId
                    }
                };
                const fetchResponse = await axios.post(`/TenantDirectoryConfiguration/RunNow`, payload);
                if (fetchResponse?.data?.success) {
                    dispatch(slice.actions.createFetchDataSuccess(fetchResponse.data.data));
                } else {
                    dispatch(slice.actions.createFetchDataError(`Failed to refresh the data. ${JSON.parse(response.data.data)}`));
                }
            }
        }
    };
}

export function loadCurrentDirectoryImport(enqueueSnackbar, dirImpInfo, sourceConnectorId, savedConnectors) {
    return async (dispatch) => {
        // if id is -1, set the template, otherwise get the information from end point
        if (dirImpInfo?.tenantDirImportId > 0) {
            const response = await axios.get(`/TenantDirectoryConfiguration/${dirImpInfo.tenantDirImportId}`);
            if (response?.data?.data) {
                const propsResponse = await axios.get(
                    `/TenantDirectoryConfiguration/DirectoryImportProperties/${dirImpInfo.tenantConnector.connectorId}`
                );
                if (propsResponse?.data?.success) {
                    dispatch(slice.actions.setDirImportProps(propsResponse.data.data.sort((a, b) => a.displayIndex - b.displayIndex)));
                    dispatch(slice.actions.setCurrentDirectoryImport(response.data.data));
                } else {
                    enqueueSnackbar('Failed to load Directory Import properties', { variant: 'error' });
                }
            }
        } else {
            const dirImportTemplate = {
                sourceConnectorId,
                sourceConnectorName: savedConnectors.find((connector) => connector.tenantConnectorId === sourceConnectorId).connectorName,
                destinationSourceId: '',
                expireOption: 'None',
                deliveryOption: 'NeverSend',
                emailMessageContent: '',
                emailAddresses: [],
                scheduleType: 'daily',
                time: '',
                scheduleActive: true,
                importName: '',
                tenantConnector: {
                    id: sourceConnectorId,
                    connectorName: savedConnectors.find((connector) => connector.tenantConnectorId === sourceConnectorId).connectorName
                },
                tenantDirImportId: 0,
                tenantDirImportProperties: [],
                tenantDirImportColumns: [],
                tenantDirImportFilters: [],
                tenantDirImportDefaults: []
            };
            // get the dirImport properties
            const importConnectorId = savedConnectors.find((connector) => connector.tenantConnectorId === sourceConnectorId).connectorId;
            const propsResponse = await axios.get(`/TenantDirectoryConfiguration/DirectoryImportProperties/${importConnectorId}`);
            if (propsResponse?.data?.success) {
                dispatch(slice.actions.setDirImportProps(propsResponse.data?.data.sort((a, b) => a.displayIndex - b.displayIndex)));
                dispatch(slice.actions.setCurrentDirectoryImport(dirImportTemplate));
            } else {
                enqueueSnackbar('Failed to load Directory Import properties', { variant: 'error' });
            }
        }
    };
}

// export function resetCurrentRemoteCollection() {
//     return async (dispatch) => {
//         dispatch(slice.actions.resetCurrentRemoteCollection());
//     };
// }

export function resetIsDirImportsLoading() {
    return async (dispatch) => {
        dispatch(slice.actions.resetIsDirImportsLoading());
    };
}

export function resetDirImportFields() {
    return async (dispatch) => {
        dispatch(slice.actions.resetDirectoryImportFields());
    };
}

// export function resetDeleteSuccess() {
//     return async (dispatch) => {
//         dispatch(slice.actions.resetDeleteSuccess());
//     };
// }

export function deleteDirectoryImport(enqueueSnackbar, id, schId, clientId) {
    return async (dispatch) => {
        // before deleting the import, get the import waiting queue and delete the entry for the given id if present
        const queuePayload = {
            request: 'GetRuntimeStatus',
            data: {
                clientId
            }
        };
        const waitingQueueResponse = await axios.post(`/TenantDirectoryConfiguration/RunNow`, queuePayload);
        if (waitingQueueResponse?.data?.success) {
            // if success, check if the import to be deleted is in the waiting queue
            const queueData = JSON.parse(waitingQueueResponse.data.data);
            // extract the importId from a string of the format - [708] ScrumIDP1 (Import_Name) where import id is 708
            const isDeleteItemInQueue = queueData.WaitingImports.find((queueImportInfo) => {
                const match = queueImportInfo.match(/\[(\d+)\]/);
                const importId = match ? parseInt(match[1], 10) : null;
                return importId === id;
            });
            if (isDeleteItemInQueue) {
                // if import to be deleted is found in waiting queue, delete the item from the waiting queue
                const deleteQueuePayload = {
                    request: 'PurgeWaitingQueue',
                    data: {
                        recordId: id,
                        clientId
                    }
                };
                const deleteQueueResponse = await axios.post(`/TenantDirectoryConfiguration/RunNow`, deleteQueuePayload);
                if (!deleteQueueResponse?.data?.success) {
                    enqueueSnackbar('Failed to delete the Directory Import from the waiting queue', { variant: 'error' });
                }
            }
        } else {
            enqueueSnackbar('Failed to get the Directory Import waiting queue', { variant: 'error' });
        }
        // do the actual delete of the Import
        const response = await axios.delete(`/TenantDirectoryConfiguration?Id=${id}`).catch((error) => {
            if (error.response) {
                console.log(`${error.response.status} - ${error.response.data}`);
            } else if (error.request) {
                console.log(`${error.request}`);
            } else {
                console.log(`Error - ${error.message}`);
            }
        });
        if (response?.data) {
            dispatch(slice.actions.deleteDirImportSuccess(id));
            // if there's a valid schId, call endpoint to delete the schedule
            if (schId > 0) {
                const schResponse = await axios.delete(`/scheduler/${schId}`);
                if (schResponse?.data?.success) {
                    dispatch(slice.actions.deleteDirImportSchSuccess(schId));
                } else {
                    enqueueSnackbar('Failed to delete the directory import schedule', { variant: 'error' });
                }
            }
        }
    };
}

export function setSourceConnector(id) {
    return async (dispatch) => {
        dispatch(slice.actions.setSourceConnectorId(id));
    };
}

function mapFieldDisplayName(field, orgLevelDescriptions, appConfigValues) {
    // if the field is not enabled in the appConfigValues, return null
    const configInfoForTheField = appConfigValues.find((config) => config.code.toLowerCase() === field.fieldName.toLowerCase());
    if (configInfoForTheField && !configInfoForTheField.enabled) {
        return null;
    }
    // if displayName involves "<%", then look up the fieldName to check if it starts with Name.
    // if yes, map to corresponding OrgDescription for org level Name{n}
    // else, lookup the fieldName in AppConfig info
    if (field.displayName.startsWith('<%')) {
        if (field.fieldName.startsWith('Name') || field.fieldName.startsWith('Code')) {
            const orgDescription = orgLevelDescriptions.find(
                (desc) => desc.level.toString() === field.fieldName.charAt(field.fieldName.length - 1)
            )?.description;
            if (orgDescription) {
                return field.fieldName.startsWith('Name') ? `${orgDescription} Name` : `${orgDescription} Code`;
            }
            return null;
        }
        // find the item in appConfigValues.code that matches the field.fieldName
        // check for any username. If no username, return defname
        const appConfigItem = appConfigValues.find((config) => config.code.toLowerCase() === field.fieldName.toLowerCase());
        return appConfigItem?.userName === '' ? appConfigItem?.defname : appConfigItem?.userName;
    }
    return field.displayName;
}

export function getDirImportFields(enqueueSnackbar, orgLevelDescriptions, appConfigValues, connectorId) {
    return async (dispatch) => {
        // get the fields from api end point
        const response = await axios.get(`/TenantDirectoryConfiguration/DirectoryImportField?connectorId=${connectorId}`).catch((error) => {
            if (error.response) {
                console.log(`${error.response.status} - ${error.response.data}`);
            } else if (error.request) {
                console.log(`${error.request}`);
            } else {
                console.log(`Error - ${error.message}`);
            }
        });
        if (response?.data?.success) {
            const transformedFields = response.data.data.map((field) => {
                const dispName = mapFieldDisplayName(field, orgLevelDescriptions, appConfigValues);
                return {
                    id: field.id,
                    dirImportGroupId: field.dirImportGroupId,
                    fieldName: field.fieldName,
                    displayName: dispName,
                    ciscoFieldName:
                        field.fieldName.startsWith('Name') && dispName?.toLowerCase().includes('department')
                            ? columnMapping['Name{n}'] // this is to account for Cisco field - Department based on the Org levels available
                            : columnMapping[field.fieldName] || '',
                    displayIndex: field.displayIndex,
                    groupName: field.dirImportGroup.groupName,
                    groupDisplayIndex: field.dirImportGroup.displayIndex,
                    // Spread the isRequired property if it exists in the original object
                    ...(field.isRequired !== undefined && { isRequired: field.isRequired })
                };
            });
            // filter out values with displayName = null
            const filteredFields = transformedFields.filter((field) => field.displayName !== null);
            dispatch(slice.actions.getDirImportFieldsSuccess(filteredFields));
        } else {
            enqueueSnackbar('Failed to load the directory import fields', { variant: 'error' });
        }
    };
}

export function fetchPreviewData(enqueueSnackbar, payload) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.createPreviewDataRequest());
            const response = await axios.post(`/TenantDirectoryConfiguration/Preview`, payload);
            if (response?.data?.success) {
                const responseData = JSON.parse(response.data?.data);
                const count = responseData?.count;
                const previewDataItems = responseData?.data;
                dispatch(slice.actions.createPreviewDataSuccess({ userCount: count, data: previewDataItems }));
            } else {
                const errorMsg = JSON.parse(response.data.data);
                // dispatch(slice.actions.createPreviewDataError(response.data.error));
                dispatch(slice.actions.createPreviewDataError(`Failed to do a Preview. ${errorMsg.ERROR}`));
            }
        } catch (ex) {
            if (ex?.response?.status === 401) {
                enqueueSnackbar('Session expired, please login again', { variant: 'error' });
            } else {
                dispatch(slice.actions.createPreviewDataError('Failed to do a Preview'));
            }
        }
    };
}

export function fetchCollectionData(enqueueSnackbar, payload, token) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.createRcDataRequest());
            const response = await axios.post(`/TenantDirectoryConfiguration/RemoteCollectionData`, payload, { cancelToken: token });
            if (response?.data?.success) {
                // if (response.data?.data?.rows === '[]') enqueueSnackbar('No Data available', { variant: 'info' });
                const responseData = JSON.parse(response.data?.data);
                if (Object.keys(responseData).length > 0 && Object.keys(responseData)[0].toLowerCase().includes('error')) {
                    dispatch(slice.actions.createRcDataError('Failed to fetch data'));
                } else {
                    dispatch(slice.actions.createRcDataSuccess(responseData));
                }
            } else {
                const errorMsg = JSON.parse(response.data.data);
                dispatch(slice.actions.createRcDataError(`Failed to fetch data. ${errorMsg.ERROR}`));
            }
        } catch (ex) {
            if (ex?.response?.status === 401) {
                enqueueSnackbar('Session expired, please login again', { variant: 'error' });
            } else {
                dispatch(slice.actions.createRcDataError('Failed to fetch data'));
            }
        }
    };
}
export function doDirImportRunNow(enqueueSnackbar, payload) {
    return async (dispatch) => {
        try {
            if (payload.request === 'ProcessImport') {
                dispatch(slice.actions.createRunNowRequest());
            } else if (payload.request === 'FetchData') {
                dispatch(slice.actions.createFetchDataRequest());
            }
            const response = await axios.post(`/TenantDirectoryConfiguration/RunNow`, payload);
            if (response?.data?.success) {
                if (payload.request === 'ProcessImport') {
                    const dataSet = JSON.parse(response.data.data);
                    if (dataSet.Success) {
                        dispatch(slice.actions.createRunNowSuccess(dataSet.Message));
                        enqueueSnackbar(dataSet.Message, { variant: 'success' });
                    } else {
                        dispatch(slice.actions.createRunNowError(dataSet.Message));
                        enqueueSnackbar(dataSet.Message, { variant: 'error' });
                    }
                } else if (payload.request === 'FetchData') {
                    dispatch(slice.actions.createFetchDataSuccess(response.data.data));
                } else {
                    const dataStatus = JSON.parse(response.data.data);
                    dispatch(slice.actions.createDataStatusSuccess({ dataStatus }));
                }
            } else if (payload.request === 'ProcessImport') {
                const errorMsg = JSON.parse(response.data.data);
                dispatch(slice.actions.createRunNowError(`Failed to run the import. ${errorMsg.ERROR}`));
            } else if (payload.request === 'FetchData') {
                dispatch(slice.actions.createFetchDataError(`Failed to fetch latest data. ${JSON.parse(response.data.data)}`));
            }
        } catch (ex) {
            if (ex?.response?.status === 401) {
                enqueueSnackbar('Session expired, please login again', { variant: 'error' });
            } else if (payload.request === 'RunNow') {
                dispatch(slice.actions.createRunNowError('Failed to run Directory Import'));
            } else if (payload.request === 'FetchData') {
                dispatch(slice.actions.createFetchDataError('Failed to run Directory Import'));
            }
        }
    };
}

export function getDirectoryImportSchedules(enqueueSnackbar, payload) {
    return async (dispatch) => {
        try {
            const response = await axios.post(`/schedulers`, payload);
            if (response.data.success && response.data.data) {
                const schedulerConfigs = response.data.data.map((schData) => ({
                    ...JSON.parse(schData.requestJson),
                    eventId: schData.eventId
                }));
                dispatch(slice.actions.getDirImportSchedulesSuccess(schedulerConfigs));
            } else {
                enqueueSnackbar('Failed to fetch Directory Import Schedules', { variant: 'error' });
                dispatch(slice.actions.getDirImportSchedulesFailure());
            }
        } catch (ex) {
            if (ex?.response?.status === 401) {
                enqueueSnackbar('Session expired, please login again', { variant: 'error' });
            } else {
                enqueueSnackbar('Failed to fetch Directory Import Schedules', { variant: 'error' });
                dispatch(slice.actions.getDirImportSchedulesFailure());
            }
        }
    };
}

export function saveDirectoryImportSchedule(configObj) {
    return async (dispatch) => {
        const response = await axios.post(`/scheduler/directory-import`, configObj).catch((error) => {
            if (error.response) {
                console.log(`${error.response.status} - ${error.response.data}`);
            } else if (error.request) {
                console.log(`${error.request}`);
            } else {
                console.log(`Error - ${error.message}`);
            }
        });
        if (response?.data?.success) {
            dispatch(slice.actions.saveDirImportScheduleSuccess({ schId: response.data.data, schConfig: configObj, edit: true }));
        }
    };
}

export function setTenantDirImportColumns(columns) {
    return async (dispatch) => {
        dispatch(slice.actions.setTenantDirImportColumns(columns));
    };
}

export function resetPreviewError() {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.resetPreviewError());
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function resetPreviewData() {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.resetPreviewData());
        } catch (ex) {
            console.log(ex.message);
        }
    };
}

export function resetRunNowError() {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.resetRunNowError());
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function resetFetchDataError() {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.resetFetchDataError());
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function resetRcDataError() {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.resetRcError());
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function resetRcData() {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.resetRcData());
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function resetRunNowSuccessMsg() {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.resetRunNowSuccessMsg());
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function setCurrentDirImportScheduleId(schId) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.setCurrentDirImportScheduleId(schId));
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function resetCurrentDirImportScheduleId() {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.resetCurrentDirImportScheduleId());
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function resetFetchStatus() {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.resetFetchStatusSuccess());
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function updateFetchStatus(fetchStatus) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.updateFetchStatusSuccess(fetchStatus));
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function setRefetchRequired(isRefetchRequired) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.setRefetchRequired(isRefetchRequired));
        } catch (ex) {
            console.log(ex);
        }
    };
}

export const {
    createDirImportSuccess,
    getDirectoryImportsSuccess,
    setCurrentDirectoryImport,
    saveDirectoryImportSuccess,
    deleteDirImportSuccess
} = slice.actions;
