// action - account reducer
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const RESET_STORE = 'RESET_STORE';
export const SET_TENANT_INFO = 'SET_TENANT_INFO';
export const SET_TENANT_CONFIG = 'SET_TENANT_CONFIG';
export const SET_FUSIONAUTH_CONFIG = 'SET_FUSIONAUTH_CONFIG';
export const SET_RECOVERY_CODES = 'SET_RECOVERY_CODES';
export const SET_MFA_VERIFICATION_INFO = 'SET_MFA_VERIFICATION_INFO';
export const SET_AUTH_INFO = 'SET_AUTH_INFO';
export const EVALUATE_FEATURES = 'EVALUATE_FEATURES';
