import moment from 'moment';

export const formatGMTOffsetToHoursAndMinutes = (utcOffsetInMinutes) => {
    const hours = Math.floor(Math.abs(utcOffsetInMinutes) / 60);
    const minutes = Math.abs(utcOffsetInMinutes) % 60;
    const sign = utcOffsetInMinutes < 0 ? '-' : '+';
    return `${sign}${hours.toString()}.${minutes.toString().padStart(2, '0')}`;
};

export const getUtcOffsetInMinutes = (gmt) => {
    return Number(gmt) * 60;
};

export function convertToHHmm(value) {
    // Attempt to parse the value as HH:mm format
    const parsedTime = moment(value, 'HH:mm');
    // If parsing is successful, return the formatted time
    if (parsedTime.isValid()) {
        return parsedTime.format('HH:mm');
    }
    return value;
}
export function convertToSeconds(timeString) {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    const totalSeconds = hours * 3600 + minutes * 60 + seconds;
    return totalSeconds;
}

export function validateAnswerTimeThreshold(value) {
    const timeParts = value.split(':');

    if (timeParts.length !== 3) {
        return 'Invalid time format. Please use HH:MM:SS';
    }

    const [hours, minutes, seconds] = timeParts.map(Number);

    if (hours > 23 || hours < 0) {
        return 'Hour must be between 0 and 23';
    }
    if (minutes > 59 || minutes < 0) {
        return 'Minutes must be between 0 and 59';
    }
    if (seconds > 59 || seconds < 0) {
        return 'Seconds must be between 0 and 59';
    }
    return null;
}
