import { Divider, Grid, ListItem, ListItemText, Typography } from '@mui/material';
// material-ui
import { styled, useTheme } from '@mui/material/styles';
import moment from 'moment';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLocation, useNavigate } from 'react-router-dom';
import { dispatch, useSelector } from 'store';
import { fetchNotification, fetchNotifications } from 'store/slices/notifications';
import NotificationType from './NotificationType';

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationItem = ({ handleClose, notification }) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const location = useLocation();
    const message = (() => {
        if (notification?.metaDataText) {
            try {
                return JSON.parse(notification.metaDataText)?.Subject;
            } catch (error) {
                return notification.metaDataText || '';
            }
        }
        return '';
    })();
    const notificationMetaData = (() => {
        if (notification?.metaDataText) {
            try {
                return JSON.parse(notification.metaDataText);
            } catch (error) {
                return notification.metaDataText || '';
            }
        }
        return '';
    })();

    const messageTime = new Date(notification.createdAt * 1000);
    const handleNotificationClick = (event) => {
        // mark notification read
        dispatch(fetchNotification(notification.notificationId));
        // redirect to another screen
        if (
            notification.notificationType === NotificationType.ReportExport ||
            notification.notificationType === NotificationType.ReportAvailable ||
            notification.notificationType === NotificationType.ReportScheduled
        ) {
            if (location.pathname === '/reporting/report-history') {
                navigate(0);
            } else {
                navigate(`/reporting/report-history`, { replace: true });
            }
        } else if (notification.notificationType === NotificationType.ReportSharing) {
            if (location.pathname?.concat(location.search) === '/reporting/reports?templateType=shared') {
                navigate(0);
            } else {
                navigate(`/reporting/reports?templateType=shared`, { replace: true });
            }
        }

        handleClose();
    };

    const getNotificationMessage = (templateName) => {
        return notification?.templateText?.replace('Template Name', templateName ?? '');
    };

    return (
        <>
            <ListItemWrapper onClick={handleNotificationClick}>
                <ListItem sx={{ pb: 2 }} alignItems="center">
                    <ListItemText
                        primary={
                            <Grid container direction="column">
                                {notification.deliveryStatus === 'Unread' && (
                                    <Grid item xs={12} sx={{ pb: 2 }}>
                                        <Typography variant="subtitle2" sx={{ fontSize: 14, color: theme.palette.grey[900] }}>
                                            {getNotificationMessage(notificationMetaData['Template Name'])}
                                        </Typography>
                                    </Grid>
                                )}
                                {notification.deliveryStatus === 'Read' && (
                                    <Grid item xs={12} sx={{ pb: 2 }}>
                                        <Typography
                                            variant="subtitle2"
                                            sx={{ fontSize: 14, color: theme.palette.mode === 'dark' ? '#9e9e9e' : '#71797E' }}
                                        >
                                            {getNotificationMessage(notificationMetaData['Template Name'])}
                                        </Typography>
                                    </Grid>
                                )}

                                <Grid item xs={12}>
                                    <Typography variant="caption" display="block">
                                        {moment(messageTime).format('LL')} at {moment(messageTime).format('hh:mm a')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        }
                    />
                </ListItem>
            </ListItemWrapper>
            <Divider />
        </>
    );
};

const NotificationList = ({ handleClose }) => {
    const notifications = useSelector((state) => state.notifications);

    const fetchMoreData = () => {
        dispatch(fetchNotifications(notifications?.notifications?.length, true));
    };

    return (
        <>
            {notifications?.notifications?.length ? (
                <InfiniteScroll
                    height="calc(100vh - 205px)"
                    dataLength={notifications?.notifications?.length}
                    next={fetchMoreData}
                    hasMore={notifications?.hasMoreData}
                    loader={<h4 style={{ 'margin-left': '16px' }}>Loading...</h4>}
                    endMessage={
                        <p style={{ textAlign: 'center' }}>
                            <b>Yay! You have seen it all</b>
                        </p>
                    }
                >
                    {notifications?.notifications?.map((notification) => {
                        return <NotificationItem key={notification.notificationId} notification={notification} handleClose={handleClose} />;
                    })}
                </InfiniteScroll>
            ) : (
                <>
                    <Divider />
                    <Typography sx={{ p: 2 }}>No notifications found.</Typography>
                </>
            )}
        </>
    );
};

export default NotificationList;
