import { createSlice } from '@reduxjs/toolkit';
import axios from 'utils/axios';
import { dispatch } from '../index';

const initialState = {
    widgets: null,
    availableWidgets: []
};

const widgets = createSlice({
    name: 'widgets',
    initialState,
    reducers: {
        addWidgetSuccess(state, action) {
            // state.widgets.push(action.payload);
            state.widgets[action.payload.id] = action.payload;
        },
        deleteWidgetSuccess(state, action) {
            // state.widgets = state.widgets.filter(({ definition }) => definition.gateID !== action.payload);
            delete state.widgets[action.payload];
        },
        getWidgetsSuccess(state, action) {
            state.widgets = action.payload;
        },
        getAvailableWidgetSuccess(state, action) {
            state.availableWidgets = action.payload;
        },
        updateWidgetById(state, action) {
            state.widgets[action.payload.id] = action.payload;
        }
    }
});

export default widgets.reducer;

// read the widget template from API and save the new widget under the specified dashboard
export function addWidget(widgetTemplateId, dashId, orgLevelDesc) {
    return async () => {
        try {
            const { data } = await axios.get(`/dashboards/widgets/templates/${widgetTemplateId}`);
            // console.log(JSON.stringify(data));
            // create a new widget from the template and save
            // If widgetTemplateId is 4 (Top Org widget), we need to reset the xAxisField and the fields array to the highest org level
            let orgBasedXAxisField = data?.xAxisField;
            let orgBasedFields = data?.fields;
            if (widgetTemplateId === 4) {
                const highestLevel = orgLevelDesc.reduce((acc, curr) => Math.max(acc, curr.level), 0);
                orgBasedXAxisField = `Name${highestLevel}`;
                orgBasedFields = orgBasedFields.map((fieldItm) =>
                    fieldItm.name.startsWith('Name') ? { name: `Name${highestLevel}`, displayName: `Name${highestLevel}` } : fieldItm
                );
            }
            const widgetParams = {
                name: data?.defaultName,
                showDataGrid: data?.showDataGrid,
                showGraph: data?.showGraph,
                graphType: data?.graphType,
                yAxisCaption: data?.yAxisCaption,
                xAxisField: orgBasedXAxisField,
                categoryField: data?.categoryField,
                rankingField: data?.rankingField,
                rankLimit: parseInt(data?.rankLimit, 10),
                rankDirection: data?.rankDirection,
                gmtOffset: data?.gmtOffset,
                groupByField: data?.groupByField || '',
                useDst: data?.useDst,
                countryCode: data?.countryCode,
                dateType: data?.dateType,
                lastX: data?.lastX,
                relativeDate: data?.relativeDate,
                includeToday: data?.includeToday,
                startTime: data?.startTime,
                endTime: data?.endTime,
                weekdays: data?.weekDays,
                startDate: data?.startDate,
                endDate: data?.endDate,
                widgetType: data?.widgetType,
                widgetTemplateId,
                fields: orgBasedFields,
                filters: data?.filters
            };
            // dispatch API call to create new widget
            const response = await axios.post(`/dashboards/${dashId}/widgets`, widgetParams).catch((error) => {
                if (error.response) {
                    console.log(`${error.response.status} - ${error.response.data}`);
                } else if (error.request) {
                    console.log(`${error.request}`);
                } else {
                    console.log(`Error - ${error.message}`);
                }
            });
            if (response?.data) {
                dispatch(widgets.actions.addWidgetSuccess({ ...widgetParams, id: response.data.id, drillDownMode: false }));
            }
        } catch (error) {
            console.log(error);
        }
    };
}

export function getWidgets(dashId) {
    return async () => {
        const response = await axios.get(`/dashboards/${dashId}/widgets`);
        // response.data - returns array. Convert it into an object with WidgetId as key
        if (response?.data) {
            const widgetsObj = response.data.reduce((acc, currItem) => {
                acc[currItem.id] = { ...currItem, drillDownMode: false };
                return acc;
            }, {});
            dispatch(widgets.actions.getWidgetsSuccess(widgetsObj));
        }
    };
}

export function getAvailableWidgets() {
    return async () => {
        const response = await axios.get(`/dashboards/widgets/templates`);
        if (response?.data) {
            dispatch(widgets.actions.getAvailableWidgetSuccess(response.data));
        }
    };
}

export function saveWidget(dashId, widgetId, widgetInfo) {
    return async () => {
        // dispatch call to the save API
        // filter out any 'All' value filter
        const response = await axios.put(`/dashboards/${dashId}/widgets/${widgetId}`, {
            ...widgetInfo,
            filters: widgetInfo.filters.filter((itm) => !(itm.value === 'All' || itm.operator === ''))
        });
        dispatch(widgets.actions.updateWidgetById(widgetInfo));
    };
}

export function updateWidget(widgetInfo) {
    return () => {
        dispatch(widgets.actions.updateWidgetById(widgetInfo));
    };
}

export function deleteWidget(dashId, widgetId) {
    return async () => {
        const response = await axios.delete(`/dashboards/${dashId}/widgets/${widgetId}`);
        dispatch(widgets.actions.deleteWidgetSuccess(widgetId));
    };
}
