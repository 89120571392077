import { Link } from 'react-router-dom';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Button, Card, CardMedia, Grid, Typography } from '@mui/material';

// project imports
import { DASHBOARD_PATH } from 'config';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { gridSpacing } from 'store/constant';

// assets
import { LoginRounded } from '@mui/icons-material';
import { IconDashboard } from '@tabler/icons';

import imageBackground from 'assets/images/maintenance/img-error-bg.svg';
import imageDarkBackground from 'assets/images/maintenance/img-error-bg-dark.svg';
import imageBlue from 'assets/images/maintenance/img-error-blue.svg';
import imageText from 'assets/images/maintenance/img-error-text.svg';
import imagePurple from 'assets/images/maintenance/img-error-purple.svg';
import useAuth from 'hooks/useAuth';

// styles
const CardMediaWrapper = styled('div')({
    maxWidth: 720,
    margin: '0 auto',
    position: 'relative'
});

const ErrorWrapper = styled('div')({
    maxWidth: 350,
    margin: '0 auto',
    textAlign: 'center'
});

const ErrorCard = styled(Card)({
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
});

const CardMediaBlock = styled('img')({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%'
});

const CardMediaBlue = styled('img')({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%'
});

const CardMediaPurple = styled('img')({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%'
});

// ==============================|| ERROR PAGE ||============================== //

const NotFound = () => {
    const theme = useTheme();
    const { isLoggedIn } = useAuth();

    return (
        <ErrorCard>
            <Grid container justifyContent="center" spacing={gridSpacing}>
                <Grid item sm={6} xs={12} display="flex">
                    <CardMediaWrapper alignSelf="center">
                        <CardMedia
                            component="img"
                            image={theme.palette.mode === 'dark' ? imageDarkBackground : imageBackground}
                            title="Slider5 image"
                        />
                        <CardMediaBlock src={imageText} title="Slider 1 image" />
                        <CardMediaBlue src={imageBlue} title="Slider 2 image" />
                        <CardMediaPurple src={imagePurple} title="Slider 3 image" />
                    </CardMediaWrapper>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <ErrorWrapper>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12}>
                                <Typography variant="h1" component="div">
                                    Something is wrong
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2">
                                    The page you are looking was moved, removed, renamed, or might never exist!{' '}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <AnimateButton>
                                    {isLoggedIn ? (
                                        <Button variant="contained" size="large" component={Link} to={DASHBOARD_PATH}>
                                            <IconDashboard style={{ fontSize: '1.3rem', marginRight: 5 }} /> Dashboard
                                        </Button>
                                    ) : (
                                        <Button variant="contained" size="large" component={Link} to="/">
                                            <LoginRounded style={{ fontSize: '1.3rem', marginRight: 5 }} /> Login
                                        </Button>
                                    )}
                                </AnimateButton>
                            </Grid>
                        </Grid>
                    </ErrorWrapper>
                </Grid>
            </Grid>
        </ErrorCard>
    );
};

export default NotFound;
