export const Category = {
    QueueDataReport: 'QueueDataReport',
    CallDataReport: 'CallDataReport',
    AgentDataReport: 'AgentDataReport'
};

export const Fields = {
    QueueHandlingType: 'QueueHandlingType',
    QueueSourceType: 'QueueSourceType',
    Agent: 'Agent',
    Queue: 'Queue',
    QueueFinalAction: 'QueueFinalAction',
    QueueDisposition: 'QueueDisposition',
    AgentFinalAction: 'AgentFinalAction',

    CallFinalAction: 'CallFinalAction',
    CallDirection: 'CallDirection'
};
