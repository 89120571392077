import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { useTheme } from '@mui/material/styles';

import DataGrid, { Column, Selection, SearchPanel, Scrolling, FilterRow } from 'devextreme-react/data-grid';
import { IconTrash, IconPencil } from '@tabler/icons';
import { Tooltip, IconButton } from '@mui/material';

const DxDataGrid = ({
    dataSet,
    columns,
    keyField,
    isFilterRow = false,
    height,
    scrolling = false,
    selectedFilterValues,
    setSelectedFilterValues,
    showBorders = false,
    selection = false,
    allowSelectAll = false,
    showRowLines = false,
    searchPanel = false,
    handleEdit,
    handleDelete
}) => {
    const theme = useTheme();
    const dataGrid = useRef(null);

    const onSelectionChanged = (e) => {
        // console.log(selectedRowsData);
        setSelectedFilterValues(e.selectedRowKeys, e.currentDeselectedRowKeys, e.selectedRowsData);
    };

    return (
        <DataGrid
            className={`${theme.palette.mode === 'dark' ? 'dx-swatch-base-dark' : ''}`}
            id="grid-container"
            height={height || 'auto'}
            dataSource={dataSet}
            keyExpr={keyField}
            onSelectionChanged={onSelectionChanged}
            ref={dataGrid}
            selectedRowKeys={selectedFilterValues}
            allowColumnResizing
            showBorders={showBorders}
            showRowLines={showRowLines}
        >
            {selection && <Selection mode="multiple" showCheckBoxesMode="always" allowSelectAll={allowSelectAll} />}
            {scrolling && <Scrolling mode="virtual" />}
            {isFilterRow && <FilterRow visible />}
            {searchPanel && <SearchPanel visible highlightSearchText={false} />}
            {columns.map((colItem, index) => {
                if (colItem.type === 'custom') {
                    return (
                        <Column
                            key={index}
                            caption={colItem.caption}
                            dataField=""
                            width={200}
                            alignment="left"
                            cellRender={(cellData) => {
                                return colItem.getComponent(cellData.data);
                            }}
                        />
                    );
                }
                if (colItem.dataField === 'actionButtons') {
                    return (
                        <Column
                            key={index}
                            caption={colItem.caption}
                            dataField=""
                            width={200}
                            alignment="center"
                            type="buttons"
                            cellRender={(cellData) => (
                                <>
                                    {handleEdit && (
                                        <Tooltip title="Edit">
                                            <span>
                                                <IconButton
                                                    color="secondary"
                                                    id={`edit${cellData.rowIndex}`}
                                                    size="small"
                                                    onClick={() => handleEdit(cellData.data)}
                                                    disabled={colItem.disableEdit ? colItem.disableEdit(cellData.data) : false}
                                                >
                                                    <IconPencil fontSize="inherit" />
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                    )}
                                    {handleDelete && (
                                        <Tooltip title="Delete">
                                            <span>
                                                <IconButton
                                                    id={`delete${cellData.rowIndex}`}
                                                    color="secondary"
                                                    size="small"
                                                    onClick={() => handleDelete(cellData.data)}
                                                    disabled={colItem.disableDelete ? colItem.disableDelete(cellData.data) : false}
                                                >
                                                    <IconTrash fontSize="inherit" />
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                    )}
                                </>
                            )}
                        />
                    );
                }

                return (
                    <Column key={index} dataField={colItem.dataField} caption={colItem.caption} encodeHtml={colItem.encodeHtml ?? true} />
                );
            })}
        </DataGrid>
    );
};

DxDataGrid.propTypes = {
    dataSet: PropTypes.arrayOf(PropTypes.object).isRequired,
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    keyField: PropTypes.string.isRequired,
    isFilterRow: PropTypes.bool,
    height: PropTypes.number,
    scrolling: PropTypes.bool,
    selectedFilterValues: PropTypes.array,
    setSelectedFilterValues: PropTypes.func,
    showBorders: PropTypes.bool,
    selection: PropTypes.bool,
    showRowLines: PropTypes.bool,
    allowSelectAll: PropTypes.bool,
    searchPanel: PropTypes.bool,
    handleEdit: PropTypes.func,
    handleDelete: PropTypes.func
};

export default DxDataGrid;
