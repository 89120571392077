import { Grid, Dialog, DialogTitle, Divider, Typography, useTheme } from '@mui/material';
import { IconX } from '@tabler/icons';
import CustomStore from 'devextreme/data/custom_store';
import IconButton from '@mui/material/IconButton';
import { axiosMSTeamsCQService } from 'utils/axios';
import Slide from '@mui/material/Slide';
import { DashboardStateContext } from '../context/DashboardStateContext';
import React, { useContext, useMemo } from 'react';
import DataGrid, { Scrolling, Grouping, Pager, Paging, Sorting } from 'devextreme-react/data-grid';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { defaultDateRangeFormat } from '../constants';
import { getDateTimeWithDstAdjustment } from '../Utils/queueDashboardUtils';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const MetricDetailsDialog = ({ apiUrl, open, handleClose, metricFilter, agentId, title, dataSize }) => {
    const { selectedQueueId, dateTimeFilter, dateTimeRange } = useContext(DashboardStateContext);
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const allowedPageSizes = [250, 500, 1000];
    const noDataText = 'No Data';

    function handleErrors(response) {
        if (!response?.data?.success) {
            throw Error(response);
        }
        return response;
    }
    const dataSource = useMemo(
        () =>
            new CustomStore({
                key: 'Id',
                load: (loadOptions) => {
                    console.log('load options', loadOptions);

                    let offset = 0;
                    let count = 0;
                    if (loadOptions.skip) {
                        offset = loadOptions.skip;
                    }
                    if (loadOptions.take) {
                        count = loadOptions.take;
                    } else {
                        count = 1000;
                    }

                    const headers = {
                        'Content-Type': 'application/json',
                        Accept: 'application/json'
                    };

                    const body = JSON.stringify({
                        offset,
                        count,
                        queueId: selectedQueueId,
                        dateTimeFilter: getDateTimeWithDstAdjustment(dateTimeFilter),
                        metricFilter,
                        agentId
                    });

                    return axiosMSTeamsCQService
                        .post(apiUrl, body, {
                            headers
                        })
                        .then(handleErrors)

                        .then((response) => response.data)
                        .then((response) => {
                            return {
                                data: response?.data,
                                totalCount: parseInt(dataSize, 10)
                            };
                        })
                        .catch((e) => {
                            console.log('Network error', e);
                            enqueueSnackbar('Failed to Load', { variant: 'error' });
                            throw Error('error in fetching data');
                        });
                }
            }),
        []
    );

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            sx={{
                p: 3,
                '& .MuiDialog-container': {
                    '& .MuiPaper-root': {
                        width: '100%',
                        maxWidth: '100%',
                        padding: '0 0 0 0',
                        borderRadius: 2
                    }
                },
                '& .dx-toolbar-after': {
                    left: 'auto',
                    right: 0
                }
            }}
            className={`dx-viewport ${theme.palette.mode === 'dark' ? 'dx-swatch-base-dark' : ''}`} // Responsible for theme support for Devextreme components
        >
            <DialogTitle>
                <Grid item direction="column" xs={8}>
                    <Typography variant="h4">{title}</Typography>

                    <Grid direction="row">
                        <Typography variant="caption" sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}>
                            {moment(dateTimeRange?.start)?.format(defaultDateRangeFormat)}
                        </Typography>
                        <Typography variant="caption" sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}>
                            {' '}
                            -{' '}
                        </Typography>
                        <Typography variant="caption" sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}>
                            {moment(dateTimeRange?.end)?.format(defaultDateRangeFormat)}
                        </Typography>
                    </Grid>
                </Grid>

                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 10,
                        top: 20
                    }}
                >
                    <IconX />
                </IconButton>
            </DialogTitle>
            <Divider />
            <Grid sx={{ pl: 2, pr: 2, pb: 2, height: '85vh' }}>
                {parseInt(dataSize, 10) === 0 ? (
                    <div className="metrics-no-data-view">{noDataText}</div>
                ) : (
                    <div style={{ height: '100%' }}>
                        <DataGrid
                            dataSource={dataSource}
                            className="datagrid"
                            noDataText={noDataText}
                            height="100%"
                            showBorders={false}
                            allowColumnReordering
                            rowAlternationEnabled
                            filtering
                            repaintChangesOnly
                            width="100%"
                            wordWrapEnabled
                            columnAutoWidth
                            allowColumnResizing
                            remoteOperations
                            errorRowEnabled={false}
                        >
                            <Scrolling mode="paging" />
                            <Sorting mode="none" />
                            <Grouping autoExpandAll={false} />
                            <Paging defaultPageSize={250} />
                            <Pager visible allowedPageSizes={allowedPageSizes} displayMode="full" showPageSizeSelector />
                        </DataGrid>
                    </div>
                )}
            </Grid>
        </Dialog>
    );
};

export default MetricDetailsDialog;
