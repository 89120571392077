import { createContext } from 'react';

import { QueueDataSource } from '../constants';

export const DEFAULT_DASHBOARD_STATE = {
    dataSource: QueueDataSource.MicrosoftTeams, // By Default assuming MSTeams for now, this will need to be changed
    dashboardId: 0,
    dashboardName: '',
    isInEditMode: false,
    hasUnsavedChanges: false,
    isDefault: false,
    // single metrics collection
    queueMetrics: {},
    // agents list, with metrics
    agentMetrics: [],
    // List of queueIds selected for the dashboard
    queueIdsFilter: [],
    // QueueIds filter to fallback to on discard changes
    discardFallbackQueueIdsFilter: [],
    // Date time filter for the dashboard
    dateTimeFilter: null,
    // Summary of the selected queues for the currrent dashboard (For the queues with Ids in queuesFilter)
    filteredQueuesMetricsSummary: [],
    // Id of the current selected queue
    selectedQueueId: null,
    // list of widgets
    widgets: [],
    discardFallbackWidgets: [],
    breakpoint: 'lg',
    // Whether changes are being saved currently
    savingDashboard: false,
    loadingQueueAndAgentMetrics: false,
    refreshingQueueAndAgentMetrics: false
};

export const DashboardStateContext = createContext();
export const DashboardStateDispatchContext = createContext();

export const DashboardStateActions = {
    LoadDashboard: 'load_dashboard',
    SetEditMode: 'set_edit_mode',
    SetQueueIdsFilter: 'set_queue_ids_filter', // This operation would always happen in edit mode
    SetDateTimeFilter: 'set_date_time_filter',
    SetSelectedQueueId: 'set_selected_queue_id',
    SetFilteredQueuesMetricsSummary: 'set_filtered_queues_metrics_summary',
    SetQueueMetrics: 'set_queue_metrics',
    SetAgentMetricsList: 'set_agent_metrics_list',
    SetWidgetsList: 'set_widgets_list',
    AddWidgetToWigetsList: 'add_widget_to_widgets_list',
    SetCurrentBreakpoint: 'set_current_breakpoint',
    SetDiscardFallbackData: 'set_discard_fallback_data',
    ResetDiscardFallbackData: 'reset_discard_fallback_data',
    SaveEditModeChanges: 'save_edit_mode_changes',
    DiscardEditModeChanges: 'discard_edit_mode_changes',
    UpdateWidgetMetrics: 'update_widget_metrics',
    UpdateWidgetConfig: 'update_widget_config',
    DeleteWidget: 'delete_widget',
    SetSavingDashboard: 'set_saving_dashboard',
    SetDashboardAsDefault: 'set_dashboard_as_default',
    SetLoadingQueueAgentMetrics: 'set_loading_queue_agent_metrics',
    SetRefreshingQueueAgentMetrics: 'set_refreshing_queue_agent_metrics',
    UpdateWidget: 'update_widget',
    SetHasUnsavedChanges: 'set_unsaved_changes',
    SetDashboardDateTimeRange: 'set_dashboard_date_time_range'
};

export const DashboardStateReducer = (state, action) => {
    switch (action.type) {
        case DashboardStateActions.LoadDashboard:
            return {
                ...state,
                isInEditMode: false,
                queueMetrics: {},
                agentMetrics: [],
                breakpoint: 'lg',
                savingDashboard: false,
                loadingQueueAndAgentMetrics: false,
                refreshingQueueAndAgentMetrics: false,
                filteredQueuesMetricsSummary: [],
                discardFallbackQueueIdsFilter: [],
                discardFallbackWidgets: [],
                dataSource: action.payload.dataSource,
                dashboardId: action.payload.id,
                dashboardName: action.payload.name,
                isDefault: action.payload.isDefault,
                queueIdsFilter: action.payload.queueIdsFilter,
                selectedQueueId: action.payload.selectedQueueId,
                dateTimeFilter: action.payload.dateTimeFilter,
                widgets: action.payload.widgets
            };
        case DashboardStateActions.SetEditMode:
            return {
                ...state,
                isInEditMode: action.payload
            };
        case DashboardStateActions.SetQueueIdsFilter:
            return {
                ...state,
                queueIdsFilter: action.payload
            };
        case DashboardStateActions.SetSelectedQueueId:
            return {
                ...state,
                selectedQueueId: action.payload
            };
        case DashboardStateActions.SetFilteredQueuesMetricsSummary:
            return {
                ...state,
                filteredQueuesMetricsSummary: action.payload
            };
        case DashboardStateActions.SetQueueMetrics:
            return {
                ...state,
                queueMetrics: action.payload
            };
        case DashboardStateActions.SetAgentMetricsList:
            return {
                ...state,
                agentMetrics: action.payload
            };
        case DashboardStateActions.SetWidgetsList:
            return {
                ...state,
                widgets: action.payload
            };
        case DashboardStateActions.AddWidgetToWigetsList:
            return {
                ...state,
                widgets: [
                    ...state.widgets,
                    {
                        id: `temp-id-${state.widgets.length}`,
                        ...action.payload
                    }
                ]
            };
        case DashboardStateActions.SetCurrentBreakpoint:
            return {
                ...state,
                breakpoint: action.payload
            };
        case DashboardStateActions.SetDiscardFallbackData:
            return {
                ...state,
                discardFallbackQueueIdsFilter: state.queueIdsFilter,
                discardFallbackWidgets: JSON.parse(JSON.stringify(state.widgets))
            };
        case DashboardStateActions.ResetDiscardFallbackData:
            return {
                ...state,
                discardFallbackQueueIdsFilter: [],
                discardFallbackWidgets: []
            };
        case DashboardStateActions.SaveEditModeChanges:
            return {
                ...state,
                widgets: action.payload.widgets,
                dashboardName: action.payload.name,
                // TODO: Update other props too
                discardFallbackQueueIdsFilter: [],
                discardFallbackWidgets: []
            };
        case DashboardStateActions.DiscardEditModeChanges:
            return {
                ...state,
                widgets: [...state.discardFallbackWidgets],
                queueIdsFilter: [...state.discardFallbackQueueIdsFilter],
                discardFallbackWidgets: [],
                discardFallbackQueueIdsFilter: []
            };
        case DashboardStateActions.UpdateWidgetMetrics: {
            const widgetToUpdateIndex = state.widgets.findIndex((item) => item.id === action.payload.widgetId);

            if (widgetToUpdateIndex === -1) {
                return state;
            }

            const updatedWidgets = [...state.widgets];
            updatedWidgets[widgetToUpdateIndex].metrics = action.payload.value;

            return {
                ...state,
                widgets: updatedWidgets
            };
        }
        case DashboardStateActions.UpdateWidgetConfig: {
            const widgetToUpdateIndex = state.widgets.findIndex((item) => item.id === action.payload.widgetId);

            if (widgetToUpdateIndex === -1) {
                return state;
            }

            const { name, ...rest } = action.payload.config;

            const updatedWidgets = [...state.widgets];
            updatedWidgets[widgetToUpdateIndex].config = rest;
            updatedWidgets[widgetToUpdateIndex].name = name;

            return {
                ...state,
                widgets: updatedWidgets
            };
        }
        case DashboardStateActions.UpdateWidget: {
            const widgetToUpdateIndex = state.widgets.findIndex((item) => item.id === action.payload.widgetId);

            if (widgetToUpdateIndex === -1) {
                return state;
            }

            const updatedWidgets = [...state.widgets];
            updatedWidgets[widgetToUpdateIndex].config = action.payload.config;
            if (action.payload.name) {
                updatedWidgets[widgetToUpdateIndex].name = action.payload.name;
            }
            updatedWidgets[widgetToUpdateIndex].metrics = action.payload.metrics;

            return {
                ...state,
                widgets: updatedWidgets
            };
        }
        case DashboardStateActions.DeleteWidget: {
            const widgetToDeleteIndex = state.widgets.findIndex((item) => item.id === action.payload.id);

            if (widgetToDeleteIndex === -1) {
                return state;
            }

            return {
                ...state,
                widgets: state.widgets.toSpliced(widgetToDeleteIndex, 1)
            };
        }
        case DashboardStateActions.SetDateTimeFilter:
            return {
                ...state,
                dateTimeFilter: action.payload.value
            };
        case DashboardStateActions.SetSavingDashboard:
            return {
                ...state,
                savingDashboard: action.payload
            };
        case DashboardStateActions.SetDashboardAsDefault:
            return {
                ...state,
                isDefault: true
            };
        case DashboardStateActions.SetLoadingQueueAgentMetrics:
            return {
                ...state,
                loadingQueueAndAgentMetrics: action.payload
            };
        case DashboardStateActions.SetRefreshingQueueAgentMetrics:
            return {
                ...state,
                refreshingQueueAndAgentMetrics: action.payload
            };
        case DashboardStateActions.SetHasUnsavedChanges:
            return {
                ...state,
                hasUnsavedChanges: action.payload
            };
        case DashboardStateActions.SetDashboardDateTimeRange:
            return {
                ...state,
                dateTimeRange: action.payload
            };
        default:
            return state;
    }
};
