import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Container } from '@mui/material';

// project imports
import useConfig from 'hooks/useConfig';
import { dispatch } from 'store';

import useRouteAccess from 'routes/useRouteAccess';
import MainCard from 'ui-component/cards/MainCard';
import NoAccess from 'views/pages/NoAccess';
import { openDrawer } from 'store/slices/menu';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open, layout }) => ({
    ...theme.typography.reportContent,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
}));

// ==============================|| MAIN LAYOUT ||============================== //

const ReportLayout = () => {
    const theme = useTheme();
    const hasAccessToCurrentRoute = useRouteAccess();

    const { container, layout, navType } = useConfig();

    useEffect(() => {
        dispatch(openDrawer(true));
        // initialize pendo
    }, [dispatch]);

    return (
        <Box
            sx={{ display: 'flex' }}
            className={`dx-viewport ${navType === 'dark' ? 'dx-swatch-base-dark' : ''}`} // Responsible for theme support for Devextreme components
        >
            {/* main content */}
            <Main theme={theme} layout={layout}>
                {!hasAccessToCurrentRoute ? (
                    <MainCard>
                        <NoAccess />
                    </MainCard>
                ) : (
                    <>
                        {container && (
                            <Container maxWidth={container ? 'lg' : false} {...(!container && { sx: { px: { xs: 0 } } })}>
                                <Outlet />
                            </Container>
                        )}
                        {!container && (
                            <>
                                <Outlet />
                            </>
                        )}
                    </>
                )}
            </Main>
        </Box>
    );
};

export default ReportLayout;
