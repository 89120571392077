import React, { useContext, useState } from 'react';

import { DashboardStateContext } from '../context/DashboardStateContext';
import QueueMetricItem from './QueueMetricItem';
import EditQueueMetricsGroupDialog from './EditQueueMetricsGroupDialog';
import EditWidgetMenu from '../EditWidgetMenu/EditWidgetMenu';
import Icon from '@mdi/react';
import { mdiAlert } from '@mdi/js';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { getRefreshingWidgetDataAnimateIcon } from '../Utils/queueDashboardUtils';
import { useTheme } from '@emotion/react';
import { chunk } from 'lodash';
import MetricDetailsDialog from '../MetricDetailsDialog/MetricDetailsDialog';
import { useSelector } from 'store';
import { Grid } from '@mui/material';

function isItemInTheLastRow(itemIndex, rowWidth, totalItems) {
    const gridItemsIndexMatrix = new Array(totalItems).fill(1).map((item, index) => item * index);
    const result = chunk(gridItemsIndexMatrix, rowWidth);

    return result[result.length - 1].includes(itemIndex);
}

function QueueMetricsGrid({ widget }) {
    const { queueMetrics, isInEditMode, savingDashboard, breakpoint, loadingQueueAndAgentMetrics, refreshingQueueAndAgentMetrics } =
        useContext(DashboardStateContext);
    const { allowedQueueMetricsWidgetConfig } = useSelector((state) => state.queueDashboard);
    const [isEditQueueMetricsDialogOpen, setIsEditQueueMetricsDialogOpen] = useState(false);
    const theme = useTheme();
    const [detailsOpen, setDetailsOpen] = React.useState(false);
    const [metric, setMetric] = React.useState('');
    const borderColor = theme.palette.mode === 'dark' ? 'rgba(97, 97, 97, 0.1)' : '#eeeeee';
    const apiUrl = '/api/DetailedDataAnalytics/getQueueDataDetailsForMetric';

    const { h, w } = widget.layout[breakpoint];
    const widgetWidth = Math.min(w, widget.config.maxMetricsPerRow);
    const allowedMetrics = allowedQueueMetricsWidgetConfig?.allowedMetrics;
    const toggleEditQueueMetricsDialog = () => {
        setIsEditQueueMetricsDialogOpen((open) => !open);
    };

    const handleDetailsDialogClose = () => {
        setDetailsOpen(false);
    };

    const handleQueueCellClick = (metric) => {
        setMetric(metric);
        setDetailsOpen(true);
    };

    const widgetControlComponent = (
        <>
            <div style={{ position: 'absolute', top: 4, right: 0, display: 'flex', alignItems: 'center' }}>
                {!isInEditMode && refreshingQueueAndAgentMetrics && getRefreshingWidgetDataAnimateIcon(widget.id)}
                {widgetWidth * h < widget.metrics.length && (
                    <Tooltip title="Resize widget to show all selected metrics">
                        <span>
                            <IconButton disabled>
                                <Icon path={mdiAlert} size={1} color="#ffa700" />
                            </IconButton>
                        </span>
                    </Tooltip>
                )}
                {isInEditMode && !savingDashboard && <EditWidgetMenu onEditClick={toggleEditQueueMetricsDialog} widgetId={widget.id} />}
            </div>
            {isEditQueueMetricsDialogOpen && (
                <EditQueueMetricsGroupDialog
                    isEditQueueMetricsDialogOpen={isEditQueueMetricsDialogOpen}
                    toggleEditQueueMetricsDialog={toggleEditQueueMetricsDialog}
                    widget={widget}
                />
            )}
        </>
    );

    if (loadingQueueAndAgentMetrics) {
        return <div className="queue-metrics-no-data-view">Loading data...</div>;
    }

    if (!queueMetrics || !Object.keys(queueMetrics).length) {
        return (
            <div className="queue-metrics-no-data-view">
                No data for the selected date range and filters
                {widgetControlComponent}
            </div>
        );
    }

    return (
        <>
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: `repeat(${widgetWidth}, 1fr)`,
                    paddingLeft: 20,
                    paddingRight: 20
                }}
            >
                {queueMetrics &&
                    widget.metrics.map((item, index) => (
                        <div
                            key={`metric-card-${item.metric}-${item.calculationType}`}
                            style={{
                                padding: '20px 0',
                                height: (140 * h + 15 * (h - 1)) / h,
                                borderBottom: !isItemInTheLastRow(index, widgetWidth, widget.metrics.length)
                                    ? `1px solid ${borderColor}`
                                    : 'none'
                            }}
                        >
                            <Grid
                                aria-hidden="true"
                                className="single-metric-item"
                                style={{
                                    borderLeft: index % widgetWidth ? `1px solid ${borderColor}` : 'none'
                                }}
                                onClick={
                                    allowedMetrics && allowedMetrics[item.metric]?.isDrilldownAllowed
                                        ? () => handleQueueCellClick(item)
                                        : null
                                }
                                sx={{
                                    cursor: allowedMetrics && allowedMetrics[item.metric]?.isDrilldownAllowed ? 'pointer' : 'auto',
                                    '&:hover ':
                                        allowedMetrics && allowedMetrics[item.metric]?.isDrilldownAllowed
                                            ? { background: theme.palette.secondary.light }
                                            : null
                                }}
                            >
                                <QueueMetricItem
                                    metricName={item.metric}
                                    metric={queueMetrics[item.metric]}
                                    calculation={item.calculationType}
                                    displayAs={item.displayAs}
                                />
                            </Grid>
                        </div>
                    ))}
                {widgetControlComponent}
            </div>
            {detailsOpen && (
                <MetricDetailsDialog
                    open={detailsOpen}
                    handleClose={handleDetailsDialogClose}
                    metricFilter={metric?.metric}
                    title={metric?.displayAs}
                    apiUrl={apiUrl}
                    dataSize={queueMetrics[metric.metric]?.count?.value}
                />
            )}
        </>
    );
}

export default function QueueMetricsGroup({ widget }) {
    return <QueueMetricsGrid widget={widget} />;
}
