import { createSlice } from '@reduxjs/toolkit';
import { axiosNotificationService } from 'utils/axios';

// initial state
export const initialState = {
    notifications: [],
    isNewNotification: false,
    loading: false,
    error: null,
    temp: false,
    hasMoreData: true
};

// ==============================|| SLICE - notifications ||============================== //
const notifications = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        fetchNotificationsRequest(state) {
            // state.loading = true;
            state.hasMoreData = true;
        },
        fetchNotificationsFailure(state, action) {
            // state.loading = false;
            state.error = action.payload;
        },
        fetchNotificationsSuccess(state, action) {
            state.temp = false;
            // state.loading = false;
            for (let i = 0; i < action.payload.data.length; i += 1) {
                if (action.payload.data[i].deliveryStatus === 'Unread') {
                    state.isNewNotification = true;
                    state.temp = true;
                    break;
                }
            }
            if (!state.temp) {
                state.isNewNotification = false;
            }
            if (!action.payload.fromLazyLoading) {
                state.notifications = action.payload.data;
            } else {
                state.notifications = state.notifications.concat(action.payload.data);
            }
            if (action.payload.data.length < 10) {
                state.hasMoreData = false;
            }
        },
        fetchNotificationsReadSuccess(state, action) {
            state.temp = false;
            for (let i = 0; i < state.notifications.length; i += 1) {
                if (state.notifications[i].notificationId !== action.payload.notificationId) {
                    state.notifications[i].deliveryStatus = 'Read';
                }
                if (state.notifications[i].deliveryStatus === 'Unread') {
                    state.isNewNotification = true;
                    state.temp = true;
                    break;
                }
            }
            if (!state.temp) {
                state.isNewNotification = false;
            }
        },
        clearAllNotificationsRequest(state, action) {
            state.loading = true;
        },
        clearAllNotificationSuccess(state, action) {
            state.loading = false;
            state.notifications = [];
            state.isNewNotification = false;
        },
        clearAllNotificationFailure(state, action) {
            state.loading = false;
        }
    }
});

export function fetchNotifications(offset, fromLazyLoading) {
    return async (dispatch) => {
        const payload = { offset, size: 10, medium: 'InApp' };
        dispatch(notifications.actions.fetchNotificationsRequest());

        try {
            const response = await axiosNotificationService.get('/notifications', { params: payload });
            if (response?.data?.success) {
                const data = response.data.data;
                dispatch(notifications.actions.fetchNotificationsSuccess({ data, fromLazyLoading }));
            } else {
                dispatch(notifications.actions.fetchNotificationsFailure(response.data.error));
            }
        } catch (ex) {
            console.log(ex);
            if (ex.response) {
                dispatch(notifications.actions.fetchNotificationsFailure(ex.response));
            }
        }
    };
}

export function fetchNotification(id) {
    return async (dispatch) => {
        // const payload = { id };
        dispatch(notifications.actions.fetchNotificationsRequest());

        try {
            const response = await axiosNotificationService.get(`/notifications/${id}`);
            if (response?.data?.success) {
                dispatch(notifications.actions.fetchNotificationsReadSuccess(response.data.data));
            } else {
                dispatch(notifications.actions.fetchNotificationsFailure(response.data.error));
            }
        } catch (ex) {
            console.log(ex);
            if (ex.response) {
                dispatch(notifications.actions.fetchNotificationsFailure(ex.response));
            }
        }
    };
}

export function setNotification(enqueueSnackbar, payload) {
    return async (dispatch) => {
        try {
            await axiosNotificationService.post('/notifications', payload);

            dispatch(fetchNotifications());
            enqueueSnackbar(payload.successMessage || 'Notification saved successfully', { variant: 'info' });
        } catch (ex) {
            console.log(ex);
            enqueueSnackbar(payload.ErrorMessage || 'Failed to save notification', { variant: 'error' });
        }
    };
}

export function clearAllNotifications() {
    return async (dispatch) => {
        dispatch(notifications.actions.clearAllNotificationsRequest());

        try {
            const response = await axiosNotificationService.delete(`/notifications`);
            if (response?.data?.success) {
                dispatch(notifications.actions.clearAllNotificationSuccess());
            } else {
                dispatch(notifications.actions.clearAllNotificationFailure(response.data.error));
            }
        } catch (ex) {
            console.log(ex);
            if (ex.response) {
                dispatch(notifications.actions.clearAllNotificationFailure(ex.response));
            }
        }
    };
}

export default notifications.reducer;

export const { fetchNotificationsRequest, fetchNotificationsFailure, fetchNotificationsSuccess } = notifications.actions;
