// Map the Infortel fields to corresponding Cisco Fields
// InfortelFields: CiscoFields
const columnMapping = {
    FirstName: 'FirstName',
    LastName: 'LastName',
    Extension: 'AssignedExtensions',
    CiscoLoginName: 'UserID',
    Device: 'ControlledDevices',
    'Name{n}': 'Department',
    WebexUserId: 'UserId',
    LocationInfo: 'Location'
};

// this is for testing purpose
export const sampleData = [
    {
        LastName: 'Leo',
        FirstName: 'Mason',
        Department: 'Civil',
        MailID: 'Ezra@tekvizion.com',
        TelephoneNumber: '4355435',
        Extension: '1002',
        UserID: '1002',
        DeviceDescription: 'SEP50F7222CF69B',
        PKID: '06fceae0-f0b6-4435-9c55-f7479547ab21',
        AssocPC: 'California',
        Building: '',
        ControlledDevice: 'SEP50F7222CF69B',
        EnableEMCC: 'F',
        EnableMobileVoice: 'F',
        EnableMobility: 'F',
        ExtensionRank: '1',
        HomePhone: '345435234',
        Manager: 'Luca',
        MiddleName: 'Jackson',
        Mobile: '253595',
        NickName: '',
        OCSPrimaryUserAddress: '',
        Site: '',
        Status: '1',
        Title: 'Testing4',
        UniqueIdentifier: '',
        UserLocale: 'English, United States',
        Custom1: '',
        Custom2: '',
        Custom3: '',
        Custom4: '',
        Custom5: ''
    },
    {
        LastName: 'Jonathan',
        FirstName: 'Mark',
        Department: 'Engineering',
        MailID: 'mjonathanr@tekvizion.com',
        TelephoneNumber: '4378435',
        Extension: '3002',
        UserID: '3002',
        DeviceDescription: 'SEP50F7222CF69B',
        PKID: '06fceae0-f0b6-4435-9c55-f7479547ab21',
        AssocPC: 'California',
        Building: '',
        ControlledDevice: 'SEP50F7222CF69B',
        EnableEMCC: 'F',
        EnableMobileVoice: 'F',
        EnableMobility: 'F',
        ExtensionRank: '1',
        HomePhone: '345435234',
        Manager: 'Luca',
        MiddleName: 'Rob',
        Mobile: '3217854467',
        NickName: '',
        OCSPrimaryUserAddress: '',
        Site: '',
        Status: '1',
        Title: 'Testing4',
        UniqueIdentifier: '',
        UserLocale: 'English, United States',
        Custom1: '',
        Custom2: '',
        Custom3: '',
        Custom4: '',
        Custom5: ''
    },
    {
        LastName: 'Ray',
        FirstName: 'Jhonson',
        Department: 'IT',
        MailID: 'rjohn@tekvizion.com',
        TelephoneNumber: '7856935',
        Extension: '6235',
        UserID: '6235',
        DeviceDescription: 'SEP50F7222CF69B',
        PKID: '06fceae0-f0b6-4435-9c55-f7479547ab21',
        AssocPC: 'Chicago',
        Building: '',
        ControlledDevice: 'SEP50F7222CF69B',
        EnableEMCC: 'F',
        EnableMobileVoice: 'F',
        EnableMobility: 'F',
        ExtensionRank: '1',
        HomePhone: '345435234',
        Manager: 'Brad',
        MiddleName: 'Paul',
        Mobile: '253595',
        NickName: '',
        OCSPrimaryUserAddress: '',
        Site: '',
        Status: '1',
        Title: 'Testing4',
        UniqueIdentifier: '',
        UserLocale: 'English, United States',
        Custom1: '',
        Custom2: '',
        Custom3: '',
        Custom4: '',
        Custom5: ''
    },
    {
        LastName: 'Levi',
        FirstName: 'Benjamin',
        Department: 'IT',
        MailID: 'rjohn@tekvizion.com',
        TelephoneNumber: '7856935',
        Extension: '6235',
        UserID: '6235',
        DeviceDescription: 'SEP50F7222CF69B',
        PKID: '06fceae0-f0b6-4435-9c55-f7479547ab21',
        AssocPC: 'Chicago',
        Building: '',
        ControlledDevice: 'SEP50F7222CF69B',
        EnableEMCC: 'F',
        EnableMobileVoice: 'F',
        EnableMobility: 'F',
        ExtensionRank: '1',
        HomePhone: '345435234',
        Manager: '',
        MiddleName: '',
        Mobile: '253595',
        NickName: '',
        OCSPrimaryUserAddress: '',
        Site: '',
        Status: '1',
        Title: 'Testing4',
        UniqueIdentifier: '',
        UserLocale: 'English, United States',
        Custom1: '',
        Custom2: '',
        Custom3: '',
        Custom4: '',
        Custom5: ''
    },
    {
        LastName: 'Alexander',
        FirstName: 'Jack',
        Department: 'Network',
        MailID: 'jalexander@tekvizion.com',
        TelephoneNumber: '7856935',
        Extension: '6235',
        UserID: '6235',
        DeviceDescription: 'SEP50F7222CF69B',
        PKID: '06fceae0-f0b6-4435-9c55-f7479547ab21',
        AssocPC: 'Chicago',
        Building: '',
        ControlledDevice: 'SEP50F7222CF69B',
        EnableEMCC: 'F',
        EnableMobileVoice: 'F',
        EnableMobility: 'F',
        ExtensionRank: '1',
        HomePhone: '345435234',
        Manager: '',
        MiddleName: '',
        Mobile: '253595',
        NickName: '',
        OCSPrimaryUserAddress: '',
        Site: '',
        Status: '1',
        Title: 'Testing4',
        UniqueIdentifier: '',
        UserLocale: 'English, United States',
        Custom1: '',
        Custom2: '',
        Custom3: '',
        Custom4: '',
        Custom5: ''
    },
    {
        LastName: 'Clark',
        FirstName: 'Emilia',
        Department: '',
        MailID: 'mclark@tekvizion.com',
        TelephoneNumber: '7489',
        Extension: '6235',
        UserID: '6235',
        DeviceDescription: 'SEP50F7222CF69B',
        PKID: '06fceae0-f0b6-4435-9c55-f7479547ab21',
        AssocPC: 'Chicago',
        Building: '',
        ControlledDevice: 'SEP50F7222CF69B',
        EnableEMCC: 'F',
        EnableMobileVoice: 'F',
        EnableMobility: 'F',
        ExtensionRank: '1',
        HomePhone: '345435234',
        Manager: 'Kristian',
        MiddleName: '',
        Mobile: '253595',
        NickName: '',
        OCSPrimaryUserAddress: '',
        Site: '',
        Status: '1',
        Title: 'Testing4',
        UniqueIdentifier: '',
        UserLocale: 'English, United States',
        Custom1: '',
        Custom2: '',
        Custom3: '',
        Custom4: '',
        Custom5: ''
    },
    {
        LastName: 'user',
        FirstName: '',
        Department: '',
        MailID: '',
        TelephoneNumber: '5665',
        Extension: '6235',
        UserID: '6235',
        DeviceDescription: 'SEP50F7222CF69B',
        PKID: '06fceae0-f0b6-4435-9c55-f7479547ab21',
        AssocPC: 'Chicago',
        Building: '',
        ControlledDevice: 'SEP50F7222CF69B',
        EnableEMCC: 'F',
        EnableMobileVoice: 'F',
        EnableMobility: 'F',
        ExtensionRank: '1',
        HomePhone: '345435234',
        Manager: 'Allyson',
        MiddleName: 'Brad',
        Mobile: '253595',
        NickName: '',
        OCSPrimaryUserAddress: '',
        Site: '',
        Status: '1',
        Title: 'Testing4',
        UniqueIdentifier: '',
        UserLocale: 'English, United States',
        Custom1: '',
        Custom2: '',
        Custom3: '',
        Custom4: '',
        Custom5: ''
    },
    {
        LastName: 'user',
        FirstName: 'Test',
        Department: '',
        MailID: 'testuser1@tekvizion.com',
        TelephoneNumber: '6550',
        Extension: '6235',
        UserID: '6235',
        DeviceDescription: 'SEP50F7222CF69B',
        PKID: '06fceae0-f0b6-4435-9c55-f7479547ab21',
        AssocPC: 'Chicago',
        Building: '',
        ControlledDevice: 'SEP50F7222CF69B',
        EnableEMCC: 'F',
        EnableMobileVoice: 'F',
        EnableMobility: 'F',
        ExtensionRank: '1',
        HomePhone: '345435234',
        Manager: 'Benjamin',
        MiddleName: 'Paul',
        Mobile: '253595',
        NickName: '',
        OCSPrimaryUserAddress: '',
        Site: '',
        Status: '1',
        Title: 'Testing4',
        UniqueIdentifier: '',
        UserLocale: 'English, United States',
        Custom1: '',
        Custom2: '',
        Custom3: '',
        Custom4: '',
        Custom5: ''
    }
];

export const sampleData1 = {
    columns: [
        'LastName',
        'FirstName',
        'Department',
        'MailID',
        'TelephoneNumber',
        'Extension',
        'UserID',
        'DeviceDescription',
        'PKID',
        'AssocPC',
        'Building',
        'ControlledDevice',
        'EnableEMCC',
        'EnableMobileVoice',
        'EnableMobility',
        'ExtensionRank',
        'HomePhone',
        'Manager',
        'MiddleName',
        'Mobile',
        'NickName',
        'OCSPrimaryUserAddress',
        'Site',
        'Status',
        'Title',
        'UniqueIdentifier',
        'UserLocale',
        'Custom1',
        'Custom2',
        'Custom3',
        'Custom4',
        'Custom5'
    ],
    rows: [
        [
            'Leo',
            'Mason',
            'Civil',
            'Ezra@tekvizion.com',
            '4355435',
            '1002',
            '1002',
            'SEP50F7222CF69B',
            '06fceae0-f0b6-4435-9c55-f7479547ab21',
            'California',
            '',
            'SEP50F7222CF69B',
            'F',
            'F',
            'F',
            '1',
            '345435234',
            'Luca',
            'Jackson',
            '253595',
            '',
            '',
            '',
            '1',
            'Testing4',
            '',
            'English, United States',
            '',
            '',
            '',
            '',
            ''
        ],
        [
            'Jonathan',
            'Mark',
            'Engineering',
            'jmark@tekvizion.com',
            '4355435',
            '1002',
            '1002',
            'SEP50F7222CF69B',
            '06fceae0-f0b6-4435-9c55-f7479547ab21',
            'California',
            '',
            'SEP50F7222CF69B',
            'F',
            'F',
            'F',
            '1',
            '345435234',
            'Luca',
            'Jackson',
            '253595',
            '',
            '',
            '',
            '1',
            'Testing4',
            '',
            'English, United States',
            '',
            '',
            '',
            '',
            ''
        ],
        [
            'Ray',
            'Jhonson',
            'IT',
            'rjohnson@tekvizion.com',
            '4355435',
            '1002',
            '1002',
            'SEP50F7222CF69B',
            '06fceae0-f0b6-4435-9c55-f7479547ab21',
            'California',
            '',
            'SEP50F7222CF69B',
            'F',
            'F',
            'F',
            '1',
            '345435234',
            'Luca',
            'Jackson',
            '253595',
            '',
            '',
            '',
            '1',
            'Testing4',
            '',
            'English, United States',
            '',
            '',
            '',
            '',
            ''
        ],
        [
            'Alexander',
            'Jackson',
            'Support',
            'ajackson@tekvizion.com',
            '',
            '1002',
            '1002',
            'SEP50F7222CF69B',
            '06fceae0-f0b6-4435-9c55-f7479547ab21',
            'California',
            '',
            'SEP50F7222CF69B',
            'F',
            'F',
            'F',
            '1',
            '345435234',
            'Luca',
            'Jackson',
            '253595',
            '',
            '',
            '',
            '1',
            'Testing4',
            '',
            'English, United States',
            '',
            '',
            '',
            '',
            ''
        ],
        [
            'Clark',
            'Emily',
            '',
            'eclark@tekvizion.com',
            '4355435',
            '1002',
            '1002',
            'SEP50F7222CF69B',
            '06fceae0-f0b6-4435-9c55-f7479547ab21',
            'California',
            '',
            'SEP50F7222CF69B',
            'F',
            'F',
            'F',
            '1',
            '345435234',
            'Luca',
            'Jackson',
            '253595',
            '',
            '',
            '',
            '1',
            'Testing4',
            '',
            'English, United States',
            '',
            '',
            '',
            '',
            ''
        ],
        [
            'user',
            '',
            '',
            'user1@tekvizion.com',
            '4355435',
            '1002',
            '1002',
            'SEP50F7222CF69B',
            '06fceae0-f0b6-4435-9c55-f7479547ab21',
            '',
            '',
            'SEP50F7222CF69B',
            'F',
            'F',
            'F',
            '1',
            '345435234',
            'Luca',
            'Jackson',
            '253595',
            '',
            '',
            '',
            '1',
            'Testing4',
            '',
            'English, United States',
            '',
            '',
            '',
            '',
            ''
        ],
        [
            'user',
            'test',
            '',
            '',
            '4355435',
            '1002',
            '1002',
            'SEP50F7222CF69B',
            '06fceae0-f0b6-4435-9c55-f7479547ab21',
            'California',
            '',
            'SEP50F7222CF69B',
            'F',
            'F',
            'F',
            '1',
            '345435234',
            'Luca',
            'Jackson',
            '253595',
            '',
            '',
            '',
            '1',
            'Testing4',
            '',
            'English, United States',
            '',
            '',
            '',
            '',
            ''
        ]
    ]
};

export default columnMapping;
