import { axiosMSTeamsCQService, axiosUserDataService } from 'utils/axios';
import { QueueDataSource } from '../constants';

export async function getAccessibleCallQueues(enqueueSnackbar, dataSource, onlyLicensed = true) {
    if (dataSource === QueueDataSource.MicrosoftTeams) {
        try {
            const subquery = onlyLicensed ? '?status=licensed' : '';
            const response = await axiosMSTeamsCQService.get(`/api/users/getAccessibleQueues${subquery}`);
            if (response.data?.success && response.data?.data) {
                return response.data.data;
            }

            // failed
            return null;
        } catch (ex) {
            // failed
            if (ex?.response?.status === 403) {
                // Do not have access
                enqueueSnackbar('Insufficient privileges to query metrics data', { variant: 'error' });
            } else {
                console.log('Error while loading queues. Error: ', ex);
            }

            return null;
        }
    }

    enqueueSnackbar(`Unsupported data source: ${dataSource} for queue analytics`, { variant: 'error' });
    return null;
}

export async function getAccessibleLicensedCallQueues(enqueueSnackbar, dataSource) {
    return getAccessibleCallQueues(enqueueSnackbar, dataSource, true);
}

export async function getFilteredQueuesMetricsSummary(enqueueSnackbar, dataSource, queueIds, dateTimeFilter) {
    if (dataSource === QueueDataSource.MicrosoftTeams) {
        try {
            const response = await axiosMSTeamsCQService.post(`/api/metrics/getSummaryForCQs`, {
                queueIds,
                dateTimeFilter
            });
            if (response.data?.success && response.data?.data) {
                return response.data.data;
            }

            // failed
            return null;
        } catch (ex) {
            // failed
            if (ex?.response?.status === 403) {
                // Do not have access
                enqueueSnackbar('Insufficient privileges to query metrics data', { variant: 'error' });
            } else {
                console.log('Error while loading metrics summary for filtered queues. Error: ', ex);
            }

            return null;
        }
    }

    enqueueSnackbar(`Unsupported data source: ${dataSource} for queue analytics`, { variant: 'error' });
    return null;
}

export async function getAllMetricsForQueue(enqueueSnackbar, dataSource, queueId, dateTimeFilter) {
    if (dataSource === QueueDataSource.MicrosoftTeams) {
        try {
            const response = await axiosMSTeamsCQService.post(`/api/metrics/getAllMetricsForCQ`, {
                queueId,
                dateTimeFilter
            });
            if (response.data?.success && response.data?.data) {
                return { data: response.data.data, status: 200 };
            }

            // failed
            return null;
        } catch (ex) {
            // failed
            if (ex?.response?.status === 403) {
                // Do not have access
                enqueueSnackbar('Insufficient privileges to query metrics data', { variant: 'error' });
            } else {
                console.log('Error while loading all metrics for filtered queue. Error: ', ex);
            }

            return { data: null, status: ex?.response?.status };
        }
    }

    enqueueSnackbar(`Unsupported data source: ${dataSource} for queue analytics`, { variant: 'error' });
    return null;
}

export async function getGroupedByTimeMetrics(
    enqueueSnackbar,
    dataSource,
    queueId,
    metricCategory,
    dateTimeFilter,
    metrics,
    groupTimeBy,
    groupDataBy,
    additionalDateTimeFilters
) {
    if (dataSource === QueueDataSource.MicrosoftTeams) {
        try {
            const response = await axiosMSTeamsCQService.post(`/api/metrics/getGroupedByTime`, {
                queueId,
                metricCategory,
                metrics,
                groupTimeBy,
                dateTimeFilter,
                groupDataBy,
                additionalDateTimeFilters
            });
            if (response.data?.success && response.data?.data) {
                return response.data.data;
            }

            // failed
            return null;
        } catch (ex) {
            // failed
            if (ex?.response?.status === 403) {
                // Do not have access
                enqueueSnackbar('Insufficient privileges to query metrics data', { variant: 'error' });
            } else {
                console.log('Error while loading the metrics data for a queue, grouped by time. Error: ', ex);
            }

            return null;
        }
    }

    enqueueSnackbar(`Unsupported data source: ${dataSource} for queue analytics`, { variant: 'error' });
    return null;
}

export async function getCallBreakdown(enqueueSnackbar, dataSource, queueId, groupBy, dateTimeFilter, additionalFilters) {
    if (dataSource === QueueDataSource.MicrosoftTeams) {
        try {
            const response = await axiosMSTeamsCQService.post(`/api/metrics/getCallBreakdownForCQ`, {
                queueId,
                groupBy,
                dateTimeFilter,
                additionalFilters
            });
            if (response.data?.success && response.data?.data) {
                return response.data.data;
            }

            // failed
            return null;
        } catch (ex) {
            // failed
            if (ex?.response?.status === 403) {
                // Do not have access
                enqueueSnackbar('Insufficient privileges to query metrics data', { variant: 'error' });
            } else {
                console.log('Error while loading the metrics data for queue grouped by call actions. Error: ', ex);
            }
            return null;
        }
    }

    enqueueSnackbar(`Unsupported data source: ${dataSource} for queue analytics`, { variant: 'error' });
    return null;
}

export async function getSelectedDashboardDetails(enqueueSnackbar, id) {
    try {
        const response = await axiosUserDataService.get(`/api/queueDashboard/${id}`);
        if (response.data?.success && response.data?.data) {
            return response.data.data;
        }

        // failed
        return null;
    } catch (ex) {
        // failed
        if (ex?.response?.status === 403) {
            // Do not have access
            enqueueSnackbar('Insufficient privileges to access queue dashboard', { variant: 'error' });
        } else {
            console.log('Error while loading queue dashboard. Error: ', ex);
        }

        return null;
    }
}

export async function updateSelectedQueueId(dashboardId, queueId) {
    try {
        const response = await axiosUserDataService.patch(`/api/queueDashboard/${dashboardId}/updateSelectedQueueId?queueId=${queueId}`);
        if (response?.data?.success && response?.data?.data) {
            return true;
        }

        console.log('Failed to save selected queueId. Error: ', response?.data?.error);
        return false;
    } catch (ex) {
        if (ex.response) {
            console.log('Failed to save selected queueId. Error: ', ex.response?.data?.error);
        } else {
            console.log('Failed to connect to the server. Try again later. Error: ', ex);
        }
    }

    return false;
}

export async function saveUpdatedDateTimeFilter(dashboardId, updatedDateTimeFilter) {
    try {
        const response = await axiosUserDataService.patch(`/api/queueDashboard/${dashboardId}/updateDateTimeFilter`, updatedDateTimeFilter);
        if (response?.data?.success && response?.data?.data) {
            return true;
        }

        console.log('Failed to save date time filter. Error: ', response?.data?.error);
        return false;
    } catch (ex) {
        if (ex.response) {
            console.log('Failed to save date time filter. Error: ', ex.response?.data?.error);
        } else {
            console.log('Failed to connect to the server. Try again later. Error: ', ex);
        }
    }

    return false;
}

export async function saveUpdatedDashboard(enqueueSnackbar, dashboardId, updatedDashboard) {
    try {
        const response = await axiosUserDataService.put(`/api/queueDashboard/${dashboardId}`, updatedDashboard);
        if (response.data?.success && response.data?.data) {
            return response.data.data;
        }
        console.log('Failed to save updated dashboard. Error: ', response?.data?.error);
        return null;
    } catch (ex) {
        // failed
        if (ex?.response?.status === 403) {
            // Do not have access (Should not happen)
            enqueueSnackbar(`Insufficient privileges to update queue dashboard with id [${dashboardId}]`, { variant: 'error' });
        } else {
            console.log('Error while saving dashboard. Error: ', ex);
        }

        return null;
    }
}

export async function setDashboardAsDefault(enqueueSnackbar, dashboardId) {
    try {
        const response = await axiosUserDataService.patch(`/api/queueDashboard/${dashboardId}/setDefault`);
        if (response.data?.success && response.data?.data) {
            return true;
        }
        console.log('Failed to set dashboard as default. Error: ', response?.data?.error);
        return false;
    } catch (ex) {
        // failed
        if (ex?.response?.status === 403) {
            // Do not have access (Should not happen)
            enqueueSnackbar(`Insufficient privileges to set queue dashboard with id [${dashboardId}] as default`, { variant: 'error' });
        } else {
            console.log('Error while setting dashboard as default. Error: ', ex);
        }

        return false;
    }
}

export async function deleteQueueDashboard(dashboardId) {
    try {
        const response = await axiosUserDataService.delete(`/api/QueueDashboard/${dashboardId}`);
        if (response?.data?.success) {
            return true;
        }
        console.log('Failed to delete queue Dashboard. Error: ', response?.data?.error);
        return false;
    } catch (ex) {
        if (ex.response) {
            // Failure from backend;
            console.log('Error Response: ', ex.response);
        } else {
            // Failed to connect to backend
            console.log('Failed to connect to backend while trying to delete Dashboard. Exception: ', ex);
        }
        return false;
    }
}
