import { createSlice } from '@reduxjs/toolkit';
import { axiosReportService } from 'utils/axios';
import { SIZE } from '../constant';

export const initialState = {
    scheduledReports: [],
    scheduledReportsLoading: false,
    scheduledReportsError: null,
    isDeleteInProgress: false,
    isDeleteCompleted: false,
    hasMoreData: true,
    scheduleReportLoadingStart: false,
    scheduleTemplateComplete: false,
    scheduleSuccess: false,
    runScheduleStart: false
};

export const slice = createSlice({
    name: 'scheduledReports',
    initialState,
    reducers: {
        fetchScheduledReportsRequest(state, action) {
            state.scheduledReportsError = null;
            state.scheduledReportsLoading = true;
            state.scheduleSuccess = false;
            if (action.payload) {
                state.scheduledReports = [];
                state.hasMoreData = true;
            }
        },
        fetchScheduledReportsSuccess(state, action) {
            if (action.payload?.length < SIZE) {
                state.hasMoreData = false;
            }
            state.scheduledReports = state.scheduledReports.concat(action.payload);
            state.scheduledReportsError = null;
            state.scheduledReportsLoading = false;
        },
        fetchScheduledReportsError(state, action) {
            state.scheduledReportsError = action.payload;
            state.scheduledReportsLoading = false;
        },

        createScheduleReportStart(state, action) {
            state.scheduleReportLoadingStart = true;
            state.scheduleTemplateComplete = false;
        },
        createScheduleReportSuccess(state, action) {
            state.scheduleReportLoadingStart = false;
            state.scheduleTemplateComplete = true;
            state.scheduleSuccess = true;
        },
        createScheduleReportError(state, action) {
            state.scheduleReportLoadingStart = false;
            // state.scheduleTemplateComplete = true;
        },
        deleteScheduledReportRequest(state) {
            state.isDeleteInProgress = true;
        },
        deleteScheduledReportSuccess(state, action) {
            state.scheduledReports = [];
            state.scheduledReports = action.payload;
            state.isDeleteCompleted = true;
            state.isDeleteInProgress = false;
        },
        deleteScheduledReportError(state, action) {
            state.scheduledReportsError = action.payload;
            state.isDeleteCompleted = true;
            state.isDeleteInProgress = false;
        },
        deleteScheduledReportClear(state) {
            state.isDeleteCompleted = false;
        },
        resetScheduleReportData(state, action) {
            state.scheduledReports = [];
        },
        resetScheduleTemplateData(state, action) {
            state.scheduleTemplateComplete = false;
        },
        createRunScheduleReportStart(state, action) {
            state.runScheduleStart = true;
        },
        createRunScheduleReportSuccess(state, action) {
            state.runScheduleStart = false;
        },
        createRunScheduleReportError(state, action) {
            state.runScheduleStart = false;
        }
    }
});

export default slice.reducer;

export function fetchScheduledReports(enqueueSnackbar, offset, searchText, isFromSearch, ownerIdFilter) {
    return async (dispatch) => {
        const payload = { offset, size: SIZE, searchQuery: searchText, ownerIdFilter };
        dispatch(slice.actions.fetchScheduledReportsRequest(isFromSearch));

        try {
            const response = await axiosReportService.get('/schedules', { params: payload });
            if (response?.data?.success) {
                dispatch(slice.actions.fetchScheduledReportsSuccess(response.data.data));
            } else {
                dispatch(slice.actions.fetchScheduledReportsError(response?.data.error));
                enqueueSnackbar('Error in fetching data', { variant: 'error' });
            }
        } catch (ex) {
            console.log(ex);
            dispatch(slice.actions.fetchScheduledReportsError());
            if (ex?.response?.status === 401) {
                enqueueSnackbar('Session expired, please login again', { variant: 'error' });
            } else {
                enqueueSnackbar('Error in getting Scheduled reports', { variant: 'error' });
            }
        }
    };
}

export function deleteScheduledReport(enqueueSnackbar, payload) {
    return async (dispatch, getState) => {
        dispatch(slice.actions.deleteScheduledReportRequest());

        try {
            const response = await axiosReportService.delete(`/schedules/delete`, { data: payload });
            if (response?.data?.success) {
                const { scheduledReports } = getState().scheduledReports;
                setTimeout(() => {
                    dispatch(
                        slice.actions.deleteScheduledReportSuccess(
                            scheduledReports.filter((item) => !payload.find((rm) => item.scheduleId === rm))
                        )
                    );
                }, 500);
                enqueueSnackbar('Schedule Deleted Successfully', { variant: 'info' });
            } else {
                dispatch(slice.actions.deleteScheduledReportError(response.data.error));
                enqueueSnackbar('Error in deleting schedule', { variant: 'error' });
            }
        } catch (ex) {
            console.log(ex);
            if (ex.response) {
                dispatch(slice.actions.deleteScheduledReportError(ex.response));
            }
            if (ex?.response?.status === 401) {
                enqueueSnackbar('Session expired, please login again', { variant: 'error' });
            } else {
                enqueueSnackbar('Error in deleting schedule', { variant: 'error' });
            }
        }
    };
}

export function resetScheduleReportData() {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.resetScheduleReportData());
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function resetScheduleTemplateData() {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.resetScheduleTemplateData());
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function deleteScheduledReportClear(payload) {
    return async (dispatch) => {
        dispatch(slice.actions.deleteScheduledReportClear());
    };
}

export function saveAndUpddateSchedule(enqueueSnackbar, isUpdate, payload, scheduleId) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.createScheduleReportStart());
            let response;
            if (!isUpdate) {
                response = await axiosReportService.post('/schedules', payload);
            } else {
                response = await axiosReportService.patch(`/schedules/${scheduleId}`, payload);
            }

            if (response?.data?.success) {
                const data = response.data.data;
                dispatch(slice.actions.createScheduleReportSuccess());
                enqueueSnackbar(`Schedule Report Successfully`, { variant: 'info' });
            } else {
                dispatch(slice.actions.createScheduleReportError());
                enqueueSnackbar(`Schedule Report failed`, { variant: 'error' });
            }
        } catch (ex) {
            console.log(ex);
            dispatch(slice.actions.createScheduleReportError());
            const errorObject = JSON.parse(ex?.response?.data?.error);
            let errorMessage = 'Schedule report failed';
            if (errorObject) {
                const [key, value] = Object.entries(errorObject)[0];
                errorMessage = value[0];
            }

            if (ex?.response?.status === 401) {
                enqueueSnackbar('Session expired, please login again', { variant: 'error' });
            } else {
                // enqueueSnackbar(`${isUpdate ? 'Update' : 'Schedule'} Report failed`, { variant: 'error' });
                enqueueSnackbar(errorMessage, { variant: 'error' });
            }
        }
    };
}
export function runSchedule(enqueueSnackbar, scheduleId) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.createRunScheduleReportStart());
            const response = await axiosReportService.post(`/schedules/${scheduleId}/export`);

            if (response?.data?.success) {
                const data = response.data.data;
                dispatch(slice.actions.createRunScheduleReportSuccess());
                enqueueSnackbar(`Schedule Run Successfully`, { variant: 'info' });
            } else {
                dispatch(slice.actions.createRunScheduleReportError());
                enqueueSnackbar(`Schedule Run failed`, { variant: 'error' });
            }
        } catch (ex) {
            console.log(ex);
            dispatch(slice.actions.createRunScheduleReportError());
            if (ex?.response?.status === 401) {
                enqueueSnackbar('Session expired, please login again', { variant: 'error' });
            } else {
                enqueueSnackbar(`Report failed`, { variant: 'error' });
            }
        }
    };
}
