import React from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, Typography } from '@mui/material';

// third party
import Draggable from 'react-draggable';

// draggable
const PaperComponent = (props) => (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props} />
    </Draggable>
);

// ===============================|| UI DIALOG - DRAGGABLE ||=============================== //

const DraggableDialog = ({
    dialogTitle,
    dialogContentText,
    dialogAdditionalContent,
    buttonLabel,
    open,
    handleClose,
    handleButtonClick,
    onCancel
}) => {
    const theme = useTheme();

    return (
        <div>
            <Dialog open={open} onClose={handleClose} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title">
                {open && (
                    <>
                        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                            {dialogTitle}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                <Typography variant="body2" component="span" align="justify">
                                    {dialogContentText}
                                </Typography>
                            </DialogContentText>
                            {dialogAdditionalContent}
                        </DialogContent>
                        <DialogActions
                            sx={{
                                paddingLeft: 2,
                                paddingRight: 2
                            }}
                        >
                            {onCancel && (
                                <Button
                                    variant="Text"
                                    sx={{ height: 'fit-content', color: theme.palette.error.main }}
                                    size="small"
                                    onClick={onCancel}
                                >
                                    Cancel
                                </Button>
                            )}
                            <Button variant="contained" size="small" onClick={handleButtonClick}>
                                {buttonLabel}
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </div>
    );
};

export default DraggableDialog;
