import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Tooltip } from 'recharts';

import EditDonutChartWidgetDialog from './EditDonutChartWidgetDialog';
import { DashboardStateContext } from '../context/DashboardStateContext';

import {
    DonutWidgetChartTypes,
    QueueDashboardEvents,
    getMappedColorsForQueueDashboardWidgets,
    GroupWidgetDataBy,
    GroupDonutChartDataHierarchy
} from '../constants';
import EditWidgetMenu from '../EditWidgetMenu/EditWidgetMenu';
import { useSnackbar } from 'notistack';
import { graphColors } from 'store/constant';
import { useTheme } from '@emotion/react';
import {
    DonutChartTooltip,
    getDateTimeWithDstAdjustment,
    getExitPreviewButton,
    getPreviousLevelButton,
    getRefreshingWidgetDataAnimateIcon
} from '../Utils/queueDashboardUtils';
import { getCallBreakdown } from '../Utils/queueDashboardApiCallsUtil';
import { Grid } from '@mui/material';
import BasicPieChart from 'views/dashboard/BasicPieChart';

export default function DonutChartWidget({ widget }) {
    const [isEditDonutChartDialogOpen, setIsEditDonutChartDialogOpen] = useState(false);
    const [donutChartData, setDonutChartData] = useState([]);
    const [needsRefresh, setNeedsRefresh] = useState(false);
    const [refreshing, setRefreshing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const getWidgetConfigObject = useCallback(
        (config) => ({
            ...config,
            drillDownMode: false,
            additionalFilters: {},
            renderDonut: config.graphType === DonutWidgetChartTypes.Donut
        }),
        []
    );

    const [previewWidgetConfig, setPreviewWidgetConfig] = useState(getWidgetConfigObject(widget.config));
    const previousConfigsRef = useRef([]);

    const sectorColors = theme.palette.mode === 'dark' ? graphColors.dark : graphColors.light;

    const { isInEditMode, savingDashboard, dataSource, selectedQueueId, dateTimeFilter } = useContext(DashboardStateContext);

    const toggleEditDonutChartDialog = () => {
        setIsEditDonutChartDialogOpen((open) => !open);
    };

    // Subscribe to queue dashboard events
    useEffect(() => {
        const handler = (e) => {
            setNeedsRefresh(true);
        };
        document.body.addEventListener(QueueDashboardEvents.RefreshWidgetData, handler);
        return () => {
            document.body.removeEventListener(QueueDashboardEvents.RefreshWidgetData, handler);
        };
    }, []);

    // loads data for donut widget
    const loadCallbreakDownChartData = useCallback(
        async (inputDataSource, queueId, inputDateTimeFilter, groupDataBy, additionalFilters, isRefresh = false) => {
            setErrorMessage(null);
            const data = await getCallBreakdown(
                enqueueSnackbar,
                inputDataSource,
                queueId,
                groupDataBy,
                getDateTimeWithDstAdjustment(inputDateTimeFilter),
                additionalFilters
            );
            if (data && data.callBreakdownData) {
                const mappedColors =
                    theme.palette.mode === 'dark'
                        ? getMappedColorsForQueueDashboardWidgets(groupDataBy).dark
                        : getMappedColorsForQueueDashboardWidgets(groupDataBy).light;
                const chartData = Object.keys(data.callBreakdownData).map((g, index) => ({
                    name: g,
                    displayName: data.callBreakdownData[g].displayName,
                    Count: data.callBreakdownData[g].count,
                    percent: data.callBreakdownData[g].percent,
                    fill: mappedColors[data.callBreakdownData[g].displayName] ?? sectorColors[index]
                }));
                const hasData = chartData.some((x) => x.Count > 0);
                if (!hasData) {
                    setDonutChartData([]);
                } else {
                    setDonutChartData(chartData);
                }
            } else if (isRefresh) {
                // NOTE: Do not reset the data of the widget
                enqueueSnackbar(`Failed to refresh data for ${widget.name} widget`, { variant: 'error' });
            } else {
                setErrorMessage('Failed to load data');
                setDonutChartData([]);
            }
        },
        [widget.name]
    );

    const onWidgetConfigUpdate = (payload) => {
        setPreviewWidgetConfig(getWidgetConfigObject(payload.config));
        previousConfigsRef.current = [];
    };

    useEffect(() => {
        // Reset to original config and clear drilldown history when switching queues
        setPreviewWidgetConfig(getWidgetConfigObject(widget.config));
        previousConfigsRef.current = [];
    }, [selectedQueueId]);

    useEffect(() => {
        // Reset to original config and clear drilldown history when going out of edit mode
        if (!isInEditMode) {
            setPreviewWidgetConfig(getWidgetConfigObject(widget.config));
            previousConfigsRef.current = [];
        }
    }, [isInEditMode]);

    useEffect(() => {
        async function onLoad() {
            setIsLoading(true);
            await loadCallbreakDownChartData(
                dataSource,
                selectedQueueId,
                dateTimeFilter,
                previewWidgetConfig.groupDataBy,
                previewWidgetConfig.additionalFilters
            );
            setIsLoading(false);
        }
        if (widget.config && widget.config.groupDataBy) {
            onLoad();
        }
        // NOTE: Don't add selectedQueueId in DA as it can cause multiple API calls and renders.
        // It is already taken into consideration in above mentioned useEffect
    }, [dataSource, dateTimeFilter, loadCallbreakDownChartData, previewWidgetConfig.groupDataBy, previewWidgetConfig.additionalFilters]);

    useEffect(() => {
        async function refreshData() {
            setRefreshing(true);
            await loadCallbreakDownChartData(
                dataSource,
                selectedQueueId,
                dateTimeFilter,
                previewWidgetConfig.groupDataBy,
                previewWidgetConfig.additionalFilters,
                true
            );
            setRefreshing(false);
            setNeedsRefresh(false);
        }
        if (!isInEditMode && needsRefresh) {
            refreshData();
        }
    }, [
        needsRefresh,
        loadCallbreakDownChartData,
        dataSource,
        dateTimeFilter,
        previewWidgetConfig.groupDataBy,
        previewWidgetConfig.additionalFilters
    ]);

    const formatLegendValue = (value, entry) => {
        const payload = entry.payload.payload.payload;
        return (
            <span className="legend-label" style={theme.palette.mode === 'dark' ? { color: '#a0a9c0' } : null}>
                {payload?.displayName} ({payload?.percent?.toFixed(2)}%)
            </span>
        );
    };

    const drillLevelUp = () => {
        const previousConfig = previousConfigsRef.current.pop();
        setPreviewWidgetConfig(previousConfigsRef.current.length === 0 ? { ...previewWidgetConfig, drillDownMode: false } : previousConfig);
    };

    const getNextDrilldownLevelConfig = (name, configObj) => {
        const previousLevel = configObj.groupDataBy;
        const updatedConfigObj = {
            ...configObj,
            additionalFilters: {
                ...configObj.additionalFilters,
                [previousLevel]: name
            },
            groupDataBy: GroupDonutChartDataHierarchy[configObj.groupDataBy],
            drillDownMode: true
        };
        return updatedConfigObj;
    };
    const handleDrillDown = async (name, configObj) => {
        if (
            configObj.groupDataBy === GroupWidgetDataBy.QueueSourceType ||
            configObj.groupDataBy === GroupWidgetDataBy.QueueHandlingType ||
            configObj.groupDataBy === GroupWidgetDataBy.QueueDisposition
        ) {
            previousConfigsRef.current.push(configObj);
            const updatedConfigObj = getNextDrilldownLevelConfig(name, configObj);
            setPreviewWidgetConfig(updatedConfigObj);
        }
    };

    const exitPreview = () => {
        const previousConfig = previousConfigsRef.current[0];
        setPreviewWidgetConfig({ ...previousConfig, drillDownMode: false });
        previousConfigsRef.current = [];
    };
    const tooltipForPieChart = () => (
        <Tooltip
            contentStyle={theme.palette.mode === 'dark' ? { backgroundColor: '#212946', border: '1px solid #D7E6EC' } : null}
            itemStyle={theme.palette.mode === 'dark' ? { color: '#D7E6EC' } : null}
            content={<DonutChartTooltip />}
        />
    );

    const showPointerCursor =
        previewWidgetConfig.groupDataBy === GroupWidgetDataBy.QueueSourceType ||
        previewWidgetConfig.groupDataBy === GroupWidgetDataBy.QueueHandlingType ||
        previewWidgetConfig.groupDataBy === GroupWidgetDataBy.QueueDisposition;

    const getUpdatedWidgetConfig = (widgetConfig) => {
        return {
            ...widgetConfig,
            dataKey: 'Count',
            nameKey: 'displayName',
            width: '100%',
            height: '80%',
            showCountInLegend: true
        };
    };
    let widgetBodyContent;
    if (isLoading) {
        widgetBodyContent = <div className="loading-data-view">Loading data...</div>;
    } else if (errorMessage) {
        widgetBodyContent = <div className="no-data-view">{errorMessage}</div>;
    } else if (donutChartData.length > 0) {
        widgetBodyContent = (
            <BasicPieChart
                data={donutChartData}
                widgetConfig={getUpdatedWidgetConfig(previewWidgetConfig)}
                handleDrillDown={handleDrillDown}
                generateTooltip={tooltipForPieChart}
                legendFormatter={formatLegendValue}
                showPointerCursor={showPointerCursor}
            />
        );
    } else {
        widgetBodyContent = <div className="no-data-view">No data for the selected date range and filters</div>;
    }

    return (
        <>
            <div className="donut-widget-header-container">
                <Grid container flexDirection="row" justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <p className="donut-widget-title" style={theme.palette.mode === 'dark' ? { color: '#D7E6EC' } : null}>
                            {widget.name}
                        </p>
                    </Grid>
                    <Grid item>
                        <Grid container flexDirection="row" justifyContent="flex-end" alignItems="center">
                            {previewWidgetConfig?.drillDownMode && !refreshing && <Grid item>{getExitPreviewButton(exitPreview)}</Grid>}
                            {previewWidgetConfig?.drillDownMode && !refreshing && previousConfigsRef.current.length > 1 && (
                                <Grid item>{getPreviousLevelButton(drillLevelUp)}</Grid>
                            )}

                            {!isInEditMode && refreshing && <Grid item>{getRefreshingWidgetDataAnimateIcon(widget.id)}</Grid>}
                            {isInEditMode && !savingDashboard && (
                                <Grid item>
                                    <EditWidgetMenu onEditClick={toggleEditDonutChartDialog} widgetId={widget.id} />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                {isEditDonutChartDialogOpen && (
                    <EditDonutChartWidgetDialog
                        isEditDonutChartDialogOpen={isEditDonutChartDialogOpen}
                        toggleEditDonutChartDialog={toggleEditDonutChartDialog}
                        widget={widget}
                        onWidgetConfigUpdate={onWidgetConfigUpdate}
                    />
                )}
            </div>
            {widgetBodyContent}
        </>
    );
}
