import Icon from '@mdi/react';
import { StatusColor, MetricCardConfig, Status } from '../constants';
import { useTheme } from '@emotion/react';

export default function QueueMetricItem({ metricName, metric, calculation, displayAs }) {
    const status = metric ? metric[calculation]?.status : Status.Normal;
    const theme = useTheme();
    const iconColor = StatusColor[status] ?? StatusColor.Normal;
    const metricValue = metric !== undefined ? metric[calculation]?.value ?? 0 : 0;
    return (
        <div className="metric-container">
            <div className="metric-icon">
                <Icon path={MetricCardConfig[metricName]?.icon(status)} color={iconColor} size={1.5} />
            </div>
            <div className="metric-values-container">
                <p className="metric-value" style={theme.palette.mode === 'dark' ? { color: '#a0a9c0' } : null}>
                    {metricValue === 'NA' ? 'N/A' : metricValue ?? 0}
                    {metricValue !== 'NA' && MetricCardConfig[metricName]?.suffix}
                </p>
                <p className="metric-label">{displayAs || MetricCardConfig[metricName]?.label}</p>
            </div>
        </div>
    );
}
