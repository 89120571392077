import { useEffect } from 'react';

// project imports
import { useDispatch, useSelector } from 'store';
import { setDefaultDashboard, createDashboard } from 'store/slices/dashboard';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { activeID, activeItem } from 'store/slices/menu';

// project imports
import MinimalLayout from 'layout/MinimalLayout';

const CustomDashboardLayout = () => {
    const { dashboards, isDashListLoaded } = useSelector((state) => state.dashboard);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const defDashboardId = dashboards?.find((dashboard) => dashboard.displayOrder === 0)?.id;

    useEffect(() => {
        if (isDashListLoaded && dashboards.length === 0) {
            dispatch(createDashboard({ name: 'Main', displayOrder: 0, layout: '{}' }, true));
        } else if (isDashListLoaded && dashboards.length > 0) {
            const defDashboard = dashboards.find((dashboard) => dashboard.displayOrder === 0);
            dispatch(setDefaultDashboard(defDashboard?.id));
        }
    }, [dashboards, isDashListLoaded, dispatch]);

    useEffect(() => {
        if (location?.pathname === '/dashboard' && isDashListLoaded && defDashboardId && params.id !== defDashboardId) {
            navigate(`/dashboard/my-dashboard/${defDashboardId}`, { replace: true });
            dispatch(activeItem([defDashboardId]));
            dispatch(activeID('dashboard'));
        }
    }, [location?.pathname, navigate, dispatch, defDashboardId, params.id, isDashListLoaded]);
    return <MinimalLayout />;
};

export default CustomDashboardLayout;
