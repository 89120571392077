import { useState, useEffect } from 'react';

import { useTheme } from '@mui/material/styles';
import { Avatar, Tooltip } from '@mui/material';

import { IconMoon, IconSun } from '@tabler/icons';
import useConfig from 'hooks/useConfig';

const ModeSection = () => {
    const theme = useTheme();
    const { navType, onChangeMenuType } = useConfig();

    const handleToggle = (e) => {
        if (navType === 'light') {
            onChangeMenuType('dark');
        } else {
            onChangeMenuType('light');
        }
    };

    return (
        <>
            <Tooltip title={navType === 'dark' ? 'Light Mode' : 'Dark Mode'} placement="top">
                <Avatar
                    variant="rounded"
                    sx={{
                        mr: 2,
                        ...theme.typography.commonAvatar,
                        ...theme.typography.mediumAvatar,
                        transition: 'all .2s ease-in-out',
                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                        color: theme.palette.secondary.main,
                        '&[aria-controls="menu-list-grow"],&:hover': {
                            background: theme.palette.secondary.main,
                            color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light
                        }
                    }}
                    onClick={handleToggle}
                    color="inherit"
                >
                    {navType === 'light' ? <IconMoon stroke={1.5} size="1.3rem" /> : <IconSun stroke={1.5} size="1.3rem" />}
                </Avatar>
            </Tooltip>
        </>
    );
};

export default ModeSection;
