import React, { useContext, useCallback, useState, useEffect, useMemo } from 'react';

import Card from '@mui/material/Card';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { IconCalendarTime, IconReload, IconDotsVertical, IconEdit, IconTrash, IconFilter, IconStar } from '@tabler/icons';
import CreateWidgetDialog from '../CreateWidgetDialog/CreateWidgetDialog';
import { DashboardStateActions, DashboardStateContext, DashboardStateDispatchContext } from '../context/DashboardStateContext';
import QueueFilterSelectionDialog from '../QueueFilterSelectionDialog/QueueFilterSelectionDialog';
import { useDispatch, useSelector } from 'store';
import DateRangePickerDialog from '../DateRangePickerDialog/DateRangePickerDialog';
import { useSnackbar } from 'notistack';
import { WidgetTypes, defaultDateRangeFormat } from '../constants';
import {
    deleteDashboardSuccess,
    loadQueueDashboardList,
    setDefaultQueueDashboardSuccess,
    updateDashboardName
} from 'store/slices/queueDashboard';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import ConfirmationDialog from 'ui-component/ConfirmationDialog';
import { Tooltip } from '@mui/material';
import { getQueueDashboardRefreshDataEventObj } from '../Utils/queueDashboardUtils';
import { deleteQueueDashboard, saveUpdatedDashboard, setDashboardAsDefault } from '../Utils/queueDashboardApiCallsUtil';
import { useTheme } from '@emotion/react';
import moment from 'moment';
import useNavigationBlocker from 'hooks/useNavigationBlocker';
import { DiscardChangesConfirmationDlgMsg } from 'store/constant';
import { isEqual } from 'lodash';

// Cooldown period (seconds) for the reload operation in title bar
const ReloadCooldownMaxTicks = 30;

export default function TitleBar() {
    const theme = useTheme();
    const [isQueueFilterSelectionDialogOpen, setIsQueueFilterSelectionDialogOpen] = useState(false);
    const [isEditDateRangeDialogOpen, setIsEditDateRangeDialogOpen] = useState(false);
    const [deleteValidationMsg, setDeleteValidationMsg] = useState(null);
    const [showDeleteConfirmationDlg, setShowDeleteConfirmationDlg] = useState(false);
    const { queueDashboards } = useSelector((state) => state.queueDashboard);
    const [error, setError] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [reloadCooldownTicks, setReloadCooldownTicks] = useState(0);
    const open = Boolean(anchorEl);

    const {
        isInEditMode,
        savingDashboard,
        dashboardId,
        dashboardName,
        queueIdsFilter,
        selectedQueueId,
        widgets,
        dateTimeFilter,
        isDefault,
        hasUnsavedChanges,
        dateTimeRange,
        dataSource
    } = useContext(DashboardStateContext);
    const [queueDashboardName, setQueueDashboardName] = useState(dashboardName);

    const { dispatchDashboardStateAction } = useContext(DashboardStateDispatchContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showDiscardChangesDialog, confirmNavigation, cancelNavigation] = useNavigationBlocker(hasUnsavedChanges);

    const handleReload = useCallback(
        (e) => {
            if (reloadCooldownTicks === 0) {
                // Dispatch the event to trigger refresh
                const event = getQueueDashboardRefreshDataEventObj();
                document.body.dispatchEvent(event);

                setReloadCooldownTicks(ReloadCooldownMaxTicks);
            }
        },
        [reloadCooldownTicks]
    );

    useEffect(() => {
        if (reloadCooldownTicks > 0) {
            // Tick down every one second
            const timeoutId = setTimeout(() => {
                setReloadCooldownTicks(reloadCooldownTicks - 1);
            }, 1000);

            return () => {
                clearTimeout(timeoutId);
            };
        }

        return () => {};
    }, [reloadCooldownTicks]);

    useEffect(() => {
        if (!queueDashboardName.trim()) {
            setError('Dashboard Name is required');
        } else if (queueDashboardName.length > 50) {
            setError('Dashboard name should not exceed 50 characters');
        } else if (queueDashboards.some((dashboard) => dashboard.name === queueDashboardName && dashboard.id !== dashboardId)) {
            setError('Dashboard Name already exists');
        } else {
            setError('');
        }
    }, [queueDashboardName, queueDashboards, dashboardId]);

    const handleMenuBtnClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const switchToEditMode = useCallback(() => {
        dispatchDashboardStateAction({ type: DashboardStateActions.SetDiscardFallbackData });
        dispatchDashboardStateAction({ type: DashboardStateActions.SetEditMode, payload: true });
        handleClose();
    }, [dispatchDashboardStateAction, handleClose]);

    const setAsDefault = useCallback(async () => {
        dispatchDashboardStateAction({ type: DashboardStateActions.SetSavingDashboard, payload: true });
        try {
            const response = await setDashboardAsDefault(enqueueSnackbar, dashboardId);
            if (response) {
                enqueueSnackbar(`Successfully set the queue dashboard [${dashboardName}] as default`, { variant: 'success' });
                dispatchDashboardStateAction({ type: DashboardStateActions.SetDashboardAsDefault });
                dispatch(setDefaultQueueDashboardSuccess(dashboardId));
                handleClose();
            }
        } finally {
            dispatchDashboardStateAction({ type: DashboardStateActions.SetSavingDashboard, payload: false });
        }
    }, [dispatchDashboardStateAction, dashboardId, dashboardName]);

    const saveEditModeChanges = async () => {
        dispatchDashboardStateAction({ type: DashboardStateActions.SetSavingDashboard, payload: true });
        try {
            const savedDashboard = await saveUpdatedDashboard(enqueueSnackbar, dashboardId, {
                name: queueDashboardName,
                queueIdsFilter,
                selectedQueueId,
                dateTimeFilter,
                widgets: widgets.map((w) => {
                    const { id, name, type, layout, metrics, config, ...rest } = w;
                    return {
                        id: id && typeof id === 'number' ? id : 0,
                        name: type === WidgetTypes.QueueMetrics ? 'Queue Metrics' : name,
                        type,
                        layout: JSON.stringify(layout),
                        metrics: metrics?.map((f, index) => {
                            const { id, metric, calculationType, displayAs } = f;
                            return {
                                id: id && typeof id === 'number' ? id : 0,
                                metric,
                                calculationType,
                                displayAs,
                                displayOrder: index
                            };
                        }),
                        config,
                        ...rest
                    };
                })
            });
            if (savedDashboard) {
                // Successful
                enqueueSnackbar(`Successfully saved changes for [${dashboardName}] queue dashboard`, { variant: 'success' });

                const oldDashboard = {
                    id: dashboardId,
                    name: dashboardName,
                    dataSource,
                    queueIdsFilter,
                    selectedQueueId,
                    isDefault,
                    widgets,
                    dateTimeFilter
                };

                // Order the widget metrics
                savedDashboard.widgets.forEach((widget) => {
                    widget.metrics.sort((metric1, metric2) => metric1.displayOrder - metric2.displayOrder);
                });

                const newDashboard = {
                    id: savedDashboard.id,
                    name: savedDashboard.name,
                    dataSource: savedDashboard.dataSource,
                    queueIdsFilter: savedDashboard.queueIdsFilter,
                    selectedQueueId: savedDashboard.selectedQueueId,
                    isDefault: savedDashboard.isDefault,
                    widgets: savedDashboard.widgets,
                    dateTimeFilter: savedDashboard.dateTimeFilter
                };

                if (!isEqual(newDashboard, oldDashboard)) {
                    // Set the updated dashboard
                    dispatchDashboardStateAction({ type: DashboardStateActions.SaveEditModeChanges, payload: savedDashboard });
                }

                dispatchDashboardStateAction({ type: DashboardStateActions.SetEditMode, payload: false });

                dispatch(
                    updateDashboardName({
                        id: dashboardId,
                        name: queueDashboardName
                    })
                );
            } else {
                enqueueSnackbar(`Failed to save changes for [${dashboardName}] queue dashboard`, { variant: 'error' });
            }
        } finally {
            dispatchDashboardStateAction({ type: DashboardStateActions.SetSavingDashboard, payload: false });
            dispatchDashboardStateAction({ type: DashboardStateActions.SetHasUnsavedChanges, payload: false });
        }
    };

    const onDashboardDeleteClick = useCallback(() => {
        setDeleteValidationMsg(`Are you sure you want to delete [${dashboardName}] queue dashboard? This action cannot be undone`);
        setShowDeleteConfirmationDlg(true);
    }, [dashboardName]);

    const handleDashboardDelete = useCallback(
        async (deleteDashboard) => {
            // Close the Dialog and Menu list
            setShowDeleteConfirmationDlg(false);
            handleClose();
            if (deleteDashboard) {
                try {
                    const response = await deleteQueueDashboard(dashboardId);
                    const defaultDashboard = queueDashboards.find((dashboard) => dashboard.isDefault && dashboard.id !== dashboardId);
                    const nextDashboard = defaultDashboard || queueDashboards.find((dashboard) => dashboard.id !== dashboardId);
                    if (response) {
                        dispatch(deleteDashboardSuccess(dashboardId));
                        // Reload the dashboard list and navigate to the next dashboard
                        dispatch(loadQueueDashboardList(enqueueSnackbar));
                        navigate(`/dashboard/queue-dashboard/${nextDashboard?.id ?? 0}`);
                        enqueueSnackbar(`Successfully deleted [${dashboardName}] queue dashboard`, { variant: 'success' });
                    } else {
                        enqueueSnackbar(`Failed to delete [${dashboardName}] queue dashboard`, { variant: 'success' });
                    }
                } catch {
                    enqueueSnackbar(`Failed to delete [${dashboardName}] queue dashboard`, { variant: 'success' });
                }
            }
            // Reset the delete message
            setDeleteValidationMsg(null);
        },
        [queueDashboards, dashboardId, dashboardName]
    );

    const discardEditModeChanges = useCallback(() => {
        dispatchDashboardStateAction({ type: DashboardStateActions.DiscardEditModeChanges });
        dispatchDashboardStateAction({ type: DashboardStateActions.SetEditMode, payload: false });
        dispatchDashboardStateAction({ type: DashboardStateActions.SetHasUnsavedChanges, payload: false });
        if (dashboardName !== queueDashboardName) {
            setQueueDashboardName(dashboardName);
        }
    }, [dispatchDashboardStateAction, dashboardName, queueDashboardName]);

    const toggleQueueFilterSelectionDialog = useCallback(() => {
        setIsQueueFilterSelectionDialogOpen((open) => !open);
        handleClose();
    }, [handleClose]);

    const toggleEditDateRangeDialogOpen = useCallback(() => {
        setIsEditDateRangeDialogOpen((open) => !open);
        handleClose();
    }, [handleClose]);

    const onUserNavigationCancel = useCallback(() => {
        cancelNavigation();
    }, []);

    const onUserNavigationConfirm = useCallback(() => {
        // Discard the changes before leaving the current page
        discardEditModeChanges();
        // Navigate to the intended page
        confirmNavigation();
    }, [discardEditModeChanges]);

    return (
        <Card
            className="title-card"
            style={{
                backgroundColor: theme.palette.mode === 'dark' ? '#111936' : null,
                border: theme.palette.mode === 'dark' ? 'none' : '1px solid #d7ccfc',
                height: error ? '84px' : '70px'
            }}
        >
            {isInEditMode ? (
                <TextField
                    sx={{ width: '25%' }}
                    size="small"
                    value={queueDashboardName}
                    label="Dashboard Name"
                    onChange={(e) => {
                        setQueueDashboardName(e.target.value);
                        dispatchDashboardStateAction({ type: DashboardStateActions.SetHasUnsavedChanges, payload: true });
                    }}
                    error={!!error}
                    helperText={error}
                />
            ) : (
                <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                    <p className="title-text" style={{ color: theme.palette.mode === 'dark' ? '#d7dcec' : '#222631' }}>
                        {dashboardName}
                    </p>
                    {isDefault && <IconStar strokeWidth={0} fill="#ffa700" />}
                </div>
            )}
            {isInEditMode ? (
                <div className="title-actions">
                    <IconButton title="Filters" disabled={savingDashboard} onClick={toggleQueueFilterSelectionDialog}>
                        <IconFilter color="#681abb" />
                    </IconButton>
                    {isQueueFilterSelectionDialogOpen && (
                        <QueueFilterSelectionDialog
                            isDialogOpen={isQueueFilterSelectionDialogOpen}
                            onDialogClose={toggleQueueFilterSelectionDialog}
                        />
                    )}
                    <CreateWidgetDialog disabled={savingDashboard} />
                    <LoadingButton
                        variant="contained"
                        title="Save"
                        loading={savingDashboard}
                        onClick={saveEditModeChanges}
                        disabled={!hasUnsavedChanges || !!error}
                    >
                        Save
                    </LoadingButton>
                    <Button
                        variant="text"
                        title="Discard changes"
                        color="error"
                        disabled={savingDashboard}
                        onClick={discardEditModeChanges}
                    >
                        {hasUnsavedChanges ? 'Discard' : 'Cancel'}
                    </Button>
                </div>
            ) : (
                <div className="title-actions">
                    {dateTimeRange && dateTimeRange.start && dateTimeRange.end && (
                        <p>
                            {moment(dateTimeRange.start).format(defaultDateRangeFormat)} -{' '}
                            {moment(dateTimeRange.end).format(defaultDateRangeFormat)}
                        </p>
                    )}
                    <IconButton title="Date Range" disabled={savingDashboard} onClick={toggleEditDateRangeDialogOpen}>
                        <IconCalendarTime color="#681abb" />
                    </IconButton>
                    <Tooltip title={reloadCooldownTicks > 0 ? `Reload in ${reloadCooldownTicks}s` : 'Reload'}>
                        <span>
                            <IconButton title="Reload" disabled={savingDashboard || reloadCooldownTicks > 0} onClick={handleReload}>
                                <IconReload color={!savingDashboard && reloadCooldownTicks === 0 ? '#681abb' : 'grey'} />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <div>
                        <IconButton title="Options" onClick={handleMenuBtnClick}>
                            <IconDotsVertical id="menu-button" />
                        </IconButton>
                        <Menu
                            id="options-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'menu-button'
                            }}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left'
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center'
                            }}
                            className="menu-container"
                        >
                            <MenuItem className="menu-item" onClick={switchToEditMode}>
                                <IconEdit className="menu-item-label" />
                                Edit Dashboard
                            </MenuItem>
                            {!isDefault && (
                                <MenuItem className="menu-item" onClick={setAsDefault}>
                                    <IconStar className="menu-item-label" />
                                    Set as Default Dashboard
                                </MenuItem>
                            )}
                            <MenuItem className="menu-item" onClick={onDashboardDeleteClick}>
                                <IconTrash className="menu-item-label" />
                                Delete Dashboard
                            </MenuItem>
                        </Menu>
                        {isEditDateRangeDialogOpen && (
                            <DateRangePickerDialog
                                isEditDateRangeDialogOpen={isEditDateRangeDialogOpen}
                                toggleEditDateRangeDialogOpen={toggleEditDateRangeDialogOpen}
                            />
                        )}
                        <ConfirmationDialog
                            title="Delete Dashboard"
                            content={deleteValidationMsg}
                            open={showDeleteConfirmationDlg}
                            handleYes={() => handleDashboardDelete(true)}
                            handleNo={() => handleDashboardDelete(false)}
                        />
                    </div>
                </div>
            )}
            <ConfirmationDialog
                title="Discard Changes"
                content={DiscardChangesConfirmationDlgMsg}
                open={showDiscardChangesDialog}
                handleYes={() => onUserNavigationConfirm()}
                handleNo={() => onUserNavigationCancel()}
            />
        </Card>
    );
}
