import { createSlice } from '@reduxjs/toolkit';

import axios, { axiosMSTeamsCQService } from 'utils/axios';
import { gzipSync } from 'zlib';
import { ConnectorTypes } from 'store/constant';
import { QueueDataSource } from 'views/QueueDashboard/constants';
import { getAccessibleCallQueues } from 'views/QueueDashboard/Utils/queueDashboardApiCallsUtil';

export const initialState = {
    savedConnectors: [],
    connectorTypesInfo: [],
    manufacturer: null,
    connectorId: -1,
    currentTenantConnector: null,
    isCurrentTenantConnectorLoading: true,
    isSavedConnectorsLoading: true,
    isSavingConnector: false,
    connectorSaveDisplayMsg: null,
    loadingLicensedQueueCount: false,
    totalLicensedQueueCount: -1 // -1 Value indicates failure to load the values
};

const slice = createSlice({
    name: 'connector',
    initialState,
    reducers: {
        getConnectorListSuccess(state, action) {
            state.savedConnectors = action.payload;
            state.isSavedConnectorsLoading = false;
        },
        getConnectorTypesInfoSuccess(state, action) {
            state.connectorTypesInfo = action.payload;
        },
        setConnectorIdSuccess(state, action) {
            state.connectorId = action.payload;
        },
        setManufacturer(state, action) {
            state.manufacturer = action.payload;
        },
        setCurrentTenantConnector(state, action) {
            state.currentTenantConnector = {
                ...state.currentTenantConnector,
                ...action.payload
            };
            state.isCurrentTenantConnectorLoading = false;
        },
        resetCurrentTenantConnector(state, action) {
            state.currentTenantConnector = null;
            state.isCurrentTenantConnectorLoading = true;
        },
        resetIsSavedConnectorsLoading(state, action) {
            state.isSavedConnectorsLoading = true;
        },
        saveConnectorSuccess(state, action) {
            const connector = action.payload.connector;
            state.currentTenantConnector = {
                ...state.currentTenantConnector,
                ...connector
            };
            state.connectorSaveDisplayMsg = action.payload.op;
        },
        deleteConnectorSuccess(state, action) {
            state.savedConnectors = state.savedConnectors.filter((connector) => connector.tenantConnectorId !== action.payload);
        },
        onLoadingLicensedQueueCount(state, action) {
            state.loadingLicensedQueueCount = true;
        },
        onLoadingLicensedQueueCountSuccess(state, action) {
            state.loadingLicensedQueueCount = false;
            if (state.totalLicensedQueueCount !== action.payload) {
                state.totalLicensedQueueCount = action.payload;
            }
        },
        onLoadingLicensedQueueCountFailure(state, action) {
            state.loadingLicensedQueueCount = false;
            state.totalLicensedQueueCount = -1;
        },
        onQueueLicensingSuccess(state, action) {
            const count = action.payload.count;
            if (action.payload.licensed) {
                state.totalLicensedQueueCount += count;
            } else {
                state.totalLicensedQueueCount -= count;
            }
        },
        setSavingConnector(state, action) {
            state.isSavingConnector = action.payload;
        },
        resetConnectorSaveDisplayMsg(state, action) {
            state.connectorSaveDisplayMsg = null;
        },
        resetConnectTypeInfoSuccess(state, action) {
            state.connectorTypesInfo = [];
        }
    }
});

export default slice.reducer;

export function getSavedConnectors() {
    return async (dispatch) => {
        // call API to get list of all saved connectors
        const response = await axios.get(`/connectors/tenant-connectors`).catch((error) => {
            if (error.response) {
                console.log(`${error.response.status} - ${error.response.data}`);
            } else if (error.request) {
                console.log(`${error.request}`);
            } else {
                console.log(`Error - ${error.message}`);
            }
        });
        if (response?.data.data) {
            dispatch(slice.actions.getConnectorListSuccess(response.data.data));
        }
    };
}

export function getConnectorTypesInfo(manufacturerId) {
    return async (dispatch) => {
        const response = await axios.get(`/connectors/manufacturers/${manufacturerId}/options`).catch((error) => {
            if (error.response) {
                console.log(`${error.response.status} - ${error.response.data}`);
            } else if (error.request) {
                console.log(`${error.request}`);
            } else {
                console.log(`Error - ${error.message}`);
            }
        });
        if (response?.data) {
            dispatch(slice.actions.getConnectorTypesInfoSuccess(response.data));
        }
    };
}

export function setConnectorId(id) {
    return async (dispatch) => {
        dispatch(slice.actions.setConnectorIdSuccess(id));
    };
}

export function setManufacturer(manufacturerInfo) {
    return async (dispatch) => {
        dispatch(slice.actions.setManufacturer(manufacturerInfo));
    };
}

export function loadCurrentTenantConnector(id, isEdit = false) {
    return async (dispatch) => {
        if (isEdit) {
            const response = await axios.get(`/connectors/tenant-connectors/${id}`);
            if (response?.data?.data) {
                dispatch(slice.actions.setCurrentTenantConnector(response.data.data));
            }
        } else {
            const response = await axios.get(`/connectors/tenant-connectors/templates/${id}`);
            if (response?.data) {
                dispatch(slice.actions.setCurrentTenantConnector(response.data));
            }
        }
    };
}

export function resetCurrentTenantConnector() {
    return async (dispatch) => {
        dispatch(slice.actions.resetCurrentTenantConnector());
    };
}

export function resetIsSavedConnectorsLoading() {
    return async (dispatch) => {
        dispatch(slice.actions.resetIsSavedConnectorsLoading());
    };
}

export function updateCurrentTenantConnector(connectorTemplate) {
    return async (dispatch) => {
        dispatch(slice.actions.setCurrentTenantConnector(connectorTemplate));
    };
}

export async function saveConnector(dispatch, configObject, isEdit, noValidate) {
    let error;
    dispatch(slice.actions.setSavingConnector(true));
    if (isEdit) {
        const reqUrl = noValidate
            ? `/connectors/tenant-connectors/${configObject.tenantConnectorId}?validate=false`
            : `/connectors/tenant-connectors/${configObject.tenantConnectorId}`;
        console.log('ReqUrl: ', reqUrl);
        const response = await axios.put(reqUrl, configObject).catch((error) => {
            if (error.response) {
                console.log(`${error.response.status} - ${error.response.data}`);
            } else if (error.request) {
                console.log(`${error.request}`);
            } else {
                console.log(`Error - ${error.message}`);
            }
        });

        // pass op: null as for edit connector no success message needs to be displayed
        if (response?.data?.success && response?.data?.data) {
            dispatch(slice.actions.saveConnectorSuccess({ connector: response.data.data, op: null }));
            // dispatch(slice.actions.setCurrentTenantConnector(response.data));
        } else {
            error = response?.data?.message ?? 'Failed to connect to the server';
        }
    } else {
        const reqUrl = noValidate ? `/connectors/tenant-connectors?validate=false` : `/connectors/tenant-connectors`;
        const response = await axios.post(reqUrl, configObject).catch((error) => {
            if (error.response) {
                console.log(`${error.response.status} - ${error.response.data}`);
            } else if (error.request) {
                console.log(`${error.request}`);
            } else {
                console.log(`Error - ${error.message}`);
            }
        });

        if (response?.data?.success && response?.data.data) {
            // pass op: 'new' for new connector to display connector creation success message
            dispatch(
                slice.actions.saveConnectorSuccess({ connector: { ...configObject, tenantConnectorId: response.data.data }, op: 'new' })
            );
            // dispatch(slice.actions.setCurrentTenantConnector({ ...configObject, tenantConnectorId: response.data.data }));
        } else {
            error = response?.data?.message ?? 'Failed to connect to the server';
        }
    }

    dispatch(slice.actions.setSavingConnector(false));
    return error;
}

export function deleteConnector(tenantConnectorId) {
    return async (dispatch) => {
        const response = await axios.delete(`/connectors/tenant-connectors/${tenantConnectorId}?validate=false`).catch((error) => {
            if (error.response) {
                console.log(`${error.response.status} - ${error.response.data}`);
            } else if (error.request) {
                console.log(`${error.request}`);
            } else {
                console.log(`Error - ${error.message}`);
            }
        });
        if (response?.data?.data) {
            dispatch(slice.actions.deleteConnectorSuccess(tenantConnectorId));
        }
    };
}

async function loadTotalLicensedCountForTeamsConnectors(allConnectors) {
    let azureADTenantIds = allConnectors
        .filter((x) => x.connectorType === ConnectorTypes.MSTeams)
        .reduce((str, c) => {
            return `${str};${c.queueLicenseIdentifyingInfo.azureADTenantId}`;
        }, '');
    // If there are no Teams Connectors, return 0
    if (!azureADTenantIds || azureADTenantIds?.length === 0) {
        return 0;
    }

    azureADTenantIds = azureADTenantIds.charAt(0) === ';' ? azureADTenantIds.slice(1) : azureADTenantIds;
    const compressedAndEncodedAadTenantIds = encodeURIComponent(gzipSync(azureADTenantIds).toString('base64'));
    try {
        const response = await axiosMSTeamsCQService.get(
            `/api/callQueues/getLicensedQueueCount?azureADTenantIds=${compressedAndEncodedAadTenantIds}`
        );
        if (response.data.success) {
            return response.data.data;
        }

        console.log('Failed to load licensed queue count from MSTeams CQ Service. Error: ', response.data.error);
    } catch (ex) {
        console.log('Failed to load licensed queue Count from MSTeams CQ Service. Error: ', ex);
    }

    return -1;
}

// Awaitable function to get the total licensed queue Count
export async function getTotalLicensedQueueCount(allConnectors) {
    let totalLicensedQueueCount = 0;
    const teamsLicensedQueueCount = await loadTotalLicensedCountForTeamsConnectors(allConnectors);
    if (teamsLicensedQueueCount < 0) {
        return -1;
    }

    totalLicensedQueueCount += teamsLicensedQueueCount;

    return totalLicensedQueueCount;
}

// Dispatchable function to load the queue count into the store
export function loadTotalLicensedQueueCount(enqueueSnackbar, allConnectors) {
    return async (dispatch) => {
        dispatch(slice.actions.onLoadingLicensedQueueCount());

        let totalLicensedQueueCount = 0;

        // Load the licensedQueue count for MSTeams
        const teamsLicensedQueueCount = await loadTotalLicensedCountForTeamsConnectors(allConnectors);
        if (teamsLicensedQueueCount < 0) {
            // failed
            enqueueSnackbar('Failed to load licensed queue count for Teams connectors', { variant: 'error' });
            dispatch(slice.actions.onLoadingLicensedQueueCountFailure());
            return;
        }
        totalLicensedQueueCount += teamsLicensedQueueCount;

        // Update the total queue count
        dispatch(slice.actions.onLoadingLicensedQueueCountSuccess(totalLicensedQueueCount));
    };
}

export async function getAzureADTenantIdsForAccessibleQueues(enqueueSnackbar) {
    const queues = await getAccessibleCallQueues(enqueueSnackbar, QueueDataSource.MicrosoftTeams, false);
    if (queues && queues.length > 0) {
        return Array.from(new Set(queues.map((q) => q.azureADTenantId)));
    }

    return [];
}

export function resetConnectorSaveDisplayMsg() {
    return async (dispatch) => {
        dispatch(slice.actions.resetConnectorSaveDisplayMsg());
    };
}

export function resetConnectorTypeInfo() {
    return async (dispatch) => {
        dispatch(slice.actions.resetConnectTypeInfoSuccess());
    };
}

export const {
    getConnectorListSuccess,
    setConnectorIdSuccess,
    setCurrentTenantConnector,
    saveConnectorSuccess,
    deleteConnectorSuccess,
    getConnectorTypesInfoSuccess,
    onQueueLicensingSuccess,
    onLoadingLicensedQueueCount,
    onLoadingLicensedQueueCountSuccess,
    onLoadingLicensedQueueCountFailure
} = slice.actions;
