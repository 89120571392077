import { Grid, Typography } from '@mui/material';
import { IconCircle } from '@tabler/icons';

const CustomLegend = ({ payload, onLegendClick, selectedData }) => {
    return (
        <Grid container justifyContent="center">
            {payload.map((entry, index) => (
                <Grid
                    item
                    key={`item-${index}`}
                    sx={{ marginBottom: '5px', marginRight: 1, cursor: 'pointer' }}
                    onClick={() => onLegendClick(entry)}
                    onKeyUp={() => onLegendClick(entry)}
                    role="button"
                    tabIndex={0}
                >
                    <Typography sx={{ color: selectedData[entry.dataKey] && '#CCCCCC' }}>
                        <IconCircle
                            style={{
                                color: selectedData[entry.dataKey] ? '#CCCCCC' : entry.color,
                                fill: selectedData[entry.dataKey] ? '#CCCCCC' : entry.color,
                                marginRight: 5
                            }}
                            size={10}
                        />
                        {entry.value}
                    </Typography>
                </Grid>
            ))}
        </Grid>
    );
};

export default CustomLegend;
