import { createSlice } from '@reduxjs/toolkit';
import { axiosMSTeamsCQService } from 'utils/axios';

export const initialState = {
    loading: true,
    queueDisplayName: '',
    queueStartTime: '',
    queueEndTime: '',

    queueCountry: '',
    queueUtcOffset: '',

    queueDst: false,

    queuePercentage: '',
    queueSeconds: '',

    queueUsers: [],
    thresholds: [],
    allowedMetricsConfig: null,
    isPerformingAction: false
};

const queueConfig = createSlice({
    name: 'queueConfig',
    initialState,
    reducers: {
        setDataLoading(state) {
            state.loading = true;
        },
        resetDataLoading(state) {
            state.loading = false;
        },
        setQueueDetails(state, action) {
            state.queueDisplayName = action.payload.displayName;
            state.queueStartTime = action.payload.activeStartTime;
            state.queueEndTime = action.payload.activeEndTime;
            state.queueDst = action.payload.useDST;
            state.queueSeconds = action.payload.serviceLevelAnswerTimeThreshold;
            state.queueCountry = action.payload.countryCode;
            state.queueUtcOffset = action.payload.utcOffset !== undefined ? action.payload.utcOffset : action.payload.utcOffsetInMinutes;
        },
        setThresholdDetails(state, action) {
            state.thresholds = action.payload;
        },
        setQueueUsers(state, action) {
            state.queueUsers = action.payload;
        },
        setAllowedMetricsConfig(state, action) {
            state.allowedMetricsConfig = action.payload;
        },
        onGrantUsersAccessSuccess(state, action) {
            state.queueUsers = [...state.queueUsers, ...action.payload];
        },
        onRevokeUserAccessSuccess(state, action) {
            state.queueUsers = state.queueUsers.filter((user) => user.infortelOwnerId !== action.payload);
        },
        setIsPerformingAction(state, action) {
            state.isPerformingAction = action.payload;
        }
    }
});

export default queueConfig.reducer;
export const {
    setQueueDetails,
    setDataLoading,
    resetDataLoading,
    setThresholdDetails,
    setQueueUsers,
    onGrantUsersAccessSuccess,
    onRevokeUserAccessSuccess,
    setAllowedMetricsConfig,
    setIsPerformingAction
} = queueConfig.actions;

export async function getAllowedMetricsConfigurationFromAPI() {
    try {
        const response = await axiosMSTeamsCQService.get(`/api/meta/getAllowedMetricsConfiguration`);
        if (response?.data?.success && response.data?.data) {
            // Success
            return response.data.data;
        }
        // Failure
        return null;
    } catch (ex) {
        if (ex.response) {
            // Failure from backend
            console.log('Error Response: ', ex.response);
        } else {
            // Failed to connect to backend
            console.log('Failed to connect to backend while trying to get the threshold configuration. Exception: ', ex);
        }
        return null;
    }
}
