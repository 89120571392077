import React, { useEffect, useState } from 'react';

// material ui
import { Autocomplete, Grid, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material';

// project imports
import { useDispatch, useSelector } from 'store';
import { getCountries, getTimezone } from 'store/slices/lookup';

const TimeZone = ({ country, gmtOffset, useDst, setCountry, setGmt, setUseDst, setTimeZoneError, setPaddingTop, disabled = false }) => {
    const { countries, countryBasedTimezone } = useSelector((state) => state.lookup);

    const countriesOptionList = countries.map((country) => ({ label: country.countryName, value: country.countryCode }));
    const timezoneOptionsList = countryBasedTimezone.map((timezone) => ({ label: timezone.description, value: timezone.gmtOffset }));

    const [timezoneList, setTimezoneList] = useState(timezoneOptionsList);
    const dispatch = useDispatch();

    // const [selCountry, setSelCountry] = useState("");
    useEffect(() => {
        if (countries?.length <= 0) dispatch(getCountries());
    }, []);

    useEffect(() => {
        dispatch(getTimezone(country));
    }, [country]);

    useEffect(() => {
        setTimezoneList(countryBasedTimezone.map((timezone) => ({ label: timezone.description, value: timezone.gmtOffset })));
    }, [countryBasedTimezone]);

    useEffect(() => {
        if (gmtOffset === '' && timezoneList.length > 0) {
            setGmt(timezoneList[0].value);
        }
    }, [timezoneList]);

    useEffect(() => {
        if (setTimeZoneError) {
            if (timezoneList?.length === 0) {
                setTimeZoneError(true);
            } else {
                setTimeZoneError(false);
            }
        }
    }, [timezoneList]);

    const getGmtValue = () => {
        let retVal = '';
        const validGmt = timezoneList.find((itm) => Number(itm.value) === Number(gmtOffset));
        if (validGmt) {
            retVal = validGmt;
        } else {
            retVal = timezoneList[0];
        }
        return retVal;
    };

    const handleCountryChange = (e, newVal) => {
        // setSelCountry(e.target.value);
        setCountry(newVal.value);
        setGmt('');
    };

    const handleGmtOffsetChange = (e, newVal) => {
        setGmt(newVal.value);
    };

    const handleDstChange = (e) => {
        setUseDst(e.target.checked);
    };

    const selectedCountry = countriesOptionList.find((itm) => itm.value === country) || null;

    return (
        <Grid
            container
            spacing={2}
            flexDirection="row"
            item
            xs={12}
            alignItems={timezoneList?.length === 0 ? '' : 'center'}
            sx={{ pt: setPaddingTop !== undefined ? setPaddingTop : 1 }}
        >
            <Grid item sx={{ pr: 1 }} xs={4.4}>
                <Autocomplete
                    disableClearable
                    disabled={disabled}
                    data-testid="country"
                    options={countriesOptionList}
                    value={selectedCountry}
                    onChange={handleCountryChange}
                    renderInput={(params) => <TextField {...params} error={timezoneList?.length === 0} />}
                />
            </Grid>
            <Grid item sx={{ pr: 1 }} xs={4.4}>
                <Autocomplete
                    disableClearable
                    disabled={disabled}
                    options={timezoneList}
                    data-testid="timezone"
                    value={timezoneList?.length > 0 ? getGmtValue() : ''}
                    onChange={handleGmtOffsetChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            error={timezoneList?.length === 0}
                            helperText={
                                timezoneList?.length === 0
                                    ? 'Timezone unavailable for the selected country. Please select another country'
                                    : ''
                            }
                        />
                    )}
                />
            </Grid>
            <Grid item xs={3.2}>
                <FormControlLabel
                    control={<Checkbox checked={useDst} onChange={(e) => handleDstChange(e)} />}
                    label="Use DST"
                    key={1}
                    disabled={disabled}
                />
            </Grid>
        </Grid>
    );
};

export default TimeZone;
