import { useContext, useState, useEffect } from 'react';
import { DashboardStateActions, DashboardStateContext, DashboardStateDispatchContext } from '../context/DashboardStateContext';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useSnackbar } from 'notistack';
import DxDataGrid from 'ui-component/extended/DxDataGrid';
import CircularProgress from '@mui/material/CircularProgress';

import { getAccessibleLicensedCallQueues } from '../Utils/queueDashboardApiCallsUtil';
import { DialogActions, Divider, DialogContent } from '@mui/material';

const ColumnsArray = [
    { dataField: 'displayName', caption: 'Queue Name' },
    { dataField: 'dataSource', caption: 'Data Source' }
];

export default function QueueFilterSelectionDialog({ isDialogOpen, onDialogClose }) {
    const [loading, setLoading] = useState(isDialogOpen);
    const [accessibleQueues, setAccessibleQueues] = useState([]);
    const { dataSource, queueIdsFilter } = useContext(DashboardStateContext);
    const { dispatchDashboardStateAction } = useContext(DashboardStateDispatchContext);
    const { enqueueSnackbar } = useSnackbar();

    const [selectedQueueIds, setSelectedQueueIds] = useState([...queueIdsFilter]);

    const saveActiveQueuesSelection = () => {
        if (selectedQueueIds.length === 0) {
            enqueueSnackbar('Atleast one queue should be selected', { variant: 'error' });
            return;
        }

        dispatchDashboardStateAction({
            type: DashboardStateActions.SetQueueIdsFilter,
            payload: selectedQueueIds
        });
        dispatchDashboardStateAction({ type: DashboardStateActions.SetHasUnsavedChanges, payload: true });

        onDialogClose();
    };

    useEffect(() => {
        async function loadLicensedQueues() {
            setLoading(true);
            const queues = await getAccessibleLicensedCallQueues(enqueueSnackbar, dataSource);
            if (queues) {
                setAccessibleQueues(queues);
            } else {
                enqueueSnackbar('Failed to load accessible queues. Try again.', { variant: 'error' });
            }
            setLoading(false);
        }

        if (isDialogOpen) {
            loadLicensedQueues();
        }
    }, [isDialogOpen]);

    return (
        <Dialog open={isDialogOpen} sx={{ p: 3 }} maxWidth="lg" fullWidth>
            <DialogTitle>Filters</DialogTitle>
            <Divider />

            <DialogContent>
                {loading ? (
                    <CircularProgress
                        sx={{ position: 'relative', top: '100%', left: '50%', marginTop: -5, marginLeft: -3, zIndex: 999 }}
                        data-testid="loading-indicator"
                    />
                ) : (
                    <DxDataGrid
                        dataSet={accessibleQueues}
                        columns={ColumnsArray}
                        selection
                        isFilterRow
                        scrolling
                        height="65vh"
                        keyField="queueId"
                        selectedFilterValues={selectedQueueIds}
                        allowSelectAll
                        setSelectedFilterValues={setSelectedQueueIds}
                    />
                )}
            </DialogContent>
            <DialogActions sx={{ pr: 2.5 }}>
                <Button variant="text" color="error" onClick={onDialogClose}>
                    Cancel
                </Button>
                <Button disabled={selectedQueueIds.length === 0} variant="contained" onClick={saveActiveQueuesSelection}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
