import { useContext, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import DateTimeFilter, { DateRangeType, RelativeDateUnitOptions } from '../DateTimeFilter/DateTimeFilter';

import { DashboardStateActions, DashboardStateContext, DashboardStateDispatchContext } from '../context/DashboardStateContext';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { getUtcOffsetInMinutes } from 'utils/QueueConfigurationFormatAndValidation';
import { CircularProgress, Divider } from '@mui/material';
import { saveUpdatedDateTimeFilter } from '../Utils/queueDashboardApiCallsUtil';

export default function DateRangePickerDialog({ isEditDateRangeDialogOpen, toggleEditDateRangeDialogOpen }) {
    const { dashboardId, dateTimeFilter, filteredQueuesMetricsSummary, selectedQueueId } = useContext(DashboardStateContext);
    const { dispatchDashboardStateAction } = useContext(DashboardStateDispatchContext);

    const [filterValues, setFilterValues] = useState(dateTimeFilter);
    const [loading, setLoading] = useState(false);
    const [hasDateTimeErrors, setHasDateTimeErrors] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const setDateTimeFilterValues = (values) => {
        const updatedDateTimeFilter = {
            type: values.type,
            date: {},
            time: {},
            countryCode: values.countryCode,
            utcOffsetInMinutes: getUtcOffsetInMinutes(values.utcOffsetInMinutes),
            useDst: values.useDst
        };

        if (values.type === DateRangeType.CurrentQueuePeriod) {
            updatedDateTimeFilter.date = null;
            updatedDateTimeFilter.time = null;
        }

        if (values.type === DateRangeType.Specific) {
            updatedDateTimeFilter.date = {
                specific: {
                    startDate: values.startDate,
                    endDate: values.endDate
                },
                relative: null,
                weekDays: values.weekDays
            };
        }

        if (values.type === DateRangeType.Relative) {
            updatedDateTimeFilter.date = {
                relative: {
                    type: values.relativeDateType,
                    length: values.relativeDateCount,
                    includeToday: values.includeToday
                },
                specific: null,
                weekDays: values.weekDays
            };

            if (values.relativeDateType === RelativeDateUnitOptions.LastNHours) {
                updatedDateTimeFilter.time = null;
            }
        }

        if (
            values.type === DateRangeType.Specific ||
            (values.type === DateRangeType.Relative && values.relativeDateType === RelativeDateUnitOptions.LastNDays)
        ) {
            updatedDateTimeFilter.time = {
                type: values.timeType,
                startTime: moment(values.startTime).format('HH:mm'),
                endTime: moment(values.endTime).format('HH:mm')
            };
        }

        setFilterValues(updatedDateTimeFilter);
    };

    const saveDateTimeFilter = async () => {
        setLoading(true);
        try {
            const success = await saveUpdatedDateTimeFilter(dashboardId, filterValues);
            if (success) {
                dispatchDashboardStateAction({
                    type: DashboardStateActions.SetDateTimeFilter,
                    payload: {
                        value: filterValues
                    }
                });
                toggleEditDateRangeDialogOpen();
            } else {
                enqueueSnackbar('Failed to save the updated date time filter', { variant: 'error' });
            }
        } catch (ex) {
            enqueueSnackbar('Failed to save the updated date time filter', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };
    const selectedQueue = filteredQueuesMetricsSummary.find((q) => q.queueId === selectedQueueId);
    return (
        <Dialog maxWidth="lg" fullWidth open={isEditDateRangeDialogOpen}>
            <div className="date-filter-container">
                <div className="date-filter-header">
                    <DialogTitle style={{ padding: 0 }}>Date and Time</DialogTitle>
                    <IconButton aria-label="close" disabled={loading} onClick={toggleEditDateRangeDialogOpen}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <Divider />
                {loading ? (
                    <CircularProgress
                        sx={{
                            position: 'relative',
                            top: '100%',
                            left: '50%',
                            marginTop: 5,
                            marginLeft: -3,
                            marginBottom: 5,
                            zIndex: 999
                        }}
                        data-testid="loading-indicator"
                    />
                ) : (
                    <DateTimeFilter
                        initialValues={filterValues}
                        setDateTimeFilterValues={setDateTimeFilterValues}
                        setHasDateTimeErrors={setHasDateTimeErrors}
                        selectedQueue={selectedQueue}
                    />
                )}
                <Stack spacing={2} direction="row" justifyContent="flex-end">
                    <Button variant="text" color="error" disabled={loading} onClick={toggleEditDateRangeDialogOpen}>
                        Cancel
                    </Button>
                    <Button variant="contained" disabled={loading || hasDateTimeErrors} onClick={saveDateTimeFilter}>
                        Save
                    </Button>
                </Stack>
            </div>
        </Dialog>
    );
}
