import React, { useState } from 'react';
import { BarChart, Label, XAxis, YAxis, Legend, ResponsiveContainer } from 'recharts';

// material-ui
import { useTheme } from '@mui/material/styles';

// project imports
import { getInitialBarProps } from 'utils/graphUtils';
import CustomLegend from './CustomLegend';

const HorizontalStackedBarChart = ({
    data,
    widgetConfig,
    colors,
    handleDrillDown,
    generateTooltip,
    generateGraphics,
    showPointerCursor
}) => {
    const theme = useTheme();
    const themeColors = theme.palette.mode === 'dark' ? colors.dark : colors.light;
    const [barProps, setBarProps] = useState(getInitialBarProps(data));

    const handleLegendClick = (e) => {
        const selectedProp = e.dataKey;
        setBarProps({
            ...barProps,
            [selectedProp]: !barProps[e.dataKey]
        });
    };

    return (
        <ResponsiveContainer width={widgetConfig.width} height={widgetConfig.height} align="center">
            <BarChart data={data} layout="vertical" margin={widgetConfig.margin}>
                <XAxis type="number" tickFormatter={widgetConfig.xAxisTickFormatter} tick={widgetConfig.themeick} height={40}>
                    <Label
                        offset={-6}
                        value={widgetConfig.labelValue}
                        style={{ fill: theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.grey[700] }}
                        id={`yAxisLabel${widgetConfig?.id}`}
                        position={{ x: '55%', y: '85%' }}
                    />
                </XAxis>
                <YAxis
                    type="category"
                    dataKey={widgetConfig.dataKey}
                    tickFormatter={widgetConfig.yAxisTickFormatter}
                    tick={widgetConfig.tick}
                    axisLine={false}
                    width={widgetConfig.yAxisWidth}
                />
                {generateTooltip(themeColors)}
                <Legend content={<CustomLegend onLegendClick={handleLegendClick} selectedData={barProps} />} />
                {generateGraphics(barProps, themeColors, handleDrillDown, widgetConfig)}
            </BarChart>
        </ResponsiveContainer>
    );
};

export default HorizontalStackedBarChart;
