import { memo, useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Divider, List, Typography, useMediaQuery } from '@mui/material';

// project imports
import NavItem from './NavItem';
import NavGroup from './NavGroup';

import useConfig from 'hooks/useConfig';
import { useMenuList } from 'menu-items/widget';

import LAYOUT_CONST from 'constant';
import { HORIZONTAL_MAX_ITEM } from 'config';
import { useSelector } from 'store';
import { IconDashboard, IconHeadset, IconStar } from '@tabler/icons';
import useMenuItems from 'hooks/useMenuItems';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = ({ menuItem }) => {
    const { dashboards } = useSelector((state) => state.dashboard);
    const { queueDashboards } = useSelector((state) => state.queueDashboard);

    const [dashboardMenuItems, setDashboardMenuItems] = useState([]);
    const { hasQueueRoles, hasLicensedQueuesAccess } = useSelector((state) => state.menu);

    /**
     * Queue Dashboards menu items
     */
    const [queueDashboardMenuItems, setQueueDashboardMenuItems] = useState([]);

    const theme = useTheme();
    const { layout } = useConfig();
    const { drawerOpen } = useSelector((state) => state.menu);
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const getMenu = useMenuList();

    useEffect(() => {
        setDashboardMenuItems(
            dashboards.map((dashboard) => ({
                id: dashboard.id,
                title: dashboard.name,
                type: 'item',
                url: `/dashboard/my-dashboard/${dashboard.id}`,
                icon: IconDashboard,
                isDashboard: true,
                breadcrumbs: true
            }))
        );
        // const defDashboard = dashboards.filter((dashboard) => dashboard.displayOrder === 0);
        // if (defDashboard.length > 0) {
        //     dispatch(setDefaultDashboard(defDashboard[0].id));
        // }
    }, [dashboards]);

    /**
     * Queue Dashboards menu items
     */
    useEffect(() => {
        if (hasQueueRoles && hasLicensedQueuesAccess) {
            setQueueDashboardMenuItems(
                queueDashboards.map((dashboard) => ({
                    id: dashboard.id,
                    title: dashboard.name,
                    type: 'item',
                    icon: IconHeadset,
                    url: `/dashboard/queue-dashboard/${dashboard.id}`,
                    breadcrumbs: false,
                    iconRight: dashboard.isDefault ? (
                        <IconStar strokeWidth={0} fill="#FFA700" style={{ width: 20, height: 20, paddingLeft: 5, marginBottom: 4 }} />
                    ) : null
                }))
            );
        } else {
            setQueueDashboardMenuItems([]);
        }
    }, [queueDashboards, hasQueueRoles, hasLicensedQueuesAccess]);

    const handlerMenuItem = () => {
        const isFound = menuItem.items?.some((element) => {
            if (element.id === 'widget') {
                return true;
            }
            return false;
        });

        if (getMenu?.id !== undefined && !isFound) {
            menuItem.items.splice(1, 0, getMenu);
        }
    };

    useEffect(() => {
        handlerMenuItem();
        // eslint-disable-next-line
    }, []);

    // last menu-item to show in horizontal menu bar
    const lastItem = layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd ? HORIZONTAL_MAX_ITEM : null;

    let lastItemIndex = menuItem.items?.length - 1;
    let remItems = [];
    let lastItemId;

    if (lastItem && lastItem < menuItem.items.length) {
        lastItemId = menuItem.items[lastItem - 1].id;
        lastItemIndex = lastItem - 1;
        remItems = menuItem.items.slice(lastItem - 1, menuItem.items.length).map((item) => ({
            title: item.title,
            elements: item.children,
            icon: item.icon,
            ...(item.url && {
                url: item.url
            })
        }));
    }

    const navItems = menuItem.items?.slice(0, lastItemIndex + 1).map((item) => {
        if (item.id === 'dashboard') {
            item.children[0].children = dashboardMenuItems;

            /**
             * Queue Dashboards menu items
             */
            if (item.children.length > 1) {
                item.children[1].children = queueDashboardMenuItems;
            }
        }
        switch (item.type) {
            case 'group':
                if (item.url && item.id !== lastItemId) {
                    return (
                        <List key={item.id}>
                            <NavItem item={item} level={1} isParents />
                            {layout !== LAYOUT_CONST.HORIZONTAL_LAYOUT && <Divider sx={{ py: 0.5 }} />}
                        </List>
                    );
                }
                return <NavGroup key={item.id} item={item} lastItem={lastItem} remItems={remItems} lastItemId={lastItemId} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return layout === LAYOUT_CONST.VERTICAL_LAYOUT || (layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && matchDownMd) ? (
        <Box {...(drawerOpen && { sx: { mt: 1.5 } })}>{navItems}</Box>
    ) : (
        <>{navItems}</>
    );
};

export default memo(MenuList);
