import moment from 'moment';

export function formatDate(dateVal) {
    if (!dateVal) return '';
    return moment(dateVal).format('M/D/YY');
}

export function formatDateMD(dateVal) {
    if (!dateVal) return '';
    return moment(dateVal).format('M/D');
}

export function formatHour(hourStr) {
    // check to see if there's a space. If there is, it means the hourStr is a `date hour` format.
    // if it is a `date hour` format, split the hour part.
    let result = '';
    if (!hourStr) {
        return result;
    }
    const formattedHourStr = hourStr.split(' ')[1] || hourStr;
    const numHour = parseInt(formattedHourStr, 10);
    if (numHour === 0) {
        result = `12AM`;
    } else if (numHour < 12) {
        result = `${numHour}AM`;
    } else {
        result = numHour === 12 ? `12PM` : `${numHour % 12}PM`;
    }
    return hourStr.split(' ')[1] ? `${formatDateMD(hourStr.split(' ')[0])} ${result}` : result;
}

export function formatTime(timeInput) {
    if (timeInput === 0) {
        return { hour: 0, minute: 0 };
    }
    const timeStr = timeInput.toString();
    const min = timeStr.slice(-2);
    const hr = timeStr.substring(0, timeStr.length - 2);
    return { hour: hr, minute: min };
}

export function formatDateTime(dateVal, timeVal) {
    let formattedTimeVal = '';
    if (timeVal === 0) {
        formattedTimeVal = '0000';
    } else {
        const hh = timeVal.toString().substr(0, 2);
        const mm = timeVal.toString().substr(2, 2);
        formattedTimeVal = `${hh}${mm}`;
    }
    return `${moment(dateVal).format('YYYYMMDD')} ${formattedTimeVal}`;
}

export function formatDateISO(dateVal) {
    return moment(dateVal).format('YYYY-MM-DD');
}

export function formatDateAsMonth(dateVal) {
    return moment(dateVal).format('MMM YY');
}

export function formatMonthYear(monthYearStr) {
    const year = monthYearStr.substring(0, 4);
    const month = monthYearStr.substring(4);
    return formatDateAsMonth(new Date(year, month - 1));
}

export function getWeekRange(startDate, endDate) {
    return `${moment(startDate).format('M/D/YY')} - ${moment(endDate).format('M/D/YY')}`;
}

export function formatNumbers(num) {
    if (num > 999 && num < 999950) {
        return `${(num / 1000).toFixed(1)}K`; // convert to K for number from > 1000 < 1 million
    }
    if (num >= 999950 && num < 999950000) {
        return `${(num / 1000000).toFixed(1)}M`; // convert to M for number from > 1 million
    }
    if (num >= 999950000) {
        return `${(num / 1000000000).toFixed(1)}B`; // convert to B for number from > 1
    }
    return `${num}`;
}

export function isNumeric(input) {
    const pattern = /^\d*$/;
    if (pattern.test(input) || input === 'Delete' || input === 'Backspace' || input === 'ArrowLeft' || input === 'ArrowRight') {
        return true;
    }
    return false;
}

export function getTimeRange(startDate, endDate) {
    return moment(endDate).diff(moment(startDate), 'hours');
}

export function getStartEndDateForMonth(monthYear, widgetConfig) {
    const momentDate = moment.utc(`01 ${monthYear}`, 'DD MMM YY'); // convert the MMM YY format to a moment
    const monthYearArr = momentDate.format('M/YY').split('/');
    const month = monthYearArr[0];
    const year = monthYearArr[1];

    const date = moment.utc(`${month}/01/${year}`, 'M/DD/YY');
    let relativeDateStart = null;
    if (widgetConfig.dateType === 1 && widgetConfig.relativeDate === 2) {
        relativeDateStart = moment.utc().subtract(widgetConfig.lastX, 'days');
    } else {
        relativeDateStart = moment.utc(`${month}/01/${year}`, 'M/DD/YY');
    }
    let startDate = null;

    if (date.isAfter(relativeDateStart, 'day')) {
        startDate = date;
    } else {
        startDate = relativeDateStart;
    }

    // if start date is not Sunday, set to previous Sunday
    if (startDate.day() !== 0) {
        startDate.day(0);
    }
    const retStartDate = startDate.format('YYYY-MM-DD');

    let endDate;
    const monthStartDate = moment.utc(`${month}/01/${year}`, 'M/DD/YY');
    if (moment.utc().isSame(monthStartDate, 'month')) {
        endDate = widgetConfig.includeToday ? moment.utc() : moment.utc().subtract(1, 'days');
    } else {
        endDate = monthStartDate.endOf('month');
        // if end date is not Saturday, set to next Saturday
        if (endDate.day() !== 6) {
            endDate.day(6);
        }
    }

    return [retStartDate, endDate.format('YYYY-MM-DD')];
}

export function getStartEndDateForWeek(weekRange) {
    const dateRangeArr = weekRange.split('-');
    const startDate = moment(dateRangeArr[0], 'M/D/YY').format('YYYY-MM-DD');
    const endDate = moment(dateRangeArr[1], 'M/D/YY').format('YYYY-MM-DD');
    return [startDate, endDate];
}

export function formatFieldPropValue(category, value) {
    let retVal = value;
    if (category.toLowerCase().includes('calldirection')) {
        switch (value) {
            case 'O':
                retVal = 'Outbound';
                break;
            case 'I':
                retVal = 'Inbound';
                break;
            case 'E':
                retVal = 'Internal';
                break;
            case 'T':
                retVal = 'Trunk to Trunk';
                break;
            default:
                retVal = value;
                break;
        }
    } else if (category.toLowerCase().includes('calldisposition')) {
        switch (value) {
            case 'IN':
                retVal = 'Inbound Answered';
                break;
            case 'IA':
                retVal = 'Inbound Abandoned';
                break;
            case 'OU':
                retVal = 'Outbound';
                break;
            case 'EX':
                retVal = 'Internal';
                break;
            default:
                retVal = value;
                break;
        }
    }
    return retVal;
}

export function getConfiguredCategories(catField, filters, callTypeDescriptions) {
    let retVal = {};
    let excludeValues = '';
    // find the filter matching the category field
    const catFldMatchFilter = filters.find((itm) => itm.fieldName.toLowerCase() === catField?.toLowerCase());
    if (catFldMatchFilter) {
        // if there's a matching filter, check what values should be included
        if (catFldMatchFilter.operator.toLowerCase() === 'in') {
            const filterValues = catFldMatchFilter.value.split(';');
            return filterValues.reduce((acc, value) => {
                acc[formatFieldPropValue(catField, value)] = 0;
                return acc;
            }, {});
        }
        // if filter operator is 'not includes', collect the values to exclude
        excludeValues = catFldMatchFilter.value.split(';').map((itm) => formatFieldPropValue(catField, itm));
    }
    switch (catField?.toLowerCase()) {
        case 'calldirection':
            retVal = {
                Outbound: 0,
                Inbound: 0,
                Internal: 0,
                'Trunk to Trunk': 0
            };
            break;
        case 'calldisposition':
            retVal = {
                'Inbound Answered': 0,
                'Inbound Abandoned': 0,
                Outbound: 0,
                Internal: 0
            };
            break;
        case 'calltypedesc':
            retVal = callTypeDescriptions?.reduce((acc, currItem) => {
                acc[currItem.effCallTypeDesc] = 0;
                return acc;
            }, {});
            break;
        default:
            retVal = {};
    }
    // if there are values to be excluded, filter them out from the categories returned
    if (excludeValues !== '') {
        const retCategories = Object.keys(retVal);
        // console.log(retCategories.filter((cat) => excludeValues.indexOf(cat) < 0));
        if (retCategories.length > 0) {
            return retCategories
                .filter((cat) => excludeValues.indexOf(cat) < 0)
                .reduce((acc, currItm) => {
                    acc[currItm] = retVal[currItm];
                    return acc;
                }, {});
        }
    }
    return retVal;
}

export function getOperatorDisplayName(operator) {
    switch (operator.toLowerCase()) {
        case 'in':
            return 'Includes';
        case 'not in':
            return 'Not Includes';
        case 'like':
            return 'Like';
        case 'not like':
            return 'Not Like';
        default:
            return operator;
    }
}

function isDSTApplied(utcDate, gmtOffset, dstTest) {
    const utcTime = utcDate.getTime();
    const localTime = new Date(utcTime + gmtOffset * 60 * 60 * 1000);
    const januaryOffset = new Date(localTime.getFullYear(), 0, 1).getTimezoneOffset();
    const juneOffset = new Date(localTime.getFullYear(), 6, 1).getTimezoneOffset();
    const isDST = Math.max(januaryOffset, juneOffset) !== localTime.getTimezoneOffset();
    return dstTest || isDST;
}

export function convertUtcToLocal(utcTime, gmtOffset, dstTest) {
    // Convert UTC time string to a date object
    const utcDate = new Date(utcTime);

    // Parse the GMT offset string and calculate the local offset in minutes
    const offsetHours = parseInt(gmtOffset, 10);
    const offsetMinutes = (gmtOffset - offsetHours) * 60;
    const localOffset = offsetHours * 60 + offsetMinutes;

    const isDstSet = isDSTApplied(utcDate, gmtOffset, dstTest);
    // Calculate the local time by adding the UTC time and the offset
    const dstAddition = isDstSet ? 60 : 0;
    const totalMinutes = localOffset + dstAddition;
    const localTime = new Date(utcDate.getTime() + totalMinutes * 60 * 1000);

    // Format the local time string as desired
    const localTimeString = moment(localTime).format('M/D h:mm a');

    return localTimeString;
}

export function getCurrentGmtAppliedDate(gmtOffset, includeToday) {
    const date = new Date();
    if (!includeToday) {
        date.setDate(date.getDate() - 1);
    }
    const offset = date.getTimezoneOffset();
    const localTime = date.getTime();
    const localOffset = offset / 60;
    const targetTime = localTime + (localOffset + parseFloat(gmtOffset)) * 60 * 60 * 1000;
    const targetDate = new Date(targetTime);
    return targetDate;
}

// export function fillDataForEveryHour(xAxisDataObject, widgetConfig, category, callTypeDesc) {
//     // if lastX Hours, calculate the last x number of Hours from current time.
//     // set startIndex and endIndex accordingly.
//     let startIndex = 0;
//     let endIndex = 0;
//     if (widgetConfig.dateType === 1 && widgetConfig.relativeDate === 1) {
//         const date = getCurrentGmtAppliedDate(widgetConfig.gmtOffset, true);
//         const utcTime = moment(date.toUTCString()).format('YYYY-MM-DD HH:mm:ss');
//         console.log(utcTime);
//         const currentTime = convertUtcToLocalMomentObj(utcTime, widgetConfig.gmtOffset);
//         const endTime = convertUtcToLocalMomentObj(utcTime, widgetConfig.gmtOffset); // .add(widgetConfig.gmtOffset, 'hours');
//         const startTime = currentTime.subtract(widgetConfig.lastX, 'hours');

//         startIndex = parseInt(startTime.format('H'), 10);
//         endIndex = parseInt(endTime.format('H'), 10);

//         console.log(`${startTime} ${endTime}`);
//     } else {
//         const startTimeStr = widgetConfig.startTime.toString(); // eg - 500
//         const endTimeStr = widgetConfig.endTime.toString(); // eg - 2200
//         startIndex = startTimeStr === '0' ? 0 : parseInt(startTimeStr.substring(startTimeStr.length - 2, 0), 10); // 5
//         endIndex = parseInt(endTimeStr.substring(endTimeStr.length - 2, 0), 10); // 22
//     }
//     const hourKeys = Object.keys(xAxisDataObject); // ['5', '6', '7'...]
//     const graphXAxisKeys = []; // this holds all the valid XAxis keys to be displayed on the graph
//     for (let i = startIndex; i <= endIndex; i += 1) {
//         graphXAxisKeys.push(i);
//     }
//     const filteredResults = {};
//     graphXAxisKeys.forEach((xAxisKey) => {
//         const filterData = xAxisDataObject[xAxisKey.toString()] || getConfiguredCategories(category, widgetConfig.filters, callTypeDesc);
//         filteredResults[xAxisKey] = filterData;
//     });

//     // const filteredHourKeys = hourKeys.filter((hourKey) => graphXAxisKeys.find((key) => key.toString() === hourKey.toString()));

//     // const missingHourKeys = graphXAxisKeys.filter((hourKey) => !hourKeys.find((key) => key.toString() === hourKey.toString()));

//     // missingHourKeys.forEach((hourKey) => {
//     //     xAxisDataObject[hourKey] = getConfiguredCategories(category, widgetConfig.filters, callTypeDesc);
//     // });

//     return filteredResults; // || xAxisDataObject;
// }

export function calculateStartEndHoursForGivenDate(dateValue, lastX) {
    const currentMoment = moment();
    // Use moment.js to parse the input date
    const inputMoment = moment(dateValue);

    const currentTime = currentMoment.hour();
    let endTime = currentTime;
    let startTime = currentTime - lastX;

    // Check if we are looking at a past date
    if (currentMoment.isAfter(inputMoment, 'day')) {
        // In this case, end time is the end of the day (23)
        endTime = 23;

        // Calculate start time based on how many hours we are into the next day
        startTime = 24 - (lastX - currentTime);
        if (startTime < 0) startTime = 0;
    } else {
        // In case we are looking at the current day, ensure startTime doesn't go into negative
        startTime = startTime < 0 ? 0 : startTime;
    }

    return [startTime, endTime];
    // return {startTime, endTime};
}

export function fillDataForEveryHour(xAxisDataObject, widgetConfig, category, callTypeDesc, dateValue) {
    const startTimeStr = widgetConfig.startTime.toString(); // eg - 500
    const endTimeStr = widgetConfig.endTime.toString(); // eg - 2200
    let startIndex = 0;
    let endIndex = 23;
    // if relativeDate = 1, calculate startIndex and endIndex based on the number of lastX hours from current time.
    if (widgetConfig.relativeDate === 1) {
        // return the start and end index for the hours based on the provided date and last X values.
        [startIndex, endIndex] = calculateStartEndHoursForGivenDate(dateValue, widgetConfig.lastX);
    } else {
        startIndex = startTimeStr === '0' ? 0 : parseInt(startTimeStr.substring(startTimeStr.length - 2, 0), 10); // 5
        endIndex = parseInt(endTimeStr.substring(endTimeStr.length - 2, 0), 10); // 22
    }

    const hourKeys = Object.keys(xAxisDataObject); // ['5', '6', '7'...]

    const missingHourKeys = Array.from({ length: endIndex - startIndex + 1 }, (_, i) => startIndex + i).filter(
        (hourKey) => !hourKeys.find((key) => key === hourKey.toString())
    );

    missingHourKeys.forEach((hourKey) => {
        xAxisDataObject[hourKey] = getConfiguredCategories(category, widgetConfig.filters, callTypeDesc);
    });

    return xAxisDataObject;
}

export function formatWeekNumber(weekNum, widgetConfig) {
    const { dateType, lastX, relativeDate, includeToday, gmtOffset, startDate, endDate } = widgetConfig;
    // Extract the year and week number from the input string
    const year = weekNum.substring(0, 4);
    const weekNumber = parseInt(weekNum.substring(4), 10);

    // Calculate the date of January 1st of the year
    const janFirst = new Date(year, 0, 1);
    // Find the day of the week of January 1st (0 = Sunday, 1 = Monday, etc.)
    const janFirstDay = janFirst.getDay();
    // Calculate the number of days to the first day of the target week
    const daysDiffToTargetWeek = 7 * (weekNumber - 2);
    const daysToTargetWeek = 7 - janFirstDay + daysDiffToTargetWeek;
    // Calculate the date of the first day of the target week
    let firstDayOfWeek = new Date(year, 0, daysToTargetWeek + 1);
    const sixDaysLater = moment(firstDayOfWeek).add(6, 'days');
    const lastDayOfWeek = sixDaysLater.isSameOrBefore(moment()) ? sixDaysLater : moment();
    if (dateType === 2) {
        const momentStart = moment(startDate);
        const momentEnd = moment(endDate);
        // Check if the week is within the date range
        if (lastDayOfWeek >= momentStart && moment(firstDayOfWeek) <= momentEnd) {
            // Get the maximum date for the week end
            const weekEnd = lastDayOfWeek.isSameOrBefore(momentEnd) ? lastDayOfWeek : momentEnd;

            // Get the minimum date for the week start
            const weekStart = moment(firstDayOfWeek).isSameOrAfter(momentStart) ? moment(firstDayOfWeek) : momentStart;

            return getWeekRange(weekStart, weekEnd);
        }
    }
    // if relative date and relative date type of days, calculate the start day based on lastX value
    if (dateType === 1 && relativeDate === 2) {
        const lastXDate = getCurrentGmtAppliedDate(gmtOffset, includeToday);
        lastXDate.setDate(lastXDate.getDate() - lastX);
        if (firstDayOfWeek < lastXDate) {
            firstDayOfWeek = lastXDate;
        }
        return getWeekRange(moment(firstDayOfWeek), lastDayOfWeek);
    }
    // If Current Month selected
    if (dateType === 1 && relativeDate === 4) {
        return getWeekRange(moment(firstDayOfWeek), lastDayOfWeek);
    }
    return null;
}

export function getDatesForSelectedDays(lastX, gmtOffset, weekdays, includeToday, passedDate) {
    const dates = [];
    const startDate = passedDate || getCurrentGmtAppliedDate(gmtOffset, includeToday);
    for (let i = 0; i <= lastX; i += 1) {
        const date = new Date(startDate.getTime());
        date.setDate(date.getDate() - i);
        if (weekdays.includes(date.getDay() + 1)) {
            // dates.push(date.toLocaleDateString());
            dates.push(formatDateISO(date));
        }
    }
    return dates;
}

export function getMonthsForSelectedDays(lastX, gmtOffset, includeToday, passedDate) {
    const today = passedDate ? new Date(passedDate) : getCurrentGmtAppliedDate(gmtOffset, includeToday);
    const startDate = passedDate ? new Date(passedDate) : getCurrentGmtAppliedDate(gmtOffset, includeToday);
    startDate.setDate(new Date().getDate() - lastX);
    const endDate = today; // includeToday ? today : new Date(today.setDate(today.getDate() - 1));

    const months = [];

    let currMonth = startDate.getMonth();
    let currYear = startDate.getFullYear();

    while (currYear < endDate.getFullYear() || (currYear === endDate.getFullYear() && currMonth <= endDate.getMonth())) {
        const dateToAdd = new Date(currYear, currMonth, 1);
        months.push(moment(dateToAdd).format('YYYYMM'));
        currMonth += 1;
        if (currMonth === 12) {
            currMonth = 0;
            currYear += 1;
        }
    }
    return months;
}

export function getDatesForSelectedMonths(months, gmtOffset, daysOfWeek, includeThisMonth, passedDate) {
    const date = passedDate || getCurrentGmtAppliedDate(gmtOffset, true);
    const result = [];
    const start = includeThisMonth ? 0 : 1;
    for (let i = start; i <= months; i += 1) {
        const prevMonth = new Date(date.getFullYear(), date.getMonth() - i, 1);
        const prevMonthDays = new Date(prevMonth.getFullYear(), prevMonth.getMonth() + 1, 0).getDate();
        for (let j = prevMonthDays; j > 0; j -= 1) {
            const prevDate = new Date(prevMonth.getFullYear(), prevMonth.getMonth(), j);
            const dayOfWeek = prevDate.getUTCDay();
            if (includeThisMonth && i === 0) {
                if (daysOfWeek.includes(dayOfWeek + 1) && prevDate <= date) {
                    result.push(formatDateISO(prevDate));
                }
            } else if (daysOfWeek.includes(dayOfWeek + 1)) {
                result.push(formatDateISO(prevDate));
            }
        }
    }
    return result;
}

function getWeekNumber(date) {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const daysSinceFirstDayOfYear = (date.getTime() - firstDayOfYear.getTime()) / (24 * 60 * 60 * 1000);
    const weekNumber = Math.ceil((daysSinceFirstDayOfYear + firstDayOfYear.getDay() + 1) / 7);
    return weekNumber;
}

export function getWeeksForSelectedDays(lastX, gmtOffset, includeToday, passedDate) {
    const today = passedDate || getCurrentGmtAppliedDate(gmtOffset, true);
    const startDate = new Date(today.getTime() - lastX * 24 * 60 * 60 * 1000);
    const endDate = includeToday ? today : new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
    const weekNumbers = new Set();

    for (let date = startDate; date < endDate; date.setDate(date.getDate() + 1)) {
        const weekNumber = getWeekNumber(date);
        // const formattedWeekNum = weekNumber < 10 ? `0${weekNumber}` : weekNumber;
        const year = date.getFullYear();
        weekNumbers.add(`${year}${weekNumber}`);
    }

    return Array.from(weekNumbers);
}

export function getMonthsForSelectedMonths(lastX, gmtOffset, includeThisMonth, passedDate) {
    const months = [];
    const now = passedDate || getCurrentGmtAppliedDate(gmtOffset, true);
    const currentMonth = now.getMonth();
    const currentYear = now.getFullYear();

    for (let i = 0; i <= lastX; i += 1) {
        let month = currentMonth - i;
        let year = currentYear;

        if (month < 0) {
            month += 12;
            year -= 1;
        }
        months.push(moment(new Date(year, month)).format('YYYYMM'));
    }

    if (!includeThisMonth) {
        months.shift();
    }
    return months;
}

export function getWeeksForSelectedMonths(lastXMonths, gmtOffset, includeThisMonth, passedDate) {
    const today = passedDate || getCurrentGmtAppliedDate(gmtOffset, true);
    const startDate = new Date(today.getFullYear(), today.getMonth() - lastXMonths, 1);
    const endDate = includeThisMonth ? today : new Date(today.getFullYear(), today.getMonth(), 0);
    const weekNumbers = new Set();

    for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
        const weekNumber = getWeekNumber(date);
        // const formattedWeekNum = weekNumber < 10 ? `0${weekNumber}` : weekNumber;
        const year = date.getFullYear();
        weekNumbers.add(`${year}${weekNumber}`);
    }

    return Array.from(weekNumbers);
}

export function getDatesFromHours(lastX, gmtOffset, passedDate) {
    const date = passedDate || getCurrentGmtAppliedDate(gmtOffset, true);
    const result = [];
    for (let i = 0; i < lastX; i += 1) {
        const prevHour = new Date(date.getTime());
        prevHour.setHours(prevHour.getHours() - i);
        const formattedDate = formatDateISO(prevHour);
        if (!result.includes(formattedDate)) {
            result.push(formattedDate);
        }
    }
    return result;
}

export function getDatesForCurrentMonth(gmtOffset, weekdays, passedDate) {
    const date = passedDate || getCurrentGmtAppliedDate(gmtOffset, true);
    const result = [];
    const start = new Date(date.getFullYear(), date.getMonth(), 1);
    const end = date;
    for (let i = start; i <= end; i.setDate(i.getDate() + 1)) {
        const currentDate = new Date(i);
        const dayOfWeek = currentDate.getUTCDay();
        if (weekdays.includes(dayOfWeek + 1)) {
            result.push(formatDateISO(currentDate));
        }
    }
    return result;
}

export function getCurrentMonth(gmtOffset) {
    const today = getCurrentGmtAppliedDate(gmtOffset, true);
    return [moment(today).format('YYYYMM')];
}

export function getWeeksForCurrentMonth(gmtOffset, passedDate) {
    const today = passedDate || getCurrentGmtAppliedDate(gmtOffset, true);
    const startDate = new Date(today.getFullYear(), today.getMonth(), 1);
    const endDate = today;
    const weekNumbers = new Set();

    for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
        const weekNumber = getWeekNumber(date);
        // const formattedWeekNum = weekNumber < 10 ? `0${weekNumber}` : weekNumber;
        const year = date.getFullYear();
        weekNumbers.add(`${year}${weekNumber}`);
    }

    return Array.from(weekNumbers);
}

export function getDatesBetween(startDate, endDate) {
    const result = [];
    let currentDate = moment(startDate);
    const end = moment(endDate);
    while (currentDate <= end) {
        result.push(formatDateISO(currentDate));
        currentDate = currentDate.add(1, 'days');
    }
    return result;
}

export function getMonthsBetween(startDateStr, endDateStr) {
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);
    const startMonth = startDate.getMonth();
    const endMonth = endDate.getMonth();
    const startYear = startDate.getFullYear();
    const endYear = endDate.getFullYear();
    const monthYearList = [];

    for (let year = startYear; year <= endYear; year += 1) {
        const start = year === startYear ? startMonth : 0;
        const end = year === endYear ? endMonth : 11;

        for (let month = start; month <= end; month += 1) {
            const date = new Date(year, month, 1);
            const monthYearStr = `${moment(date).format('MM-YY')}`;
            monthYearList.push(monthYearStr);
        }
    }

    return monthYearList;
}

export function getWeeksBetween(startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const weekNumbers = new Set();

    for (let date = start; date <= end; date.setDate(date.getDate() + 1)) {
        const weekNumber = getWeekNumber(date);
        const year = date.getFullYear();
        weekNumbers.add(`${year}${weekNumber}`);
    }

    return Array.from(weekNumbers);
}

export function getListOfWeeksToDisplay({ dateType, relativeDate, lastX, gmtOffset, includeToday, weekdays, startDate, endDate }) {
    if (dateType === 1) {
        switch (relativeDate.toString()) {
            case '2':
                return getWeeksForSelectedDays(lastX, gmtOffset, includeToday);
            case '3':
                return getWeeksForSelectedMonths(lastX, gmtOffset, includeToday);
            case '4':
                return getWeeksForCurrentMonth(gmtOffset);
            default:
                return [];
        }
    } else {
        return getWeeksBetween(startDate, endDate);
    }
}

export function getListOfMonthsToDisplay({ dateType, relativeDate, lastX, gmtOffset, includeToday, weekdays, startDate, endDate }) {
    if (dateType === 1) {
        switch (relativeDate.toString()) {
            case '2':
                return getMonthsForSelectedDays(lastX, gmtOffset, includeToday);
            case '3':
                return getMonthsForSelectedMonths(lastX, gmtOffset, includeToday);
            case '4':
                return getCurrentMonth(gmtOffset);
            default:
                return [];
        }
    } else {
        return getMonthsBetween(startDate, endDate);
    }
}

export function getListOfDatesToDisplay({ dateType, relativeDate, lastX, gmtOffset, includeToday, weekdays, startDate, endDate }) {
    if (dateType === 1) {
        switch (relativeDate.toString()) {
            case '1':
                return getDatesFromHours(lastX, gmtOffset);
            case '2':
                return getDatesForSelectedDays(lastX, gmtOffset, weekdays, includeToday);
            case '3':
                return getDatesForSelectedMonths(lastX, gmtOffset, weekdays, includeToday);
            case '4':
                return getDatesForCurrentMonth(gmtOffset, weekdays);
            default:
                return [];
        }
    } else {
        return getDatesBetween(startDate, endDate);
    }
}

export function fillDataForSelectedDateRange(dataObj, widgetConfig, category, callTypeDesc) {
    const allDates = getListOfDatesToDisplay(widgetConfig);
    const dataDates = Object.keys(dataObj);
    const missingDates = allDates.filter((dateKey) => !dataDates.find((key) => formatDateISO(key) === dateKey));
    missingDates.forEach((dateKey) => {
        dataObj[dateKey] = getConfiguredCategories(category, widgetConfig.filters, callTypeDesc);
    });
    return dataObj;
}

export function fillDataForSelectedMonths(dataObj, widgetConfig, category, callTypeDesc) {
    const allMonths = getListOfMonthsToDisplay(widgetConfig);
    const dataMonths = Object.keys(dataObj);
    const missingMonths = allMonths.filter((monthKey) => !dataMonths.find((key) => key === monthKey));
    missingMonths.forEach((monthKey) => {
        dataObj[monthKey] = getConfiguredCategories(category, widgetConfig.filters, callTypeDesc);
    });
    return dataObj;
}

export function fillDataForSelectedWeeks(dataObj, widgetConfig, category, callTypeDesc) {
    const allWeeks = getListOfWeeksToDisplay(widgetConfig);
    const dataWeeks = Object.keys(dataObj);
    const missingWeeks = allWeeks.filter((weekKey) => !dataWeeks.find((key) => key === weekKey));
    missingWeeks.forEach((weekKey) => {
        dataObj[weekKey] = getConfiguredCategories(category, widgetConfig.filters, callTypeDesc);
    });
    return dataObj;
}

export function formatDataByDateHour(dateGroupObj, widgetConfig, category, callTypeDesc) {
    // fill in data for all dates selected
    const allDisplayDates = getListOfDatesToDisplay(widgetConfig);
    const dataDates = Object.keys(dateGroupObj);
    const missingDates = allDisplayDates.filter((dateKey) => !dataDates.find((key) => formatDateISO(key) === dateKey));
    missingDates.forEach((dateKey) => {
        dateGroupObj[dateKey] = fillDataForEveryHour({}, widgetConfig, category, callTypeDesc, dateKey);
    });
    // return an array grouped by date/hour
    // const retArr = Object.entries(dateGroupObj).flatMap(([date, hourValues]) =>
    //     hourValues.map((obj) => {
    //         const hour = Object.keys(obj)[0];
    //         const hourlyData = obj[hour];
    //         const newObj = { name: `${formatDate(date)} ${formatHour(hour)}` };
    //         return { ...newObj, ...hourlyData };
    //     })
    // );
    // return retArr;
    const retObj = {};
    const dates = Object.keys(dateGroupObj);
    dates.forEach((date) => {
        const hourObj = dateGroupObj[date];
        Object.keys(hourObj).forEach((hour) => {
            retObj[`${date} ${hour}`] = dateGroupObj[date][hour];
        });
    });
    return retObj;
}

export function reduceByDate(inputArray) {
    return inputArray?.reduce((acc, itm) => {
        if (acc[itm.date]) {
            acc[itm.date] = [...acc[itm.date], itm];
        } else {
            acc[itm.date] = [itm];
        }
        return acc;
    }, {});
}

export function startEndDateDifference(startDate, endDate) {
    const dateFormat = 'YYYY-MM-DD';
    const start = moment(startDate, dateFormat);
    const end = moment(endDate, dateFormat);
    const differenceInDays = end.diff(start, 'days');
    return differenceInDays;
}
