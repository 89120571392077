// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconCloudDataConnection } from '@tabler/icons';

// constant
const icons = {
    IconCloudDataConnection
};

const administration = {
    id: 'administration',
    title: <FormattedMessage id="administration" />,
    type: 'group',
    children: [
        {
            id: 'connectors',
            title: <FormattedMessage id="connectors" />,
            type: 'item',
            url: '/administration/connectors',
            icon: icons.IconCloudDataConnection
        }
    ]
};

export default administration;
