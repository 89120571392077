import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// project imports
import { useDispatch, useSelector } from 'store/index';
import { addWidget } from 'store/slices/widgets';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Box, Button, Checkbox, FormGroup, FormControlLabel, Grid, Stack } from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// assets

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const AddWidget = ({ closePopper }) => {
    const theme = useTheme();

    const [isLoaded, setLoaded] = useState(false);
    const [selectedWidgets, setSelectedWidgets] = useState([]);

    const dispatch = useDispatch();
    const { availableWidgets } = useSelector((state) => state.widgets);
    const { dashboard } = useSelector((state) => state.dashboard);
    const { orgLevelDescriptions } = useSelector((state) => state.lookup);

    useEffect(() => {
        setLoaded(true);
    }, [availableWidgets]);

    const handleChange = (e) => {
        if (e.target.checked) {
            setSelectedWidgets([...selectedWidgets, e.target.name]);
        } else {
            setSelectedWidgets(selectedWidgets.filter((item) => item !== e.target.name));
        }
    };

    const addWidgets = (e) => {
        e.preventDefault();
        closePopper();
        selectedWidgets.forEach((widget) => {
            dispatch(addWidget(parseInt(widget, 10), dashboard.id, orgLevelDescriptions));
            // dispatch(layoutWillChange(true));
        });
    };

    return (
        <Grid item xs={12}>
            <PerfectScrollbar style={{ height: '100%', maxHeight: 'calc(100vh - 205px)', overflowX: 'hidden' }}>
                <Box
                    sx={{
                        ml: 2,
                        mr: 3,
                        [theme.breakpoints.down('md')]: {
                            mr: 2
                        },
                        mb: 2
                    }}
                >
                    <FormGroup>
                        {isLoaded &&
                            availableWidgets.map((i) => (
                                <FormControlLabel
                                    sx={{ ...theme.typography.body1 }}
                                    control={<Checkbox size="small" onChange={handleChange} name={`${i.id}`} />}
                                    label={i.defaultName}
                                    key={i.id}
                                />
                            ))}
                    </FormGroup>
                </Box>
            </PerfectScrollbar>
            <Grid item xs={12} justifyContent="center" sx={{ paddingBottom: '16px' }}>
                <Stack direction="row" spacing={2} justifyContent="center">
                    <Button
                        id="addWidgetBtn"
                        variant="contained"
                        onClick={(e) => {
                            addWidgets(e);
                        }}
                        disabled={selectedWidgets.length === 0}
                    >
                        Add
                    </Button>
                </Stack>
            </Grid>
        </Grid>
    );
};

AddWidget.propTypes = {
    closePopper: PropTypes.func
};

export default AddWidget;
