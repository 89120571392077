import { createSlice } from '@reduxjs/toolkit';

// project imports
import { axiosMSTeamsCQService, axiosReportService } from 'utils/axios';
import { sortBy } from 'lodash';
import { updateOrgLevelAlias } from 'views/Reports/utils';

const initialState = {
    previewData: null,
    previewDataLoading: false,
    previewDataError: null,
    reportId: 0,
    runNowDataLoaded: false,
    templateName: '',
    runNowDataError: null,
    exportStart: false,
    isExportSuccess: false,
    selectedColumnsFromTemplate: [],
    selectedGroupingsFromTemplates: [],
    reportLoadingStart: false,
    reportDetails: {},
    queueRecordDetails: {},
    queueRecordLoading: false
};
const slice = createSlice({
    name: 'report',
    initialState,
    reducers: {
        createExportRequest(state) {
            state.exportStart = true;
        },
        createExportSuccess(state) {
            state.exportStart = false;
            state.isExportSuccess = true;
        },
        createExportError(state) {
            state.exportStart = false;
        },

        createPreviewDataRequest(state) {
            state.previewDataLoading = true;
            state.previewData = null;
            state.previewDataError = null;
        },
        createPreviewDataSuccess(state, action) {
            state.previewDataLoading = false;
            state.previewData = action.payload;
            state.previewDataError = null;
        },
        createPreviewDataError(state, action) {
            state.previewDataLoading = false;
            state.previewData = null;
            state.previewDataError = action.payload;
        },
        createRunNowSuccess(state, action) {
            state.reportId = action.payload;
            state.reportLoadingStart = false;
        },
        createRunNowRequest(state, action) {
            state.reportLoadingStart = true;
            state.runNowDataError = null;
            state.reportId = 0;
        },

        createRunNowError(state, action) {
            state.runNowDataError = action.payload;
            state.createRunNowSuccess = false;
        },
        createRunNowFailure(state, action) {
            state.createRunNowSuccess = false;
        },

        resetReportId(state, action) {
            state.reportId = 0;
            state.reportLoadingStart = false;
        },
        cleanUpExport(state, action) {
            state.exportStart = false;
            state.isExportSuccess = false;
        },
        resetTemplateName(state, action) {
            state.templateName = '';
        },
        resetPreviewError(state, action) {
            state.previewDataError = null;
        },
        setTemplateName(state, action) {
            state.templateName = action.payload;
        },
        setReportParameter(state, action) {
            state.selectedColumnsFromTemplate = [...action.payload.selectedColumns];
            state.selectedGroupingsFromTemplates = [...action.payload.selectedGroupings];
        },
        setReportDetails(state, action) {
            state.reportDetails = {
                reportStartDate: action.payload.reportStartDate,
                reportStartTime: action.payload.reportStartTime,
                gmtOffsetInMinutes: action.payload.gmtOffsetInMinutes,
                reportEndDate: action.payload.reportEndDate,
                reportEndTime: action.payload.reportEndTime,
                templateName: action.payload.templateName,
                reportConfigDetails: action.payload.reportConfigDetails
            };
        },
        setQueueRecordDetails(state, action) {
            state.queueRecordDetails = action.payload;
            state.queueRecordLoading = false;
        },
        createQueueRecordDetails(state, action) {
            state.queueRecordLoading = true;
        },
        createQueueRecordError(state, action) {
            state.queueRecordLoading = false;
        },
        clearQueueRecordDetails(state, action) {
            state.queueRecordDetails = {};
            state.queueRecordLoading = false;
        }
    }
});

export default slice.reducer;

export function fetchPreviewData(enqueueSnackbar, payload) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.createPreviewDataRequest());
            const response = await axiosReportService.post(`/ReportGeneration/preview`, payload);
            if (response?.data?.success) {
                if (response.data?.data?.reportData === '[]') enqueueSnackbar('No Data available', { variant: 'info' });

                dispatch(slice.actions.createPreviewDataSuccess(JSON.parse(response.data.data.reportData)));
            } else {
                dispatch(slice.actions.createPreviewDataError(response.data.error));
            }
        } catch (ex) {
            console.log(ex);
            if (ex.response.data.error) {
                dispatch(slice.actions.createPreviewDataError(ex.response.data.error));
            }
            if (ex?.response?.status === 401) {
                enqueueSnackbar('Session expired, please login again', { variant: 'error' });
            }
        }
    };
}

export function fetchReportDetails(enqueueSnackbar, payload) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.createRunNowRequest());
            const response = await axiosReportService.post(`/ReportGeneration/report`, payload);
            if (response?.data?.success) {
                dispatch(slice.actions.createRunNowSuccess(response.data.data.reportId));
            } else {
                dispatch(slice.actions.createRunNowError(response.data.error));
            }
        } catch (ex) {
            console.log(ex);
            if (ex.response.data.error) {
                dispatch(slice.actions.createRunNowError(ex.response.data.error));
            }
            if (ex?.response?.status === 401) {
                enqueueSnackbar('Session expired, please login again', { variant: 'error' });
                dispatch(slice.actions.createRunNowFailure());
            } else {
                enqueueSnackbar('Error in getting Report Data', { variant: 'error' });
                dispatch(slice.actions.createRunNowFailure());
            }
        }
    };
}
export function setReportParameter(selectedColumns, selectedGroupings) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.setReportParameter({ selectedColumns, selectedGroupings }));
        } catch (ex) {
            console.log(ex);
        }
    };
}

const extractColumns = (fields, templateFields, dispatch, orgLevelDescription) => {
    templateFields = updateOrgLevelAlias(templateFields, orgLevelDescription);
    let selectedColumns = [];
    let selectedGroupings = [];

    templateFields.forEach((field) => {
        if (field.isDefaultColumn) selectedColumns.push(field);
        if (field.isGroupable) {
            if (field.isDefaultGroup) selectedGroupings.push({ ...field, category: 'Available Grouping' });
        }
    });
    selectedColumns = sortBy(selectedColumns, 'columnOrder');
    selectedGroupings = sortBy(selectedGroupings, 'groupOrder');

    dispatch(setReportParameter(selectedColumns, selectedGroupings));
};

const extractColumnsForReports = (fieldsAndGroups, dispatch, orgLevelDescription) => {
    fieldsAndGroups = updateOrgLevelAlias(fieldsAndGroups, orgLevelDescription);
    let selectedColumns = [];
    let selectedGroupings = [];

    fieldsAndGroups.forEach((field) => {
        selectedColumns.push(field);
        if (field.groupOrder > 0) {
            selectedGroupings.push({ ...field, category: 'Available Grouping' });
        }
    });
    selectedColumns = sortBy(selectedColumns, 'columnOrder');
    selectedGroupings = sortBy(selectedGroupings, 'groupOrder');

    dispatch(setReportParameter(selectedColumns, selectedGroupings));
};

export function fetchReportDetailsFromTemplates(enqueueSnackbar, payload, templateId, orgLevelDescription) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.createRunNowRequest());
            const response = await axiosReportService.post(`/ReportGeneration/report/${templateId}`, payload);
            if (response?.data?.success) {
                const templateResponse = response.data?.data?.templateResponse;
                extractColumns(templateResponse?.fieldCategories, templateResponse.templateFields, dispatch, orgLevelDescription);
                dispatch(slice.actions.createRunNowSuccess(response.data.data.reportId));
            } else {
                dispatch(slice.actions.createRunNowError(response.data.error));
            }
        } catch (ex) {
            console.log(ex);
            if (ex.response?.data?.error) {
                dispatch(slice.actions.createRunNowError(ex.response.data.error));
            }
            if (ex?.response?.status === 401) {
                enqueueSnackbar('Session expired, please login again', { variant: 'error' });
                dispatch(slice.actions.createRunNowFailure());
            } else {
                enqueueSnackbar('Error in getting Report Data', { variant: 'error' });
                dispatch(slice.actions.createRunNowFailure());
            }
        }
    };
}

export function fetchReportDetailsFromHistory(enqueueSnackbar, reportId, orgLevelDescription) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.createRunNowRequest());
            const response = await axiosReportService.get(`/Reports/${reportId}`);
            if (response?.data?.success) {
                const reportFieldDetails = response.data?.data?.reportFieldDetails;
                dispatch(slice.actions.setReportDetails(response.data?.data));
                extractColumnsForReports(reportFieldDetails, dispatch, orgLevelDescription);
                // dispatch(slice.actions.createRunNowSuccess(response.data.data.id));
            } else {
                dispatch(slice.actions.createRunNowError(response.data.error));
            }
        } catch (ex) {
            console.log(ex);
            if (ex.response?.data?.error) {
                dispatch(slice.actions.createRunNowError(ex.response.data.error));
            }
            if (ex?.response?.status === 401) {
                enqueueSnackbar('Session expired, please login again', { variant: 'error' });
                dispatch(slice.actions.createRunNowFailure());
            } else {
                enqueueSnackbar('Error in getting Report Data', { variant: 'error' });
                dispatch(slice.actions.createRunNowFailure());
            }
        }
    };
}

export function resetReportId() {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.resetReportId());
        } catch (ex) {
            console.log(ex);
        }
    };
}
export function clearQueueRecordDetails() {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.clearQueueRecordDetails());
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function cleanUpExport() {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.cleanUpExport());
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function setTemplateName(templateName) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.setTemplateName(templateName));
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function resetTemplateName() {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.resetTemplateName());
        } catch (ex) {
            console.log(ex);
        }
    };
}
export function resetPreviewError() {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.resetPreviewError());
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function exportReportFile(enqueueSnackbar, reportId, payload) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.createExportRequest());
            const response = await axiosReportService.post(`/Reports/${reportId}/export`, payload);
            if (response?.data?.success) {
                dispatch(slice.actions.createExportSuccess(response.data.data.reportId));
            } else {
                dispatch(slice.actions.createExportError(response.data.error));
            }
        } catch (ex) {
            console.log(ex);
            if (ex.response.data.error) {
                dispatch(slice.actions.createExportError(ex.response.data.error));
            }
            if (ex?.response?.status === 401) {
                enqueueSnackbar('Session expired, please login again', { variant: 'error' });
            } else {
                enqueueSnackbar('Error in getting Report', { variant: 'error' });
            }
        }
    };
}

export function getQueueRecordDetails(enqueueSnackbar, payload) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.createQueueRecordDetails());
            const response = await axiosMSTeamsCQService.post('/api/DetailedDataAnalytics/getQueueRecordDetails', payload);
            if (response?.data?.success) {
                dispatch(slice.actions.setQueueRecordDetails(response.data?.data));
            } else {
                dispatch(slice.actions.createQueueRecordError(response.data.error));
            }
        } catch (ex) {
            console.log(ex);
            if (ex.response?.data?.error) {
                dispatch(slice.actions.createQueueRecordError(ex.response.data.error));
            }
            if (ex?.response?.status === 401) {
                enqueueSnackbar('Session expired, please login again', { variant: 'error' });
                dispatch(slice.actions.createQueueRecordError());
            } else {
                enqueueSnackbar('Error in getting Data', { variant: 'error' });
                dispatch(slice.actions.createQueueRecordError());
            }
        }
    };
}
export function setQueueRecordLoading() {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.createQueueRecordDetails());
        } catch (ex) {
            console.log(ex);
        }
    };
}
