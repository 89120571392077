import React, { useState } from 'react';
import { LineChart, XAxis, YAxis, Label, Legend, ResponsiveContainer } from 'recharts';

// material-ui
import { useTheme } from '@mui/material/styles';

// project imports
import { getInitialBarProps } from 'utils/graphUtils';
import debounce from 'utils/generic';
import CustomLegend from './CustomLegend';

const BasicLineChart = ({ data, widgetConfig, colors, handleDrillDown, generateTooltip, generateGraphics, showPointerCursor }) => {
    const theme = useTheme();
    const themeColors = theme.palette.mode === 'dark' ? colors.dark : colors.light;
    // this state is used to store the hover over points on the graph to correctly drill down to the clicked data point.
    // This needs to be done because Line onClick does not provide the clicked data point unlike Bar components
    const [activeHoverPoint, setActiveHoverPoint] = useState(null);
    const debounceSetActiveHoverPoint = debounce(setActiveHoverPoint, 300);
    const [lineProps, setLineProps] = useState(getInitialBarProps(data));

    const handleLegendClick = (e) => {
        const selectedProp = e.dataKey;
        setLineProps({
            ...lineProps,
            [selectedProp]: !lineProps[e.dataKey]
        });
    };

    const handleLineClick = () => {
        const drillDownXAxis = activeHoverPoint && activeHoverPoint[0]?.payload && activeHoverPoint[0]?.payload[widgetConfig.dataKey];
        if (drillDownXAxis) handleDrillDown(drillDownXAxis, widgetConfig);
    };

    return (
        <ResponsiveContainer width={widgetConfig.width} height={widgetConfig.height}>
            <LineChart
                data={data}
                margin={widgetConfig.margin}
                onClick={handleLineClick}
                cursor={showPointerCursor ? 'pointer' : 'default'}
            >
                <XAxis dataKey={widgetConfig.dataKey} tickFormatter={widgetConfig.xAxisTickFormatter} tick={widgetConfig.tick} />
                <YAxis
                    axisLine={false}
                    tickFormatter={widgetConfig.yAxisTickFormatter}
                    tick={widgetConfig.tick}
                    width={widgetConfig.yAxisWidth}
                >
                    <Label
                        offset={8}
                        value={widgetConfig.labelValue}
                        angle={-90}
                        position={{ x: '5%', y: '25%' }}
                        style={{ fill: theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.grey[700] }}
                        id={`yAxisLabel${widgetConfig?.id}`}
                    />
                </YAxis>
                {generateTooltip(debounceSetActiveHoverPoint, themeColors)}
                <Legend content={<CustomLegend onLegendClick={handleLegendClick} selectedData={lineProps} />} />
                {generateGraphics(lineProps, themeColors)}
            </LineChart>
        </ResponsiveContainer>
    );
};

export default BasicLineChart;
