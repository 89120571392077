import { createSlice } from '@reduxjs/toolkit';
import axios, { axiosMSTeamsCQService } from 'utils/axios';
import moment from 'moment';
import { Category } from 'views/Reports/Constants';

export const initialState = {
    dataSourceList: [],
    extensionLocationList: [],
    orgList: [],
    orgLevelDescriptions: [],
    countries: [],
    countryBasedTimezone: [],
    callTypeDescriptions: [],
    selectedDataSource: '',
    columnLookUpData: [],
    columnLookUpLoading: false,
    appConfigValues: [],
    jurisdiction: [],
    queues: [],
    agents: [],
    agentFinalAction: [],
    usersWithLogin: [],
    availableDateRange: {
        startDate: '',
        endDate: '',
        displayMessage: ''
    },
    directoryCustomAttribute: [],
    queueFilterConfig: {}
};

const slice = createSlice({
    name: 'lookup',
    initialState,
    reducers: {
        getDataSourceSuccess(state, action) {
            state.dataSourceList = action.payload;
        },
        getOrganizationSuccess(state, action) {
            state.orgList = action.payload;
        },
        getCountriesSuccess(state, action) {
            state.countries = action.payload;
        },
        getTimezoneSuccess(state, action) {
            state.countryBasedTimezone = action.payload;
        },
        getExtensionLocationSuccess(state, action) {
            state.extensionLocationList = action.payload;
        },
        getOrgLevelDescriptionSuccess(state, action) {
            state.orgLevelDescriptions = action.payload;
        },
        getCallTypeDescSuccess(state, action) {
            state.callTypeDescriptions = action.payload;
        },
        setDefaultDataSource(state, action) {
            state.selectedDataSource = action.payload;
        },
        getColumnLookupRequest(state) {
            state.columnLookUpLoading = true;
            state.columnLookUpData = [];
            state.columnLookUpError = null;
        },
        getColumnLookupSuccess(state, action) {
            state.columnLookUpLoading = false;
            state.columnLookUpData = action.payload;
        },
        getColumnLookupError(state, action) {
            state.columnLookUpLoading = false;
            state.columnLookUpData = [];
            state.columnLookUpError = action.payload;
        },
        getAppConfigSuccess(state, action) {
            state.appConfigValues = action.payload;
        },
        getJurisdictionSuccess(state, action) {
            state.jurisdiction = action.payload;
        },
        getQueuesSuccess(state, action) {
            state.queues = action.payload;
        },
        getAgentFinalActionSuccess(state, action) {
            state.agentFinalAction = action.payload;
        },
        getAgentsSuccess(state, action) {
            state.agents = action.payload;
        },
        setUsersWithLogin(state, action) {
            state.usersWithLogin = action.payload;
        },
        getAvailableDateRange(state, action) {
            state.availableDateRange = action.payload;
        },
        resetAvailableDateRange(state) {
            state.availableDateRange = {
                startDate: '',
                endDate: '',
                displayMessage: ''
            };
        },
        setDirectoryCustomeAttribute(state, action) {
            state.directoryCustomAttribute = action.payload;
        },
        getFilterConfigSuccess(state, action) {
            state.queueFilterConfig = action.payload;
        }
    }
});

export default slice.reducer;

export function getDataSources(ownerId) {
    return async (dispatch) => {
        const response = await axios.get(`/sites?ownerId=${ownerId}`);
        const formattedList = response?.data?.map((dataSource) => ({
            label: dataSource.name,
            value: dataSource.name,
            id: dataSource.siteId
        }));
        dispatch(slice.actions.getDataSourceSuccess(formattedList));
        // if formatted list is not empty, set first data source as the default
        if (formattedList.length > 0) {
            dispatch(slice.actions.setDefaultDataSource(formattedList[0].value));
        }
        // get the extension locations for the default data source
        const locationResponse = await axios.get(`/locations?siteId=${formattedList[0].id || -1}`);
        const formattedExtLocList = locationResponse.data.map((item) => ({ label: item.locationName, value: item.locationId }));
        dispatch(slice.actions.getExtensionLocationSuccess(formattedExtLocList));
    };
}

export function getOrganizations(ownerId) {
    return async (dispatch) => {
        const response = await axios.get(`/orgs?ownerId=${ownerId}`);
        dispatch(slice.actions.getOrganizationSuccess(response.data));
    };
}

export function getCountries() {
    return async (dispatch) => {
        const response = await axios.get(`/refs/countries`);
        dispatch(slice.actions.getCountriesSuccess(response.data));
    };
}

export function getTimezone(country) {
    return async (dispatch) => {
        const response = await axios.get(`/refs/timezones?code=${country}`);
        dispatch(slice.actions.getTimezoneSuccess(response.data));
    };
}
export function getJurisdiction() {
    return async (dispatch) => {
        const response = await axios.get(`/dropdown/jurisdiction`);
        dispatch(slice.actions.getJurisdictionSuccess(response.data));
    };
}
export function getQueues() {
    return async (dispatch) => {
        const response = await axiosMSTeamsCQService.get(`/api/users/getAccessibleQueues?status=licensed`);
        dispatch(slice.actions.getQueuesSuccess(response?.data?.data));
    };
}
export function getAgentFinalAction() {
    return async (dispatch) => {
        const response = await axiosMSTeamsCQService.get(`/api/meta/agentDetailReportFiltersConfig`);
        dispatch(slice.actions.getAgentFinalActionSuccess(response?.data?.data?.agentFinalAction?.allowedValues));
    };
}
export function getQueueFilterConfig() {
    return async (dispatch) => {
        const response = await axiosMSTeamsCQService.get(`/api/meta/queueDetailReportFiltersConfig`);
        dispatch(slice.actions.getFilterConfigSuccess(response?.data?.data));
    };
}
export function getAgents() {
    return async (dispatch) => {
        const response = await axiosMSTeamsCQService.get(`api/users/getAccessibleAgents`);
        dispatch(slice.actions.getAgentsSuccess(response?.data?.data));
    };
}

export function getExtensionLocations() {
    return async (dispatch) => {
        const response = await axios.get(`/locations`);
        const formattedList = response.data.map((item) => ({ label: item.locationName, value: item.locationId }));
        dispatch(slice.actions.getExtensionLocationSuccess(formattedList));
    };
}

export function getOrgLevelDescriptions() {
    return async (dispatch) => {
        const response = await axios.get(`/orgs/levels`);
        // filter out the unused levels
        const usedOrgLevels = response.data.filter((item) => !item.description.toLowerCase().includes('unused'));
        dispatch(slice.actions.getOrgLevelDescriptionSuccess(usedOrgLevels));
    };
}

export function getCallTypeDescriptions() {
    return async (dispatch) => {
        const response = await axios.get(`/refs/call-types`);
        dispatch(slice.actions.getCallTypeDescSuccess(response.data));
    };
}

export function getColumnLookUp(ownerId, columnName, query, cb) {
    return async (dispatch) => {
        cb();
        dispatch(slice.actions.getColumnLookupRequest());
        try {
            const response = await axios.get(`/${columnName}?ownerId=${ownerId}${query ? `&query=${query}` : ''}`);
            dispatch(slice.actions.getColumnLookupSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.getColumnLookupError(error));
        }
    };
}

export function getAppConfig() {
    return async (dispatch) => {
        const response = await axios.get(`/AppConfiguration/AppConfig`);
        if (response?.data) {
            dispatch(slice.actions.getAppConfigSuccess(response.data));
        }
    };
}

export function setUsersWithLoginData(userOwnerId, authorizedUserRoles) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/lookup/userswithLogin?roles=${authorizedUserRoles}`);
            if (response?.data) {
                const data = response.data.filter((d) => d.ownerID !== userOwnerId);
                dispatch(slice.actions.setUsersWithLogin(data));
            }
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function getAvailableDateRange(templateType = Category.CallDataReport) {
    return async (dispatch) => {
        try {
            let response;
            switch (templateType) {
                case Category.AgentDataReport: {
                    const res = await axiosMSTeamsCQService.get(
                        `/api/DetailedDataAnalytics/analyticsAvailableDateRange?analyticsType=Agent`
                    );
                    response = res?.data;
                    break;
                }
                case Category.QueueDataReport: {
                    const res = await axiosMSTeamsCQService.get(
                        `/api/DetailedDataAnalytics/analyticsAvailableDateRange?analyticsType=Queue`
                    );
                    response = res?.data;
                    break;
                }
                case Category.CallDataReport:
                    response = await axios.get(`/lookup/processed-date-range`);
                    break;
                default:
                    console.log('Invalid template type');
            }
            if (response?.data) {
                const startDate = moment(response.data.utcRangeStart).format('yyyy-MM-DD');
                const endDate = moment(response.data.utcRangeEnd).format('yyyy-MM-DD');
                const displayMessage = `Available Date Range: ${startDate} to ${endDate}`;
                dispatch(slice.actions.getAvailableDateRange({ startDate, endDate, displayMessage }));
            }
        } catch (ex) {
            const errorObj = { startDate: '', endDate: '', displayMessage: 'No Data Available' };
            dispatch(slice.actions.getAvailableDateRange(errorObj));
            console.log(ex);
        }
    };
}

export function resetAvailableDateRange() {
    return async (dispatch) => {
        dispatch(slice.actions.resetAvailableDateRange());
    };
}

export function getDirectoryCustomAttribute() {
    return async (dispatch) => {
        try {
            const response = await axios.get(`lookup/custom-attributes`);
            if (response?.data) {
                dispatch(slice.actions.setDirectoryCustomeAttribute(response.data));
            }
        } catch (ex) {
            console.log(ex);
        }
    };
}
