import { useSelector, useDispatch } from 'store';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { useFormik } from 'formik';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';

import { createBlankQueueDashboard, createQueueDashboardByCopying } from 'store/slices/queueDashboard';
import { DateTimeFilterType, QueueDataSource } from '../constants';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { getAccessibleLicensedCallQueues } from '../Utils/queueDashboardApiCallsUtil';

export default function CreateQueueDashboardDialog({ isDialogOpen, toggleDialogOpen }) {
    const { queueDashboards, queueDashboardTemplates } = useSelector((state) => state.queueDashboard);
    const [isLoading, setIsLoading] = useState(false);
    const [hasValErrors, setHasValErrors] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const onCreateDashboardCallback = (id) => {
        setIsLoading(false);
        if (id) {
            navigate(`/dashboard/queue-dashboard/${id}`);
        }
    };

    const formik = useFormik({
        initialValues: {
            dashboardName: '',
            copyFrom: 'default'
        },

        validate: (values) => {
            const errors = {};
            if (!values.dashboardName?.trim()) {
                errors.dashboardName = 'Dashboard name is required';
                setHasValErrors(true);
            } else if (values.dashboardName.length > 50) {
                errors.dashboardName = 'Dashboard name should not exceed 50 characters';
                setHasValErrors(true);
            } else if (queueDashboards.some((dashboard) => dashboard.name === values.dashboardName)) {
                errors.dashboardName = 'Dashboard name already exists';
                setHasValErrors(true);
            } else {
                setHasValErrors(false);
            }
            return errors;
        },

        onSubmit: async (values) => {
            setIsLoading(true);

            const payload = { name: values.dashboardName };

            if (values.copyFrom === 'blank') {
                const accessibleQueues = await getAccessibleLicensedCallQueues(enqueueSnackbar, QueueDataSource.MicrosoftTeams);

                if (!accessibleQueues || !accessibleQueues.length) {
                    enqueueSnackbar('Failed to get licensed queues', { variant: 'error' });
                    return;
                }

                payload.queueIdsFilter = accessibleQueues.map((q) => q.queueId);

                payload.dataSource = QueueDataSource.MicrosoftTeams;

                payload.widgets = [];
                payload.dateTimeFilter = {
                    type: DateTimeFilterType.QueuePeriod,
                    date: null,
                    time: null,
                    countryCode: 'US',
                    utcOffsetInMinutes: -360,
                    useDST: true
                };

                dispatch(createBlankQueueDashboard(enqueueSnackbar, payload, onCreateDashboardCallback));

                toggleDialogOpen();
                return;
            }

            if (values.copyFrom === 'default') {
                const accessibleQueues = await getAccessibleLicensedCallQueues(enqueueSnackbar, QueueDataSource.MicrosoftTeams);
                if (!accessibleQueues || !accessibleQueues.length) {
                    enqueueSnackbar('Failed to get licensed queues. Try again', { variant: 'error' });
                    return;
                }

                // NOTE: Only one dashboard template is supported right now
                payload.baseTemplateId = queueDashboardTemplates[0].id;
                payload.queueIdsFilter = accessibleQueues.map((q) => q.queueId);
            } else {
                payload.baseDashboardId = values.copyFrom;
            }

            dispatch(createQueueDashboardByCopying(enqueueSnackbar, payload, onCreateDashboardCallback));
            toggleDialogOpen();
        }
    });

    return (
        <Dialog open={isDialogOpen}>
            <div style={{ width: 478 }}>
                <div className="create-queue-dashboard-dialog-header">
                    <DialogTitle sx={{ padding: 0 }}>New Queue Dashboard</DialogTitle>
                    <IconButton aria-label="close" onClick={toggleDialogOpen}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <form className="dialog-content" onSubmit={formik.handleSubmit}>
                    <TextField
                        id="dashboardName"
                        name="dashboardName"
                        label="Dashboard Name"
                        variant="outlined"
                        value={formik.values.dashboardName}
                        onChange={formik.handleChange}
                        error={Boolean(formik.errors.dashboardName)}
                        helperText={formik.errors.dashboardName}
                    />
                    <FormControl fullWidth>
                        <InputLabel id="group-by-select-label">Copy From</InputLabel>
                        <Select
                            labelId="group-by-select-label"
                            id="copyFrom"
                            name="copyFrom"
                            value={formik.values.copyFrom}
                            onChange={formik.handleChange}
                            label="Copy From"
                        >
                            <MenuItem key="group-by-option_default" value="default">
                                Default Template
                            </MenuItem>
                            <MenuItem key="group-by-option_blank" value="blank">
                                Blank Dashboard
                            </MenuItem>
                            {queueDashboards.map((option) => (
                                <MenuItem key={`group-by-option_${option.id}`} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </form>
                <Stack className="buttons-container" spacing={2} direction="row" justifyContent="flex-end">
                    <Button variant="text" color="error" onClick={toggleDialogOpen}>
                        Cancel
                    </Button>
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        loading={isLoading}
                        onClick={formik.handleSubmit}
                        disabled={hasValErrors}
                    >
                        Save
                    </LoadingButton>
                </Stack>
            </div>
        </Dialog>
    );
}
