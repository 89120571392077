import { useContext, useEffect, useLayoutEffect, useMemo } from 'react';
import { Responsive as ResponsiveGridLayout } from 'react-grid-layout';
import { withSize } from 'react-sizeme';
import AgentMetricsTable from '../AgentMetricsTable/AgentMetricsTable';
import { DashboardStateActions, DashboardStateContext, DashboardStateDispatchContext } from '../context/DashboardStateContext';
import DonutChartWidget from '../DonutChartWidget/DonutChartWidget';
import { WidgetTypes } from '../constants';
import TimeSeriesWidget from '../TimeSeriesWidget/TimeSeriesWidget';
import MainCard from 'ui-component/cards/MainCard';
import { useTheme } from '@mui/material/styles';
import QueueMetricsGroup from '../QueueMetricsGroup/QueueMetricsGroup';
import { isEqual } from 'lodash';

const generateLayoutObject = (widgets, isInEditMode, savingDashboard) => {
    const layoutObject = {
        sm: [],
        md: [],
        lg: []
    };

    widgets.forEach((widget) => {
        Object.keys(layoutObject).forEach((item) => {
            let layout;

            if (typeof widget.layout[item] === 'object') {
                layout = widget.layout[item];
            } else {
                layout = { x: 0, y: 0, h: 2, w: 2 };
            }

            layoutObject[item].push({
                ...layout,
                i: widget.id.toString(),
                isDraggable: isInEditMode && !savingDashboard,
                isResizable: isInEditMode && !savingDashboard
            });
        });
    });

    return layoutObject;
};

function MainWidgetGrid({ size: { width } }) {
    const { widgets, discardFallbackWidgets, breakpoint, isInEditMode, savingDashboard } = useContext(DashboardStateContext);
    const { dispatchDashboardStateAction } = useContext(DashboardStateDispatchContext);
    const theme = useTheme();

    const currentLayout = useMemo(
        () => generateLayoutObject(widgets, isInEditMode, savingDashboard),
        [widgets, isInEditMode, savingDashboard]
    );

    useLayoutEffect(() => {
        let bp;
        if (width >= 1200) {
            bp = 'lg';
        }
        if (width >= 996 && width < 1200) {
            bp = 'md';
        }
        if (width >= 768 && width < 996) {
            bp = 'sm';
        }
        if (width >= 480 && width < 768) {
            bp = 'xs';
        }
        if (width < 480) {
            bp = 'xxs';
        }
        if (breakpoint !== bp) {
            dispatchDashboardStateAction({ type: DashboardStateActions.SetCurrentBreakpoint, payload: bp });
        }
    }, [width]);

    const generateWidgetCard = (widget) => {
        let widgetToCreate;

        switch (widget.type) {
            case WidgetTypes.QueueMetrics:
                widgetToCreate = <QueueMetricsGroup widget={widget} />;
                break;
            case WidgetTypes.AgentTable:
                widgetToCreate = <AgentMetricsTable widget={widget} />;
                break;
            case WidgetTypes.TimeSeries:
                widgetToCreate = <TimeSeriesWidget widget={widget} />;
                break;
            case WidgetTypes.DonutOrPieChart:
            default:
                widgetToCreate = <DonutChartWidget widget={widget} />;
        }

        return (
            <MainCard content={false} boxShadow={isInEditMode} key={widget.id} id="box" shadow={theme.shadows[16]}>
                {widgetToCreate}
            </MainCard>
        );
    };

    const onLayoutChange = (_, layouts) => {
        widgets.forEach((widget) => {
            // eslint-disable-next-line
            const updatedLayoutObject = layouts[breakpoint].find((layout) => layout.i == widget.id);
            const { x, y, w, h } = updatedLayoutObject;
            widget.layout[breakpoint] = { x, y, w, h };
        });

        dispatchDashboardStateAction({
            type: DashboardStateActions.SetWidgetsList,
            payload: widgets
        });

        if (isInEditMode) {
            // Note: Comparing the whole widget object as both layout and previous unsaved changes must be taken into account
            dispatchDashboardStateAction({
                type: DashboardStateActions.SetHasUnsavedChanges,
                payload: !isEqual(widgets, discardFallbackWidgets)
            });
        }
    };

    return (
        <div style={{ paddingBottom: 100 }}>
            {!isInEditMode && !widgets.length ? (
                <div className="no-widgets-view">Edit Dashboard to add new widgets</div>
            ) : (
                <ResponsiveGridLayout
                    cols={{ lg: 6, md: 4, sm: 3, xs: 2, xxs: 1 }}
                    layouts={currentLayout}
                    breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                    onLayoutChange={onLayoutChange}
                    rowHeight={140}
                    margin={[15, 15]}
                    containerPadding={[0, 0]}
                    draggableHandle="#box"
                    width={width}
                    compactType="vertical"
                >
                    {widgets.map(generateWidgetCard)}
                </ResponsiveGridLayout>
            )}
        </div>
    );
}

export default withSize({ refreshRate: 60 })(MainWidgetGrid);
