import { createSlice } from '@reduxjs/toolkit';

import axios from 'utils/axios';

export const initialState = {
    remoteCollections: [],
    newRemoteCollectionId: -1,
    currentRemoteCollection: null,
    isRemoteCollectionsLoading: true,
    deleteSuccess: false,
    RCFailure: null
};

const slice = createSlice({
    name: 'remoteCollection',
    initialState,
    reducers: {
        createRCSuccess(state, action) {
            // state.newRemoteCollectionId = action.payload;
            state.remoteCollections = [
                ...state.remoteCollections,
                { id: action.payload.id, remoteName: action.payload.remoteName, contactInfo: action.payload.contactInfo }
            ];
            state.newRemoteCollectionId = action.payload.id;
        },
        RCFailure(state, action) {
            state.RCFailure = action.payload;
            state.isRemoteCollectionsLoading = false;
        },
        getRemoteCollectionsSuccess(state, action) {
            state.remoteCollections = action.payload;
            state.isRemoteCollectionsLoading = false;
        },
        setCurrentRemoteCollection(state, action) {
            state.currentRemoteCollection = {
                ...state.currentRemoteCollection,
                ...action.payload
            };
        },
        resetIsRemoteCollectionsLoading(state, action) {
            state.isRemoteCollectionsLoading = true;
        },
        resetCurrentRemoteCollection(state, action) {
            state.currentRemoteCollection = null;
        },
        saveRemoteCollectionSuccess(state, action) {
            const index = state.remoteCollections.findIndex((item) => item.id === action.payload.id);

            if (index !== -1) {
                // Edit: Update the existing item
                state.remoteCollections[index] = {
                    ...state.remoteCollections[index],
                    id: action.payload.id,
                    remoteName: action.payload.remoteName,
                    contactInfo: action.payload.contactInfo
                };
            }
            state.currentRemoteCollection = {
                ...state.currentRemoteCollection,
                ...action.payload
            };
        },
        deleteRCSuccess(state, action) {
            state.remoteCollections = state.remoteCollections.filter((RC) => RC.id !== action.payload);
            state.deleteSuccess = true;
        },
        resetDeleteSuccess(state, action) {
            state.deleteSuccess = false;
        }
    }
});

export default slice.reducer;

export function getRemoteCollections(enqueueSnackbar) {
    return async (dispatch) => {
        // call API to get list of all enabled remote collections
        const response = await axios.get(`/connectors/remote-collections`).catch((error) => {
            if (error.response) {
                console.log(`${error.response.status} - ${error.response.data}`);
            } else if (error.request) {
                console.log(`${error.request}`);
            } else {
                console.log(`Error - ${error.message}`);
            }
        });
        if (!response?.data?.success) {
            dispatch(slice.actions.RCFailure(response.data.message));
            enqueueSnackbar(response.data.message, { variant: 'error' });
        }
        if (response?.data?.success && response?.data.data) {
            dispatch(slice.actions.getRemoteCollectionsSuccess(response.data.data));
        }
    };
}

export function saveRemoteCollection(enqueueSnackbar, configObj, isEdit = false) {
    return async (dispatch) => {
        // create a new remote collection
        if (isEdit) {
            const response = await axios.put(`/connectors/remote-collections/${configObj.id}`, configObj).catch((error) => {
                if (error.response) {
                    console.log(`${error.response.status} - ${error.response.data}`);
                } else if (error.request) {
                    console.log(`${error.request}`);
                } else {
                    console.log(`Error - ${error.message}`);
                }
            });
            if (!response?.data?.success) {
                dispatch(slice.actions.RCFailure(response.data.message));
                enqueueSnackbar(`Save Failed : ${response.data.message}`, { variant: 'error' });
            }
            if (response?.data?.success && response?.data?.data) {
                dispatch(slice.actions.saveRemoteCollectionSuccess(response.data.data));
            }
        } else {
            const { remoteName, contactInfo, comments } = configObj;
            const response = await axios.post(`/connectors/remote-collections`, { remoteName, contactInfo, comments }).catch((error) => {
                if (error.response) {
                    console.log(`${error.response.status} - ${error.response.data}`);
                } else if (error.request) {
                    console.log(`${error.request}`);
                } else {
                    console.log(`Error - ${error.message}`);
                }
            });
            if (!response?.data?.success) {
                dispatch(slice.actions.RCFailure(response.data.message));
                enqueueSnackbar(`Cloud Connect server creation Failed: ${response.data.message}`, { variant: 'error' });
            }
            // directly update remote collections
            if (response?.data?.success && response?.data?.data) {
                dispatch(slice.actions.createRCSuccess({ id: response.data.data, remoteName, contactInfo }));
            }
        }
    };
}

export function loadCurrentRemoteCollection(id = null, isEdit = false) {
    return async (dispatch) => {
        if (isEdit) {
            const response = await axios.get(`/connectors/remote-collections/${id}`);
            if (response?.data?.data) {
                dispatch(slice.actions.setCurrentRemoteCollection(response.data.data));
            }
        } else {
            const response = await axios.get(`/connectors/remote-collections/template`);
            if (response?.data) {
                dispatch(slice.actions.setCurrentRemoteCollection(response.data));
            }
        }
    };
}

export function resetCurrentRemoteCollection() {
    return async (dispatch) => {
        dispatch(slice.actions.resetCurrentRemoteCollection());
    };
}

export function resetIsRemoteCollectionsLoading() {
    return async (dispatch) => {
        dispatch(slice.actions.resetIsRemoteCollectionsLoading());
    };
}

export function resetDeleteSuccess() {
    return async (dispatch) => {
        dispatch(slice.actions.resetDeleteSuccess());
    };
}

export function deleteRemoteCollection(RCId) {
    return async (dispatch) => {
        const response = await axios.delete(`/connectors/remote-collections/${RCId}?validate=false`).catch((error) => {
            if (error.response) {
                console.log(`${error.response.status} - ${error.response.data}`);
            } else if (error.request) {
                console.log(`${error.request}`);
            } else {
                console.log(`Error - ${error.message}`);
            }
        });
        if (response?.data?.data) {
            dispatch(slice.actions.deleteRCSuccess(RCId));
        }
    };
}

export const { createRCSuccess, getRemoteCollectionsSuccess, setCurrentRemoteCollection, saveRemoteCollectionSuccess, deleteRCSuccess } =
    slice.actions;
