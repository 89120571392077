import React from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Stack } from '@mui/material';

// project imports
import { getWeekRange } from 'utils/format';
import { graphColors } from 'store/constant';
import { IconCircle } from '@tabler/icons';

const CustomTooltip = ({ active, payload, label, colors, setActiveHoverPoint, valueFormatter }) => {
    const theme = useTheme();
    const genericColors = theme.palette.mode === 'dark' ? graphColors.dark : graphColors.light;
    if (active && payload && payload.length) {
        if (setActiveHoverPoint) setActiveHoverPoint(payload);
        return (
            <div
                className="custom-tooltip"
                style={{
                    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.background.paper,
                    color: theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.grey[700],
                    padding: '5px',
                    border: '1px solid rgb(204, 204, 204)'
                }}
            >
                <p className="label" style={{ margin: '5px', padding: '0px' }}>{`${label}`}</p>
                {payload.length < 5 ? (
                    <Stack direction="column" spacing={1}>
                        {payload.map((itm, index) => (
                            <p
                                key={itm.name}
                                style={{
                                    margin: '5px',
                                    padding: '0px'
                                }}
                            >
                                <IconCircle
                                    style={{
                                        color: colors[itm.name] || colors[index] || genericColors[index],
                                        fill: colors[itm.name] || colors[index] || genericColors[index],
                                        marginRight: 5
                                    }}
                                    size={10}
                                />
                                {`${itm.name} : ${valueFormatter ? valueFormatter(itm.value) : itm.value}`}
                            </p>
                        ))}
                    </Stack>
                ) : (
                    <Stack direction="column">
                        {Object.values(
                            payload.reduce((acc, currItm, index) => {
                                if (index === 0 || index % 2 === 0) {
                                    acc[index] = acc[index] || [{ name: currItm.name, value: currItm.value, idx: index }];
                                } else {
                                    acc[index - 1][1] = { name: currItm.name, value: currItm.value, idx: index };
                                }
                                return acc;
                            }, {})
                        ).map((group, idx) => (
                            <Stack key={idx} direction="row">
                                {group.map((itm, childIndex) => (
                                    <p
                                        key={itm.name}
                                        style={{
                                            margin: '5px',
                                            padding: '0px'
                                        }}
                                    >
                                        <IconCircle
                                            style={{
                                                color: colors[itm.name] || colors[itm.idx] || genericColors[itm.idx],
                                                fill: colors[itm.name] || colors[itm.idx] || genericColors[itm.idx],
                                                marginRight: 5
                                            }}
                                            size={10}
                                        />
                                        {`${itm.name} : ${itm.value}`}
                                    </p>
                                ))}
                            </Stack>
                        ))}
                    </Stack>
                )}
            </div>
        );
    }
    return null;
};

export default CustomTooltip;
