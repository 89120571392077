import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    azureADTenantId: null,
    loading: false,
    queues: [],
    connectorLicensedQueueCount: 0
};

export const CallQueueStatus = {
    Licensed: 'Licensed',
    Unlicensed: 'Unlicensed',
    Removed: 'Removed'
};

// CQSN => CallQueueStatusNumber - This is used for the internal queues logic
export const CQSN = {
    Licensed: 1,
    Unlicensed: 2,
    Removed: 3,
    Unknown: 4
};

const callQueues = createSlice({
    name: 'callQueues',
    initialState,
    reducers: {
        setDataLoading(state) {
            state.loading = true;
        },
        resetDataLoading(state) {
            state.loading = false;
        },
        setConnectionInfo(state, action) {
            state.azureADTenantId = action.payload.azureADTenantId;
            state.queues = [];
        },
        loadCallQueuesSuccess(state, action) {
            const queues = action.payload;

            let licensedQueueCount = 0;
            // NOTE: Backend will return "upns" field with a list of upn strings
            // Need to assign that appended value to "upn" field
            queues.forEach((q) => {
                q.upn = q.upns && Array.isArray(q.upns) ? q.upns.join('<br/>') : q.upns;
                q.lastActive = q.lastActive || '';
                if (CallQueueStatus.Licensed.localeCompare(q.status, undefined, { sensitivity: 'base' }) === 0) {
                    q.statusNum = CQSN.Licensed;
                    licensedQueueCount += 1;
                } else if (CallQueueStatus.Unlicensed.localeCompare(q.status, undefined, { sensitivity: 'base' }) === 0) {
                    q.statusNum = CQSN.Unlicensed;
                } else if (CallQueueStatus.Removed.localeCompare(q.status, undefined, { sensitivity: 'base' }) === 0) {
                    q.statusNum = CQSN.Removed;
                } else {
                    q.statusNum = CQSN.Unknown;
                }
            });

            state.queues = queues;
            state.connectorLicensedQueueCount = licensedQueueCount;
        }
    }
});

export default callQueues.reducer;

export const { setDataLoading, resetDataLoading, setConnectionInfo, loadCallQueuesSuccess } = callQueues.actions;
