/**
 * axios setup to use api service
 */

import axios from 'axios';
import { VERSION } from 'config';
import { constant } from 'lodash';

const baseUrl =
    process.env.NODE_ENV === 'development'
        ? `${process.env.REACT_APP_BASE_URL_DEV}/v${VERSION}`
        : `${process.env.REACT_APP_API_BASE_URL}/v${VERSION}`;

const axiosServices = axios.create({
    baseURL: baseUrl,
    headers: {
        common: {
            'Subscription-Key': process.env.REACT_APP_API_SUBSCRIPTION
        }
    },
    cancelToken: axios.CancelToken.source().token
});

export const getCancelTokenSource = () => axios.CancelToken.source();

export const axiosCustom = axios.create({
    baseURL: baseUrl,
    headers: {
        common: {
            'Subscription-Key': process.env.REACT_APP_API_SUBSCRIPTION
        }
    }
});

export const axiosReportService = axios.create({
    baseURL: process.env.REACT_APP_REPORT_API_BASE_URL,
    headers: {
        common: {
            'Ocp-Apim-Subscription-Key': process.env.REACT_APP_REPORT_API_SUBSCRIPTION
        }
    }
});

export const axiosNotificationService = axios.create({
    baseURL: process.env.REACT_APP_NOTIFICATION_API_BASE_URL,
    headers: {
        common: {
            'Ocp-Apim-Subscription-Key': process.env.REACT_APP_REPORT_API_SUBSCRIPTION
        }
    }
});

export const axiosMSTeamsCQService = axios.create({
    baseURL: process.env.REACT_APP_MSTEAMS_CQ_SERVICE_URL,
    headers: {
        common: {
            'Ocp-Apim-Subscription-Key': process.env.REACT_APP_MSTEAMS_CQ_API_SUBSCRIPTION
        }
    }
});

export const axiosUserDataService = axios.create({
    baseURL: process.env.REACT_APP_USER_DATA_SERVICE_URL,
    headers: {
        common: {
            'Ocp-Apim-Subscription-Key': process.env.REACT_APP_USER_DATA_API_SUBSCRIPTION
        }
    }
});

export const axiosRemoteApiService = axios.create({
    baseURL: `${process.env.REACT_APP_REMOTE_API_URL}`
});

// export const axiosPrivate = axios.create({
//     baseURL: baseUrl,
//     headers: {
//         common: {
//             'Subscription-Key': process.env.REACT_APP_API_SUBSCRIPTION
//         }
//     }
//     // withCredentials: true;
// });

// if (process.env.NODE_ENV === 'development') {
//     axiosServices.defaults.baseURL = `${process.env.REACT_APP_BASE_URL_DEV}/v${VERSION}`;
// } else {
//     axiosServices.defaults.baseURL = `${process.env.REACT_APP_API_BASE_URL}/v${VERSION}`;
// }

// axiosServices.defaults.headers.common['Subscription-Key'] = process.env.REACT_APP_API_SUBSCRIPTION;

// interceptor for http
axiosServices.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error?.response?.status === 401) {
            console.log('401 error');
            // if unauthorized request, log the user out
            window.location.href = '/logout';
            return '';
        }
        return Promise.reject((error.response && error.response.data) || 'Wrong Services');
    }
);

axiosCustom.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject((error.response && error.response.data) || 'Wrong Services')
);

const uuidv4 = () => {
    return '10000000-1000-4000-8000-100000000000'.replace(
        /[018]/g,
        (c) => (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16) // eslint-disable-line no-bitwise
    );
};
axiosReportService.interceptors.request.use((config) => {
    config.headers['X-Trace-Id'] = uuidv4();
    return config;
});

axiosNotificationService.interceptors.request.use((config) => {
    config.headers['X-Trace-Id'] = uuidv4();
    return config;
});

export default axiosServices;
