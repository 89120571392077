import { useRoutes } from 'react-router-dom';

// routes
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import RcRegistrationRoutes from './RcRegistrationRoutes';
import NotFound from '../views/pages/NotFound';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([
        LoginRoutes,
        MainRoutes,
        RcRegistrationRoutes,
        {
            path: '*',
            element: <NotFound />
        }
    ]);
}
