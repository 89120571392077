// material-ui
import { Link, Typography, Stack } from '@mui/material';

// ==============================|| FOOTER - AUTHENTICATION 2 & 3 ||============================== //

const AuthFooter = () => (
    <Stack direction="row" justifyContent="center">
        <Typography variant="subtitle2" sx={{ pr: 4 }}>
            &copy; {new Date().getFullYear()}, ISI Telemanagement Solutions LLC
        </Typography>
        <Typography variant="subtitle2" component={Link} href="https://www.isi-info.com/privacy-policy " target="_blank" underline="hover">
            Privacy Policy
        </Typography>
    </Stack>
);

export default AuthFooter;
