import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';
import { DASHBOARD_PATH } from 'config';
import { useSelector } from 'store';

// ==============================|| GUEST GUARD ||============================== //

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */

const GuestGuard = ({ children }) => {
    const { isLoggedIn } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const { defaultDashboard } = useSelector((state) => state.dashboard);

    useEffect(() => {
        const ccRegistration = sessionStorage.getItem('ccregistration');
        if (isLoggedIn && !ccRegistration) {
            navigate(DASHBOARD_PATH, { replace: true });
        }
        if (isLoggedIn && ccRegistration) {
            // navigate to CC register screen
            navigate(`/register/cloud-connect`, { replace: true });
        }
    }, [isLoggedIn, navigate, defaultDashboard]);

    return children;
};

GuestGuard.propTypes = {
    children: PropTypes.node
};

export default GuestGuard;
