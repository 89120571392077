import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    title: null,
    parentTitles: []
};

// ==============================|| SLICE - BREADCRUMB ||============================== //

const menu = createSlice({
    name: 'breadcrumb',
    initialState,
    reducers: {
        setTitle(state, action) {
            state.title = action.payload;

            // Reset the parent Title array if the title was set by a menu item or a menu sub item
            if (state.parentTitles.length > 0) {
                state.parentTitles = [];
            }
        },
        // To be used when there is a nesting and the breadcrumb menu will be using a custom title
        // which cannot be retrieved from the path / search parameters. Like in case of connectors
        // In that case, the path parameter is the connector Id but the title is the Connector name.. So, for the
        // child pages of connectors, like queues, that connector name needs to be preserved
        // Expected payload: {
        //      index: index of the child, relative to the first sub item in the menu. Like in connectors menu,
        //               a connector is the first sub item and it's child queues will have index 0
        //      title: title of the child
        //  }
        setChildTitle(state, action) {
            // Remove all the parent titles after this index and add the current title as the parent title
            const childIndex = action.payload.index === undefined ? -1 : 0;
            if (childIndex >= 0) {
                if (childIndex < state.parentTitles.length) {
                    // Only keep the old titles before the current index
                    state.parentTitles = state.parentTitles.toSpliced(childIndex);
                } else {
                    // Keep the current parent titles and also add the current title as the parent title
                    state.parentTitles = [...state.parentTitles, state.title];
                }

                state.title = action.payload.title;
            } else {
                console.log('Invalid value for the child title index');
            }
        }
    }
});

export default menu.reducer;

export const { setTitle, setChildTitle } = menu.actions;
