import React, { useState, useContext, useMemo, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

import { Status, StatusColor } from '../constants';
import { DashboardStateContext } from '../context/DashboardStateContext';
import EditAgentMetricsTableDialog from './EditAgentMetricsTableDialog';
import AgentTableNameColumnHeader from './constants';
import EditWidgetMenu from '../EditWidgetMenu/EditWidgetMenu';
import { getRefreshingWidgetDataAnimateIcon } from '../Utils/queueDashboardUtils';
import { useTheme } from '@emotion/react';
import { convertToSeconds } from 'utils/QueueConfigurationFormatAndValidation';
import MetricDetailsDialog from '../MetricDetailsDialog/MetricDetailsDialog';
import { useSelector } from 'store';
import { visuallyHidden } from '@mui/utils';
import { Box } from '@mui/material';

export default function AgentMetricsTable({ widget }) {
    const [isEditTableDialogOpen, setIsEditTableDialogOpen] = useState(false);
    const [detailsOpen, setDetailsOpen] = React.useState(false);
    const [selectedMetric, setSelectedMetric] = React.useState(null);
    const [selectedAgent, setSelectedAgent] = React.useState(null);
    const [agentNameClick, setAgentNameClick] = React.useState(false);
    const theme = useTheme();
    const { allowedAgentTableWidgetConfig } = useSelector((state) => state.queueDashboard);
    const [sortDetail] = widget.config?.sortOrder !== undefined ? widget.config?.sortOrder : [{}];
    const [sortOrder, setSortOrder] = useState(sortDetail);

    const apiUrl = '/api/DetailedDataAnalytics/getAgentDataDetailsForMetric';
    const defaultCellBgColor = theme.palette.mode === 'dark' ? '#212946' : '#ffffff';
    const defaultCellFontColor = theme.palette.mode === 'dark' ? '#a0a9c0' : '#5c6e83';
    const allowedMetrics = allowedAgentTableWidgetConfig?.allowedMetrics;
    const { agentMetrics, isInEditMode, savingDashboard, loadingQueueAndAgentMetrics, refreshingQueueAndAgentMetrics } =
        useContext(DashboardStateContext);

    const toggleEditTableDialog = () => {
        setIsEditTableDialogOpen((open) => !open);
    };

    useEffect(() => {
        // set the sort order when sort is updated from the edit agent metrics dialog
        const [updatedSortDetail] = widget.config?.sortOrder !== undefined ? widget.config?.sortOrder : [{}];
        setSortOrder(updatedSortDetail);
    }, [widget.config?.sortOrder]);

    const getSortOrder = () => (sortOrder.ascending ? 'asc' : 'desc');
    const sortedAgentMetricsArray = useMemo(() => {
        const sortByColumnName = sortOrder?.columnName;
        const sortBy = widget.metrics.find((item) => item.displayAs === sortByColumnName);

        if (sortByColumnName === AgentTableNameColumnHeader || !sortBy) {
            const sortedAgentMetrics = agentMetrics.toSorted((itemA, itemB) => {
                if (itemA.name < itemB.name) return -1;
                if (itemA.name > itemB.name) return 1;
                return 0;
            });
            return sortOrder?.ascending ? sortedAgentMetrics : sortedAgentMetrics.reverse();
        }
        const sortByColumnMetric = sortBy?.metric;
        const sortByColumnCalculationType = sortBy?.calculationType;

        const sortedAgentMetrics = agentMetrics.sort((itemA, itemB) => {
            const metricValueA = itemA.metrics[sortByColumnMetric][sortByColumnCalculationType]?.value;
            const metricValueB = itemB.metrics[sortByColumnMetric][sortByColumnCalculationType]?.value;
            if (metricValueA.includes(':') && metricValueB.includes(':')) {
                return convertToSeconds(metricValueA) - convertToSeconds(metricValueB);
            }
            return parseFloat(metricValueA, 10) - parseFloat(metricValueB, 10);
        });

        return sortOrder?.ascending ? sortedAgentMetrics : sortedAgentMetrics.reverse();
    }, [agentMetrics, sortOrder?.columnName, sortOrder?.ascending]);

    const handleDetailsDialogClose = () => {
        setDetailsOpen(false);
    };

    const handleAgentCellClick = (isAgentName, agent, data) => {
        setSelectedAgent(agent);
        setSelectedMetric(data);
        setAgentNameClick(isAgentName);
        setDetailsOpen(true);
    };

    const handleLiveSort = (columnName, event) => {
        const isAsc = sortOrder.columnName === columnName && sortOrder?.ascending === true;
        setSortOrder({
            columnName,
            ascending: !isAsc
        });
    };
    return (
        <>
            <div className="table-widget-header-container" style={theme.palette.mode === 'dark' ? { border: 'none' } : null}>
                <p className="table-widget-title" style={theme.palette.mode === 'dark' ? { color: '#D7E6EC' } : null}>
                    Agents
                </p>
                {!isInEditMode && refreshingQueueAndAgentMetrics && getRefreshingWidgetDataAnimateIcon(widget.id)}
                {isInEditMode && !savingDashboard && <EditWidgetMenu onEditClick={toggleEditTableDialog} widgetId={widget.id} />}
                {isEditTableDialogOpen && (
                    <EditAgentMetricsTableDialog
                        columns={widget.metrics}
                        widgetId={widget.id}
                        config={widget.config}
                        isEditTableDialogOpen={isEditTableDialogOpen}
                        toggleEditTableDialog={toggleEditTableDialog}
                    />
                )}
            </div>
            {loadingQueueAndAgentMetrics ? (
                <div className="loading-data-view">Loading data...</div>
            ) : (
                /** Use the parent widget container's full height for this table */
                <TableContainer sx={{ borderRadius: 0, maxHeight: '100%' }}>
                    <Table
                        stickyHeader
                        sx={{
                            minWidth: 650,
                            borderCollapse: 'collapse',
                            '& .MuiTableCell-root': {
                                border: '1px solid rgba(97, 97, 97, 0.1)'
                            }
                        }}
                        aria-label="agents table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    align="left"
                                    key="name"
                                    sortDirection={sortOrder.columnName === AgentTableNameColumnHeader ? getSortOrder() : 'asc'}
                                >
                                    <TableSortLabel
                                        active={sortOrder.columnName === AgentTableNameColumnHeader}
                                        direction={sortOrder.columnName === AgentTableNameColumnHeader ? getSortOrder() : 'asc'}
                                        onClick={(e) => handleLiveSort(AgentTableNameColumnHeader, e)}
                                    >
                                        {AgentTableNameColumnHeader}
                                        {sortOrder.columnName === AgentTableNameColumnHeader ? (
                                            <Box component="span" sx={visuallyHidden} />
                                        ) : null}
                                    </TableSortLabel>
                                </TableCell>
                                {widget.metrics.map((col) => (
                                    <TableCell
                                        align="left"
                                        key={`agent-table-col-header-${col.metric}-${col.calculationType}`}
                                        sortDirection={sortOrder.columnName === col.displayAs ? getSortOrder() : 'asc'}
                                    >
                                        <TableSortLabel
                                            active={sortOrder.columnName === col.displayAs}
                                            direction={sortOrder.columnName === col.displayAs ? getSortOrder() : 'asc'}
                                            onClick={(e) => handleLiveSort(col.displayAs, e)}
                                        >
                                            {col.displayAs}
                                            {sortOrder.columnName === col.displayAs ? <Box component="span" sx={visuallyHidden} /> : null}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedAgentMetricsArray.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell
                                        onClick={() => handleAgentCellClick(true, row)}
                                        align="left"
                                        sx={{ '&:hover ': { background: theme.palette.secondary.light }, cursor: 'pointer' }}
                                    >
                                        {row.name?.length ? row.name : 'N.A.'}
                                    </TableCell>
                                    {widget.metrics.map((col) => (
                                        <TableCell
                                            onClick={
                                                allowedMetrics && allowedMetrics[col.metric]?.isDrilldownAllowed
                                                    ? () => handleAgentCellClick(false, row, col)
                                                    : null
                                            }
                                            key={`agent-table-col-${col.metric}-${col.calculationType}`}
                                            align="left"
                                            sx={{
                                                backgroundColor:
                                                    row.metrics[col.metric]?.[col.calculationType]?.status === Status.Normal
                                                        ? defaultCellBgColor
                                                        : StatusColor[row.metrics[col.metric]?.[col.calculationType]?.status],
                                                color:
                                                    row.metrics[col.metric]?.[col.calculationType]?.status === Status.Normal
                                                        ? defaultCellFontColor
                                                        : '#000000',
                                                '&:hover ':
                                                    allowedMetrics && allowedMetrics[col.metric]?.isDrilldownAllowed
                                                        ? { background: theme.palette.secondary.light }
                                                        : null,
                                                cursor:
                                                    allowedMetrics && allowedMetrics[col.metric]?.isDrilldownAllowed ? 'pointer' : 'auto'
                                            }}
                                        >
                                            {row.metrics[col.metric]?.[col.calculationType]?.value ?? 0}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            {detailsOpen && (
                <MetricDetailsDialog
                    open={detailsOpen}
                    handleClose={handleDetailsDialogClose}
                    metricFilter={selectedMetric?.metric}
                    agentId={selectedAgent?.id}
                    apiUrl={apiUrl}
                    title={
                        agentNameClick
                            ? selectedAgent?.name?.concat(' : ').concat('All Calls')
                            : selectedAgent?.name?.concat(' : ').concat(selectedMetric?.displayAs)
                    }
                    dataSize={
                        agentNameClick
                            ? selectedAgent?.metrics?.presentedCalls?.count?.value ?? 0 // for agent name click presented calls number is considered as total number
                            : selectedAgent?.metrics[selectedMetric?.metric]?.count?.value ?? 0
                    }
                />
            )}
        </>
    );
}
