import React, { useContext, useEffect, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { DashboardStateActions, DashboardStateDispatchContext } from '../context/DashboardStateContext';
import MetricsSubsetGrid from '../MetricsSubsetGrid/MetricsSubsetGrid';
import { useSelector } from 'store';

export default function EditQueueMetricsGroupDialog({ isEditQueueMetricsDialogOpen, toggleEditQueueMetricsDialog, widget }) {
    const { allowedQueueMetricsWidgetConfig } = useSelector((state) => state.queueDashboard);
    const { dispatchDashboardStateAction } = useContext(DashboardStateDispatchContext);

    const [metricsConfig, setMetricsConfig] = useState(widget.metrics);
    const [maxMetricsPerRow, setMaxMetricsPerRow] = useState(widget.config?.maxMetricsPerRow ?? 5);
    const [metricsPerRowError, setMetricPerRowError] = useState(null);
    const [metricSubsetError, setMetricSubsetError] = useState(false);

    const saveFieldsConfig = () => {
        if (maxMetricsPerRow < 1 || maxMetricsPerRow > 6) {
            return;
        }

        dispatchDashboardStateAction({
            type: DashboardStateActions.UpdateWidget,
            payload: {
                widgetId: widget.id,
                metrics: metricsConfig.map((metric, index) => ({ ...metric, displayOrder: index })),
                config: { ...widget.config, maxMetricsPerRow: parseInt(maxMetricsPerRow, 10) }
            }
        });
        dispatchDashboardStateAction({ type: DashboardStateActions.SetHasUnsavedChanges, payload: true });

        toggleEditQueueMetricsDialog();
    };

    const validateMetricPerRow = (event) => {
        const value = Number(event.target.value);
        if (!event.target.value) {
            setMetricPerRowError('Max Metrics Per Row is required');
        } else if (value < 1 || value > 6) {
            setMetricPerRowError('Max Metrics Per Row value should be between 1 and 6');
        } else {
            setMetricPerRowError(null);
        }
    };

    return (
        <Dialog fullWidth open={isEditQueueMetricsDialogOpen}>
            <div className="edit-queue-metrics-dialog-container">
                <div className="edit-queue-metrics-dialog-header">
                    <DialogTitle sx={{ padding: 0, paddingLeft: 3 }}>Edit Widget</DialogTitle>
                    <IconButton aria-label="close" onClick={toggleEditQueueMetricsDialog}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <div className="edit-queue-metrics-dialog-content">
                    <TextField
                        sx={{ width: '25%', marginBottom: 4 }}
                        type="number"
                        label="Max Metrics Per Row"
                        variant="outlined"
                        value={maxMetricsPerRow}
                        onChange={(e) => {
                            setMaxMetricsPerRow(e.target.value);
                            validateMetricPerRow(e);
                        }}
                        error={metricsPerRowError !== null}
                        helperText={metricsPerRowError !== null ? metricsPerRowError : ''}
                    />
                    <MetricsSubsetGrid
                        fieldsConfig={metricsConfig}
                        setFieldsConfig={setMetricsConfig}
                        allowedMetricsConfig={allowedQueueMetricsWidgetConfig?.allowedMetrics ?? {}}
                        isReorderable
                        setHasError={setMetricSubsetError}
                    />
                </div>
                <Stack spacing={2} direction="row" justifyContent="flex-end">
                    <Button variant="text" color="error" onClick={toggleEditQueueMetricsDialog}>
                        Cancel
                    </Button>
                    <Button disabled={metricsPerRowError || metricSubsetError} variant="contained" onClick={saveFieldsConfig}>
                        Save
                    </Button>
                </Stack>
            </div>
        </Dialog>
    );
}
