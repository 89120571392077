import { useTheme } from '@emotion/react';
import { CardContent, Grid, Typography } from '@mui/material';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Area, Bar, Line, Tooltip } from 'recharts';
import { graphColors } from 'store/constant';
import BasicAreaChart from 'views/dashboard/BasicAreaChart';
import BasicLineChart from 'views/dashboard/BasicLineChart';
import CustomTooltip from 'views/dashboard/CustomTooltip';
import HorizontalStackedBarChart from 'views/dashboard/HorizontalStackedBarChart';
import SimpleBarChart from 'views/dashboard/SimpleBarChart';
import {
    getMappedColorsForQueueDashboardWidgets,
    QueueDashboardEvents,
    TimeSeriesGroupTimeByOptions,
    TimeSeriesMetricsCategory,
    TimeSeriesWidgetChartTypes
} from '../constants';
import { DashboardStateContext } from '../context/DashboardStateContext';
import EditWidgetMenu from '../EditWidgetMenu/EditWidgetMenu';
import { getGroupedByTimeMetrics } from '../Utils/queueDashboardApiCallsUtil';
import {
    getDateTimeWithDstAdjustment,
    getExitPreviewButton,
    getPreviousLevelButton,
    getRefreshingWidgetDataAnimateIcon,
    lengthOfTimeLabel
} from '../Utils/queueDashboardUtils';
import EditTimeSeriesWidgetDialog from './EditTimeSeriesWidgetDialog';
import MainCard from 'ui-component/cards/MainCard';
import { IconCircle } from '@tabler/icons';

function getQueueMetricDataKey(metric, calc) {
    return `${metric}:${calc}`;
}

function getAgentMetricDataKey(agentId, metric, calc) {
    return `${agentId}:${metric}:${calc}`;
}

function getYValueFromString(value) {
    if (value == null) {
        return 0;
    }

    if (typeof value !== 'string') {
        return value;
    }

    if (value.includes(':')) {
        // value is expected to be in this form: <hours>:<minutes>:<seconds>
        const time = value.split(':');
        return parseInt(time[0], 10) * 3600 + parseInt(time[1], 10) * 60 + parseInt(time[2], 10);
    }

    return parseInt(value, 10);
}

function getHHmmssStringFromSeconds(secs) {
    if (secs == null) {
        return '00:00:00';
    }

    return moment.utc(secs * 1000).format('HH:mm:ss');
}

export default function TimeSeriesWidget({ widget }) {
    const [isEditTimeSeriesDialogOpen, setIsEditTimeSeriesDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const {
        isInEditMode,
        savingDashboard,
        dataSource,
        selectedQueueId,
        dateTimeFilter: dashboardDateTimeFilter
    } = useContext(DashboardStateContext);
    const { enqueueSnackbar } = useSnackbar();
    const [needsRefresh, setNeedsRefresh] = useState(false);
    const [refreshing, setRefreshing] = useState(false);
    const [chartData, setChartData] = useState([]);
    const [chartDataComponents, setChartDataComponents] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);

    const getWidgetConfigObject = useCallback(
        (config) => ({
            ...config,
            drillDownMode: false,
            additionalDateTimeFilters: {},
            metrics: widget.metrics
        }),
        [widget.metrics]
    );
    const [previewWidgetConfig, setPreviewWidgetConfig] = useState(getWidgetConfigObject(widget.config));
    const previousConfigsRef = useRef([]);

    // Subscribe to queue dashboard events
    useEffect(() => {
        const handler = (e) => {
            setNeedsRefresh(true);
        };
        document.body.addEventListener(QueueDashboardEvents.RefreshWidgetData, handler);
        return () => {
            document.body.removeEventListener(QueueDashboardEvents.RefreshWidgetData, handler);
        };
    }, []);

    const theme = useTheme();

    const toggleEditTimeSeriesDialogOpen = () => {
        setIsEditTimeSeriesDialogOpen((open) => !open);
    };

    const dateTimeFilter = widget.config?.dateTimeFilter ?? dashboardDateTimeFilter;

    const loadTimeSeriesChartData = useCallback(
        async (
            inputDataSource,
            queueId,
            metricCategory,
            inputDateTimeFilter,
            inputMetrics,
            groupTimeBy,
            groupDataBy,
            additionalDateTimeFilters = {},
            isRefresh = false
        ) => {
            setErrorMessage(null);

            const data = await getGroupedByTimeMetrics(
                enqueueSnackbar,
                inputDataSource,
                queueId,
                metricCategory,
                getDateTimeWithDstAdjustment(inputDateTimeFilter),
                inputMetrics?.map((f) => ({
                    metric: f.metric,
                    calculationType: f.calculationType
                })),
                groupTimeBy,
                groupDataBy,
                additionalDateTimeFilters
            );
            // Extract and format data from the response
            if (data && data.queueCallCounts) {
                const xVals = Object.keys(data.queueCallCounts);
                if (!xVals || !xVals.length) {
                    // No data
                    setChartData([]);
                    setChartDataComponents([]);
                    return;
                }

                const fieldMap = [];
                const queueCallValues = Object.values(data.queueCallCounts);
                queueCallValues?.forEach((value) => {
                    const keys = Object.keys(value);
                    keys?.forEach((key) => {
                        if (fieldMap.indexOf(key) === -1) {
                            fieldMap.push(key);
                        }
                    });
                });

                const componentList = [];
                fieldMap?.forEach((k) => {
                    componentList.push({ metric: k, key: getQueueMetricDataKey(k, 'count'), label: k });
                });
                const formattedData = xVals.map((x) => {
                    const out = { xVal: x };
                    componentList.forEach((l) => {
                        out[l.key] = getYValueFromString(data.queueCallCounts[x][l.metric]);
                    });
                    return out;
                });
                setChartData(formattedData);
                setChartDataComponents(componentList);
            } else if (data && data.queueMetrics) {
                const xVals = Object.keys(data.queueMetrics);
                if (!xVals || !xVals.length) {
                    // No data
                    setChartData([]);
                    setChartDataComponents([]);
                    return;
                }

                // Will be non null when metric category for the widget is QueueCallTimes or QueueCallCount
                const fieldMap = inputMetrics.reduce((prev, curr) => {
                    if (!prev[curr.metric]) {
                        prev[curr.metric] = {};
                    }

                    prev[curr.metric][curr.calculationType] = {
                        label: curr.displayAs,
                        order: curr.displayOrder
                    };

                    return prev;
                }, {});

                const metrics = Object.keys(data.queueMetrics[xVals[0]]).map((k) => {
                    return {
                        name: k,
                        calculations: Object.keys(data.queueMetrics[xVals[0]][k])
                    };
                });
                // Build the list of components of the chart data
                const componentList = metrics.reduce((prev, m) => {
                    const lst = m.calculations.map((c) => ({
                        metric: m.name,
                        calculation: c,
                        key: getQueueMetricDataKey(m.name, c),
                        label: fieldMap[m.name]?.[c]?.label ?? ''
                    }));
                    return [...prev, ...lst];
                }, []);
                // Build the formatted data required for the charts
                const formattedData = xVals.map((x) => {
                    const out = { xVal: x };
                    componentList.forEach((l) => {
                        out[l.key] = getYValueFromString(data.queueMetrics[x][l.metric][l.calculation]);
                    });
                    return out;
                });
                setChartData(formattedData);
                setChartDataComponents(componentList);
            } else if (data && data.agentMetrics) {
                // Will be non null when metric category for the widget is AgentCallTimes or CallsAnsweredByAgent
                const xVals = Object.keys(data.agentMetrics);
                if (!xVals || !xVals.length) {
                    // No data
                    setChartData([]);
                    setChartDataComponents([]);
                    return;
                }

                // data.agentMetrics format
                // {
                //     interval1: {
                //         agentId1: {
                //             upn: "upn",
                //             name: "name",
                //             metrics: {
                //                 metric1: {
                //                     calculationType: "value"
                //                 }
                //             }
                //         },
                //         agentId2: ...
                //     },
                //     interval2: {
                //         agentId1: ...,
                //         agentId3: ...
                //     }
                // }
                // Need to create one component for each unique agent (identified by agentId)
                const uniqueAgentIds = new Set();
                const componentList = [];
                Object.keys(data.agentMetrics).forEach((interval) => {
                    Object.keys(data.agentMetrics[interval]).forEach((agentId) => {
                        if (uniqueAgentIds.has(agentId)) {
                            return;
                        }
                        uniqueAgentIds.add(agentId);
                        const metricsObject = data.agentMetrics[interval][agentId].metrics ?? {};
                        // Agents entry here would only have one metric & one calculation each
                        const metricName = Object.keys(metricsObject)[0] ?? 'metricName';
                        const calculation = Object.keys(metricsObject[metricName] ?? {})[0] ?? 'calculation';
                        const comp = {
                            agentId,
                            metric: metricName,
                            calculation,
                            key: getAgentMetricDataKey(agentId, metricName, calculation),
                            label: data.agentMetrics[interval][agentId].name
                        };

                        componentList.push(comp);
                    });
                });
                const formattedData = xVals.map((x) => {
                    const out = { xVal: x };
                    componentList.forEach((l) => {
                        out[l.key] = getYValueFromString(data.agentMetrics[x][l.agentId]?.metrics?.[l.metric]?.[l.calculation]);
                    });
                    return out;
                });
                setChartData(formattedData);
                setChartDataComponents(componentList);
            } else if (isRefresh) {
                // NOTE: Do not reset the data of the widget
                enqueueSnackbar(`Failed to refresh data for ${widget.name} widget`, { variant: 'error' });
            } else {
                setErrorMessage('Failed to load data');
                setChartData([]);
                setChartDataComponents([]);
            }
        },
        [widget.name]
    );

    const onWidgetConfigUpdate = (payload) => {
        setPreviewWidgetConfig(getWidgetConfigObject(payload.config));
        previousConfigsRef.current = [];
    };

    useEffect(() => {
        // Reset to original saved config and clear drilldown history when switching queues
        setPreviewWidgetConfig(getWidgetConfigObject(widget.config));
        previousConfigsRef.current = [];
    }, [selectedQueueId]);

    useEffect(() => {
        // Reset to original saved config and clear drilldown history when going out of edit mode
        if (!isInEditMode) {
            setPreviewWidgetConfig(getWidgetConfigObject(widget.config));
            previousConfigsRef.current = [];
        }
    }, [isInEditMode]);

    useEffect(() => {
        async function onLoad() {
            setIsLoading(true);
            await loadTimeSeriesChartData(
                dataSource,
                selectedQueueId,
                previewWidgetConfig.metricCategory,
                dateTimeFilter,
                previewWidgetConfig.metrics,
                previewWidgetConfig.groupTimeBy,
                previewWidgetConfig.groupDataBy,
                previewWidgetConfig.additionalDateTimeFilters
            );
            setIsLoading(false);
        }
        if (widget && widget.config && dateTimeFilter) {
            if (widget.config.metricCategory !== TimeSeriesMetricsCategory.QueueCallCounts) {
                if (widget.metrics === null || widget.metrics.length <= 0) {
                    return;
                }
            }
            if (
                (widget.config.metricCategory === TimeSeriesMetricsCategory.CallsAnsweredByAgent ||
                    widget.config.metricCategory === TimeSeriesMetricsCategory.AgentCallTimes) &&
                widget.metrics.length > 1
            ) {
                enqueueSnackbar(
                    `Invalid configuration for time series widget. Only 1 metric should be selected when widget type is ${widget.config.metricCategory}`,
                    { variant: 'error' }
                );
                return;
            }
            // Load the data from the API
            onLoad();
        }
        // TODO: This hook is being called even when queue list selector is opened / closed. This seems to be because
        //  Metrics and config are changing. Figure out why metrics and config are changing just by opening / closing queue selector
    }, [dataSource, dateTimeFilter, previewWidgetConfig, loadTimeSeriesChartData]);

    useEffect(() => {
        async function refreshData() {
            setRefreshing(true);
            await loadTimeSeriesChartData(
                dataSource,
                selectedQueueId,
                previewWidgetConfig.metricCategory,
                dateTimeFilter,
                previewWidgetConfig.metrics,
                previewWidgetConfig.groupTimeBy,
                previewWidgetConfig.groupDataBy,
                previewWidgetConfig.additionalDateTimeFilters,
                true
            );
            setRefreshing(false);
            setNeedsRefresh(false);
        }
        if (!isInEditMode && needsRefresh) {
            refreshData();
        }
    }, [needsRefresh, dataSource, dateTimeFilter, previewWidgetConfig, loadTimeSeriesChartData]);

    const drillLevelUp = () => {
        const previousConfig = previousConfigsRef.current.pop();
        setPreviewWidgetConfig(previousConfigsRef.current.length === 0 ? { ...previewWidgetConfig, drillDownMode: false } : previousConfig);
    };

    const getNextDrilldownLevelConfig = (name, configObj) => {
        const previousLevel = configObj.groupTimeBy === TimeSeriesGroupTimeByOptions.Weeks ? 'week' : 'date';
        let yAxisCaption = configObj.yAxisCaption;
        if (configObj.yAxisCaption.includes('calls')) {
            yAxisCaption = `# calls per ${previousLevel === 'week' ? 'day' : 'hour'}`;
        }
        const updatedConfigObj = {
            ...configObj,
            additionalDateTimeFilters: {
                ...configObj.additionalDateTimeFilters,
                [previousLevel]: name
            },
            groupTimeBy: previousLevel === 'week' ? TimeSeriesGroupTimeByOptions.Days : TimeSeriesGroupTimeByOptions.Hours,
            drillDownMode: true,
            yAxisCaption
        };
        return updatedConfigObj;
    };
    const handleDrillDown = async (name, configObj) => {
        if (configObj.groupTimeBy === TimeSeriesGroupTimeByOptions.Weeks || configObj.groupTimeBy === TimeSeriesGroupTimeByOptions.Days) {
            previousConfigsRef.current.push(configObj);
            setPreviewWidgetConfig(getNextDrilldownLevelConfig(name, configObj));
        }
    };

    const exitPreview = () => {
        const previousConfig = previousConfigsRef.current[0];
        setPreviewWidgetConfig({ ...previousConfig, drillDownMode: false });
        previousConfigsRef.current = [];
    };
    const showPointerCursor =
        previewWidgetConfig.groupTimeBy === TimeSeriesGroupTimeByOptions.Weeks ||
        previewWidgetConfig.groupTimeBy === TimeSeriesGroupTimeByOptions.Days;

    const generateToolTipProp = (...args) => {
        switch (widget.config.graphType) {
            case TimeSeriesWidgetChartTypes.VerticalBar:
            case TimeSeriesWidgetChartTypes.HorizontalBar: {
                const [colors] = args;
                return (
                    <Tooltip
                        offset={10}
                        cursor={{ fill: 'transparent' }}
                        content={
                            <CustomTooltip
                                colors={colors} // args[0] : themeColor
                                valueFormatter={(value) =>
                                    widget.config?.metricCategory?.toLowerCase()?.includes('time')
                                        ? getHHmmssStringFromSeconds(value)
                                        : value
                                }
                            />
                        }
                    />
                );
            }
            case TimeSeriesWidgetChartTypes.Area:
            default: {
                const [setActiveHoverPoint, colors] = args;
                return (
                    <Tooltip
                        offset={10}
                        cursor={{ fill: 'transparent' }}
                        content={
                            <CustomTooltip
                                valueFormatter={(value) =>
                                    previewWidgetConfig?.metricCategory?.toLowerCase()?.includes('time')
                                        ? getHHmmssStringFromSeconds(value)
                                        : value
                                }
                                colors={colors}
                                setActiveHoverPoint={setActiveHoverPoint}
                            />
                        }
                    />
                );
            }
        }
    };
    const genericGraphColors = theme.palette.mode === 'dark' ? graphColors.dark : graphColors.light;
    const generateGradients = (areas, colors) => {
        const gradientArr = [];
        areas.forEach((item, index) => {
            gradientArr.push(
                <linearGradient
                    key={`area-gradient-${colors[item.metric] ?? genericGraphColors[index]}`}
                    id={`color${index}`}
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                >
                    <stop offset="5%" stopColor={colors[item.metric] ?? genericGraphColors[index]} stopOpacity={0.8} />
                    <stop offset="95%" stopColor={colors[item.metric] ?? genericGraphColors[index]} stopOpacity={0} />
                </linearGradient>
            );
        });
        return gradientArr;
    };
    const generateChartGraphics = (...args) => {
        const [selectedData, colors, drillDownHandler, widgetInfo] = args;
        switch (widget.config.graphType) {
            case TimeSeriesWidgetChartTypes.VerticalBar:
                return chartDataComponents.map((comp, index) => (
                    <Bar
                        key={`bar-horizontal-${comp.key}`}
                        name={comp.label}
                        type="monotone"
                        stackId="a"
                        dataKey={comp.key}
                        hide={selectedData[comp.key]} // args[0]: Selected Data on chart
                        fill={colors[comp.metric] ?? genericGraphColors[index]} // args[1] : themeColor
                        onClick={(data) => drillDownHandler(data.xVal, widgetInfo)} // args[2]: drilldown function, args[3]: widgetCongig object
                        cursor={showPointerCursor ? 'pointer' : 'default'}
                    />
                ));
            case TimeSeriesWidgetChartTypes.HorizontalBar:
                return chartDataComponents.map((comp, index) => (
                    <Bar
                        key={`bar-horizontal-${comp.key}`}
                        name={comp.label}
                        type="monotone"
                        stackId="a"
                        dataKey={comp.key}
                        hide={selectedData[comp.key]} // args[0]: Selected Data on chart
                        fill={colors[comp.metric] ?? genericGraphColors[index]} // args[1] : themeColor
                        onClick={(data) => drillDownHandler(data.xVal, widgetInfo)} // args[2]: drilldown function, args[3]: widgetCongig object
                        cursor={showPointerCursor ? 'pointer' : 'default'}
                    />
                ));
            case TimeSeriesWidgetChartTypes.Area:
                return chartDataComponents.map((comp, index) => (
                    <Area
                        key={`area-${comp.key}`}
                        type="monotone"
                        name={comp.label}
                        dataKey={comp.key}
                        stroke={colors[comp.metric] ?? genericGraphColors[index]} // args[1] : themeColor
                        hide={selectedData[comp.key]} // args[0]: Selected Data on chart
                        fill={`url(#color${index})`}
                        fillOpacity={1}
                    />
                ));
            default:
                return chartDataComponents.map((comp, index) => (
                    <Line
                        key={`line-${comp.key}`}
                        name={comp.label}
                        type="monotone"
                        dataKey={comp.key}
                        stroke={colors[comp.metric] ?? genericGraphColors[index]} // args[1] : themeColor
                        hide={selectedData[comp.key]} // args[0]: Selected Data on chart
                        cursor={showPointerCursor ? 'pointer' : 'default'}
                    />
                ));
        }
    };

    const getUpdatedWidgetConfig = (widgetConfig) => {
        const commonKeys = {
            width: '99%',
            height: '99%',
            dataKey: 'xVal',
            tick: theme.palette.mode === 'dark' ? { fill: '#a0a9c0' } : {},
            labelValue: widgetConfig.yAxisCaption,
            margin: {
                top: 10,
                right: 20,
                left: 20,
                bottom: 5
            },
            yAxisWidth: widgetConfig.yAxisCaption === lengthOfTimeLabel ? 80 : 50
        };
        switch (widget.config.graphType) {
            case TimeSeriesWidgetChartTypes.VerticalBar:
                return {
                    ...widgetConfig,
                    ...commonKeys
                };
            case TimeSeriesWidgetChartTypes.HorizontalBar:
                return {
                    ...widgetConfig,
                    ...commonKeys,
                    yAxisTickFormatter: (value) =>
                        widgetConfig?.metricCategory?.toLowerCase()?.includes('time') ? getHHmmssStringFromSeconds(value) : value,
                    labelPosition: 'left',
                    yAxisWidth: widgetConfig.groupTimeBy === TimeSeriesGroupTimeByOptions.Hours ? 50 : 80
                };
            case TimeSeriesWidgetChartTypes.Area:
                return {
                    ...widgetConfig,
                    ...commonKeys,
                    yAxisTickFormatter: (value) =>
                        widgetConfig?.metricCategory?.toLowerCase()?.includes('time') ? getHHmmssStringFromSeconds(value) : value,
                    chartData: chartDataComponents
                };
            default:
                return {
                    ...widgetConfig,
                    ...commonKeys,
                    yAxisTickFormatter: (value) =>
                        widgetConfig?.metricCategory?.toLowerCase()?.includes('time') ? getHHmmssStringFromSeconds(value) : value
                };
        }
    };

    const chartComponent = () => {
        switch (widget.config.graphType) {
            case TimeSeriesWidgetChartTypes.VerticalBar:
                /**
                 * Bar Chart (Vertical)
                 */
                return (
                    <SimpleBarChart
                        data={chartData}
                        colors={getMappedColorsForQueueDashboardWidgets(previewWidgetConfig.groupDataBy)}
                        widgetConfig={getUpdatedWidgetConfig(previewWidgetConfig)}
                        handleDrillDown={handleDrillDown}
                        generateGraphics={generateChartGraphics}
                        generateTooltip={generateToolTipProp}
                        showPointerCursor={showPointerCursor}
                    />
                );
            case TimeSeriesWidgetChartTypes.HorizontalBar:
                /**
                 * Bar Chart (Horizontal)
                 */
                return (
                    <HorizontalStackedBarChart
                        data={chartData}
                        colors={getMappedColorsForQueueDashboardWidgets(previewWidgetConfig.groupDataBy)}
                        widgetConfig={getUpdatedWidgetConfig(previewWidgetConfig)}
                        handleDrillDown={handleDrillDown}
                        generateGraphics={generateChartGraphics}
                        generateTooltip={generateToolTipProp}
                        showPointerCursor={showPointerCursor}
                    />
                );
            case TimeSeriesWidgetChartTypes.Area:
                /**
                 * Area Chart
                 */
                return (
                    <BasicAreaChart
                        data={chartData}
                        colors={getMappedColorsForQueueDashboardWidgets(previewWidgetConfig.groupDataBy)}
                        widgetConfig={getUpdatedWidgetConfig(previewWidgetConfig)}
                        handleDrillDown={handleDrillDown}
                        generateGraphics={generateChartGraphics}
                        generateTooltip={generateToolTipProp}
                        showPointerCursor={showPointerCursor}
                        generateGradients={generateGradients}
                    />
                );

            /**
             * Line Chart
             */
            default:
                return (
                    <BasicLineChart
                        data={chartData}
                        colors={getMappedColorsForQueueDashboardWidgets(previewWidgetConfig.groupDataBy)}
                        widgetConfig={getUpdatedWidgetConfig(previewWidgetConfig)}
                        handleDrillDown={handleDrillDown}
                        generateGraphics={generateChartGraphics}
                        generateTooltip={generateToolTipProp}
                        showPointerCursor={showPointerCursor}
                    />
                );
        }
    };

    let widgetBodyContent;
    if (isLoading) {
        widgetBodyContent = <div className="loading-data-view">Loading data...</div>;
    } else if (errorMessage) {
        widgetBodyContent = <div className="no-data-view">{errorMessage}</div>;
    } else if (chartData.length > 0) {
        widgetBodyContent = chartComponent();
    } else {
        widgetBodyContent = <div className="no-data-view">No data for the selected date range and filters</div>;
    }

    return (
        <MainCard content={false} sx={{ height: 1, borderColor: 'transparent' }} boxShadow shadow={theme.shadows[16]}>
            <CardContent sx={{ height: 1, pt: 0, pl: 2, pb: '10px!important' }}>
                <Grid container sx={{ height: 1, position: 'relative' }}>
                    <Grid item xs>
                        <Grid container flexDirection="row" justifyContent="space-between" alignItems="center">
                            <Grid item>
                                <p className="timeseries-widget-title" style={theme.palette.mode === 'dark' ? { color: '#D7E6EC' } : null}>
                                    {widget.name}
                                </p>
                            </Grid>
                            <Grid item>
                                <Grid container flexDirection="row" justifyContent="flex-end" alignItems="center">
                                    {previewWidgetConfig?.drillDownMode && !refreshing && (
                                        <Grid item>{getExitPreviewButton(exitPreview)}</Grid>
                                    )}
                                    {previewWidgetConfig?.drillDownMode && !refreshing && previousConfigsRef.current.length > 1 && (
                                        <Grid item>{getPreviousLevelButton(drillLevelUp)}</Grid>
                                    )}

                                    {!isInEditMode && refreshing && <Grid item>{getRefreshingWidgetDataAnimateIcon(widget.id)}</Grid>}
                                    {isInEditMode && !savingDashboard && (
                                        <Grid item>
                                            <EditWidgetMenu onEditClick={toggleEditTimeSeriesDialogOpen} widgetId={widget.id} />
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {widgetBodyContent}
                    </Grid>
                </Grid>

                {isEditTimeSeriesDialogOpen && (
                    <EditTimeSeriesWidgetDialog
                        isEditTimeSeriesDialogOpen={isEditTimeSeriesDialogOpen}
                        toggleEditTimeSeriesDialogOpen={toggleEditTimeSeriesDialogOpen}
                        widget={widget}
                        onWidgetConfigUpdate={onWidgetConfigUpdate}
                    />
                )}
            </CardContent>
        </MainCard>
    );
}
