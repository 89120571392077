import { createSlice } from '@reduxjs/toolkit';

// initial state
export const initialState = {
    selectedItem: ['dashboard'],
    selectedID: null,
    drawerOpen: false,
    hasQueueRoles: false,
    hasLicensedQueuesAccess: false
};

// ==============================|| SLICE - MENU ||============================== //

const menu = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        activeItem(state, action) {
            state.selectedItem = action.payload;
        },

        activeID(state, action) {
            state.selectedID = action.payload;
        },

        openDrawer(state, action) {
            state.drawerOpen = action.payload;
        },
        setHasQueueRoles(state, action) {
            state.hasQueueRoles = action.payload;
        },
        setHasLicensedQueuesAccess(state, action) {
            state.hasLicensedQueuesAccess = action.payload;
        }
    }
});

export default menu.reducer;

export const { activeItem, openDrawer, activeID, setHasQueueRoles, setHasLicensedQueuesAccess } = menu.actions;
