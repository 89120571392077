import React, { useState } from 'react';
import { BarChart, Bar, Label, XAxis, YAxis, Legend, ResponsiveContainer } from 'recharts';

// material-ui
import { useTheme } from '@mui/material/styles';

// project imports
import { getInitialBarProps } from 'utils/graphUtils';
import CustomLegend from './CustomLegend';

const SimpleBarChart = ({ data, widgetConfig, colors, handleDrillDown, generateTooltip, generateGraphics, showPointerCursor }) => {
    const theme = useTheme();
    const themeColors = theme.palette.mode === 'dark' ? colors.dark : colors.light;
    const [barProps, setBarProps] = useState(getInitialBarProps(data));

    const handleLegendClick = (e) => {
        const selectedProp = e.dataKey;
        setBarProps({
            ...barProps,
            [selectedProp]: !barProps[e.dataKey]
        });
    };

    return (
        <ResponsiveContainer width={widgetConfig.width} height={widgetConfig.height}>
            <BarChart data={data} margin={widgetConfig.margin}>
                <XAxis dataKey={widgetConfig.dataKey} tickFormatter={widgetConfig.xAxisTickFormatter} tick={widgetConfig.tick} />
                <YAxis axisLine={false} tickFormatter={widgetConfig.yAxisTickFormatter} tick={widgetConfig.tick}>
                    <Label
                        offset={8}
                        value={widgetConfig.labelValue}
                        angle={-90}
                        position={{ x: '5%', y: '25%' }}
                        style={{ fill: theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.grey[700] }}
                        id={`yAxisLabel${widgetConfig?.id}`}
                    />
                </YAxis>
                {generateTooltip(themeColors)}
                <Legend content={<CustomLegend onLegendClick={handleLegendClick} selectedData={barProps} />} />
                {generateGraphics(barProps, themeColors, handleDrillDown, widgetConfig)}
            </BarChart>
        </ResponsiveContainer>
    );
};

export default SimpleBarChart;
