import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const appInsightsConfig = {
    appInsights: null
};

if (process.env.REACT_APP_AZURE_APP_INSIGHTS_CONNECTION_STRING) {
    appInsightsConfig.appInsights = new ApplicationInsights({
        config: {
            connectionString: process.env.REACT_APP_AZURE_APP_INSIGHTS_CONNECTION_STRING
            // enableAutoRouteTracking: true // option to log all route changes automatically
        }
    });

    appInsightsConfig.appInsights.loadAppInsights();
    appInsightsConfig.appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview
}

export default appInsightsConfig;
